import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment/moment';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router';

const ButtonThird = ({ nlatestReferalListData }) => {

  const navigate = useNavigate()
  const selector = useSelector(state => state);

  return (
    <div className='overflow-x-auto'>
      <Loader loading={selector?.Dashboard?.loading} />
      <div className='lg:block hidden'>
        <div className='flex justify-between items-center p-2'>
          <h2 className='ttext-base font-semibold text-blue-950'>Latest Referrals</h2>
          <button className="text-end font-bold text-blue-900 cursor-pointer underline" onClick={() => navigate("/app/referral-appSetting")}>View all</button>
        </div>
        <table class="w-full text-left rtl:text-right bg-white text-[#313131] rounded-lg">
          <thead class=" text-[#313131] font-semibold border-b border-[#D1D1D1]">
            <tr className="border-b capitalize">
              <th class="px-6 py-4 whitespace-nowrap">Referral By</th>
              <th class="px-6 py-4 whitespace-nowrap">Referral To</th>
              <th class="px-6 py-4 whitespace-nowrap">City</th>
            </tr>
          </thead>
          <tbody className='rounded-lg'>
            {
              nlatestReferalListData && nlatestReferalListData.length > 0 ? (nlatestReferalListData?.map((item, id) => (
                <tr class="bg-white capitalize" key={id}>
                  <td class="font-[400] text-[12px] text-black py-3 px-6  gap-2">{item?.refFrom?.name} <br></br> <span>{item?.refFrom?.mobNo}</span></td>
                  <td class="px-6 py-3 text-[12px]">{item?.userId?.name} <br></br> <span>{item?.userId?.mobNo}</span></td>
                  <td class="px-6 py-3 text-[12px]">{item?.city?.name || 'N/A'}</td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={5} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>



      <section className="space-y-5 py-10 lg:hidden block">
        <h2>Latest Referrals</h2>
        {nlatestReferalListData && nlatestReferalListData.length > 0 ? (
          nlatestReferalListData?.map((item) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Referral By </p>
                  </div>
                  <div className='w-full'>
                    <div className=" items-center  gap-2 capitalize">
                      {item?.refFrom?.name
                      } <br></br> <span>{item?.refFrom?.mobNo}</span>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    {item?.city?.name || 'N/A'}
                  </div>
                </div>

                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Referral To
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.userId?.name} <br></br> <span>{item?.userId?.mobNo}</span>
                    </p>
                  </div>
                </div>
              </>
            </div>
          ))
        ) : (<p className="text-center">Data Not Available</p>)}
        <div className="bg-white text-end pr-6 py-5 font-bold underline text-blue-950 cursor-pointer" >
          <button onClick={() => navigate("/app/referral-appSetting")} >
            View all
          </button>
        </div>
      </section>

    </div>
  )
}

export default ButtonThird
