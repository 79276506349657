import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAction } from "../../_actions/dashboard.action";
import moment from "moment";

const ButtonSecond = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  

  let DemoRequestListData =
    selector?.Dashboard?.getContactList?.data?.list;

  useEffect(() => {
    let demoDoneListData = {
      "cityId": "",
      "keyWord": "",
      "pageNo": 1,
      "size": 7,
      "status": null,
      "userType": 'PARENT'
    }
    dispatch(dashboardAction.getContactSeenList(demoDoneListData))
  }, []);
  return (
    <div className="overflow-x-auto">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-6">
        <thead class=" text-[#313131] text-center font-bold  border-b-2 border-[#D1D1D1]">
          <tr className="border-b">
            <th scope="col" class="px-6 py-3">
              User
            </th>
            <th scope="col" class="px-6 py-3">
              Open Contact Details
            </th>
            <th scope="col" class="px-6 py-3">
              City
            </th>
            <th scope="col" class="px-6 py-3">
              Date
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          {DemoRequestListData &&
            DemoRequestListData?.map((item, id) => (
              <tr class="bg-white capitalize">
                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                  <p>{item?.userId?.name ?? 'No User'}</p>
                  <p>{item?.userId?.mobNo ?? 'No User'}</p>
                  <p>{item?.userType ?? 'No User'}</p>
                </td>
                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                  <p>{item?.parentId?.name ?? 'No User'}</p>
                  <p>{item?.parentId?.mobNo ?? 'No User'}</p>
                  <p>{'PARENT'}</p>
                </td>
                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">{item?.cityId?.name ?? '-'}</td>
                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">{moment(item?.createdAt).format('DD-MMM-YYYY') ?? '-'}</td>

              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ButtonSecond;
