/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, parentAction, tutortoliveAction, userListAction } from "../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment/moment";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaAngleRight, FaChevronRight } from "react-icons/fa";
import "./Tutor.css";
import { tutionAction } from "../../_actions";
import { confirmAlert } from "react-confirm-alert";
import { MdOutlineClose } from "react-icons/md";
import { FaChevronCircleRight } from "react-icons/fa";
import Loader from "../../components/Loader";
import { statusWriteFormat, teachModeViewData, timeZoneReturnDateType, truncateText } from "../../_helpers";
import KycViewModel from "./Model/KycViewModel";
import ImagePreviewModal from "./Model/ImagePreview";
import DetailsPreViewModel from "./Model/DetailsPreViewModel";
import ViewAndUpdate from "./Model/ViewAndUpdate";
import { tutorUpdateInitialState } from "../../_helpers/dataInitialState";
import FilterComponent from "./Model/FilterComponent";


const DeletedTutors = ({ selectedCities }) => {
	const dispatch = useDispatch();
	const selector = useSelector((state) => state);
	const [blockedTutorData, setblockedTutorData] = useState();
	const [action, setAction] = useState(null);
	const [history, setHistory] = useState(false);
	const [appliedModal, setAppliedModal] = useState(false);
	const [demoDoneModal, setDemoDoneModal] = useState(false);
	const [completedTuitionModal, setcompletedTuitionModal] = useState(false);
	const [demoDoneList, setDemoDoneList] = useState("");
	const [completedTuition, setCompletedTuition] = useState("");
	const [appliedTuitionData, setAppliedTuitionData] = useState("");
	const [filters, setFilters] = useState({
		pinCode: '',
		gender: '',
		classId: '',
		subjectId: '',
		teachingMode: ''
	});

	const onClose = () => {
		setAppliedModal(false);
		setDemoDoneModal(false);
		setcompletedTuitionModal(false);
	};
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const pageSize = 10;
	const [allPincodeList, setAllPincodeList] = useState("");
	const [allClassListByAdminData, setAllClassListByAdminData] = useState([]);
	const [subjectListData, setSubjectListData] = useState()
	const [fullData, setFullData] = useState('')
	const [kycDataShow, setKycDataShow] = useState(false);
	const [kycListData, setKycListData] = useState();
	const menuRef = useRef(null);
	const mobileRef = useRef(null)
	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setAction(null);
		}
		if (mobileRef.current && !mobileRef.current.contains(event.target)) {
			setAction(null);
		}
	};


	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		let resData = {
			status: "DELETE",
			userType: "TUTOR",
			keyWord: "",
			fromDate: "",
			toDate: "",
			sortOrder: "desc",
			sortBy: "createdAt",
			pageNo: currentPage,
			size: pageSize,
			isDeleted: true
		}
		if (selectedCities != "") {
			resData["cityId"] = [selectedCities]
		}
		dispatch(userListAction.getUserList(resData));
		dispatch(tutionAction.getAllCityList());
		dispatch(tutionAction.gitAllClassListByAdmin());
	}, [currentPage, selectedCities]);

	useEffect(() => {
		if (selector?.userList?.userList?.data?.total) {
			setTotalPages(
				Math.ceil(selector?.userList?.userList?.data?.total / pageSize)
			);
		}
		setKycListData(selector?.userList?.userKycListByUserId?.data);
		setblockedTutorData(() => selector?.userList?.userList?.data?.demoList);
		setAllClassListByAdminData(selector?.tution?.getAllClassList?.data);
		setSubjectListData(selector?.userList?.getAllSubjectList?.data)
		setFullData(() => selector?.userList?.getTutorUpdateById?.data)
		setAppliedTuitionData(() => selector?.userList?.appliedTutionList?.data);
		setDemoDoneList(() => selector?.userList?.demoDoneList?.data);
		setCompletedTuition(() => selector?.userList?.completeTutionList?.data);
	}, [selector]);

	useEffect(() => {
		setAllPincodeList(selector?.Citys?.cityGetList?.find(city => city._id === selectedCities)?.pinCode ?? []);
	}, [selectedCities])

	const handlePreviousPage = () => {
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) setCurrentPage(currentPage + 1);
	};


	const toggleAction = (id) => {
		setAction(action === id ? null : id);
	};

	const showHistory = (resDataId) => {
		setHistory((prevresDataId) =>
			prevresDataId === resDataId ? null : resDataId
		);
		setAction(false);
	};

	const kycDetailModal = (data) => {
		let kycData = {
			id: data?._id,
		};
		setKycDataShow(true);
		dispatch(userListAction.getKycByUserId(kycData));

	};

	const handleAppliedTution = (data) => {
		setAppliedModal(true);

		let appliedData = {
			tutorId: data._id,
		};
		dispatch(userListAction.getAppliedTutionList(appliedData));
	};
	const handleDemoDone = (data) => {
		setDemoDoneModal(true);
		let demoDoneData = {
			tutorId: data._id,
		};
		dispatch(userListAction.getDemoDoneList(demoDoneData));
	};

	const handleCompleteTution = (data) => {
		setcompletedTuitionModal(true);
		let completeDemoData = {
			tutorId: data._id,
		};
		dispatch(userListAction.completeTutionList(completeDemoData));
	};


	const handleFilterSearchSearch = (data) => {
		let resData = {
			status: "BLOCK",
			userType: "TUTOR",
			keyWord: "",
			fromDate: "",
			toDate: "",
			sortOrder: "desc",
			sortBy: "createdAt",
			pageNo: currentPage,
			size: pageSize,
			...filters,
			...data
		};
		if (selectedCities != "") {
			resData["cityId"] = [selectedCities]
		}
		setTimeout(() => {
			dispatch(userListAction.getUserList(resData));
		}, 500)
	}


	const [isModalVisible, setIsModalVisible] = useState(false);

	const [image, setImage] = useState("")

	const handleCloseFunctionImageModel = (data) => {
		if (data) {
			setImage(() => data)
		}
		else {
			setImage("")
		}
		setIsModalVisible(!isModalVisible);
	};


	const [isModalDetails, setIsModalDetails] = useState(false);
	const handelDetailsModel = (data) => {
		if (data) {
			let tutorId = {
				id: data._id
			}
			tutortoliveAction.getTutorByPromiseId(tutorId).then((response) => {
				if (response.data) {
					setFullData(response.data)
					setIsModalDetails(!isModalDetails)
				}
			})
		} else {
			setFullData({})
			setIsModalDetails(!isModalDetails)
		}
	}

	const handleRevokeAccount = (data) => {
		confirmAlert({
			title: `Revoke Account`,
			message: `Are you sure to perform this action?`,
			buttons: [
				{
					label: 'Yes',
					onClick: () => {
						parentAction.revokeUserAccountByAdminPromise({ userId: data._id }).then(res => {
							dispatch(alertActions.success("Success."))

						}).catch(err => {
							dispatch(alertActions.error("Please try again later."))
						})
						let resData = {
							status: "DELETE",
							userType: "TUTOR",
							keyWord: "",
							fromDate: "",
							toDate: "",
							sortOrder: "desc",
							sortBy: "createdAt",
							pageNo: currentPage,
							size: pageSize,
							isDeleted: true
						}
						if (selectedCities != "") {
							resData["cityId"] = [selectedCities]
						}
						dispatch(userListAction.getUserList(resData));

					}

				},
				{
					label: 'No'
				}
			]
		});
		setAction(null);


	}
	return (
		<>

			<section className="">
				<Loader loading={selector?.userList?.loading} />

				<FilterComponent allClassListByAdminData={allClassListByAdminData} allPincodeList={allPincodeList} subjectListData={subjectListData} filters={filters} setFilters={setFilters} handleFilterSearchSearch={handleFilterSearchSearch} />


				<div className="lg:block hidden">

					<div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
						<table className="min-w-full table-autorounded-xl overflow-x-auto">
							<thead className="">
								<tr className="border-b border-[#D3D3D3] capitalize">
									{/* <th className="px-6 py-3 whitespace-nowrap text-sm text-black">#</th> */}
									<th className="px-6 py-3 whitespace-nowrap text-left text-sm text-black">Tutor Name</th>
									<th className="px-6 py-3 whitespace-nowrap text-sm text-black">City</th>
									<th className="px-6 py-3 whitespace-nowrap text-sm text-black">KYC</th>
									<th className="px-6 py-3 whitespace-nowrap text-sm text-black">Signup Date</th>
									<th className="px-6 py-3 whitespace-nowrap text-sm text-black">History</th>
									<th className="px-6 py-3 whitespace-nowrap text-sm text-black">Action</th>
								</tr>
							</thead>
							<tbody>
								{blockedTutorData && blockedTutorData.length > 0 ? (
									blockedTutorData?.map((itm, index) => (
										<tr key={index} className="table_row capitalize border-b">
											{/* <td className="px-4 py-4 text-sm text-gray-900 text-center">{index + 1}</td> */}

											<td className="px-4 py-4 flex items-center  gap-4 text-sm text-gray-900">
												<div className="flex items-center justify-center gap-2">
													<span onClick={itm?.image ? () => handleCloseFunctionImageModel(itm?.image) : null}>
														{itm?.image && itm?.image ?
															<img className="rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
																src={
																	itm?.image}
																alt="User"
															/> : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
																<span className="text-base font-semibold text-[#033565]">
																	{itm?.name ? (itm.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
															</div>}
													</span>
													<div className="w-24">
														<p className="font-semibold whitespace-nowrap text-[#023565]">{itm?.name ?? "N/A"}</p>
														<p className="text-gray-500 whitespace-nowrap">{itm?.mobNo ?? "No Contact"}</p>
														<div className="flex items-center gap-1 cursor-pointer pt-1" onClick={() => handelDetailsModel(itm)}>
															<p className="text-base font-normal cursor-pointer">More</p>
															<FaChevronCircleRight
																className="text-[#023565] text-base cursor-pointer" />
														</div>
													</div>
												</div>
											</td>

											<td className="px-4 py-4 text-sm text-gray-900 text-center">{itm?.city?.name ?? "No City"}</td>
											<td className="px-4 py-4 text-sm text-[#023565]">
												<span className={`text-center flex items-center justify-center ${itm?.kycStatus !== 0 ? "underline hover:text-red-500 cursor-pointer font-semibold" : ""} relative`} onClick={itm?.kycStatus !== 0 ? () => kycDetailModal(itm) : null}> {itm?.kycStatus !== 0 ? "View" : "N/A"}</span>
											</td>
											<td className="px-4 py-4 text-sm text-gray-900 text-center">
												{itm?.createdAt ? moment(itm?.createdAt).format('DD-MMM-YYYY') : "N/A"}
											</td>

											<td className="px-4 py-4 text-gray-900 relative table-cell">
												<div onClick={() => showHistory(itm._id)} className="relative font-semibold text-sm flex justify-center items-center cursor-pointer hover:text-red-500 underline">View</div>
												{history && history === itm._id && (
													<div className="absolute right-16 mt-1 w-48 bg-white rounded-lg shadow-lg z-10" ref={menuRef}>
														<ul className="bg-white text-sm font-bold rounded-xl border divide-y">
															<li
																className="px-4 py-2 text-[#C60E6B] cursor-pointer hover:bg-gray-100"
																onClick={() => handleAppliedTution(itm)}
															>
																Applied Tuitions
															</li>
															<li
																className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
																onClick={() => handleDemoDone(itm)}
															>
																Demo Done
															</li>
															<li
																className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
																onClick={() => handleCompleteTution(itm)}
															>
																Completed Tuitions
															</li>
														</ul>
													</div>
												)}
											</td>

											<td className="px-4 py-4 text-gray-900 relative table-cell">
												<span className="flex justify-center items-center">
													<BsThreeDotsVertical onClick={() => toggleAction(itm._id)} className="text-2xl cursor-pointer text-red-500 " />
												</span>

												{action === itm._id && (
													<div className="absolute right-16 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10" ref={menuRef}>
														<ul className=" text-sm font-bold text-[#023565]">
															<li
																className="flex justify-between items-center cursor-pointer hover:bg-gray-100 p-3 hover:text-[#E4006F] "
																onClick={() => handleRevokeAccount(itm)}
															>
																<span className="text-[#023565] text-sm font-semibold">
																	Revoke Account
																</span>
																<FaAngleRight className="hover:text-[#C60E6B]" />
															</li>
														</ul>
													</div>
												)}
											</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan={9} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>

				{totalPages > 1 && (
					<div className="flex justify-end items-center py-2 px-2">
						<button
							className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
								}`}
							onClick={handlePreviousPage}
							disabled={currentPage === 1}
						>
							<MdOutlineKeyboardArrowLeft />
						</button>
						<span className="text-gray-700 mx-1">
							<span className="border px-4 py-2 text-black rounded">
								{currentPage}
							</span>{" "}
							of {totalPages}
						</span>
						<button
							className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
								}`}
							onClick={handleNextPage}
							disabled={currentPage === totalPages}
						>
							<FaChevronRight />
						</button>
					</div>
				)}

				<KycViewModel kycDataShow={kycDataShow} setKycDataShow={setKycDataShow} kycListData={kycListData} />
				<ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />

				{appliedModal && (
					<>
						<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
							<div className="relative w-11/12 mx-auto lg:w-7/12">
								<button
									className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
									onClick={onClose}
								>
									<span><MdOutlineClose /></span> Close
								</button>
								<div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
									<div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
										<h2 className="text-lg font-semibold">History of applied tuition</h2>
									</div>
									<div className="relative lg:h-[400px] overflow-y-auto">
										<div className="overflow-x-auto overflow-hidden p-4">
											<table className="table_full w-full border border-gray-200">
												<thead>
													<tr className="text-center bg-gray-100 border-b">
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															Parent Name
														</th>
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															Student Name
														</th>
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															City
														</th>
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															Class
														</th>
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															Number Of Classe
														</th>
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															TuitionFee
														</th>
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															Tutor Fee
														</th>
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															Company Share
														</th>
														<th className="py-2 px-4 text-left border whitespace-nowrap">
															CreatedAt
														</th>
													</tr>
												</thead>
												<tbody>
													{appliedTuitionData && appliedTuitionData.length > 0 ? (
														appliedTuitionData.map((itm, index) => (
															<tr key={index} className="border-b capitalize">
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm.parentId?.name ?? "N/A"}
																</td>
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm.bookFreeDemo?.map((std, idx) => (
																		<span key={idx}>
																			{std?.studentId?.studentFullName ?? "N/A"}
																		</span>
																	))}
																</td>
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm.cityId?.name ?? "No City"}
																</td>
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm.bookFreeDemo?.map((cls, idx) => (
																		<span key={idx}>
																			{cls?.classId?.name ?? "N/A"}
																		</span>
																	))}
																</td>
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm.numberOfClasses ?? "N/A"}
																</td>
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm.tuitionFee ?? "N/A"}
																</td>
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm.tutorFee ?? "N/A"}
																</td>
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm.companyShare ?? "N/A"}
																</td>
																<td className="py-2 px-4 border whitespace-nowrap">
																	{itm?.createdAt ? moment(itm?.createdAt).format("DD-MM-YYYY") : "No DOB"}
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="5" className="py-2 px-4 border-b">
																No Data Available
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}

				{demoDoneModal && (
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
						<div className="relative w-11/12 mx-auto lg:w-7/12">




							<button
								className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
								onClick={onClose}
							>
								<span><MdOutlineClose /></span> Close
							</button>
							<div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								<div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h2 className="text-lg font-semibold">History of Demo Done</h2>
								</div>
								<div className="relative lg:h-[400px] overflow-y-auto">

									<div className="overflow-x-auto overflow-hidden p-4">

										<table className="table_full w-full border border-gray-200">
											<thead>
												<tr className="text-center bg-gray-100 border-b capitalize">
													<th className="py-2 px-4 text-left border whitespace-nowrap">Parent Name</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">Student Name</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">City</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">Class</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">number Of Classes</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">tuition Fee</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">tutor Fee</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">company Share</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">createdAt</th>
												</tr>
											</thead>
											<tbody>
												{demoDoneList && demoDoneList.length > 0 ? (
													demoDoneList.map((itm, index) => (
														<tr key={index} className="border-b capitalize">
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.parentId?.name ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.bookFreeDemo?.map((std, idx) => (
																	<span key={idx}>
																		{std?.studentId?.studentFullName ?? "N/A"}
																	</span>
																))}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.cityId?.name ?? "No City"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.bookFreeDemo?.map((cls, idx) => (
																	<span key={idx}>
																		{cls?.classId?.name ?? "N/A"}
																	</span>
																))}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.numberOfClasses ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.tuitionFee ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.tutorFee ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.companyShare ?? "N/A"}
															</td>

															<td className="py-2 px-4 border whitespace-nowrap">
																{moment(itm.createdAt).format("DD-MM-YY hh:mm A") ??
																	"N/A"}
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="5" className="py-2 px-4 border whitespace-nowrap">
															No Data Available
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}


				{completedTuitionModal && (
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
						<div className="relative w-11/12 mx-auto lg:w-7/12">
							<button
								className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
								onClick={onClose}
							>
								<span><MdOutlineClose /></span> Close
							</button>
							<div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								<div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h2 className="text-lg font-semibold">History of Completed Tuition</h2>
								</div>
								<div className="relative lg:h-[400px] overflow-y-auto">
									<div className="overflow-x-auto overflow-hidden p-4">
										<table className="table_full w-full border border-gray-200">
											<thead>
												<tr className="text-center bg-gray-100 border-b capitalize">
													<th className="py-2 px-4 text-left border whitespace-nowrap">Parent Name</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">Student Name</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">City</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">Class</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">Number Of Classes</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">tuition Fee</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">tutor Fee</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">company Share</th>
													<th className="py-2 px-4 text-left border whitespace-nowrap">createdAt</th>
												</tr>
											</thead>
											<tbody>
												{completedTuition && completedTuition.length > 0 ? (
													completedTuition.map((itm, index) => (
														<tr key={index}>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.parentId?.name ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.bookFreeDemo?.map((std, idx) => (
																	<span key={idx}>
																		{std?.studentId?.studentFullName ?? "N/A"}
																	</span>
																))}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.cityId?.name ?? "No City"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.bookFreeDemo?.map((cls, idx) => (
																	<span key={idx}>
																		{cls?.classId?.name ?? "N/A"}
																	</span>
																))}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.numberOfClasses ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.tuitionFee ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.tutorFee ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.companyShare ?? "N/A"}
															</td>
															<td className="py-2 px-4 border whitespace-nowrap">
																{itm.createdAt ? timeZoneReturnDateType(itm.createdAt) : "N/A"}
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="5" className="py-2 px-4 border whitespace-nowrap">
															No Data Available
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}



				<DetailsPreViewModel isModalOpen={isModalDetails} closeModal={handelDetailsModel} response={fullData} />

				<section className="space-y-5 py-10 lg:hidden block">
					{blockedTutorData && blockedTutorData.length > 0 ? (
						blockedTutorData?.map((itm, id) => (
							<div key={id} className=' bg-white p-4 rounded-[10px]'>
								<>
									<div className='flex justify-between p-2 w-full'>
										<div className='w-full'>
											<p className='text-[16px] font-[700] text-left'>Tutor Name</p>
										</div>
										<div className='w-full'>
											<div className="flex items-center  gap-2 capitalize">
												<img
													className="w-12 h-12 rounded"
													src={
														itm?.image ??
														"https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
													}
													alt="User"
												/>
												<div>
													<div className="text-xs font-medium text-black ">{itm?.name ?? "No Data"}</div>
													<div className="text-xs text-gray-500">{itm?.mobNo || 'No Number'}</div>
													<FaChevronCircleRight
														className="text-[#023565] cursor-pointer"
														onClick={() => handelDetailsModel(itm)}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='flex justify-between p-2 w-full'>
										<div className='w-full'>
											<p className='text-[16px] font-[700] text-left'>City</p>
										</div>
										<div className='w-full'>
											<p className='text-[14px] font-[400]' >{itm?.city?.name ?? "No City"}</p>
										</div>
									</div>
									<div className='flex justify-between p-2 w-full'>
										<div className='w-full'>
											<p className='text-[16px] font-[700] text-left'>  Signup Date</p>
										</div>
										<div className='w-full'>
											<p className='text-[14px] font-[400]'>  {moment(itm?.createdAt).format("DD-MMM-YYYY")}</p>
										</div>
									</div>

									<div className='flex justify-between p-2 w-full'>
										<div className='w-full'>
											<p className='text-[16px] font-[700] text-left'>History</p>
										</div>
										<div className='w-full relative'>
											<span onClick={() => showHistory(itm._id)}>View</span>
											{history && history === itm._id && (
												<div
													className="absolute top-14 right-1 forfontsmall w-44 shadow-lg z-10"

												>
													<ul className="bg-white text-sm font-bold rounded-xl border">
														<li
															className="px-4 py-2 text-[#C60E6B] cursor-pointer hover:bg-gray-100"
															onClick={() => handleAppliedTution(itm)}
														>
															Applied Tuitions
														</li>
														<li
															className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
															onClick={() => handleDemoDone(itm)}
														>
															Demo Done
														</li>
														<li
															className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
															onClick={() => handleCompleteTution(itm)}
														>
															Completed Tuitions
														</li>
													</ul>
												</div>
											)}

										</div>
									</div>
									<div className='flex justify-between p-2 w-full'>
										<div className='w-full'>
											<p className='text-[16px] font-[700] text-left'>Action</p>
										</div>
										<div className='w-full'>
											<p className='text-[14px] font-[400] relative'>
												<BsThreeDotsVertical
													className="mx-auto text-2xl cursor-pointer text-[#C60E6B] "
													onClick={() => toggleAction(itm._id)}
												/>

												{action && action === itm._id && (
													<div
														className="absolute right-50 w-44  bg-white border border-gray-300 rounded-lg shadow-lg z-10"
													>
														<ul className=" text-sm font-bold ">
															<li
																className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
																onClick={() => handleRevokeAccount(itm)}
															>
																Revoke Account
															</li>
														</ul>
													</div>
												)}
											</p>
										</div>
									</div>
								</>
							</div>

						))

					) : (
						<p className="text-center">Data Not Found</p>
					)

					}
				</section>
			</section>
		</>
	);
};

export default DeletedTutors;
