import React, { useEffect, useState } from 'react'
import Button from './Button'
import { useSelector, useDispatch } from 'react-redux'
import ActiveTutor from './ActiveTutor'
import InActiveTutor from './InActiveTutor'
import BlockedTutors from './BlockedTutors'
import TutorHeader from './TutorHeader'
import { userListAction } from '../../_actions'
import DeletedTutors from './DeletedTutors'

const TutorProfile = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const selector = useSelector(state => state)
  const dispatch = useDispatch()
  const [active, setActive] = useState("Active Tutors")
  const buttonName = ["Active Tutors", "Inactive Tutors", "Blocked Tutors", "Deleted Tutors"]

  const handleClick = (data) => {
    setActive(data)
  }

  const [selectedCities, setSelectedCities] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")
  const [selectedData, setSelectedData] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [searchText, setSearchText] = useState()


  useEffect(() => {
    setSelectedData(() => selector?.userList?.getUserListForSearch?.data)
  }, [selector])



  useEffect(() => {
    let details = {
      "cityId": "",
      "userType": "TUTOR",
      "isDisable": false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])

  // const onSelect = (selectedList) => {
  //   setSelectCity(selectedList)

  // }


  function ParamDataSendFunc(active) {
    switch (active) {
      case "Active Tutors":
        return {
          status: "ACTIVE",
          userType: "TUTOR",

          fromDate: "",
          toDate: "",
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      case "Inactive Tutors":
        return {
          status: "INACTIVE",
          userType: "TUTOR",

          fromDate: "",
          toDate: "",
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      case "Blocked Tutors":
        return {
          status: "BLOCK",
          userType: "TUTOR",

          fromDate: "",
          toDate: "",
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      default:
        return {}
    }
  }


  const searchHandle = () => {
    const userData = (selectedCities && selectedCities.length > 0)
      ? selectedCities.map((ele) => ele.id)
      : [];
    if (userData.length == 0) {
      return
    }

    dispatch(userListAction.getUserList({ ...ParamDataSendFunc(active), userId: userData }));


  }

  // const onRemove = (selectedList) => {
  //   setSelectCity(selectedList)
  // }

  const handleSearch = (e) => {
    setSearchText(e.target.value)

    dispatch(userListAction.getUserList({ ...ParamDataSendFunc(active), keyWord: e.target.value }));

  }
  return (
    <>
      <TutorHeader searchText={searchText} handleSearch={handleSearch} selectedCities={selectedCities} setSelectedCities={setSelectedCities} roleId={roleId} />

      <section className='overflow-hidden overflow-y-auto h-screen'>
        <div className="m-5">
          <section className='flex gap-3'>

            {
              buttonName.map((item, id) => (
                <div className={`px-3 py-2 rounded-full lg:text-sm text-[10px] font-semibold ${active === item ? 'bg-[#023565] text-white' : 'bg-gray-400 text-white'}`} onClick={() => handleClick(item)}>
                  <Button key={id} name={item} />
                </div>
              ))
            }
          </section>
          <section>
            {active === "Active Tutors" && <ActiveTutor selectedCities={selectedCities} />}
            {active === "Inactive Tutors" && <InActiveTutor selectedCities={selectedCities} />}
            {active === "Blocked Tutors" && <BlockedTutors selectedCities={selectedCities} />}
            {active === "Deleted Tutors" && <DeletedTutors selectedCities={selectedCities} />}
          </section>
        </div>
      </section>
    </>
  )
}

export default TutorProfile