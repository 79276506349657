import { headerForPrivateAPI, APIcallFunction, APIcallFunctionForFile, headerForPrivateMediaAPI } from "../_helpers";
import { uploadImagConstants } from "../_constants";
import { alertActions } from "./alert.actions";

export const uploadImageAction = {
    UploadImage
};

function UploadImage(data) {
    const formData = new FormData()
    formData.append("image", data)
    const credentials = {
        header: headerForPrivateMediaAPI(),
        method: "POST",
        body: formData,
        endPoint: "uploadImage",
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunctionForFile(credentials).then(
            (user) => {
                //   dispatch(alertActions.success("Banner created"));
                dispatch(success(user));
                dispatch(alertActions.success("Uploaded"))
                //   dispatch(BannerGet(bannerSet));
            },
            (error) => {
                dispatch(failure(error));

            }
        );
    };
    function request() {
        return { type: uploadImagConstants.UPLOAD_IMAGE_REQUEST };
    }
    function success(user) {
        return { type: uploadImagConstants.UPLOAD_IMAGE_SUCCESS, user };
    }
    function failure(error) {
        return { type: uploadImagConstants.UPLOAD_IMAGE_FAILURE, error };
    }
}