import React, { useState, useEffect } from "react";
import { userListAction, tutionAction } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { FaFilterCircleXmark } from "react-icons/fa6";
import { RiEqualizerFill } from "react-icons/ri";


const tuitionFees = [
  { label: 'Tuition Fee', value: '' },
  { label: '1-2000', value: '1-2000' },
  { label: '2001-5000', value: '2001-5000' },
  { label: '5001-10000', value: '5001-10000' },
  { label: '10001-15000', value: '10001-15000' },
  { label: '15001-20000', value: '15001-20000' },
  { label: 'More than 20000', value: 'more-than-20000' }
];

const tutorFee = [
  { label: 'Tutor Fee', value: '' },
  { label: '1-2000', value: '1-2000' },
  { label: '2001-5000', value: '2001-5000' },
  { label: '5001-10000', value: '5001-10000' },
  { label: '10001-15000', value: '10001-15000' },
  { label: '15001-20000', value: '15001-20000' },
  { label: 'More than 20000', value: 'more-than-20000' }
];


const TuitionFilters = (props) => {
  let { filters, setFilters } = props;
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [tutorList, setTutorList] = useState([])
  const [classList, setClassList] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})
  const [subjectList, setSubjectList] = useState([])
  const [classReferenceList, setClassReferenceList] = useState([])
  const [leadMangerList, setLeadMangerList] = useState([])
  const [parentList, setParentList] = useState([])

  const OptionList = () => {
    const options = [{ label: 'Select Date', value: '' }];
    for (let i = 1; i <= 31; i++) {
      options.push({ value: `${i}`, label: i });
    }
    return options;
  };
  const OptionListForDate = () => {
    const options = [{ label: 'Select Date', value: '' }];
    for (let i = 1; i <= 31; i++) {
      const value = i < 10 ? `0${i}` : `${i}`;
      options.push({ value, label: i });
    }
    return options;
  };
  useEffect(() => {
    dispatch(userListAction.getAllTutorListWithNameOrId({}))
    dispatch(tutionAction.gitAllClassListByAdmin({}))
    dispatch(tutionAction.classReferenceList({}))
    dispatch(tutionAction.getAllLeadManger({}))
    userListAction.getAllParentListWithNameOrIdByPromise().then(res => setParentList(res.data)).catch(err => setParentList([]))
  }, [])

  useEffect(() => {
    setTutorList(() => selector?.userList?.getAllTutorListWithNameOrId?.data)
    setClassList(() => selector?.tution?.getAllClassList?.data)
    setClassReferenceList(() => selector?.tution?.classReferenceList?.data)
    setLeadMangerList(() => selector?.tution?.getAllLeadManger?.data)
  }, [selector])

  const handleClassChange = (id, selectedOption) => {
    const classSelected = classList.find((ele) => id === ele._id)
    setSubjectList(classSelected?.subjectId || []);
    setFilters((prevState) => ({
      ...prevState,
      classId: id,
      subjectId: ""
    }))
    setSelectedFilter((prevState) => ({
      ...prevState,
      classId: selectedOption,
      subjectId: { label: "Select Subject", value: "" }
    }))
  }

  const handleResetFilters = () => {
    let obj = { ...filters };
    Object.keys(obj).forEach(key => {
      obj[key] = "";
    });
    setFilters(obj)
    setSelectedFilter({})
    setSubjectList([])
  }



  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000',
      },
      borderRadius: '5px',
    }),
    menu: (provided) => ({
      ...provided,

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000 !important',
    }),
  };


  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="filter lg:flex justify-end flex-col md:flex-row gap-4 hidden">
        {(filters?.tuitionFee != null) &&
          <>
            <Select
              // id="tuitionFee"
              name="tuitionFee"
              className="capitalize w-full text-black text-sm z-40"
              styles={customStyles}
              classNamePrefix="react-select"
              value={selectedFilter?.tuitionFee || { label: 'Tuition Fee', value: '' }}
              options={tuitionFees}
              onChange={(selectedOption, actionMeta) => {
                setFilters((prevState) => ({
                  ...prevState,
                  tuitionFee: selectedOption.value
                }))
                setSelectedFilter((prevState) => ({
                  ...prevState,
                  tuitionFee: selectedOption
                }))
              }}
              placeholder="Tuition Fee"
            />
          </>}

        {(filters?.tutorFee != null) &&
          <>
            <Select
              id="tutorFee"
              name="tutorFee"
              className="capitalize w-full text-black text-sm z-40"
              styles={customStyles}
              classNamePrefix="react-select"
              value={selectedFilter?.tutorFee || { label: 'Tutor Fee', value: '' }}
              options={tutorFee}
              onChange={(selectedOption, actionMeta) => {
                setFilters((prevState) => ({
                  ...prevState,
                  tutorFee: selectedOption.value
                }))
                setSelectedFilter((prevState) => ({
                  ...prevState,
                  tutorFee: selectedOption
                }))
              }}
              placeholder="Tutor Fee"
            />

          </>}
        {(filters?.tutorId != null) &&

          <>
            <Select
              id="tutorId"
              name="Tutor Name"
              className="capitalize w-full text-black text-sm z-40"
              styles={customStyles}
              classNamePrefix="react-select"
              value={selectedFilter?.tutorId || { label: 'Select Tutor', value: '' }}
              options={tutorList && tutorList.length > 0
                ? [{ label: 'Select Tutor', value: '' }, ...tutorList.map((tutor) => ({
                  label: `${tutor.name} ${tutor.mobNo}`,
                  value: tutor._id,
                }))
                ]
                : []}
              onChange={(selectedOption, actionMeta) => {
                setFilters((prevState) => ({
                  ...prevState,
                  tutorId: selectedOption.value
                }))
                setSelectedFilter((prevState) => ({
                  ...prevState,
                  tutorId: selectedOption
                }))
              }}
              placeholder="Tutor Name"
            />

          </>
        }

        {(filters?.parentId != null) &&

          <>
            <Select
              id="parentId"
              name="Parent Name"
              className="capitalize w-full text-black text-sm z-40"
              styles={customStyles}
              classNamePrefix="react-select"
              value={selectedFilter?.parentId || { label: 'Select Parent', value: '' }}
              options={parentList && parentList.length > 0
                ? [{ label: 'Select Parent', value: '' }, ...parentList.map((parent) => ({
                  label: `${parent.name} ${parent.mobNo}`,
                  value: parent._id,
                }))
                ]
                : []}
              onChange={(selectedOption, actionMeta) => {
                setFilters((prevState) => ({
                  ...prevState,
                  parentId: selectedOption.value
                }))
                setSelectedFilter((prevState) => ({
                  ...prevState,
                  parentId: selectedOption
                }))
              }}
              placeholder="Parent Name"
            />

          </>
        }

        {
          (filters?.classReference != null) &&
          <>
            <Select
              id="classReference"
              name="classReference"
              className="capitalize w-full text-black text-sm z-40"
              styles={customStyles}
              classNamePrefix="react-select"
              value={selectedFilter?.classReference || { label: 'Select Class reference', value: '' }}
              options={classReferenceList && classReferenceList.length > 0
                ? [{ label: 'Select Class reference', value: '' }, ...classReferenceList.map((refer) => ({
                  label: refer,
                  value: refer,
                }))
                ]
                : []}
              onChange={(selectedOption, actionMeta) => {
                setFilters((prevState) => ({
                  ...prevState,
                  classReference: selectedOption.value
                }))
                setSelectedFilter((prevState) => ({
                  ...prevState,
                  classReference: selectedOption
                }))
              }}
              placeholder="Class Ref..."
            />
          </>
        }
        {
          (filters?.classId != null) &&

          <>
            <Select
              id="classId"
              name="classId"
              className="capitalize w-full text-black text-sm z-40"
              styles={customStyles}
              classNamePrefix="react-select"
              value={selectedFilter?.classId || { label: 'Select Class', value: '' }}
              options={classList && classList.length > 0
                ? [{ label: 'Select Class', value: '' }, ...classList.map((tutor) => ({
                  label: tutor.name.toUpperCase(),
                  value: tutor._id,
                }))
                ]
                : []}
              onChange={(selectedOption, actionMeta) => handleClassChange(selectedOption.value, selectedOption)}
              placeholder="Class"
            />

          </>
        }
        {
          (filters?.classId != null && filters?.subjectId != null) &&


          <>
            <Select
              id="subjectId"
              name="subjectId"
              className="capitalize w-full text-black text-sm z-40"
              styles={customStyles}
              classNamePrefix="react-select"
              value={selectedFilter?.subjectId || { label: 'Select Subject', value: '' }}
              options={subjectList && subjectList.length > 0
                ? [{ label: 'Select Subject', value: '' }, ...subjectList.map((tutor) => ({
                  label: tutor.name,
                  value: tutor._id,
                }))
                ]
                : []}
              onChange={(selectedOption, actionMeta) => {
                setFilters((prevState) => ({
                  ...prevState,
                  subjectId: selectedOption.value
                }))
                setSelectedFilter((prevState) => ({
                  ...prevState,
                  subjectId: selectedOption
                }))
              }}
              placeholder="Subject"

            />
          </>
        }
        {
          (filters?.dueDate != null) &&
          <Select
            id="dueDate"
            name="dueDate"
            value={selectedFilter?.dueDate || { label: 'Select Date', value: '' }}
            className="capitalize w-full text-black text-sm z-40"
            styles={customStyles}
            classNamePrefix="react-select"
            options={OptionList()}
            onChange={(selectedOption, actionMeta) => {
              setFilters((prevState) => ({
                ...prevState,
                dueDate: selectedOption.value
              }))
              setSelectedFilter((prevState) => ({
                ...prevState,
                dueDate: selectedOption
              }))
            }}
            placeholder="Fee Due Date"

          />
        }
        {
          (filters?.preferredTeachMode != null) && <Select
            id="preferredTeachMode"
            name="preferredTeachMode"
            className="capitalize w-full text-black text-sm z-40"
            styles={customStyles}
            classNamePrefix="react-select"
            value={selectedFilter?.preferredTeachMode || { label: 'Select Tuition Mode', value: '' }}
            options={[
              { value: '', label: 'Select Tuition Mode' },
              { value: 'HOME_TUITION', label: 'Home Tuition' },
              { value: 'ONLINE_TUITION', label: 'Online Tuition' },
            ]}
            onChange={(selectedOption, actionMeta) => {
              setFilters((prevState) => ({
                ...prevState,
                preferredTeachMode: selectedOption.value
              }))
              setSelectedFilter((prevState) => ({
                ...prevState,
                preferredTeachMode: selectedOption
              }))
            }}
            placeholder="Tuition Mode"

          />
        }

        {
          (filters?.leadSource != null) &&
          <Select
            id="leadSource"
            name="leadSource"
            className="capitalize w-full text-black text-sm z-40"
            styles={customStyles}
            classNamePrefix="react-select"
            value={selectedFilter?.leadSource || { label: 'Select Lead Source', value: '' }}
            options={[
              { value: '', label: 'Select Lead Source' },
              { value: 'CREATED_SELF', label: 'App' },
              { value: 'CREATED_ADMIN', label: 'Admin' },
            ]}
            onChange={(selectedOption, actionMeta) => {
              setFilters((prevState) => ({
                ...prevState,
                leadSource: selectedOption.value
              }))
              setSelectedFilter((prevState) => ({
                ...prevState,
                leadSource: selectedOption
              }))

            }}
            placeholder="Lead Source"

          />
        }

        {
          (filters.requiredGender != null) &&
          <Select
            id="requiredGender"
            name="requiredGender"
            className="capitalize w-full text-black text-sm z-40"
            styles={customStyles}
            classNamePrefix="react-select"
            value={selectedFilter?.requiredGender || { label: 'Select Gender', value: '' }}
            options={[
              { value: '', label: 'Select Gender' },
              { value: 'MALE', label: 'Male' },
              { value: 'FEMALE', label: 'Female' },
              { value: 'NO_PREFERENCE', label: 'No Preference' },
            ]}
            onChange={(selectedOption, actionMeta) => {
              setFilters((prevState) => ({
                ...prevState,
                requiredGender: selectedOption.value
              }))
              setSelectedFilter((prevState) => ({
                ...prevState,
                requiredGender: selectedOption
              }))
            }}
            placeholder="Gender"

          />
        }
        {
          (filters?.assignToEmployee != null) &&
          <Select
            id="assignToEmployee"
            name="assignToEmployee"
            className="capitalize w-full text-black text-sm z-40"
            styles={customStyles}
            classNamePrefix="react-select"
            value={selectedFilter?.assignToEmployee || { label: 'Select Lead Manager', value: '' }}
            options={leadMangerList && leadMangerList.length > 0
              ? [{ label: 'Select Lead Manager', value: '' }, ...leadMangerList.map((lead) => ({
                label: lead.userName,
                value: lead._id,
              }))
              ]
              : []}
            onChange={(selectedOption, actionMeta) => {
              setFilters((prevState) => ({
                ...prevState,
                assignToEmployee: selectedOption.value
              }))
              setSelectedFilter((prevState) => ({
                ...prevState,
                assignToEmployee: selectedOption
              }))

            }}
            placeholder="Lead Mana..."

          />
        }
        {
          (filters?.demoDate != null) &&
          <Select
            id="demoDate"
            name="demoDate"
            value={selectedFilter?.demoDate || { label: 'Select Date', value: '' }}
            className="capitalize w-full text-black text-sm z-40"
            styles={customStyles}
            classNamePrefix="react-select"
            options={OptionListForDate()}
            onChange={(selectedOption, actionMeta) => {
              setFilters((prevState) => ({
                ...prevState,
                demoDate: selectedOption.value
              }))
              setSelectedFilter((prevState) => ({
                ...prevState,
                demoDate: selectedOption
              }))

            }}
            placeholder="Demo Date"

          />
        }

        <button className="text-center font-bold" onClick={() => handleResetFilters()}>
          <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" />
        </button>
      </div>
      <div className="relative lg:hidden block">
        <div className="flex justify-end gap-4">
          <RiEqualizerFill color="#023565" onClick={toggleDropdown} size={24} />
          <button className="text-center font-bold" onClick={() => handleResetFilters()}>
            <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" />
          </button>
        </div>

        {isOpen && (
          <div
            className="origin-top-right   mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-4"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            <div className="filter flex justify-end  flex-col  gap-4">
              {(filters?.tuitionFee != null) &&
                <>
                  <Select
                    // id="tuitionFee"
                    name="tuitionFee"
                    className="capitalize w-full "
                    styles={customStyles}
                    classNamePrefix="react-select"
                    value={selectedFilter?.tuitionFee || { label: 'Tuition Fee', value: '' }}
                    options={tuitionFees}
                    onChange={(selectedOption, actionMeta) => {
                      setFilters((prevState) => ({
                        ...prevState,
                        tuitionFee: selectedOption.value
                      }))
                      setSelectedFilter((prevState) => ({
                        ...prevState,
                        tuitionFee: selectedOption
                      }))
                    }}
                    placeholder="Tuition Fee"
                  />
                </>}

              {(filters?.tutorFee != null) &&
                <>
                  <Select
                    id="tutorFee"
                    name="tutorFee"
                    className="capitalize w-full "
                    styles={customStyles}
                    classNamePrefix="react-select"
                    value={selectedFilter?.tutorFee || { label: 'Tutor Fee', value: '' }}
                    options={tutorFee}
                    onChange={(selectedOption, actionMeta) => {
                      setFilters((prevState) => ({
                        ...prevState,
                        tutorFee: selectedOption.value
                      }))
                      setSelectedFilter((prevState) => ({
                        ...prevState,
                        tutorFee: selectedOption
                      }))
                    }}
                    placeholder="Tutor Fee"
                  />

                </>}
              {(filters?.tutorId != null) &&

                <>
                  <Select
                    id="tutorId"
                    name="Tutor Name"
                    className="capitalize w-full text-black text-sm z-40"
                    styles={customStyles}
                    classNamePrefix="react-select"
                    value={selectedFilter?.tutorId || { label: 'Select Tutor', value: '' }}
                    options={tutorList && tutorList.length > 0
                      ? [{ label: 'Select Tutor', value: '' }, ...tutorList.map((tutor) => ({
                        label: tutor.name,
                        value: tutor._id,
                      }))
                      ]
                      : []}
                    onChange={(selectedOption, actionMeta) => {
                      setFilters((prevState) => ({
                        ...prevState,
                        tutorId: selectedOption.value
                      }))
                      setSelectedFilter((prevState) => ({
                        ...prevState,
                        tutorId: selectedOption
                      }))
                    }}
                    placeholder="Tutor Name"
                  />

                </>
              }

              {
                (filters?.classReference != null) &&
                <>
                  <Select
                    id="classReference"
                    name="classReference"
                    className="capitalize w-full text-black text-sm z-40"
                    styles={customStyles}
                    classNamePrefix="react-select"
                    value={selectedFilter?.classReference || { label: 'Select Class reference', value: '' }}
                    options={classReferenceList && classReferenceList.length > 0
                      ? [{ label: 'Select Class reference', value: '' }, ...classReferenceList.map((refer) => ({
                        label: refer,
                        value: refer,
                      }))
                      ]
                      : []}
                    onChange={(selectedOption, actionMeta) => {
                      setFilters((prevState) => ({
                        ...prevState,
                        classReference: selectedOption.value
                      }))
                      setSelectedFilter((prevState) => ({
                        ...prevState,
                        classReference: selectedOption
                      }))
                    }}
                    placeholder="Class Ref..."
                  />
                </>
              }
              {
                (filters?.classId != null) &&

                <>
                  <Select
                    id="classId"
                    name="classId"
                    className="capitalize w-full text-black text-sm z-40"
                    styles={customStyles}
                    classNamePrefix="react-select"
                    value={selectedFilter?.classId || { label: 'Select Class', value: '' }}
                    options={classList && classList.length > 0
                      ? [{ label: 'Select Class', value: '' }, ...classList.map((tutor) => ({
                        label: tutor.name,
                        value: tutor._id,
                      }))
                      ]
                      : []}
                    onChange={(selectedOption, actionMeta) => handleClassChange(selectedOption.value, selectedOption)}
                    placeholder="Class"
                  />

                </>
              }
              {
                (filters?.classId != null && filters?.subjectId != null) &&


                <>
                  <Select
                    id="subjectId"
                    name="subjectId"
                    className="capitalize w-full text-black text-sm z-40"
                    styles={customStyles}
                    classNamePrefix="react-select"
                    value={selectedFilter?.subjectId || { label: 'Select Subject', value: '' }}
                    options={subjectList && subjectList.length > 0
                      ? [{ label: 'Select Subject', value: '' }, ...subjectList.map((tutor) => ({
                        label: tutor.name,
                        value: tutor._id,
                      }))
                      ]
                      : []}
                    onChange={(selectedOption, actionMeta) => {
                      setFilters((prevState) => ({
                        ...prevState,
                        subjectId: selectedOption.value
                      }))
                      setSelectedFilter((prevState) => ({
                        ...prevState,
                        subjectId: selectedOption
                      }))
                    }}
                    placeholder="Subject"

                  />
                </>
              }
              {
                (filters?.dueDate != null) &&
                <Select
                  id="dueDate"
                  name="dueDate"
                  value={selectedFilter?.dueDate || { label: 'Select Date', value: '' }}
                  className="capitalize w-full text-black text-sm z-40"
                  styles={customStyles}
                  classNamePrefix="react-select"
                  options={OptionList()}
                  onChange={(selectedOption, actionMeta) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      dueDate: selectedOption.value
                    }))
                    setSelectedFilter((prevState) => ({
                      ...prevState,
                      dueDate: selectedOption
                    }))
                  }}
                  placeholder="Fee Due Date"

                />
              }
              {
                (filters?.preferredTeachMode != null) && <Select
                  id="preferredTeachMode"
                  name="preferredTeachMode"
                  className="capitalize w-full text-black text-sm z-40"
                  styles={customStyles}
                  classNamePrefix="react-select"
                  value={selectedFilter?.preferredTeachMode || { label: 'Select Tuition Mode', value: '' }}
                  options={[
                    { value: '', label: 'Select Tuition Mode' },
                    { value: 'HOME_TUITION', label: 'Home Tuition' },
                    { value: 'ONLINE_TUITION', label: 'Online Tuition' },
                  ]}
                  onChange={(selectedOption, actionMeta) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      preferredTeachMode: selectedOption.value
                    }))
                    setSelectedFilter((prevState) => ({
                      ...prevState,
                      preferredTeachMode: selectedOption
                    }))
                  }}
                  placeholder="Tuition Mode"

                />
              }

              {
                (filters?.leadSource != null) &&
                <Select
                  id="leadSource"
                  name="leadSource"
                  className="capitalize w-full text-black text-sm z-40"
                  styles={customStyles}
                  classNamePrefix="react-select"
                  value={selectedFilter?.leadSource || { label: 'Select Lead Source', value: '' }}
                  options={[
                    { value: '', label: 'Select Lead Source' },
                    { value: 'CREATED_SELF', label: 'App' },
                    { value: 'CREATED_ADMIN', label: 'Admin' },
                  ]}
                  onChange={(selectedOption, actionMeta) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      leadSource: selectedOption.value
                    }))
                    setSelectedFilter((prevState) => ({
                      ...prevState,
                      leadSource: selectedOption
                    }))

                  }}
                  placeholder="Lead Source"

                />
              }

              {
                (filters.requiredGender != null) &&
                <Select
                  id="requiredGender"
                  name="requiredGender"
                  className="capitalize w-full text-black text-sm z-40"
                  styles={customStyles}
                  classNamePrefix="react-select"
                  value={selectedFilter?.requiredGender || { label: 'Select Gender', value: '' }}
                  options={[
                    { value: '', label: 'Select Gender' },
                    { value: 'MALE', label: 'Male' },
                    { value: 'FEMALE', label: 'Female' },
                    { value: 'NO_PREFERENCE', label: 'No Preference' },
                  ]}
                  onChange={(selectedOption, actionMeta) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      requiredGender: selectedOption.value
                    }))
                    setSelectedFilter((prevState) => ({
                      ...prevState,
                      requiredGender: selectedOption
                    }))
                  }}
                  placeholder="Gender"

                />
              }
              {
                (filters?.assignToEmployee != null) &&
                <Select
                  id="assignToEmployee"
                  name="assignToEmployee"
                  className="capitalize w-full text-black text-sm z-40"
                  styles={customStyles}
                  classNamePrefix="react-select"
                  value={selectedFilter?.assignToEmployee || { label: 'Select Lead Manager', value: '' }}
                  options={leadMangerList && leadMangerList.length > 0
                    ? [{ label: 'Select Lead Manager', value: '' }, ...leadMangerList.map((lead) => ({
                      label: lead.userName,
                      value: lead._id,
                    }))
                    ]
                    : []}
                  onChange={(selectedOption, actionMeta) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      assignToEmployee: selectedOption.value
                    }))
                    setSelectedFilter((prevState) => ({
                      ...prevState,
                      assignToEmployee: selectedOption
                    }))

                  }}
                  placeholder="Lead Mana..."

                />
              }
              {
                (filters?.demoDate != null) &&
                <Select
                  id="demoDate"
                  name="demoDate"
                  value={selectedFilter?.demoDate || { label: 'Select Date', value: '' }}
                  className="capitalize w-full text-black text-sm z-40"
                  styles={customStyles}
                  classNamePrefix="react-select"
                  options={OptionListForDate()}
                  onChange={(selectedOption, actionMeta) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      demoDate: selectedOption.value
                    }))
                    setSelectedFilter((prevState) => ({
                      ...prevState,
                      demoDate: selectedOption
                    }))

                  }}
                  placeholder="Demo Date"

                />
              }
            </div>
          </div>
        )}
      </div>

    </>
  )
}

export default TuitionFilters