import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardAction } from '../../_actions/dashboard.action'
import { useNavigate } from 'react-router'

const ButtonFour = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch();
    const selector = useSelector(state => state);

    let newTicketList = selector?.Dashboard?.getNewTicket?.data?.list
    useEffect(() => {
        let resData = {
            "cityId": "",
            "isManagement": false,
            "keyWord": "",
            "pageNo": 1,
            "size": 7
        }

        dispatch(dashboardAction.getNewTicketList(resData))
    }, [])

    return (
        <div className='overflow-x-auto'>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-6">
                <thead class=" text-[#313131] text-center font-bold  border-b-2 border-[#D1D1D1]">
                    <tr className="border-b">
                        <th scope="col" class="px-6 py-3">
                            User Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            City
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Subject
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Message
                        </th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        newTicketList && newTicketList.length ? newTicketList?.map((item, id) => (
                            <tr class="bg-white capitalize" key={id}>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    {item?.userId?.name ?? 'No Data'} <br></br> {item?.userId?.mobNo ?? 'No Data'}
                                </td>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    {item?.cityId?.name ?? 'No Data'}
                                </td>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    {item?.title ?? 'No Title'}
                                </td>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    {item?.msg ?? 'No Message'}
                                </td>
                            </tr>

                        )) : <p className='justify-center'>Data not found.</p>
                    }


                </tbody>
            </table>
            <div class="bg-white text-end pr-6 py-5 font-bold underline text-blue-950 cursor-pointer"><button onClick={()=>navigate("/app/help-&-support-cb")}>View all</button></div>
        </div>
    )
}

export default ButtonFour
