/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAction } from "../../_actions/dashboard.action.js";
import Card from "./Card.js";
import ButtonThird from "./ButtonThird.js";
import ButtonFour from "./ButtonFour.js";
import Dashboardjson2 from "../dashboard/Dashboard2.json";
import KycHeader from "./KycHeader.js";
import KycTabel from "./KycTabel.js";
import "./Dashboard.css";
import DashBoardHeader from "./DashboardHeader/DashBoardHeader.js";
import { useNavigate } from "react-router";


const Dashboard = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [dashBoardCount, setDashBoardCount] = useState({ "onGoingTuition": 0, "onDemoTuition": 0, "demoDoneTuition": 0, "latestDemoDone": 0, "latestDemoRequest": 0, "newReferral": 0, "newTicket": 0, "latestTutorChange": 0 })
  const [newState, setNewState] = useState();
  const [active, setShowActive] = useState("latest-demo-request");
  const [response, setResponse] = useState([]);
  const [cardActive, setCardActive] = useState();
  const navigate = useNavigate()
  useEffect(() => {
    const cityData = roleId !== 1 ? adminCity && adminCity.length ? adminCity : [] : []
    dispatch(dashboardAction.getDashboardCount({ cityId: cityData }));
  }, []);
  const [nlatestReferalListData, setnlatestReferalListData] = useState()
  const [totalPages, setTotalPages] = useState();
  const [newTicketList, setnewTicketList] = useState()
  const [totalPages4, setTotalPages4] = useState(1);

  const currentPage = 1
  const pageSize = 5



  useEffect(() => {
    const newReferralDetails = {
      "cityId": "",
      "keyWord": "",
      pageNo: currentPage,
      size: pageSize,
    }
    const resData = {
      "cityId": "",
      "keyWord": "",
      pageNo: currentPage,
      size: pageSize,
    }
    dispatch(dashboardAction.getNewReferralsList(newReferralDetails))
    dispatch(dashboardAction.getNewTicketList(resData))
  }, [currentPage]);



  useEffect(() => {
    setnewTicketList(() => selector?.Dashboard?.getNewTicket?.data?.list)
    setnlatestReferalListData(() => selector?.Dashboard?.getLatestReferralList?.data?.list)
    setDashBoardCount(() => selector?.Dashboard?.dashboardCountList?.data)

    if (selector?.Dashboard?.getNewTicket?.data?.total) {
      setTotalPages4(Math.ceil(selector.Dashboard.getNewTicket.data.total / pageSize));
    }
    if (selector?.Dashboard?.getLatestReferralList?.data?.total) {
      setTotalPages(Math.ceil(selector.Dashboard.getLatestReferralList.data.total / pageSize));
    }
  }, [selector])

  const handleResponse = (data) => {
    setResponse(data)
  }

  const buttonName = [
    `New Referrals`,
    `Latest Issues`,
  ]

  const [activeTab, setActiveTab] = useState('issues');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const handleBtnClick = (data) => {
    setShowActive(tabResValue(data));
    navigate(`?tab=${tabResValue(data)}`)
  };

  const handleActiveCard = (val) => {
    setCardActive(val);
  };

  const setNewStateHandle = (data) => {
    setNewState(data)
  }

  function valueReturnData(name) {
    switch (name) {
      case "Latest Demo Done":
        return dashBoardCount?.latestDemoDone;
      case "Latest Demo Request":
        return dashBoardCount?.latestDemoRequest;
      case "New Referrals":
        return dashBoardCount?.newReferral;
      case "Latest Issues":
        return dashBoardCount?.newTicket;
      case "Latest Tutor Change Request":
        return dashBoardCount?.latestTutorChange
      default:
        return 0;
    }
  }

  function tabResValue(state) {
    switch (state) {
      case `Latest Demo Done`:
        return `latest-demo-done`
      case `Latest Demo Request`:
        return `latest-demo-request`
      case `New Referrals`:
        return `new-referrals`
      case `Latest Issues`:
        return `latest-issues`
      case `Latest Tutor Change Request`:
        return `latest-tutor-change-requests`
      default:
        return state
    }
  }





  return (
    <>
      <DashBoardHeader />
      <section className="p-5 overflow-hidden overflow-y-auto">
        <div className="flex flex-col lg:flex-row gap-5">
          {/* left side */}
          <div className="w-full lg:w-4/5 mx-auto lg:px-0 ">
            <section className="grid lg:grid-cols-3 grid-cols-1 gap-4">
              {Dashboardjson2.map((item, index) => (
                <div
                  key={index}
                  className={`rounded-3xl w-full ${cardActive === index ? "bg-[#C60E6B] text-white"
                    : "bg-white text-[#023565] hover:bg-[#C60E6B] hover:text-white transition duration-300 ease-in-out"
                    }`}
                >
                  <Card
                    Group3={item.img}
                    index={cardActive}
                    title={item.title}
                    numbers={item.numbers}
                    buttonName={item.buttonName}
                    data={dashBoardCount}
                    onClick={() => handleResponse(item)}
                    link={item.url}
                  />
                </div>
              ))}
            </section>
            {/* <section className="mt-8 w-full 2xl:w-[100%]">
              <div>
                <section className="flex flex-wrap section_button gap-3 text-center">
                  {buttonName.map((item, index) => {

                    return (
                      <div
                        key={index}
                        className={`text-gray-100 font-bold py-2 px-3 sm:flex-row rounded-full text-sm mb-3 lg:mb-0 ${active === tabResValue(item) ? "bg-[#023565]" : "bg-[#9C9EAB] hover:bg-[#C60E6B] hover:text-white transition duration-300 ease-in-out"}`}>
                        <ButtonCom onClick={() => handleBtnClick(item)} name={item} value={valueReturnData(item)}
                        />
                      </div>
                    )
                  })}
                </section>
              </div>
            </section> */}

            {/* <div className="flex items-center space-x-2 pt-5">
              <div className={`px-6 py-3 rounded-lg w-full cursor-pointer ${activeTab === 'referrals' ? 'bg-[#033565]' : 'bg-[#9C9EAB]'}`} onClick={() => handleTabClick('referrals')}>
                <h2 className="text-white font-semibold text-lg text-center">Latest Referrals</h2>
              </div>
              <div className={`px-6 py-3 rounded-lg w-full cursor-pointer ${activeTab === 'issues' ? 'bg-[#033565]' : 'bg-[#9C9EAB]'}`} onClick={() => handleTabClick('issues')}>
                <h2 className="text-white font-semibold text-lg text-center">Latest Issues</h2>
              </div>
            </div> */}

            {activeTab === 'referrals' ? (
              <div className="w-full pt-2">
                <ButtonThird setNewStateHandle={setNewStateHandle} totalPages={totalPages} nlatestReferalListData={nlatestReferalListData} />
              </div>
            ) : (
              <div className="w-full pt-2">
                <ButtonFour setNewStateHandle={setNewStateHandle} totalPages4={totalPages4} newTicketList={newTicketList} />
              </div>
            )}

          </div>

          {/* Right side */}
          <div className="w-full lg:w-2/5 2xl:w-[25%] lg:px-0 ">
            <div className="rounded-lg shadow-md">
              <div className="bg-[#033565] rounded-t-lg shadow-md py-2">
                <KycHeader />
              </div>
              <div className="bg-white rounded-b-lg shadow-md p-4 ">
                <KycTabel />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
