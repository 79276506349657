/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions, employprofileAction, tutionAction } from '../../_actions';
import { BsThreeDotsVertical } from "react-icons/bs";
import { uploadImageAction } from "../../_actions";
import { MdOutlineClose, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaAngleRight, FaChevronLeft, FaChevronRight, FaPlus } from "react-icons/fa";
import Loader from '../../components/Loader';
import Multiselect from 'multiselect-react-dropdown';
import { roleAction } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import { downloadCSV } from '../../_helpers';

import moment from 'moment';
import { BiExport } from 'react-icons/bi';
export const AllEmployees = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const [allEmplyList, setallEmplyList] = useState()

  let uploadEmployeeImage = selector?.ImageUpload?.fileDetails?.imageUrl


  const [action, setAction] = useState(null);
  const [createModal, setCreateModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10
  const { loading } = selector?.ImageUpload ? selector?.ImageUpload : {}
  const menuRef = useRef(null)
  const mobileRef = useRef(null)
  const [allCityListData, setAllCityListData] = useState()
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [roleList, setRoleList] = useState();
  const [error, setErrors] = useState('')

  const [createEmployeeData, setCreateEmployeeData] = useState({})
  const [employeeId, setEmployeeId] = useState(null)
  const [passwordData, setPasswordData] = useState({})



  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setAction(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setAllCityListData(selector?.tution?.getAllCityList?.data)
  }, [selector])

  const uploadEmpImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
      if (!validImageTypes.includes(fileType)) {
        alert('Please select a valid image file (JPEG, PNG, or SVG).');
        return;
      }
      dispatch(uploadImageAction.UploadImage(file));
    }

  }




  const handleAction = (id) => {
    setAction(prev => prev === id ? null : id);
  };

  useEffect(() => {
    let roleData = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      pageNo: currentPage,
      size: pageSize,
    };
    let emplyDetails = {
      "keyWord": "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "desc",
      "sortBy": "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };



    dispatch(employprofileAction.getSubAdminList(emplyDetails));
    dispatch(tutionAction.getAllCityList());
    dispatch(roleAction.roleGet(roleData))

  }, [currentPage]);

  useEffect(() => {
    if (selector?.EmplyProfile?.getAllEmplyList?.data?.total) {
      setTotalPages(Math.ceil(selector?.EmplyProfile?.getAllEmplyList?.data.total / pageSize));
    }
    setallEmplyList(() => selector?.EmplyProfile?.getAllEmplyList?.data?.list)
    setRoleList(selector?.Roles?.roleList?.data?.list)
    setAllCityListData(selector?.tution?.getAllCityList?.data);


  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const handleactiveInactive = (data) => {
    let emplyDetails = {
      "keyWord": "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "",
      pageNo: currentPage,
      size: pageSize,
    };

    let dataforstatus = {
      id: data._id,
    }

    confirmAlert({
      title: `Confirm to ${data?.isDisable === false ? 'Inactive' : 'Active'}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(employprofileAction.updateSubAdminStatus(dataforstatus, emplyDetails))

        },
        {
          label: 'No'
        }
      ]
    });

    setAction(false)
  }








  const handleCreate = () => {
    setCreateEmployeeData({})
    setSelectedOptions([])
    setErrors({})
    setEmployeeId(null)
    setCreateModal(true)
  }

  const handleSelectMultiSelect = (selectedList) => {
    setSelectedOptions(selectedList);
    // setSelectedCity(selectedList?.map((ele) => ele._id))
    if (selectedList.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, city: '' }));
    }

  };

  const handleRemoveMultiSelect = (selectedList) => {
    setSelectedOptions(selectedList);

  };

  const options = allCityListData?.map((ele) => ele) || [];






  const handleClose = () => {
    setCreateEmployeeData({})
    setSelectedOptions([])
    setCreateModal(false)
    setErrors({})
    setEmployeeId(null)
  }

  useEffect(() => {
    let emplyDetails = {
      "keyWord": "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "",
      pageNo: 1,
      size: 100000000000,
    };
    dispatch(employprofileAction.getSubAdminList(emplyDetails));


  }, [dispatch])

  let allemplyDownload = selector?.EmplyProfile?.getAllEmplyList?.data?.list



  const fields = ['Name', 'Employee ID', 'Role', 'City', 'Status']

  const convertToCSV = (data) => {
    const array = typeof data !== 'object' ? JSON.parse(data) : data;
    let csv = fields.join(',') + '\r\n';
    array.forEach(element => {
      const userName = element?.userName || '-';
      const employeeId = element?.employeeId || '-';
      const role = element?.role?.name || '-';
      const city = element?.city?.map(city => city.name).join(',') || '-';
      const status = element?.isDisable === false ? 'Active' : 'Inactive';

      const row = [userName, employeeId, role, city, status].join(',');
      csv += row + '\r\n';
    });

    return csv

  }

  const handleCityOnSelect = (e) => {
    let cityID = e.target.value;

    if (cityID === '') {
      let emplyDetails = {
        "keyWord": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "sortBy": "",
        pageNo: currentPage,
        size: pageSize,
      };

      dispatch(employprofileAction.getSubAdminList(emplyDetails));;
    } else {
      let emplyDetails = {
        cityId: [cityID],
        "keyWord": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "sortBy": "",
        pageNo: currentPage,
        size: pageSize,
      };

      setTimeout(() => {
        dispatch(employprofileAction.getSubAdminList(emplyDetails));;
      }, 500);


    }
  };

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setCreateEmployeeData((prev) => ({
      ...prev,
      [name]: value
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: ""
    }));
  }


  const fieldErrorMessages = {
    userName: 'User name is required',
    name: 'Name is required',
    mobNo: 'Mobile number is required',
    email: 'Email is required',
    fatherName: 'Father\'s name is required',
    motherName: 'Mother\'s name is required',
    address: 'Address is required',
    dob: 'Date of birth is required',
    bloodGroup: 'Blood group is required',
    govtIdNo: 'Government ID number is required',
    qualification: 'Qualification is required',
    skills: 'Skills are required',
    previousExperience: 'Previous experience is required',
    monthlySalary: 'Monthly salary is required',
    password: 'Password is required',
    roleId: 'Role is required',
  };

  const validateForm = () => {
    const errorsData = {};

    // List of required fields
    const requiredFields = [
      'userName', 'name', 'mobNo', 'email', 'fatherName', 'motherName',
      'address', 'dob', 'bloodGroup', 'govtIdNo', 'qualification', 'skills',
      'previousExperience', 'monthlySalary', 'roleId',
    ];

    if (!employeeId) {
      requiredFields.push('password')
    }

    requiredFields.forEach(field => {
      if (!createEmployeeData[field] || createEmployeeData[field].trim() === '') {
        errorsData[field] = fieldErrorMessages[field];
      }
    });

    if (selectedOptions.length === 0) {
      errorsData["city"] = `City is required`;

    }

    setErrors(errorsData)

    return Object.keys(errorsData).length === 0;
  };

  const handleSubmitCreateEmployee = (e) => {
    e.preventDefault();

    if (validateForm()) {

      const listData = {
        "keyWord": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "sortBy": "",
        pageNo: currentPage,
        size: pageSize,
      };
      const createData = {
        ...createEmployeeData,
        dob: new Date(createEmployeeData.dob).toUTCString(),
        image: uploadEmployeeImage ? uploadEmployeeImage : 'https://beforeigosolutions.com/wp-content/uploads/2021/12/dummy-profile-pic-300x300-1.png',
        city: selectedOptions.map(ele => ele._id)
      }

      dispatch(employprofileAction.createSubAdmin(createData, listData))

      handleClose()

    }

  }

  const handleEditPopup = (data) => {
    setEmployeeId(data._id)
    setCreateEmployeeData(() => ({
      roleId: data.role.id,
      dob: data.dob,
      image: data.image,
      userName: data.userName,
      name: data.name,
      mobNo: data.mobNo,
      email: data.email,
      fatherName: data.fatherName,
      motherName: data.motherName,
      address: data.address,
      bloodGroup: data.bloodGroup,
      govtIdNo: data.govtIdNo,
      qualification: data.qualification,
      skills: data.skills,
      previousExperience: data.skills,
      monthlySalary: data.monthlySalary
    }))
    setSelectedOptions(data.city)
    setCreateModal(true)
  }

  const handleSubmitEditEmployee = (e) => {
    e.preventDefault();

    if (validateForm()) {

      const listData = {
        "keyWord": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "sortBy": "",
        pageNo: currentPage,
        size: pageSize,
      };
      const editData = {
        dob: new Date(createEmployeeData.dob).toUTCString(),
        image: uploadEmployeeImage ? uploadEmployeeImage : createEmployeeData.image,
        city: selectedOptions.map(ele => ele._id),
        id: employeeId,
        userName: createEmployeeData.userName,
        name: createEmployeeData.name,
        roleId: createEmployeeData.roleId,
        mobNo: createEmployeeData.mobNo,
        email: createEmployeeData.email,
        fatherName: createEmployeeData.fatherName,
        motherName: createEmployeeData.motherName,
        address: createEmployeeData.address,
        bloodGroup: createEmployeeData.bloodGroup,
        govtIdNo: createEmployeeData.govtIdNo,
        qualification: createEmployeeData.qualification,
        skills: createEmployeeData.skills,
        previousExperience: createEmployeeData.skills,
        monthlySalary: createEmployeeData.monthlySalary
      }

      dispatch(employprofileAction.updateSubAdmin(editData, listData))

      handleClose()

    }


  }

  const handleUpdatePassword = (data) => {
    setPasswordData({
      open: true,
      password: "",
      id: data._id
    })
  }

  const handleSubmitPassword = (e) => {
    e.preventDefault()
    let err = {}
    if (!passwordData.password) {
      err.password = "Please enter password."
    }
    setErrors(err)
    if (Object.keys(err).length === 0) {
      employprofileAction.updateSubAdminPasswordByPromise({id:passwordData.id, password:passwordData.password}).then(res => {
        dispatch(alertActions.success("Success."))
        setPasswordData({})
        const reqData = {
          "keyWord": "",
          "fromDate": "",
          "toDate": "",
          "sortOrder": "desc",
          "sortBy": "createdAt",
          pageNo: currentPage,
          size: pageSize,
        };
        dispatch(employprofileAction.getSubAdminList(reqData));
        
      }).catch(err => {
        dispatch(alertActions.error("Please try again later."))
      })
    }
  }


  return (
    <>
      <div className="md:flex justify-end gap-2 lg:-mt-10 mt-4 md:space-y-0 space-y-2">
        <select onChange={handleCityOnSelect} className="block md:w-40 w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 capitalize">
          <option value=''>All City</option>
          {
            allCityListData && allCityListData.length > 0 ? (
              allCityListData.map((city) => (

                <option className='capitalize' key={city._id} value={city._id}>
                  {city.name}
                </option>
              ))
            ) : null
          }
        </select>
        <div className='flex items-center justify-center space-x-3'>
          <button className="bg-white px-4 py-1.5 border-2 border-black gap-2  flex justify items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out-center rounded-lg" onClick={() => downloadCSV(convertToCSV(allemplyDownload))}>
            <span>Export</span>
            <span><BiExport size={20} /></span>
          </button>
          <button
            className="flex justify-center items-center gap-2 md:w-48 w-full text-[#C60E6B] py-2.5 text-sm  border border-gray-300 rounded-lg bg-gray-50"
            onClick={handleCreate}
          >
            <span>
              <FaPlus />
            </span>{" "}
            Create Employee
          </button>
        </div>
      </div>
      <Loader loading={selector?.EmplyProfile?.loading} />
      <Loader loading={loading} />

      <section className="lg:block hidden">
        <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
          <table className="min-w-full table-autorounded-xl overflow-x-auto">
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='text-black'>
                <th className="px-4 py-3 whitespace-nowrap text-sm text-left ">Name</th>
                <th className="px-4 py-3 whitespace-nowrap text-sm text-left ">Employee ID</th>
                <th className="px-4 py-3 whitespace-nowrap text-sm text-left ">Role</th>
                <th className="px-4 py-3 whitespace-nowrap text-sm text-left ">City</th>
                <th className="px-4 py-3 whitespace-nowrap text-sm text-left ">Status</th>
                <th className="px-4 py-3 whitespace-nowrap text-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {allEmplyList && allEmplyList.length > 0 ? (allEmplyList?.map((ele, id) => (
                <tr key={id} className='border-b border-[#D1D1D1] capitalize'>
                  <td className="px-4 py-4  text-sm text-gray-900">
                    <div className="flex items-center gap-2">
                      <span>
                        {ele?.image && ele?.image ?
                          <img className="rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
                            src={
                              ele?.image}
                            alt="User"
                          /> : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                            <span className="text-base font-semibold text-[#033565]"> {ele?.name ? (ele.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                          </div>}
                      </span>
                      <div className="w-24">
                        <p className="font-semibold whitespace-nowrap text-[#023565]">{ele?.name ?? "N/A"}</p>
                        <p className="text-gray-500 whitespace-nowrap">{ele?.mobNo ?? "N/A"}</p>
                      </div>
                    </div>
                  </td>

                  <td className='px-4 py-4 text-sm text-gray-900 text-left'>{ele?.employeeId ?? 'N/A'}</td>
                  <td className="px-4 py-4 text-sm text-gray-900 text-left">{ele?.role?.name || '-'}</td>
                  <td className="px-4 py-4 text-sm text-gray-900 text-left">
                    {ele && ele.city && ele.city.length > 0 ? (ele.city.map(city => city?.name).join(', ')) : (<span>No cities available</span>)}
                  </td>
                  <td className={`px-4 py-4 text-sm text-gray-900 text-left font-semibold ${ele.isDisable === false ? 'text-green-700' : 'text-red-600'}`}>
                    {ele.isDisable === false ? 'Active' : 'Inactive'}
                  </td>

                  <td className="px-4 py-4 text-gray-900 relative">
                    <span className="flex justify-center items-center">
                      <BsThreeDotsVertical onClick={() => handleAction(ele._id)} className="text-2xl cursor-pointer text-red-500 " />
                    </span>
                    {action === ele._id && (
                      <div className="absolute right-24 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10" ref={menuRef}>
                        <ul className="font-semibold text-[#023565]">
                          <li className="px-4 py-1 border-b cursor-pointer hover:text-[#E4006F]  flex justify-between items-center hover:bg-gray-100" onClick={() => handleactiveInactive(ele)} >
                            {ele?.isDisable === false ? "Inactive" : "Active"}
                            <FaAngleRight className="hover:text-[#C60E6B]" />
                          </li>
                          <li className="px-4 py-1 hover:text-[#E4006F]  cursor-pointer flex justify-between items-center hover:bg-gray-100" onClick={() => handleEditPopup(ele)}>
                            Edit Profile
                            <FaAngleRight className="hover:text-[#C60E6B]" />
                          </li>
                          <li className="px-4 py-1 hover:text-[#E4006F]  cursor-pointer flex justify-between items-center hover:bg-gray-100" onClick={() => handleUpdatePassword(ele)}>
                            Update Password
                            <FaAngleRight className="hover:text-[#C60E6B]" />
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={6} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section >


      <section className="space-y-5 py-6 lg:hidden block">
        {allEmplyList && allEmplyList.length > 0 ? (allEmplyList?.map((ele) => (
          <div className=' bg-white p-4 rounded-[10px]'>
            <>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Name</p>
                </div>
                <div className='w-full'>
                  <div className="flex items-center  gap-2 capitalize">
                    {ele?.image && ele?.image ?
                      <img className="rounded max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
                        src={ele?.image}
                        alt="User"
                      /> : <div className="bg-gray-300 rounded h-14 w-14 flex justify-center items-center">
                        <span className="text-base font-semibold text-[#033565]"> {ele?.name ? (ele.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                      </div>}
                    <div>
                      <div className="font-medium text-black">{ele?.userName || '-'}</div>
                      <div className="text-gray-500">{ele?.mobNo || '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Employee ID</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>{ele?.employeeId ?? 'N/A'}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Role</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>  {ele?.role?.name || '-'} </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>City</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] capitalize'> {
                    ele && ele.city && ele.city.length > 0 ? (
                      ele.city.map(city => city?.name).join(', ')
                    ) : (
                      <span>No cities available</span>
                    )
                  }</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Status</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    <span className={` ${ele.isDisable === false ? 'text-green-500' : 'text-red-500'}`}>
                      {ele.isDisable === false ? 'Active' : 'Inactive'}
                    </span>
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Action</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] relative'>
                    <BsThreeDotsVertical className=' text-2xl text-[#C60E6B]' onClick={() => handleAction(ele._id)} />
                    {action === ele._id && (
                      <div className="absolute rounded-lg bg-white w-44 border shadow-lg" ref={mobileRef}>
                        <ul className="font-semibold text-[#023565]">
                          <li className="px-4 py-2 border-b cursor-pointer hover:text-[#E4006F]  flex justify-between items-center hover:bg-gray-100" onClick={() => handleactiveInactive(ele)} >
                            Active/Inactive
                            <FaAngleRight className="hover:text-[#C60E6B]" />

                          </li>
                          <li className="px-4 py-2 hover:text-[#E4006F]  cursor-pointer flex justify-between items-center hover:bg-gray-100" onClick={() => handleEditPopup(ele)}>
                            Edit Profile
                            <FaAngleRight className="hover:text-[#C60E6B]" />

                          </li>
                        </ul>
                        <li className="px-4 py-2 hover:text-[#E4006F]  cursor-pointer flex justify-between items-center hover:bg-gray-100" onClick={() => handleUpdatePassword(ele)}>
                          Update Password
                          <FaAngleRight className="hover:text-[#C60E6B]" />

                        </li>
                      </div>
                    )}
                  </p>
                </div>
              </div>

            </>
          </div>

        ))

        ) : (
          <p>Data Not Found</p>
        )

        }
      </section>



      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}

      {
        createModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[900px]">
                <div className="flex justify-end">
                  <button onClick={handleClose} className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center">
                    <MdOutlineClose /> Close
                  </button>
                </div>
                <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">{employeeId ? "Edit Employee " : "Create New Employee"} </h2>
                  </div>
                  <div className='lg:max-h-[600px] overflow-y-auto Pokercardbar'>
                    <form onSubmit={employeeId ? handleSubmitEditEmployee : handleSubmitCreateEmployee}>
                      <div className=' grid lg:grid-cols-3 gap-4  p-4'>
                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>User Name</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='User name' name='userName' id='userName' value={createEmployeeData?.userName || ""}
                            onChange={handleOnChange}
                            disabled={employeeId ? true : false}
                          />
                          {error.userName && <p className="text-red-500 text-sm">{error.userName}</p>}
                        </div>
                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Full Name</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='full name' name='name' id='name' value={createEmployeeData?.name || ""}
                            onChange={handleOnChange}
                          />
                          {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
                        </div>
                        {!employeeId ?
                          <div className='space-y-1'>
                            <label className='block text-gray-800 text-[14px]'>Password</label>
                            <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='password' name='password' id='password' value={createEmployeeData?.password || ""}
                              onChange={handleOnChange}
                            />
                            {error.password && <p className="text-red-500 text-sm">{error.password}</p>}
                          </div>
                          : null}



                        <div className='space-y-1'>
                          <label className='block text-gray-700 text-[14px]'>Role</label>
                          <select name='roleId' value={createEmployeeData?.roleId} onChange={handleOnChange} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 capitalize'>
                            <option>Select Role</option>
                            {
                              roleList && roleList.length > 0 ? (
                                roleList.map((role, id) => (
                                  <option key={id} value={role._id}>{role.name}</option>
                                ))
                              ) : (
                                <option>No roles available</option>
                              )
                            }

                          </select>
                          {error.roleId && <p className="text-red-500 text-sm">{error.roleId}</p>}

                        </div>

                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Mobile No</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='mobile number' name='mobNo' id='mobNo' value={createEmployeeData?.mobNo || ""}
                            onChange={(e) => !isNaN(e.target.value) ? handleOnChange(e) : {}}
                          />
                          {error.mobNo && <p className="text-red-500 text-sm">{error.mobNo}</p>}
                        </div>

                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Email</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='email' name='email' id='email' value={createEmployeeData?.email || ""}
                            onChange={handleOnChange}
                          />
                          {error.email && <p className="text-red-500 text-sm">{error.email}</p>}
                        </div>
                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Date of Birth</label>
                          <input type='date' className='w-full p-2 border border-gray-300 rounded' name='dob' id='dob' value={createEmployeeData?.dob ? moment(createEmployeeData?.dob).format('YYYY-MM-DD') : ""}
                            onChange={handleOnChange}
                          />
                          {error.dob && <p className="text-red-500 text-sm">{error.dob}</p>}
                        </div>
                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Father's Name</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='father name' name='fatherName' id='fatherName' value={createEmployeeData?.fatherName || ""}
                            onChange={handleOnChange}
                          />
                          {error.fatherName && <p className="text-red-500 text-sm">{error.fatherName}</p>}
                        </div>

                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Mother's Name</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='mother name' name='motherName' id='motherName' value={createEmployeeData?.motherName || ""}
                            onChange={handleOnChange}
                          />
                          {error.motherName && <p className="text-red-500 text-sm">{error.motherName}</p>}
                        </div>

                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Address</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='address' name='address' id='address' value={createEmployeeData?.address || ""}
                            onChange={handleOnChange}
                          />
                          {error.address && <p className="text-red-500 text-sm">{error.address}</p>}
                        </div>
                        <div className='space-y-1'>
                          <label className='block text-gray-700 text-[14px]'>City</label>
                          <Multiselect
                            className='capitalize'
                            selectedValues={selectedOptions}
                            options={options}
                            onSelect={handleSelectMultiSelect}
                            onRemove={handleRemoveMultiSelect}
                            displayValue="name"
                          />
                          {error.city && <p className="text-red-500 text-sm">{error.city}</p>}

                        </div>

                        <div className="flex flex-col mb-4">
                          <label htmlFor="bloodGroup" className="mb-2 text-sm font-medium text-gray-700 text-[14px]">
                            Select Blood Group
                          </label>
                          <select
                            id="bloodGroup"
                            name="bloodGroup"
                            value={createEmployeeData?.bloodGroup || ""}
                            onChange={handleOnChange}
                            className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <option value="">Blood Group</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                          </select>
                          {error.bloodGroup && <p className="text-red-500 text-sm">{error.bloodGroup}</p>}

                        </div>
                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Govt Id No</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='Govt Id No' name='govtIdNo' id='govtIdNo' value={createEmployeeData?.govtIdNo || ""}
                            onChange={handleOnChange}
                          />
                          {error.govtIdNo && <p className="text-red-500 text-sm">{error.govtIdNo}</p>}
                        </div>
                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Qualification</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='qualification' name='qualification' id='qualification' value={createEmployeeData?.qualification || ""}
                            onChange={handleOnChange}
                          />
                          {error.qualification && <p className="text-red-500 text-sm">{error.qualification}</p>}
                        </div>

                        <div className='space-y-1'>
                          <label className='block text-gray-800 text-[14px]'>Skills</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='skills' name='skills' id='skills' value={createEmployeeData?.skills || ""}
                            onChange={handleOnChange}
                          />
                          {error.skills && <p className="text-red-500 text-sm">{error.skills}</p>}
                        </div>

                        <div className='space-y-1'>
                          <label className='block text-gray-700 text-[14px]'>Previous Experience</label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='previous experience' name='previousExperience' id='previousExperience' value={createEmployeeData?.previousExperience || ""}
                            onChange={handleOnChange}
                          />
                          {error.previousExperience && <p className="text-red-500 text-sm">{error.previousExperience}</p>}
                        </div>

                        <div className='space-y-1'>
                          <label className='block text-gray-700 text-[14px]'>Monthly Salary <span className='text-xs text-gray-900'>(+ Incentive)</span></label>
                          <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='monthly salary' name='monthlySalary' id='monthlySalary' value={createEmployeeData?.monthlySalary || ""}
                            onChange={handleOnChange}
                          />
                          {error.monthlySalary && <p className="text-red-500 text-sm">{error.monthlySalary}</p>}
                        </div>

                        <div>
                          <label>Image</label>
                          <input type="file" id="image" accept="image/png, image/jpeg, image/svg+xml" onChange={uploadEmpImage} className="w-full p-1 border border-gray-300 rounded" />
                        </div>
                        {createEmployeeData.image && (
                          <div className="mt-4">
                            <img
                              src={createEmployeeData.image}
                              alt={createEmployeeData.name}
                              className="w-12 h-12 rounded"
                            />
                          </div>
                        )}
                      </div>
                      <div className='flex justify-center py-4'>
                        <button type="submit"
                          className="w-60 bg-[#023565] text-white font-semibold py-2 px-4 rounded-full">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>


          </>

        )
      }
      {passwordData?.open && <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
        <div className="relative w-11/12 mx-auto lg:w-[600px]">
          <button
            className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
            onClick={() => { setPasswordData({}); setErrors({}) }}
          >
            <span><MdOutlineClose /></span> Close
          </button>
          <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h2 className="text-lg font-semibold">Update Password</h2>
            </div>
            <div className="relative  overflow-y-auto">
              <form onSubmit={handleSubmitPassword} className="p-4">
                <div className="mb-4">
                  <input type='text' className='w-full p-2 border border-gray-300 rounded' placeholder='Password' name='password' id='password'
                    value={passwordData?.password || ""}
                    onChange={(e) => setPasswordData((prev) => ({ ...prev, password: e.target.value }))}
                  />
                  {error.password && <p className="text-red-500 text-sm">{error.password}</p>}

                </div>
                <div className="flex justify-center space-x-2">
                  <button
                    type="submit"
                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>}

    </>
  );
};
