import { classConstants } from "../_constants";

export default function Class(state = {}, action) {

    switch (action.type) {
        case classConstants.GET_CLASS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case classConstants.GET_CLASS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                classList:action?.users?.data?.list,
                classTotal:action?.users?.data?.total
            };
        case classConstants.GET_CLASS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


         case classConstants.CREATE_CLASS_LIST_REQUEST:
         return {
            ...state,
            loading:true,
           };
        case classConstants.CREATE_CLASS_LIST_SUCCESS:
            return {
                ...state,
                loading:false,
                classCreate:action?.users
            };
         case classConstants.CREATE_CLASS_LIST_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error
            } ;


            case classConstants.DELETE_CLASS_LIST_REQUEST:
                return {
                  ...state,
                  loading:true
                };
              case classConstants.DELETE_CLASS_LIST_SUCCESS:
               return{
                ...state,
                loading:false,
               };
              case classConstants .DELETE_CLASS_LIST_FAILURE:
                return {
                  ...state,
                  loading:false,
                  error:action.error
                };  


                case classConstants.UPDATE_CLASS_LIST_REQUEST:
                return {
                  ...state,
                  loading:true
                };
              case classConstants.UPDATE_CLASS_LIST_SUCCESS:
               return{
                ...state,
                loading:false,
               };
              case classConstants .UPDATE_CLASS_LIST_FAILURE:
                return {
                  ...state,
                  loading:false,
                  error:action.error
                }  

                
                case classConstants.ENA_DIS_CLASS_REQUEST:
                  return {
                    ...state,
                    loading:true
                  };
                case classConstants.ENA_DIS_CLASS_SUCCESS:
                 return{
                  ...state,
                  loading:false,
                 };
                case classConstants .ENA_DIS_CLASS_FAILURE:
                  return {
                    ...state,
                    loading:false,
                    error:action.error
                  }      
                  
                  

                  case classConstants.GET_ALL_SUBJECT_REQUEST:
                    return {
                      ...state,
                      loading:true
                    };
                  case classConstants.GET_ALL_SUBJECT_SUCCESS:
                   return{
                    ...state,
                    loading:false,
                    getAllSubject:action?.users
                   };
                  case classConstants .GET_ALL_SUBJECT_FAILURE:
                    return {
                      ...state,
                      loading:false,
                      error:action.error
                    }  

        default:
            return state
    };

}