import { headerForPrivateAPI, APIcallFunction } from "../_helpers";
import { tutionConstant } from "../_constants";
import { alertActions } from "./alert.actions";
import { dashboardAction } from "./dashboard.action";

export const tutionAction = {
  getDemoClassListStatus,
  suggestedTutorListForAdmin,
  getDemoById,
  updateStatusDemoApproveOrReject,
  revertDemoClass,
  assignTutorByAdmin,
  createCopyByAdmin,
  getDemoClassListStatus1,
  demoDoneByAdmin,
  tuitionRemoveHoldByAdmin,
  changeTutorByAdmin,
  getLogHistoryList,
  tuitionHoldByAdmin,
  closeTuitionClassByAdmin,
  updateTuitionMode,
  activeDemoListByParentId,
  createTuition,
  getAllTutorList,
  getAllParentList,
  getParentIdGetPinCode,
  getStudentListByParentId,
  updateDemoInfoByAdmin,
  getAllCityList,
  getCityPinCodeList,
  createDemoByAdmin,
  gitAllClassListByAdmin,
  getTuitionCount,
  getAllBoardByAdmin,
  getOnGoingTuitionList,
  tuitionRequestDeniedByAdmin,
  getCreditBaseDemoClassListByStatus,
  addUpdateRemark,
  getTutorById,
  updateStatusDemoApproveOrReject1,
  UpdateInfoActiveOnApp,
  getDemoByIdPromise,
  getChangeTutorRequestTuitionList,
  classReferenceList,
  getAllLeadManger,
  getStudentListByParentIdForDemo,
  checkMobNoByPromise,
  deniedChangeTutorRequest,
  updateDemoStatusByAdminByPromise,
  deleteDemoByAdminByPromise
}

function updateDemoStatusByAdminByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/updateDemoStatusByAdmin"
  };

  return APIcallFunction(credentials)

}

function deleteDemoByAdminByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/deleteDemoByAdmin"
  };

  return APIcallFunction(credentials)

}

function deniedChangeTutorRequest(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/deniedChangeTutorRequest"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success("Successfully denied the tutor change request."))
        dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.DENIED_CHANGE_TUTOR_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.DENIED_CHANGE_TUTOR_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.DENIED_CHANGE_TUTOR_FAILURE, error };
  }
}


function checkMobNoByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "user/checkMobileNumber"
  };

  return APIcallFunction(credentials)

}





function getStudentListByParentIdForDemo(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getStudentListByParentIdForDemo"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_FAILURE, error };
  }
}

function getAllLeadManger(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "admin/getAllLeadManger"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_ALL_LEAD_MANAGER_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_ALL_LEAD_MANAGER_SUCCESS  , user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_ALL_LEAD_MANAGER_FAILURE, error };
  }
}

function classReferenceList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/classReferenceList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
      }
    );

  };
  function request() {
    return { type: tutionConstant.CLASS_REFERENCE_LIST_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.CLASS_REFERENCE_LIST_SUCCESS  , user };
  }
  function failure(error) {
    return { type: tutionConstant.CLASS_REFERENCE_LIST_FAILURE, error };
  }
}

function suggestedTutorListForAdmin(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/suggestedTutorListForAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_SUGGESTED_TUTOR_LIST_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_SUGGESTED_TUTOR_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_SUGGESTED_TUTOR_LIST_FAILURE, error };
  }
}

function getDemoClassListStatus(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getDemoClassListByStatus"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getTuitionCount())

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GETDEMO_CLASSLIST_BYSTATUS_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GETDEMO_CLASSLIST_BYSTATUS_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GETDEMO_CLASSLIST_BYSTATUS_FAILURE, error };
  }
}

function getChangeTutorRequestTuitionList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getChangeTutorRequestTuitionList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_TUITION_TUTOR_CHANGE_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_TUITION_TUTOR_CHANGE_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_TUITION_TUTOR_CHANGE_FAILURE, error };
  }
}
function getDemoClassListStatus1(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getDemoClassListByStatus"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getTuitionCount())
        // getTuitionCount()

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_DEMO_CLASSLIST_BY_STATUS_1_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_DEMO_CLASSLIST_BY_STATUS_1_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_DEMO_CLASSLIST_BY_STATUS_1_FAILURE, error };
  }
}


function getDemoById(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getDemoById"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        getTuitionCount()

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_DEMO_BYID_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_DEMO_BYID_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_DEMO_BYID_FAILURE, error };
  }
}

function getDemoByIdPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getDemoById"
  };

  return APIcallFunction(credentials)

}


function updateStatusDemoApproveOrReject(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/updateStatusDemoApproveOrReject"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(data.status !== "REJECTED" ? "Demo class activate successfully.": "Demo class rejected successfully."))
        dispatch(getTuitionCount())
        dispatch(getDemoClassListStatus(demoData))
        dispatch(getDemoClassListStatus1(demoData))
        dispatch(dashboardAction.getLatestDemoRequestList(demoData));
        dispatch(dashboardAction.getDashboardCount());

      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: tutionConstant.UPDATE_STATUS_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.UPDATE_STATUS_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.UPDATE_STATUS_FAILURE, error };
  }
}


function updateStatusDemoApproveOrReject1(data, demoReq) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/updateStatusDemoApproveOrReject"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(dashboardAction.getLatestDemoRequestList(demoReq))
        dispatch(dashboardAction.getDashboardCount());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: tutionConstant.UPDATE_STATUS_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.UPDATE_STATUS_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.UPDATE_STATUS_FAILURE, error };
  }
}

function revertDemoClass(data, listData, demoRequestDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/revertDemoClass"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getDemoClassListStatus(listData))
        dispatch(getDemoClassListStatus1(listData))
        // dispatch(dashboardAction.getLatestDemoDoneList(demoRequestDetails))
        // dispatch(getOnGoingTuitionList())
        dispatch(getTuitionCount())

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.REVERT_DEMO_CLASS_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.REVERT_DEMO_CLASS_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.REVERT_DEMO_CLASS_FAILURE, error };
  }
}



function assignTutorByAdmin(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/assignTutorByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(dashboardAction.getDashboardCount());
        dispatch(getDemoClassListStatus(demoData))
        dispatch(dashboardAction?.getLatestDemoRequestList(demoData))


        dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.ASSIGN_TUTOR_BYADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.ASSIGN_TUTOR_BYADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.ASSIGN_TUTOR_BYADMIN_FAILURE, error };
  }
}


function createCopyByAdmin(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/createCopyByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getDemoClassListStatus(demoData))
        dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.CREATE_COPY_BYADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.CREATE_COPY_BYADMIN_FAILURE, user };
  }
  function failure(error) {
    return { type: tutionConstant.CREATE_COPY_BYADMIN_FAILURE, error };
  }
}


function demoDoneByAdmin(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/demoDoneByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getDemoClassListStatus(demoData))
        dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.DEMO_DONE_BYADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.DEMO_DONE_BYADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.DEMO_DONE_BYADMIN_FAILURE, error };
  }
}


function tuitionRemoveHoldByAdmin(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/tuitionRemoveHoldByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getDemoClassListStatus(demoData))
        dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.TUITION_REMOVE_HOLD_BY_ADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.TUITION_REMOVE_HOLD_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.TUITION_REMOVE_HOLD_BY_ADMIN_FAILURE, error };
  }
}

function changeTutorByAdmin(data, demoData, demoRequestDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/changeTutorByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getTuitionCount())
        dispatch(dashboardAction.getLatestChangeTutorRequest(demoRequestDetails))
        dispatch(dashboardAction.getLatestDemoDoneList(demoRequestDetails))
        dispatch(getDemoClassListStatus(demoData))
        dispatch(getDemoClassListStatus1(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.CHANGE_TUTOR_BY_ADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.CHANGE_TUTOR_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.CHANGE_TUTOR_BY_ADMIN_FAILURE, error };
  }
}

function getLogHistoryList(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getLogHistoryList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_LOG_HISTORY_LIST_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_LOG_HISTORY_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_LOG_HISTORY_LIST_FAILURE, error };
  }
}

function tuitionHoldByAdmin(data, datas) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/tuitionHoldByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getOnGoingTuitionList(datas))
        dispatch(getTuitionCount())
        dispatch(getDemoClassListStatus(datas))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.TUITION_HOLD_BY_ADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.TUITION_HOLD_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.TUITION_HOLD_BY_ADMIN_FAILURE, error };
  }
}


function closeTuitionClassByAdmin(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/closeTuitionClassByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getOnGoingTuitionList(demoData))
        dispatch(getTuitionCount())
        dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.TUITION_CLOSE_BY_ADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.TUITION_CLOSE_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.TUITION_CLOSE_BY_ADMIN_FAILURE, error };
  }
}

function updateTuitionMode(data, parentIdSet) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/updateTuitionMode"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(activeDemoListByParentId(parentIdSet))
        dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.UPDATE_TUITION_MODE_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.UPDATE_TUITION_MODE_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.UPDATE_TUITION_MODE_FAILURE, error };
  }
}

function activeDemoListByParentId(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/activeDemoListByParentId"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
        dispatch(getTuitionCount())
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.ACTIVE_DEMO_LIST_BY_PERENTID_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.ACTIVE_DEMO_LIST_BY_PERENTID_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.ACTIVE_DEMO_LIST_BY_PERENTID_FAILURE, error };
  }
}


function createTuition(data, demoData, demoRequestDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/createTuition"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getDemoClassListStatus(demoData))
        dispatch(getDemoClassListStatus1(demoData))
        dispatch(getTuitionCount())
        dispatch(dashboardAction.getLatestDemoRequestList(demoRequestDetails))
        dispatch(dashboardAction.getLatestDemoDoneList(demoRequestDetails))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.CREATE_TUITION_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.CREATE_COPY_BYADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.CREATE_TUITION_FAILURE, error };
  }
}

function getAllTutorList(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getAllTutorList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getTuitionCount())
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_ALL_TUTOR_LIST_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_ALL_TUTOR_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_ALL_TUTOR_LIST_FAILURE, error };
  }
}

function getAllParentList(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getAllParentList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_ALL_PARENT_LIST_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_ALL_PARENT_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_ALL_PARENT_LIST_FAILURE, error };
  }
}


function getParentIdGetPinCode(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getParentIdGetPinCode"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_PARENT_ID_GET_PIN_CODE_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_PARENT_ID_GET_PIN_CODE_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_PARENT_ID_GET_PIN_CODE_FAILURE, error };
  }
}


function getStudentListByParentId(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getStudentListByParentId"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_FAILURE, error };
  }
}


function updateDemoInfoByAdmin(data, listPayload) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/updateDemoInfoByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        if(listPayload){
          dispatch(getDemoClassListStatus(listPayload));
        }
        // dispatch(getDemoById(demoData))
        dispatch(alertActions.success('Updated successfully.'))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.UPATE_DEMO_INFO_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.UPATE_DEMO_INFO_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.UPATE_DEMO_INFO_FAILURE, error };
  }
}


function getAllCityList(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    // body: data ?? {},
    endPoint: "city/getAllCityList"
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_ALL_CITY_LIST_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_ALL_CITY_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_ALL_CITY_LIST_FAILURE, error };
  }
}



function getCityPinCodeList(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "pinCode/getCityPinCodeList "
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_CITY_PINCODE_LIST_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_CITY_PINCODE_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_CITY_PINCODE_LIST_FAILURE, error };
  }
}



function createDemoByAdmin(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/createDemoByAdmin "
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.CREATE_DEMO_BY_ADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.CREATE_DEMO_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.CREATE_DEMO_BY_ADMIN_FAILURE, error };
  }
}


function gitAllClassListByAdmin(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "class/gitAllClassListByAdmin "
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getDemoClassListStatus(demoData))
      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_ALL_CLASS_LIST_BY_ADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_ALL_CLASS_LIST_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_ALL_CLASS_LIST_BY_ADMIN_FAILURE, error };
  }
}

function getTuitionCount(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getTuitionCount "
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));

      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_TUITION_COUNT_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_TUITION_COUNT_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_TUITION_COUNT_FAILURE, error };
  }
}








function getAllBoardByAdmin(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "board/getAllBoardByAdmin "
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));

      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_ALL_BOARD_LIST_BY_ADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_ALL_BOARD_LIST_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_ALL_BOARD_LIST_BY_ADMIN_FAILURE, error };
  }
}












function getOnGoingTuitionList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getOnGoingTuitionList"
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));

      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_ONGOING_TUITION_LIST_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_ONGOING_TUITION_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_ONGOING_TUITION_LIST_FAILURE, error };
  }
}










function tuitionRequestDeniedByAdmin(data, demoDoneListData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/tuitionRequestDeniedByAdmin"
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));
        dispatch(dashboardAction.getLatestChangeTutorRequest(demoDoneListData))


      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.TUITION_DENIE_BYADMIN_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.TUITION_DENIE_BYADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.TUITION_DENIE_BYADMIN_FAILURE, error };
  }
}


function getCreditBaseDemoClassListByStatus(data, demoDoneListData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "creditBaseDemoClass/getCreditBaseDemoClassListByStatus"
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_FAILURE, error };
  }
}


function addUpdateRemark(data, demoData, parentIdSet) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "DEMO/addUpdateRemark"
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getDemoClassListStatus(demoData))
        dispatch(getDemoClassListStatus1(demoData))
        if (parentIdSet && parentIdSet !== 'Hold') {
          dispatch(activeDemoListByParentId(parentIdSet))
        }

        dispatch(getTuitionCount())
        dispatch(alertActions.success("Success."))
      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.ADD_REMARK_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.ADD_REMARK_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.ADD_REMARK_FAILURE, error };
  }
}







function getTutorById(data, demoData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getTutorById"
  };


  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getDemoClassListStatus(demoData))
        dispatch(getDemoClassListStatus1(demoData))

      },
      (error) => {

        dispatch(failure(error));
        dispatch(alertActions.error(error));

      }
    );

  };
  function request() {
    return { type: tutionConstant.GET_TUTOR_DETAILS_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.GET_TUTOR_DETAILS_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.GET_TUTOR_DETAILS_FAILURE, error };
  }
}









function UpdateInfoActiveOnApp(data, parentIdSet) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/UpdateInfoActiveOnApp"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(activeDemoListByParentId(parentIdSet))
        dispatch(alertActions.success("Successfully updated."))

      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: tutionConstant.UPDATE_INFO_ACTIVE_ON_APP_REQUEST };
  }
  function success(user) {
    return { type: tutionConstant.UPDATE_INFO_ACTIVE_ON_APP_SUCCESS, user };
  }
  function failure(error) {
    return { type: tutionConstant.UPDATE_INFO_ACTIVE_ON_APP_FAILURE, error };
  }
}
