/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classAction } from "../../_actions/class.action";
import { subjectAction } from "../../_actions/subject.action";
import { connect } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineAdd, MdOutlineClose } from "react-icons/md";
import Multiselect from "multiselect-react-dropdown";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../../components/Loader";
import { timeZoneReturnDateType } from "../../_helpers";

const ClassComponent = (props) => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state)
  let { classList, searchText, setRefresh } = props;
  let [subjectList, setSubjectList] = useState();
  const [error, setError] = useState("");

  const [classModal, setClassModal] = useState(false);

  const [actionClassId, setActionClassId] = useState(null);
  const [name, setName] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [offset, setoffset] = useState(0);
  const [classEditFormModal, setClassEditFormModal] = useState(false);
  const [newEditClassName, setNewEditClassName] = useState({
    name: "",
    sortName: "",
    newEditClassName: '',
    subjectId: [],
  });

  // const [error, setError] = useState("");


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;


  const menuRef = useRef(null)


  const mobileRef = useRef(null)


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setActionClassId(null);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const [selectedValues, setSelectedValues] = useState([]);
  const options = subjectList?.filter(e => !e?.isDisable)?.map((item, id) => ({ name: item.name, id: item._id }));

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValues(selectedList);
    if (selectedList.length > 0) {
      setError("");
    }
    if (errorData && errorData["selectedValues"]) {
      setErrorData((prevErrorData) => ({ ...prevErrorData, selectedValues: null }));
    }

  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(selectedList);
    if (selectedList.length === 0) {
      setError("Please select at least one subject");
    }
  };

  useEffect(() => {
    const ClassList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const ClassList1 = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: pageNo,
      size: 1000,
    };
    dispatch(classAction.classGet(ClassList));
    dispatch(subjectAction.subjectGet(ClassList1));

  }, [currentPage, searchText]);

  const DeleteClass = (id) => {
    const ClassList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const DeleteId = { id };

    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => { dispatch(classAction.classDelete(DeleteId, ClassList)); setRefresh(true) }

        },
        {
          label: 'No'
        }
      ]
    });

  };
  ////Class Create Function=================================>

  const classCreateFunction = () => {
    setClassModal(true);
    dispatch(classAction.gitAllSubjectList())
    setSelectedValues('')
  };

  useEffect(() => {
    setSubjectList(selector?.Subject?.SubjectList)
  }, [selector])


  const handleClassForm = (event) => {
    event.preventDefault();
    if (!handleValidation()) {
      return;
    }

    let subjectId = selectedValues.map(item => item.id);
    const ClassList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };

    const classCreateName = { name, subjectId };
    dispatch(classAction.classCreate(classCreateName, ClassList));
    setClassModal(false);
    closeModal()
    setRefresh(true)

  };


  const handleClassEditForm = (data) => {

    setClassEditFormModal(true);

    setNewEditClassName({
      name: data.name,
      sortName: data.sortName,
      _id: data._id,
      subjectId: data.subjectId?.map((itm) => ({ name: itm.name, _id: itm._id })),
    });
    setSelectedValues(data.subjectId?.map((itm) => ({ name: itm.name, id: itm._id })));
  };

  const handleEditClass = (event) => {
    setNewEditClassName({ ...newEditClassName, name: event.target.value });
  };

  const handleClassEditFormSubmit = () => {
    const ClassList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };

    const editClassData = {
      id: newEditClassName._id,
      name: newEditClassName.name,
      subjectId: selectedValues.map((itm) => itm.id),
    }

    dispatch(classAction.classEdit(editClassData, ClassList));
    setClassEditFormModal(false);
  };

  const handleClassAction = (item) => {
    const ClassList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const EnaDis = { id: item._id };

    confirmAlert({
      title: `Confirm to ${item?.isDisable === false ? 'Inactive' : 'Active'}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(classAction.classEnaDis(EnaDis, ClassList))

        },
        {
          label: 'No'
        }
      ]
    });



  };

  const handleAction = (cityId) => {
    setActionClassId((prevCityId) => (prevCityId === cityId ? null : cityId));
  };

  const closeModal = () => {
    setClassModal(false)
    setName("")
    setSelectedValues("")
    setErrorData({})

  }


  useEffect(() => {
    if (selector?.Class?.classTotal) {
      setTotalPages(Math.ceil(selector?.Class?.classTotal / pageSize));
    }
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const [errorData, setErrorData] = useState({})
  const handleValidation = () => {
    const errors = {};
    if (!name) {
      errors["name"] = "Name is required";
    }
    if (selectedValues.length === 0) {
      errors["selectedValues"] = "Please select at least one subject";
    }

    setErrorData(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <div className="flex justify-end px-6 md:-mt-16">
        <span className="bg-white rounded-lg text-[#C60E6B] font-semibold px-4 py-2 mb-2 cursor-pointer flex justify-between items-center space-x-1" onClick={classCreateFunction}>  <MdOutlineAdd className="mx-2 " /> Add New</span>
      </div>

      <Loader loading={selector?.Class?.loading} />

      <div className="m-5">

        <section className="lg:block hidden">
          <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
            <table className="min-w-full table-autorounded-xl overflow-x-auto">
              <thead className="border-b border-[#D1D1D1]">
                <tr className="text-black">
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">S.No</th>
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">Name</th>
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">Subject</th>
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">Status</th>
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">Create Date</th>
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {classList && classList.length > 0 ? (classList.map((item, id) => (
                  <tr key={id} className="border-b border-[#D1D1D1] capitalize">
                    <td className="px-4 py-4 text-sm text-gray-900 text-left whitespace-nowrap">
                      {(currentPage - 1) * pageSize + id + 1}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-left whitespace-nowrap">
                      {item.name.toUpperCase()}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-left">
                      {item?.subjectId?.length > 0 ? (item.subjectId.map((itm, index) =>
                        (<span key={index}>{index > 0 && ", "}{itm?.name || "N/A"}</span>))) : (<span>N/A</span>)}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-left whitespace-nowrap">
                      <span className={`whitespace-nowrap font-semibold ${item.isDisable ? "text-red-600" : "text-green-700"}`}>
                        {item.isDisable ? "Inactive" : "Active"}
                      </span>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-left whitespace-nowrap">
                      {timeZoneReturnDateType(item?.createdAt)}
                    </td>

                    <td className="px-4 py-4 text-gray-900 relative">
                      <span className="flex justify-center items-center">
                        <BsThreeDotsVertical onClick={() => handleAction(item._id)} className="text-2xl cursor-pointer text-red-500 " />
                      </span>
                      {actionClassId === item._id && (
                        <div className="absolute right-10 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-50" ref={menuRef}>
                          <ul className="w-full text-[#023565]">
                            <li className="flex justify-between items-center px-2 py-1 hover:text-[#E4006F] border-b cursor-pointer hover:bg-gray-100 rounded">
                              <span className={`cursor-pointer ${item.isDisable ? "text-green-700" : "text-[#023565]"}`} onClick={() => handleClassAction(item)}>
                                {item.isDisable ? "Active" : "Inactive"}
                              </span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                            <li className="flex justify-between place-items-center px-2 py-1 hover:text-[#E4006F] border-b font- cursor-pointer hover:bg-gray-100 rounded" onClick={() => handleClassEditForm(item)}>
                              <div className="p">View & Update</div>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                            <li className="flex justify-between items-center px-2 py-1 cursor-pointer hover:text-[#E4006F] font- hover:bg-gray-100 rounded" onClick={() => DeleteClass(item._id)}>
                              <div className="p">Remove</div>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                          </ul>
                        </div>
                      )}

                    </td>

                  </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center text-gray-500 py-4">
                      Data Not Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        {classModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={closeModal}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Create  Class </h2>
                  </div>
                  <div className="relative">
                    <form onSubmit={handleClassForm} className="space-y-2 p-4">
                      <div className="space-y-2">
                        <label
                          htmlFor="className"
                          className="block 2 text-sm font-medium text-gray-900"
                        >
                          Class Name:
                        </label>
                        <input
                          type="text"
                          id="className"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            if (errorData && errorData['name']) {
                              setErrorData((prevErrorData) => ({ ...prevErrorData, name: null }))
                            }
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg capitalize focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          placeholder="Enter class name..."
                        />
                        {errorData && errorData["name"] ? <p className="text-red-500 mt-2">{errorData["name"]}</p> : null}
                      </div>
                      <div className="space-y-2">
                        <label
                          htmlFor="className"
                          className="block 2 text-sm font-medium text-gray-900"
                        >
                          Subject :
                        </label>
                        <Multiselect
                          options={options}
                          selectedValues={selectedValues}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          displayValue="name"
                        />
                        {errorData && errorData["selectedValues"] ? <p className="text-red-500 mt-2">{errorData["selectedValues"]}</p> : null}
                      </div>
                      <div className="flex justify-center items-center pt-2">
                        <button
                          type="submit"
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Add New
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
        }

        {
          classEditFormModal && (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                  <button
                    className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                    onClick={() => setClassEditFormModal(false)}
                  >
                    <span><MdOutlineClose /></span> Close
                  </button>
                  <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t">
                      <h2 className="text-lg font-semibold">Update Class</h2>
                    </div>
                    <div className="relative">
                      <form className="space-y-2 p-4">
                        <div className="space-y-2">
                          <label
                            htmlFor="classEditName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Class Name:
                          </label>
                          <input
                            type="text"
                            id="classEditName"
                            value={newEditClassName.name}
                            onChange={handleEditClass}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md capitalize"
                            required
                          />
                        </div>
                        <div className="space-y-2">
                          <label
                            htmlFor="sortEditName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Subject:
                          </label>
                          <Multiselect
                            options={options}
                            selectedValues={selectedValues}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue="name"

                          />
                        </div>
                        <div className="flex justify-center items-center pt-2">
                          <button
                            type="button"
                            className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                            onClick={handleClassEditFormSubmit}
                          >
                            Update Class
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }

        <section className="md:hidden block py-4 space-y-5 overflow-hidden overflow-y-auto">
          {classList &&
            classList.length > 0 ? (
            classList.map((item, id) => (
              <div className=' bg-white p-4 rounded-[10px]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>S.No</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>{offset + id + 1}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Name</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>{item && item.name ? item.name : null}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Subject</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.subjectId?.length > 0 ? (
                          item.subjectId.map((itm, index) => (
                            <span key={index}>
                              {index > 0 && ", "}
                              {itm?.name || "N/A"}
                            </span>
                          ))
                        ) : (
                          <span>N/A</span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Status</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        <span
                          className={`font-semibold ${item.isDisable ? "text-red-600" : "text-[#00AFE2]"
                            }`}
                        >
                          {item.isDisable ? "Inactive" : "Active"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Created At</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'> {timeZoneReturnDateType(item?.createdAt)}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left relative'>
                        <BsThreeDotsVertical
                          className="text-2xl cursor-pointer  hover:text-[#E4006F] relative"
                          onClick={() => handleAction(item._id)}
                        />
                        {actionClassId === item._id && (
                          <div
                            className="absolute  border right-5 py-1 px-2  bg-white  border-gray-300 rounded-lg shadow-lg z-10 "
                            ref={mobileRef}

                          >
                            <ul className="w-full">
                              <li className="flex justify-between items-center  text-[#C60E6B] border-b cursor-pointer hover:bg-gray-100 rounded">
                                <span
                                  className={`cursor-pointer ${item.isDisable ? "text-red-600" : "text-green-600"}`}
                                  onClick={() => handleClassAction(item)}
                                >
                                  {item.isDisable ? "Activate" : "InActive"}
                                </span>
                                <img src="/Group 1000001172.png" alt="icon" />
                              </li>
                              <li className="flex justify-between place-items-center text-[#023565] border-b - cursor-pointer hover:bg-gray-100 p-1 rounded" onClick={() => handleClassEditForm(item)}>
                                <div className="p">View & Update</div>
                                <img src="/Group 1000001230.png" alt="icon" />
                              </li>
                              <li className="flex justify-between items-center gap-3 cursor-pointer text-[#023565] - hover:bg-gray-100 p-1 rounded" onClick={() => DeleteClass(item._id)}>
                                <div className="p">Delete</div>
                                <img src="/Group 1000001230.png" alt="icon" />
                              </li>
                            </ul>
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
            <div>
              <p className="text-center text-gray-500 py-4">
                Data Not Available
              </p>
            </div>
          )}
        </section>
      </div >
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">
              {currentPage}
            </span>{" "}
            of{" "}
            <span className="border px-4 py-2 text-black  rounded">
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages
              ? "opacity-50 cursor-not-allowed"
              : ""
              }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  classList: state.Class.classList,
  classTotal: state.Class.classTotal,
});

export default connect(mapStateToProps)(ClassComponent);
