/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { alertActions, parentAction, tutionAction, userActions, userListAction } from "../../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaAngleRight, FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import Loader from "../../../components/Loader";
import { timeStanderFollowFunction, truncateText } from "../../../_helpers";
import StudentDetail from "./Models/StudentDetail";
import ImagePreviewModal from "../../Tutor-Profile/Model/ImagePreview";
import UpdateNoteModel from "./Models/UpdateNoteModel";
import UpdateTuitionModel from "./Models/UpdateTuitionModel";
import AssignTutorPopUp from "./Models/AssignTutorPopUp";
import ViewParent from "./Models/ViewParent";
import { suggestTutorBtn } from "../../../_helpers/dataInitialState";
import TuitionFilters from "./TuitionFilters";
import ViewMoreTuitionModel from "./Models/ViewMoreTuitionModel";
import { FaRegUserCircle } from "react-icons/fa";


export function teachModeViewData(data) {
  switch (data) {
    case "HOME_TUITION":
      return "In-Home"
    case "ONLINE_TUITION":
      return "Online"
    default:
      return null
  }
}

const ButtonFourTabel = (props) => {
  const { headerCity, searchText } = props;
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const suggestedTutor = selector?.tution?.suggestedTutorList?.data
    ? selector?.tution?.suggestedTutorList?.data
    : null;
  const [open, setOpen] = useState(false);
  const [onDemoListData, setOnDemoListData] = useState([]);
  const [fullDetailsData, setFullDetailsData] = useState();
  const [assignTutorModal, setAssignTutorModal] = useState(false);
  const [assignTutorList, setAssignTutorList] = useState("");
  const [demoIdForAssign, setDemoIdForAssign] = useState({});
  const [assignTutorTab, setAssignTutorTab] = useState("Suggested Tutors");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [rejectResionModal, setRejectResionModal] = useState(false)
  const [rejectResion, setRejectResion] = useState('')
  const [rejectData, setRejectData] = useState({})


  const [tutorDetailsModal, setTutorDetailsModal] = useState(false);
  const [tutorDetailsModalData, setTutorDetailsModalData] = useState({})
  const [fullData, setFullData] = useState('')
  const [tutorDetails, setTutorDetails] = useState(false);


  const menuRef = useRef(null)


  const mobileRef = useRef(null)

  const [filters, setFilters] = useState({
    tutorFee: "",
    preferredTeachMode: "",
    tutorId: "",
    classId: "",
    subjectId: "",
    classReference: "",
    leadSource: "",
    requiredGender: "",
    assignToEmployee: "",
    parentId: ""
  })


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setOpen(null);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const navigate = useNavigate();

  useEffect(() => {
    let onDemoTuition = {
      status: [4, 5],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId != "") ? [filters.tutorId] : [],
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],

    };
    dispatch(tutionAction.getDemoClassListStatus(onDemoTuition));
  }, [currentPage, filters, headerCity, searchText]);

  useEffect(() => {
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(Math.ceil(selector?.tution?.demoList?.data?.total / pageSize));
    }
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setOnDemoListData(() => selector?.tution?.demoList?.data?.list);
    setFullData(() => selector?.userList?.getTutorUpdateById?.data)

  }, [selector]);


  const openAction = (id) => {
    setOpen(id === open ? null : id);
  };

  const assignTutor = (data) => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode === 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }));
    dispatch(tutionAction.getAllTutorList(query));
    setAssignTutorModal(true);
    setAssignTutorList(data);
    setDemoIdForAssign(data._id);
    setOpen(false)
  };

  const handleAssignTutor = (item) => {
    setAssignTutorTab(item);
  };

  let allTutorDataList = props && props?.getAllTutorList && props?.getAllTutorList?.data
    ? props?.getAllTutorList?.data
    : [];




  const handleAssign = (data) => {
    let demoData = {
      status: [4, 5],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId != "") ? [filters.tutorId] : [],
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],


    };
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data,
    };
    dispatch(tutionAction.changeTutorByAdmin(assignTutorInfo, demoData));

    setAssignTutorModal(false)

    const alertBox2 = document.getElementById("alert-box1");
    alertBox2.style.display = "block";
    setTimeout(() => {
      alertBox2.style.display = "none";
    }, 1000);

    setOpen(false)



  };
  const handleHistory = (data) => {
    navigate(`/app/viewhistory/${data?._id}`);

    setFullDetailsData([data]);
  };


  const handleClassReject = (data) => {

    setRejectResionModal(true)

    let acceptedData = {
      status: "REJECTED",
      demoId: data._id,
    };

    setRejectData(acceptedData)
  };

  const handleViewAndUpdate = (data) => {
    navigate(`/app/viewandupdate/${data?._id}/${data?.parentId?._id}`);
  };
  /// Send to demo Request===========>
  const sendToDemoRequest = (data) => {
    let listData = {
      status: [4, 5],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId != "") ? [filters.tutorId] : [],
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],

    };
    let demorequestDetails = {
      demoId: data._id,
    };
    dispatch(tutionAction.revertDemoClass(demorequestDetails, listData));
    setOpen(false)
    dispatch(alertActions.success('Tuition Send To Demo Request'))

  };

  ///Mark Demo Done==========>
  const handleMarkDemoDone = (data) => {
    let Demodata = {
      status: [3],
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };

    let demoDoneDetails = {
      demoId: data._id
    }
    dispatch(tutionAction.demoDoneByAdmin(demoDoneDetails, Demodata))
    dispatch(alertActions.success('Mark Demo Done Success'))

  }
  const [details, setFullDetails] = useState(false);


  const [remarkModal, setRemarkModal] = useState(false)
  const [remarkId, setRemarkId] = useState('')
  const [remark, setRemark] = useState('')

  const addRemark = (data) => {
    let remarkId = data?._id
    setRemarkId(remarkId)
    setRemarkModal(true)
    setRemark(data?.reason ?? '')

  }

  const handleRemarkSubmit = (e) => {
    e.preventDefault()
    let demoData = {
      status: [3],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId != "") ? [filters.tutorId] : []
    };
    let resStatus = 'Hold'

    let remarkData =
    {
      "demoId": remarkId,
      remark: remark ? remark : 'N/As',

    }
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData, resStatus))
    setRemarkModal(false)
    setRemark('')
  }

  const remarkClose = () => {
    setRemark('')
    setRemarkModal(false)
  }


  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState("");
  const [tuitionDetails, setTuitionDetails] = useState({})

  const handleFullDetails = (data) => {
    userActions.getUserByPromiseId({ id: data.parentId._id }).then((res) => {
      setfullDetailsModal(true);
      setfullDetailsModalData(res?.data);
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        teachingLocation: data?.teachingLocation,
        requiredGender: data?.requiredGender,
        location: data?.location

      })
    })
  };

  const handleRejectResion = (e) => {
    e.preventDefault()

    let demoData = {
      status: [4, 5],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId != "") ? [filters.tutorId] : [],
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],

    };

    let data = {
      ...rejectData,
      remark: rejectResion
    }

    dispatch(
      tutionAction.updateStatusDemoApproveOrReject(data, demoData)
    );
    setRejectResionModal(false)
    setRejectResion('')
    const alertBox = document.getElementById("alert-box");
    alertBox.style.display = "block";
    setTimeout(() => {
      alertBox.style.display = "none";
    }, 1000);

  }


  const handlerejctCloseModal = () => {
    setRejectResionModal(false)
    setRejectResion('')
  }

  useEffect(() => {
    let details = {
      "cityId": "",
      "userType": "TUTOR",
      "isDisable": false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])


  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [studentListdata, setStudentListData] = useState();
  const viewStudent = (data, demoId) => {
    let studentListDetails = {
      parentId: data._id,
      demoId: demoId
    };
    parentAction.getStudentListByParentIdAndDemoIdPromise(studentListDetails).then((resData) => {
      setViewStudentModal(true);
      setStudentListData(resData.data)
    }).catch((err) => {
    });
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState("")
  const handleCloseFunctionImageModel = (data) => {
    if (data) {
      setImage(() => data)
    }
    else {
      setImage("")
    }
    setIsModalVisible(!isModalVisible);
  };


  const [updateDemoModel, setUpdateDemoModel] = useState(false)
  const [inputChangeField, setInputChangeField] = useState({})
  const [allStudentList, setAllStudentData] = useState([])
  function updateDemoModelFunction(data) {
    tutionAction.getDemoByIdPromise({ demoId: data._id }).then((res) => {
      setUpdateDemoModel(true)
      let bookFreeDemo = [...res.data.bookFreeDemo]
      bookFreeDemo = bookFreeDemo.map(free => ({ ...free, studentId: free.studentId.studentFullName }))

      setInputChangeField({
        ...res.data,
        bookFreeDemo: bookFreeDemo
      })
    })

    let studentListDetails = {
      parentId: data.parentId._id,
    };
    parentAction.getStudentListByParentIdForDemoPromise(studentListDetails).then((resData) => {
      setAllStudentData(resData.data)
    }).catch((err) => {
    });
  }

  const [isViewMoreModel, setIsViewMoreModel] = useState(false)
  const [tuitionId, setTuitionId] = useState(null)




  return (
    <div className="  rounded-xl">
      <Loader loading={selector?.tution?.loading} />

      <TuitionFilters filters={filters} setFilters={setFilters} />
      <div className="bg-white border lg:block hidden border-[#D3D3D3] rounded-lg mt-5 overflow-x-auto ">
        <table className="min-w-full table-auto divide-y divide-gray-200 ">
          <thead className="min-w-full rounded-sm">
            <tr className="table_head border-b ">
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Parent Name</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Student</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                <tr className="flex justify-center items-center">{" "}Address</tr>
                <tr className="flex justify-center items-center"> City</tr>
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                <tr className="flex justify-center items-center">{" "} Demo Date</tr>
                <tr className="flex justify-center items-center"> Time</tr>
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Assigned Tutor</th>
              {/* <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Tutor Status</th> */}
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                <tr className="flex justify-center items-center">Lead Source</tr>
                <tr className="flex justify-center items-center">Manager</tr>
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Mode</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Note</th>
              <th className="px-6 py-3 whitespace-nowrap text-sm">Action</th>
            </tr>
          </thead>

          <tbody className=" divide-y divide-gray-200">
            {onDemoListData && onDemoListData.length > 0 ? (
              onDemoListData?.map((item, id) => (
                <tr key={id} className={`border-b capitalize `}>
                  <td className="px-4 py-4 flex items-center gap-4 text-sm text-gray-900">
                    <div className="flex items-center justify-center gap-2">
                      <span onClick={item?.parentId?.image ? () => handleCloseFunctionImageModel(item?.parentId?.image) : null}>
                        {item?.parentId && item?.parentId?.image ?
                          <img
                            alt=""
                            src={
                              item?.parentId && item?.parentId?.image
                                ? item?.parentId?.image
                                : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                            }
                            onError={(e) => {
                              e.target.src =
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                            }}
                            className="rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                          />
                          : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                            <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                          </div>}
                      </span>
                      <div className="w-24">
                        <p className="font-semibold whitespace-nowrap text-[#023565]">
                          {item?.parentId?.name ?? "Not Available"}
                        </p>
                        <p className="text-gray-500 whitespace-nowrap">
                          {item?.parentId?.mobNo ?? "Not Available"}
                        </p>
                        <div className="flex items-center  gap-1 cursor-pointer" onClick={() => handleFullDetails(item)} >
                          <p className="text-base font-normal cursor-pointer">More</p>
                          <FaChevronCircleRight
                            className="text-[#023565] text-base cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="px-4 py-4 text-sm">
                    <span className="flex items-center justify-center"><FaEye size={24} className="cursor-pointer text-[#023565] hover:text-[#E4006F]" onClick={() => viewStudent(item?.parentId, item._id)} /></span>
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-900">
                    <span className="flex justify-center items-center text-center cursor-pointer" title={item?.address || 'N/A'}>  {truncateText(item?.address, 15) ?? 'N/A'}</span>
                    <span className="flex justify-center items-center">{item.cityId?.name ?? "N/A"}</span>
                  </td>

                  <td className="px-4 py-4 text-sm text-gray-900">
                    <span className="text-center flex items-center justify-center">{item?.demoDate ? item?.demoDate : null}</span>
                    <span className="text-center flex items-center justify-center">{item?.demoTime ? timeStanderFollowFunction(item?.demoTime) : null}</span>
                  </td>

                  <td className="px-4 py-4 text-sm">
                    <span className="text-center flex items-center justify-center text-[#023565] font-semibold">{item?.tutorId?.name}</span>
                    <span className="text-center flex items-center justify-center text-gray-500">{item?.tutorId?.mobNo}</span>
                  </td>

                  <td className="px-4 py-4 text-sm text-gray-900">
                    <span className="flex items-center justify-center">{item.leadSource === "CREATED_SELF" ? "App" : "Admin"}</span>
                    <span className="flex items-center justify-center">{item?.assignToEmployee ? item.assignToEmployee.userName : "N/A"}</span>
                  </td>

                  <td className="px-6 py-4 text-gray-900"> {teachModeViewData(item?.preferredTeachMode)}</td>

                  {/* <td className="px-4 py-4 text-sm text-gray-900 text-center">
                    {item?.preferredTeachMode == "ONLINE_TUITION" ? "Online" : "In-Home"}
                  </td> */}

                  <td className={`px-4 py-4 cursor-pointer font-semibold text-center ${item.reason ? 'text-[#00AFE2]' : 'text-[#023565]'}`} onClick={() => addRemark(item)}>{item?.reason ? 'View' : 'Add'}
                  </td>

                  <td className="px-4 py-4 text-gray-900 relative table-cell">
                    <span className="flex justify-center items-center"><BsThreeDotsVertical onClick={() => openAction(item._id)} className="text-2xl cursor-pointer text-red-500" /></span>
                  </td>
                  {open === item._id && (
                    // <div className="absolute right-5 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10" ref={menuRef}>
                    //   <div className="py-1 font-semibold text-start text-[#023565]">
                    //     <div className="flex justify-between place-items-center cursor-pointer border-b hover:text-[#E4006F] hover:bg-gray-100 " onClick={() => assignTutor(item)}>
                    //       <button
                    //         className="block px-3 py-3 text-sm  "
                    //       >
                    //         Change Tutor
                    //       </button>
                    //       <FaAngleRight className="hover:text-[#C60E6B]" />
                    //     </div>
                    //     <div className="flex justify-between place-items-center cursor-pointer border-b hover:text-[#E4006F] hover:bg-gray-100" onClick={() => handleMarkDemoDone(item)}>
                    //       <button
                    //         className="block px-3 py-3  text-sm   "

                    //       >
                    //         Mark Demo Done
                    //       </button>

                    //       <FaAngleRight className="hover:text-[#C60E6B]" />
                    //     </div>

                    //     <div className="flex justify-between place-items-center cursor-pointer border-b hover:text-[#E4006F] hover:bg-gray-100" onClick={() => sendToDemoRequest(item)}>
                    //       <button
                    //         className=" ps-2 py-3  text-sm text-[#023565]  "

                    //       >
                    //         Send to Demo Request
                    //       </button>
                    //       <FaAngleRight className="hover:text-[#C60E6B]" />
                    //     </div>
                    //     <div className="flex justify-between place-items-center cursor-pointer border-b hover:text-[#E4006F] hover:bg-gray-100" onClick={() => updateDemoModelFunction(item)}>
                    //       <div>
                    //         <button
                    //           className="block px-3 py-3  text-sm text-[#023565] hover:bg-gray-100 "

                    //         >
                    //           View & Update Info
                    //         </button>
                    //       </div>
                    //       <FaAngleRight className="hover:text-[#C60E6B]" />
                    //     </div>

                    //     <div className="flex justify-between place-items-center cursor-pointer border-b hover:text-[#E4006F] hover:bg-gray-100" onClick={() => handleClassReject(item)}>
                    //       <button
                    //         className="block px-3 py-3  text-sm text-[#023565] hover:bg-gray-100 "

                    //       >
                    //         Reject
                    //       </button>
                    //       <FaAngleRight className="hover:text-[#C60E6B]" />
                    //     </div>

                    //     <div className="flex justify-between place-items-center cursor-pointer hover:text-[#E4006F] hover:bg-gray-100" onClick={() => handleHistory(item)}>
                    //       <button
                    //         className="block px-3 py-3  text-sm text-[#023565] hover:bg-gray-100 "

                    //       >
                    //         View History
                    //       </button>
                    //       <FaAngleRight className="hover:text-[#C60E6B]" />
                    //     </div>
                    //   </div>
                    // </div>
                    <ul className="bg-white border text-[#023565] rounded-md font-bold  absolute w-48 right-20 mt-16 py-2 text-start px-2 text-sm shadow-lg z-50" ref={menuRef}>
                      <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => assignTutor(item)}>
                        <button className="w-full text-left">Change Tutor</button>
                        <FaAngleRight className="text-md" />
                      </li>
                      <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleMarkDemoDone(item)}>
                        <button className="w-full text-left">Mark Demo Done</button>
                        <FaAngleRight className="text-md" />
                      </li>
                      <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => sendToDemoRequest(item)}>
                        <button className="w-full text-left"> Send to Demo Request</button>
                        <FaAngleRight className="text-md" />
                      </li>
                      <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => updateDemoModelFunction(item)}>
                        <button className="w-full text-left"> View & Update</button>
                        <FaAngleRight className="text-md" />
                      </li>
                      <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleClassReject(item)}>
                        <button className="w-full text-left">Reject</button>
                        <FaAngleRight className="text-md" />
                      </li>
                      <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleHistory(item)}>
                        <button className="w-full text-left">View History</button>
                        <FaAngleRight className="text-md" />
                      </li>
                    </ul>
                  )}
                </tr>
              ))
            ) : (
              <td colSpan={10} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
            )
            }
          </tbody>
        </table>
      </div>

      <ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />

      <StudentDetail
        setViewStudentModal={setViewStudentModal} viewStudentModal={viewStudentModal} setStudentListData={setStudentListData} studentListdata={studentListdata}
      />

      <ViewParent
        fullDetails={fullDetailsModal}
        setFullDetails={setfullDetailsModal}
        DetailsData={fullDetailsModalData}
        tuitionDetails={tuitionDetails}
      />
      <UpdateTuitionModel
        handleBack={() => { setUpdateDemoModel(false); setInputChangeField({}) }}
        updateDemoModel={updateDemoModel}
        inputChangeField={inputChangeField}
        allStudentList={allStudentList}
        setInputChangeField={setInputChangeField}
        listPayload={{
          status: [3, 4, 5],
          keyWord: searchText,
          cityId: headerCity ? [headerCity] : "", fromDate: "",
          toDate: "",
          sortOrder: "",
          sortBy: "",
          pageNo: currentPage,
          size: pageSize,
          ...filters,
          tutorId: (filters.tutorId != "") ? [filters.tutorId] : []
        }}
      />

      <UpdateNoteModel
        remarkModal={remarkModal}
        remarkClose={remarkClose}
        Heading={"Update Note"}
        remark={remark}
        setRemark={setRemark}
        handleRemarkSubmit={handleRemarkSubmit}
        btnName={`${remark !== "N/A" || remark ? "Edit" : "Add"} Note`}
      />


      {
        rejectResionModal && (

          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={handlerejctCloseModal}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Reason</h2>
                  </div>
                  <div className="relative  overflow-y-auto">
                    <form onSubmit={handleRejectResion} className="p-4">
                      <div className="mb-4">
                        <label htmlFor="skipResion" className="block text-gray-700 font-bold mb-2">Reason for Reject</label>
                        <input
                          type="text"
                          id="skipResion"
                          value={rejectResion}
                          onChange={(e) => setRejectResion(e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded-md"
                          placeholder="Enter reason"
                        />
                      </div>
                      <div className="flex justify-center space-x-2">
                        <button
                          type="submit"
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

          </>

        )
      }

      {details && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[900px]">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setFullDetails(false)}
            >
              <span><MdOutlineClose /></span> Close
            </button>

            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div>
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Tutor Details</h2>
                </div>
                <div className="p-2 overflow-hidden overflow-x-auto">
                  <table className="table-auto w-full border text-sm">
                    <thead>
                      <tr className="capitalize border-b">
                        <th className="px-4 py-2 text-left">Address</th>
                        <th className="px-4 py-2 text-left">Board</th>
                        <th className="px-4 py-2 text-left">Class Mode</th>
                        <th className="px-4 py-2 text-left">Tuition Fee</th>
                        <th className="px-4 py-2 text-left">Tutor Fee</th>
                        <th className="px-4 py-2 text-left">No Of Classes</th>
                        <th className="px-4 py-2 text-left">Class Duration</th>
                        <th className="px-4 py-2 text-left">Class Reference</th>
                        {/* <th className="px-4 py-2 text-left">Remark</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="capitalize">
                        <td className="px-4 py-2">{fullDetailsData?.address}</td>
                        <td className="px-4 py-2">{fullDetailsData?.board}</td>
                        <td className="px-4 py-2">
                          {fullDetailsData?.tutorInfoId?.teachingMode?.map((ele, idx) => (
                            <small key={idx}>{ele}</small>
                          ))}
                        </td>
                        <td className="px-4 py-2">{fullDetailsData?.tuitionFee}</td>
                        <td className="px-4 py-2">{fullDetailsData?.tutorFee}</td>
                        <td className="px-4 py-2">{fullDetailsData?.numberOfClasses}</td>
                        <td className="px-4 py-2">{fullDetailsData?.classDuration}</td>
                        <td className="px-4 py-2">{fullDetailsData?.classReference}</td>
                        {/* <td className="px-4 py-2">{fullDetailsData?.remark}</td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  {/* Additional content if needed */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      <div id="alert-box" style={{ display: "none", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "yellow", padding: "10px", borderRadius: "10px" }}>
        Tuition rejected!
      </div>

      <div id="alert-box1" style={{ display: "none", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "yellow", padding: "10px", borderRadius: "10px" }}>
        Tuition Assign!
      </div>



      <AssignTutorPopUp
        assignTutorModal={assignTutorModal}
        setAssignTutorModal={setAssignTutorModal}
        suggestTutorBtn={suggestTutorBtn}
        assignTutorTab={assignTutorTab}
        handleAssignTutor={handleAssignTutor}
        assignTutorList={assignTutorList}
        allTutorDataList={allTutorDataList}
        handleAssign={handleAssign}
        suggestedTutor={suggestedTutor}
      />
      {
        tutorDetailsModal && (
          <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50'>
            <div className='bg-white w-auto h-auto p-3 shadow-lg rounded'>
              <div className='flex justify-end'>
                <button className='bg-blue-600 text-white px-2 py-1 cursor-pointer' onClick={() => setTutorDetailsModal(false)}>Close</button>
              </div>
              <div>
                <table className='w-full table-auto'>
                  <thead>
                    <tr className='bg-gray-200'>
                      <th className='px-4 py-2'>Name</th>
                      <th className='px-4 py-2'>Image</th>
                      <th className='px-4 py-2'>Mobile No</th>
                      <th className='px-4 py-2'>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='border px-4 py-2'>{tutorDetailsModalData?.name || 'N/A'}</td>
                      <td className='border px-4 py-2'>
                        {tutorDetailsModalData?.image ? (
                          <img src={tutorDetailsModalData.image} alt='Tutor' className='h-10 w-10 rounded-full' />
                        ) : 'N/A'}
                      </td>
                      <td className='border px-4 py-2'>{tutorDetailsModalData?.mobNo || 'N/A'}</td>
                      <td className='border px-4 py-2'>{tutorDetailsModalData?.email || 'N/A'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        )
      }



      {
        tutorDetails && (
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none bg-black bg-opacity-40"
          >
            <div className="relative w-11/12 mx-auto lg:w-[800px]">
              <div className="flex justify-end">
                <button
                  onClick={() => setTutorDetails(false)}
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                >
                  <MdOutlineClose /> Close
                </button>
              </div>

              <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Tutor Details </h2>
                </div>

                <div className="grid md:grid-cols-2 gap-4 p-2">
                  <div className="w-full text-center">

                    <img
                      src={fullData?.image ?? "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"}
                      alt="Tutor"
                      className="w-56 h-56 mx-auto "
                    />
                  </div>
                  <div className="w-full capitalize space-y-2">
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Name</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.name ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Gender</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.gender ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Teaching Exp</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.teachingExp ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Address</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.address ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Mobile No</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.mobNo ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Email</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.email ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">School name</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.schoolName ?? "N/A"}</p>
                    </div>


                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">UG Course</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.ugCourse ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">PG Course</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{fullData?.tutorInfoId?.pgCourse ?? "N/A"}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Classes</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">
                        <p>
                          {fullData?.tutorInfoId?.classYouCanTeach?.map((ele) => ele?.name).join(', ') ?? 'N/A'}
                        </p>
                      </p>
                    </div>

                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%] capitalize">Subjects</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">
                        {fullData?.tutorInfoId?.subYouCanTeach?.map((ele) => ele?.name).join(', ') ?? "N/A"}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <section className="space-y-5 lg:hidden block py-6">
        {onDemoListData && onDemoListData.length > 0 ? (
          onDemoListData?.map((item, index) => (
            <div key={index} className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
              <div className="flex justify-between p-2 w-full">
                <div className="w-full">
                  <p className="text-[14px] font-[600] text-left">
                    Parent Name
                  </p>
                </div>
                <div className="w-full flex items-start">
                  <div className="flex md:justify-start justify-center gap-2 w-[95%]">
                    <div onClick={item?.parentId?.image ? () => handleCloseFunctionImageModel(item?.parentId?.image) : null}>
                      {item?.parentId && item?.parentId?.image ?
                        <img
                          alt=""
                          src={
                            item?.parentId && item?.parentId?.image
                              ? item?.parentId?.image
                              : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          onError={(e) => {
                            e.target.src =
                              "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                          }}
                          className="rounded  max-w-[30px] max-h-[30px] min-w-[30px] min-h-[30px]"
                        />
                        : <FaRegUserCircle className="rounded max-w-[30px] max-h-[30px] min-w-[30px] min-h-[30px]" />}
                    </div>
                    <div className="">
                      <p className="text-[12px] font-medium whitespace-nowrap">
                        {item?.parentId?.name ?? "Not Available"}
                      </p>
                      <p className="text-[12px] font-medium text-gray-500 whitespace-nowrap">
                        {item?.parentId?.mobNo ?? "Not Available"}
                      </p>
                      <div className="flex items-center  gap-1 cursor-pointer" onClick={() => handleFullDetails(item)}>
                        <p className="text-[12px] font-medium cursor-pointer">More</p>
                        <FaChevronCircleRight
                          className="text-[#023565] text-base cursor-pointer" />
                      </div>

                    </div>
                  </div>
                  <div className="w-[5%] relative">
                    <BsThreeDotsVertical
                      onClick={() => openAction(item._id)}
                      className="text-2xl text-[#C60E6B]"
                    />
                    {open === item._id && (
                      <div className="absolute right-5 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10" ref={mobileRef}>
                        <div className="py-1 font-semibold text-start ">
                          <div className="flex justify-between place-items-center cursor-pointer" onClick={() => assignTutor(item)}>
                            <button className="block px-3 py-2 text-sm text-pink-600 hover:bg-gray-100 border-b">
                              Change Tutor
                            </button>
                            <FaAngleRight className="hover:text-[#C60E6B]" />
                          </div>
                          <div className="flex justify-between place-items-center cursor-pointer" onClick={() => handleMarkDemoDone(item)}>
                            <button className="block px-3 py-2  text-sm text-[#023565] hover:bg-gray-100 border-b">
                              Mark Demo Done
                            </button>
                            <FaAngleRight className="hover:text-[#C60E6B]" />
                          </div>

                          <div className="flex justify-between place-items-center cursor-pointer" onClick={() => sendToDemoRequest(item)}>
                            <button className=" px-3 py-2  text-sm text-[#023565] hover:bg-gray-100 border-b">
                              Send to Demo Request
                            </button>
                            <div>
                              <img alt="" src="/Group 1000001230.png" />
                            </div>
                          </div>
                          <div className="flex justify-between place-items-center cursor-pointer" onClick={() => handleViewAndUpdate(item)}>
                            <div>
                              <button
                                className="block px-3 py-2  text-sm text-[#023565] hover:bg-gray-100 border-b"

                              >
                                View & Update Info
                              </button>
                            </div>
                            <div>
                              <img alt="" src="/Group 1000001230.png" />
                            </div>
                          </div>

                          <div className="flex justify-between place-items-center cursor-pointer" onClick={() => handleClassReject(item)}>
                            <button
                              className="block px-3 py-2  text-sm text-[#023565] hover:bg-gray-100 border-b"

                            >
                              Reject
                            </button>
                            <div>
                              <img alt="" src="/Group 1000001230.png" />
                            </div>
                          </div>

                          <div className="flex justify-between place-items-center cursor-pointer" onClick={() => handleHistory(item)}>
                            <button
                              className="block px-3 py-2  text-sm text-[#023565] hover:bg-gray-100 "

                            >
                              View History
                            </button>
                            <div>
                              <img alt="" src="/Group 1000001230.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>


              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>Student Name</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'> <FaEye size={24} className=" cursor-pointer  text-[#023565] hover:text-[#E4006F] text-center"
                    onClick={() => viewStudent(item?.parentId, item._id)} /></p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>Address</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]' title={item?.address || 'N/A'}>
                    {/* {truncateText(item?.address, 20) ?? 'N/A'}  */}
                    {item?.address || 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>City</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'> {item.cityId?.name ?? "N/A"}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>Demo Date / Time</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    {item?.demoDate} / {item?.demoTime ? timeStanderFollowFunction(item?.demoTime) : null}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>Assigned Tutor</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'> {item?.tutorId?.name} / {item?.tutorId?.mobNo}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>	Lead Source</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    {item.leadSource === "CREATED_SELF" ? "App" : "Admin"} / {item?.assignToEmployee ? item?.assignToEmployee?.userName ? item?.assignToEmployee?.userName : "N/A" : "N/A"}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'> Mode</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'> {item?.preferredTeachMode == "ONLINE_TUITION" ? "Online" : "In-Home"}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>Note</p>
                </div>
                <div className='w-full'>
                  <p className={`text-[16px] font-[700] cursor-pointer ${item.reason ? 'text-[#00AFE2]' : 'text-[#023565]'}`} onClick={() => addRemark(item)}>
                    {
                      item?.reason ? 'View' : 'Add'
                    }</p>
                </div>
              </div>

            </div>

          ))

        ) : (
          <p className="px-6 py-4 font-[400] bg-white rounded-md text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</p>
        )

        }
      </section>
      {isViewMoreModel && <ViewMoreTuitionModel isViewMoreModel={isViewMoreModel} setIsViewMoreModel={setIsViewMoreModel} tuitionId={tuitionId} />}
      {
        totalPages > 1 && (
          <div className="flex justify-end items-center py-2">
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdOutlineKeyboardArrowLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
            </span>
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )
      }

    </div >
  );
};

// export default ActiveTable;

const mapStateToProps = (state) => ({
  isStateMangerRes: state.tution.isStateMangerRes,
  suggestedTutorList: state.tution.suggestedTutorList,
  getAllTutorList: state.tution.getAllTutorList,
});

const mapDispatchToProps = (dispatch) => ({
  tutionAction: () => dispatch(tutionAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonFourTabel);
// export default ;
