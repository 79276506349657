import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tutortoliveAction, userListAction } from "../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronCircleRight, FaChevronLeft, FaChevronRight, FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import "./Tutor.css";
import { MdOutlineClose } from "react-icons/md";
import { tutorUpdateInitialState } from "../../_helpers/dataInitialState";
import { teachModeViewData } from "../../_helpers";
import UpdateTutor from "./Model/UpdateTutor";
import Loader from "../../components/Loader";


const ActiveTutor = ({ searchText }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = useSelector((state) => state);

  let kycListData = selector?.userList?.userKycListByUserId?.data;
  let onGoingTutuionListById = selector?.userList?.getOnGoingTutionList?.data;
  let ratingList = selector?.userList?.getRatingList?.data;

  const [userList, setUserList] = useState([])

  const [kycDataShow, setKycDataShow] = useState(false);
  const [kycData, setKycData] = useState();
  const [onGointTutionList, setOnGoingTutionList] = useState(false);
  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState('');

  const [activeItem, setActiveItem] = useState(null);
  const [blockedPoup, setBlockpouUp] = useState(false);
  const [name, setName] = useState("");
  const [blockedData, setNewblockedData] = useState({});
  const [ratingModal, setRatingModal] = useState(false);
  const [selectedTutorName, setSelectedTutorName] = useState("");
  const [newAcceptData, setNewAcceptData] = useState(tutorUpdateInitialState);
  const [selectedTeachMode, setSelectedTeachMode] = useState([]);
  const [selectedPinCode, setSelectedPinCode] = useState([]);
  const [subjectYouCanTeach, setSubjectYouCanTeach] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [acceptModal, setAcceptModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const menuRef = useRef(null)

  useEffect(() => {
    let resData = {
      status: "ACTIVE",
      userType: "TUTOR",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(userListAction.getUserList(resData));
  }, [currentPage]);

  useEffect(() => {
    setUserList(selector?.userList?.userList?.data?.demoList || [])
    if (selector?.userList?.userList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.userList?.userList?.data?.total / pageSize)
      );
    }
  }, [selector]);



  const handleFullDetails = (data) => {
    setfullDetailsModal(true)
    setfullDetailsModalData([data])
  }

  const kycDetailModal = (data) => {

    let kycData = {
      id: data?._id,
    };
    setKycDataShow(true);
    setKycData([data]);
    dispatch(userListAction.getKycByUserId(kycData));
  };




  const handleFeePayment = (item) => {
    navigate(`/app/feepayment`, { state: { tutorData: item } });
  };
  const handleRating = (data) => {
    setRatingModal(true);

    let ratingId = {
      userId: data._id,
    };

    dispatch(userListAction.getRatingByuserId(ratingId));
  };

  const handleAction = (resDataId) => {
    setActiveItem((prevresDataId) => prevresDataId === resDataId ? null : resDataId);
  };

  const handleOnGoingTuitions = (data) => {
    let tutorIdData = {
      tutorId: data._id,
    };
    setOnGoingTutionList(true);
    dispatch(userListAction.getOnGoingTutionList(tutorIdData));
  };

  const handleBlockedTutor = (data) => {
    setBlockpouUp(true);

    let blockedData = {
      status: "BLOCK",
      id: data._id,
    };

    setNewblockedData(blockedData);
  };

  const handleSubmit = () => {
    let resData = {
      status: "ACTIVE",
      userType: "TUTOR",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    let blockednEWData = {
      ...blockedData,
      reason: name,
    };

    dispatch(userListAction.blockByAdmin(blockednEWData, resData));
    setBlockpouUp(false);
    setActiveItem(null);
    setName("");
  };

  const handleUpdateTutor = async (user) => {
    tutortoliveAction.getTutorByPromiseId({
      id: user._id
    }).then(async (data) => {
      let user = data.data
      setNewAcceptData({
        _id: user._id,
        name: user?.name,
        email: user?.email,
        whatsappNo: user?.whatsappNo,
        address: user?.address,
        city: user?.city,
        pinCode: user?.pinCode,
        dob: user?.tutorInfoId?.dob,
        gender: user?.tutorInfoId?.gender,
        schoolName: user?.tutorInfoId?.schoolName,
        schoolPassOutYear: user?.tutorInfoId?.schoolPassOutYear,
        ugCourse: user?.tutorInfoId?.ugCourse,
        ugCollegeName: user?.tutorInfoId?.ugCollegeName,
        ugPassOutYear: user?.tutorInfoId?.ugPassOutYear,
        pgCourse: user?.tutorInfoId?.pgCourse,
        pgCollegeName: user?.tutorInfoId?.pgCollegeName,
        pgPassOutYear: user?.tutorInfoId?.pgPassOutYear,
        teachingExp: user?.tutorInfoId?.teachingExp,
      })
      setSelectedTeachMode(user?.tutorInfoId?.teachingMode.map((ele) => ({ name: teachModeViewData(ele), _id: ele })))
      setSelectedPinCode(user?.tutorInfoId?.pinCodeYouCanGo?.map((ele) => ({ name: ele, _id: ele })) || [])
      setSubjectYouCanTeach(user?.tutorInfoId?.subYouCanTeach)
      setSelectedClass(user?.tutorInfoId?.classYouCanTeach)
      setAcceptModal((prev) => !prev);
    })

  }

  const closeModal = () => {
    setAcceptModal(false);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActiveItem(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Loader loading={selector?.userList?.loading} />

      <section className="lg:block hidden py-10">
        <div className=" border border-[#D3D3D3] rounded-xl overflow-x-auto">
          <table className="min-w-full bg-white rounded-xl">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-[#313131]">
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Tutor Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  City
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  KYC
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-center">
                  Ongoing Tuitions
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-center">
                  Fee Payment History
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Rating
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userList && userList.length ?
                userList?.map((item, id) => (
                  <tr key={id} className="border-b border-[#D1D1D1] capitalize">
                    <td className="flex items-center gap-4 px-6 py-4 text-sm text-gray-900 ">
                      <div className="flex items-center justify-center gap-2">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={
                            item?.image ??
                            "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          alt="User"
                        />
                        <div className="w-24">
                          <p className="font-medium whitespace-nowrap">{item?.name ?? "No Data"}</p>
                          <p className="text-gray-500 whitespace-nowrap">{item?.mobNo ?? "No Contact"}</p>
                        </div>
                      </div>
                      <FaChevronCircleRight
                        className="text-[#023565] text-lg cursor-pointer"
                        onClick={() => handleFullDetails(item)}
                      />
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      {item?.city?.name ?? "No City"}
                    </td>
                    <td className="px-6 py-4 text-sm font-bold cursor-pointer underline text-gray-900" onClick={() => kycDetailModal(item)}>
                      View
                    </td>
                    <td className="px-6 py-4 text-sm font-bold cursor-pointer underline text-center text-gray-900" onClick={() => handleOnGoingTuitions(item)}>
                      View
                    </td>
                    <td className="px-6 py-4 text-sm font-bold cursor-pointer underline text-center text-gray-900" onClick={() => handleFeePayment(item)}>
                      View
                    </td>
                    <td className="px-6 py-4 text-sm relative text-gray-900 ">
                      <span className="text-[#C60E6B]  font-semibold">
                        {item && item?.rating ? item.rating.toFixed(2) : "0"}
                      </span>

                      <div className="  ">
                        <button
                          className="font-bold underline "
                          onClick={() => handleRating(item)}
                        >
                          View
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-4 relative">
                      <button className="  font-bold underline ">
                        <BsThreeDotsVertical
                          className="text-2xl text-[#C60E6B]"
                          onClick={() => handleAction(item._id)}
                        />
                      </button>

                    </td>
                    {activeItem && activeItem === item._id && (
                      <div className="absolute right-0 w-44  bg-white border border-gray-300 rounded-lg shadow-lg z-10" ref={menuRef}>
                        <ul className="divide-y divide-gray-300">
                          <li
                            className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-t-lg"
                          >
                            <span
                              className="text-[#C60E6B] text-sm font-semibold"
                              onClick={() => handleUpdateTutor(item)}
                            >
                              View & Update Info
                            </span>
                            <span className="ml-auto font-semibold text-[#C60E6B]">
                              &gt;
                            </span>
                          </li>
                          <li
                            className="flex items-center cursor-pointer hover:bg-gray-100 p-3"
                            onClick={() => handleBlockedTutor(item)}
                          >
                            <span className="text-[#023565] text-sm font-semibold">
                              Block Tutor
                            </span>
                            <span className="ml-auto text-blue-600">&gt;</span>
                          </li>
                          <li
                            className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-b-lg"
                          >
                            <span className="text-[#023565] text-sm font-semibold">
                              View History
                            </span>
                            <span className="ml-auto text-blue-600">&gt;</span>
                          </li>
                        </ul>
                      </div>
                    )}
                  </tr>
                ))
                : <td className="text-center">Data not found.</td>

              }

            </tbody>
          </table>


        </div>
      </section>
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      {kycDataShow && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[700px]">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setKycDataShow(false)}
            >
              <span><MdOutlineClose /></span> Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold"> KYC (Know Your Customer) </h2>
              </div>
              <div className="relative lg:h-[300px] overflow-y-auto">
                <div className="grid md:grid-cols-2 gap-4 p-4">
                  <div className="w-full text-center">
                    <img
                      src={kycListData?.selfie}
                      className="w-56 h-56 mx-auto "
                      alt="Document Selfie"
                    />
                  </div>
                  <div className="w-full capitalize space-y-2">
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Aadhaar No</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.govtIdNumber ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Name</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.name ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Date Of Birth</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.dob}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Gender</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.gender === 'M' ? 'MALE' : kycListData?.gender === 'F' ? 'FEMALE' : 'N/A'}</p>
                    </div>
                    {/* <div className="w-full flex justify-between items-center">
                        <p className="font-semibold w-[40%]">Address</p>
                        <span className="w-[20%]">:</span>
                        <p className="w-[40%]">{kycListData?.loc ?? 'N/A'}</p>
                      </div> */}
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">House</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.house ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Street</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.street ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Village Town City</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.vtc ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Location</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.loc ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Landmark</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.landmark ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Post Office</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.po ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">District</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.dist ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">State</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.state ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Country</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.country ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Pincode</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.pinCode ?? 'N/A'}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">KYC Done On</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.createdAt ? moment(kycListData?.createdAt).format('DD-MMM-YYYY hh:mm A') : ""}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">KYC Done On</p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{moment(kycListData?.kycDoneOn).format('DD-MMM-YYYY')}</p>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="font-semibold w-[40%]">Face Match </p>
                      <span className="w-[20%]">:</span>
                      <p className="w-[40%]">{kycListData?.status === 1 ? "Yes" : "No"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {onGointTutionList && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[800px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setOnGoingTutionList(false)}
              >
                <span><MdOutlineClose /></span> Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Ongoing Tuition</h2>
                </div>
                <div className="relative lg:h-[200px] overflow-y-auto">
                  <div className="overflow-x-auto overflow-hidden p-4">
                    <table className="w-full bg-white table-auto border rounded-lg">
                      <thead className="border-b">
                        <tr className="text-center bg-[#F3F4F6]">
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Student Name / Subject</td>
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Location</td>
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Tution Fee</td>
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Tution Duration</td>
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Created At</td>
                        </tr>
                      </thead>
                      <tbody>
                        {onGoingTutuionListById &&
                          onGoingTutuionListById?.map((item, id) => (
                            <tr
                              className="text-center"
                              key={id}
                              style={{ textTransform: "capitalize" }}
                            >
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {item?.bookFreeDemo?.map((std, id) => (
                                  <div>
                                    <span className="text-green-600 font-semibold cursor-pointer hover_show">
                                      view
                                    </span>
                                    <div className=" w-full hover_clss">
                                      <table className="border table-auto ">
                                        <thead>
                                          <tr className="text-center">
                                            <td>Student</td>
                                            <td>Subject</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <span>
                                                {std?.studentId?.studentFullName}
                                              </span>
                                            </td>
                                            <td>
                                              <span>{std?.subjectId?.name}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ))}
                              </td>
                              <td>{item?.location}</td>
                              <td>{item?.tuitionFee}</td>
                              <td className="">
                                <p>

                                  <span> Start:-</span>
                                  {item?.startTime ?? "Not  Available"}
                                </p>
                                <p>

                                  <span> End:-</span>
                                  {item?.endTime ?? "Not  Available"}
                                </p>
                              </td>
                              <td>
                                {moment(item?.createdAt).format("DD-MMM-YYYY ")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {blockedPoup && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[600px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setBlockpouUp(false)}
              >
                <span><MdOutlineClose /></span> Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Give Blocked Reason </h2>
                </div>
                <div className="relative lg:h-[200px] overflow-y-auto">
                  <form className="space-y-2 p-4">
                    <div>
                      <label
                        htmlFor="subjectName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Blocked Reason:
                      </label>
                      <textarea
                        type="text"
                        id="subjectName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        required
                      />
                    </div>
                    <div className="flex justify-center items-center pt-2">
                      <button
                        onClick={handleSubmit}
                        className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                      >
                        Blocked
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {ratingModal && (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[800px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setRatingModal(false)}
              >
                <span><MdOutlineClose /></span> Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Rating</h2>
                </div>
                <div className="relative lg:h-[400px] overflow-y-auto">
                  <div className="overflow-x-auto overflow-hidden p-4">
                    <table className="w-full bg-white table-auto border rounded-lg">
                      <thead className="border">
                        <tr className="bg-[#F3F4F6]">
                          <th className="px-4 py-2  border text-left">Rating By Admin</th>
                          <th className="px-4 py-2  border text-left">Rating By User</th>
                          <th className="px-4 py-2  border text-left">Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ratingList &&
                          ratingList.map((rating, id) => (
                            <tr key={id} className="border capitalize">
                              <td className="px-4 py-2 border ">
                                {rating?.ratingByAdmin?.userName ?? "-"}
                              </td>
                              <td className="px-4 py-2 border ">
                                {rating?.ratingByUser?.name ?? "-"}
                              </td>
                              <td className="px-4 py-2 border ">
                                <div className="flex items-center">
                                  {Array.from({ length: rating.rating }, (_, index) => (
                                    <FaStar key={index} className="text-yellow-500" />
                                  ))}
                                  <span className="ml-2">{rating.rating}</span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <UpdateTutor
        closeModal={closeModal}
        newAcceptData={newAcceptData}
        acceptModal={acceptModal}
        setNewAcceptData={setNewAcceptData}
        currentPage={currentPage}
        pageSize={pageSize}
        selectedTeachMode={selectedTeachMode}
        setSelectedTeachMode={setSelectedTeachMode}
        selectedPinCode={selectedPinCode}
        setSelectedPinCode={setSelectedPinCode}
        subjectYouCanTeach={subjectYouCanTeach}
        setSubjectYouCanTeach={setSubjectYouCanTeach}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        status={"ACTIVE"}
      />
      {
        fullDetailsModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60">
              <div className="relative w-11/12 mx-auto lg:w-[800px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setfullDetailsModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold"> Tutor Details </h2>
                  </div>
                  <div className="relative h-[310px] overflow-y-auto">
                    {fullDetailsModalData && fullDetailsModalData?.map((itm, id) => (
                      <div key={id} className="grid lg:grid-cols-3 gap-4 p-4">
                        <div className="lg:col-span-1 w-full text-center">
                          <img
                            src={itm?.image ?? 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'}
                            className="w-56 h-56 mx-auto "
                            alt="Document Selfie"
                          />
                        </div>
                        <div className="lg:col-span-2 w-full capitalize space-y-2">
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">Name</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.name}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">Mobile No</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.mobNo}</p>
                          </div>
                          <div className="w-full flex justify-between items-start ">
                            <p className="font-semibold w-[40%]">Email</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%] break-all">{itm?.email}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">Address</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.address}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">D O B</p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{moment(itm?.tutorInfoId?.dob).format('DD-MMM-YYYY')}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">School Pass Out </p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.tutorInfoId?.schoolPassOutYear}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">UG Pass Out </p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.tutorInfoId?.ugPassOutYear}</p>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <p className="font-semibold w-[40%]">PG Pass Out </p>
                            <span className="w-[20%]">:</span>
                            <p className="w-[40%]">{itm?.tutorInfoId?.pgPassOutYear}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }



      <section className="space-y-5 py-10 lg:hidden block">
        {userList && userList.length > 0 ? (
          userList?.map((item, id) => (
            <div key={id} className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Tutor Name</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          item?.image ??
                          "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                        }
                        alt="User"
                      />
                      <div>
                        <div className="text-xs font-medium text-black ">{item?.name ?? "No Data"}</div>
                        <div className="text-xs text-gray-500">{item?.mobNo || 'No Number'}</div>
                        <FaChevronCircleRight
                          className="text-[#023565] cursor-pointer"
                          onClick={() => handleFullDetails(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' >{item?.city?.name ?? "No City"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>KYC</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => kycDetailModal(item)}>View</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Ongoing Tuitions</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleOnGoingTuitions(item)}>View</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Fee Payment History</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleFeePayment(item)}> View
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Rating</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'> {item && item?.rating ? item.rating.toFixed(2) : "0"}</p>
                    <p className='text-[14px] font-[400] relative' onClick={() => handleRating(item)}> View</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <BsThreeDotsVertical
                        className="text-2xl text-[#C60E6B]"
                        onClick={() => handleAction(item._id)}
                      />

                      {activeItem && activeItem === item._id && (
                        <div
                          className="absolute right-50 w-44  bg-white border border-gray-300 rounded-lg shadow-lg z-10"
                        >
                          <ul className="divide-y divide-gray-300">
                            <li
                              className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-t-lg"
                            >
                              <span
                                className="text-[#C60E6B] text-sm font-semibold"
                                onClick={() => handleUpdateTutor(item)}
                              >
                                View & Update Info
                              </span>
                              <span className="ml-auto font-semibold text-[#C60E6B]">
                                <img alt="" src="/Group 1000001172.png" />
                              </span>
                            </li>
                            <li
                              className="flex items-center cursor-pointer hover:bg-gray-100 p-3"
                              onClick={() => handleBlockedTutor(item)}
                            >
                              <span className="text-[#023565] text-sm font-semibold">
                                Block Tutor
                              </span>
                              <span className="ml-auto text-blue-600">
                                <img alt="" src="/Group 1000001230.png" />

                              </span>
                            </li>
                            <li
                              className="flex items-center cursor-pointer hover:bg-gray-100 p-3 rounded-b-lg"
                            >
                              <span className="text-[#023565] text-sm font-semibold">
                                View History
                              </span>
                              <span className="ml-auto text-blue-600">
                                <img alt="" src="/Group 1000001230.png" />

                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>

    </>
  );
};

export default ActiveTutor;
