import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tutortoliveAction } from "../../_actions";
import KycStatus from "../dashboard/KycStatus.json";
import { FaChevronCircleRight } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import moment from "moment/moment";
import "./Dashboard.css";


const KycTabel = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  let newJoinedTutorList = selector?.TutorToLive?.getNewJoinTutorList?.data?.demoList
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [tutorData, setTutorData] = useState('');

  const [allNewJoinedTutor, setAllNewJoinedTutor] = useState(false)

  const handleClick = (person) => {
    setSelectedPerson(person);
    setTutorData([person])
  };

  useEffect(() => {
    let newJoindData = {
      "status": "NEW_TUTOR",
      "isManagement": false,
      "keyWord": "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "asc",
      "pageNo": 1,
      "size": 8
    }
    dispatch(tutortoliveAction.getNewlyJoinTutorList(newJoindData))
  }, []);

  const handleAllNewJoinedTutor = () => {
    setAllNewJoinedTutor(true)
    // navigate('/app/newjointutorlist')
    navigate('/app/tutor-to-live-cb')
  }
  return (
    <>
      <section className=" kycTableData">
        {newJoinedTutorList && newJoinedTutorList?.map((item, index) => {
          return (
            <div
              key={index}
              className="card flex kycDatatable justify-between text-center place-items-center py-2 border-b-2 "
            >
              <div className="  imageName flex justify-between place-items-center gap-1 ">
                <img src={item?.image ?? 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'} className="rounded-full w-11 h-11" />
                <p className="personName">
                  {item.name} <br />
                  <span className="text-xs kycstatus text-gray-500 mx-2">{moment(item?.createdAt).format('DD-MMM-YYYY')}</span>
                </p>
              </div>

              <div className="button arrowButton  ">
                <button className=" ">

                  <img src="/right .png" onClick={() => handleClick(item)} />

                </button>
              </div>

            </div>

          );
        })}
        {selectedPerson && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="modal bg-white shadow-lg rounded-lg w-3/6">
              <div className="modal-content flex justify-end p-4">
                <span
                  className="close cursor-pointer text-2xl text-purple-500"
                  onClick={() => setSelectedPerson(null)}
                >
                  <IoIosCloseCircle />
                </span>
              </div>
              <div className="m-4 bg-white border border-[#D3D3D3] rounded-lg">
                <table className="w-full">
                  <thead className="">
                    <tr className="border-b">
                      <th className="px-6 py-3  font- text-black  tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3  font- text-black  tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3  font- text-black  tracking-wider">
                        City
                      </th>
                      <th className="px-6 py-3  font- text-black  tracking-wider">
                        Address
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tutorData &&
                      tutorData.map((tutor, id) => (
                        <tr key={id} className="text-center capitalize">
                          <td className="px-6 py-3 whitespace-nowrap">
                            {tutor.name}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            {tutor?.kycStatus ? 'Done' : 'Pending'}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            {tutor?.city?.name ?? 'No Data'}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            {tutor?.address ?? 'No Data'}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className="bg-white text-end pr-6 py-5 font-bold underline text-blue-950 cursor-pointer" >
          <button onClick={() => handleAllNewJoinedTutor()}>
            see all

          </button>
        </div>

        {
          allNewJoinedTutor && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="modal bg-white shadow-lg rounded-lg w-3/6">
                <div className="modal-content flex justify-end p-4">
                  <span
                    className="close cursor-pointer text-2xl text-purple-500"
                    onClick={() => setAllNewJoinedTutor(false)}
                  >
                    <IoIosCloseCircle />
                  </span>
                </div>
                <div className="m-4 bg-white border border-[#D3D3D3] rounded-lg">
                  <table className="w-full">
                    <thead className="">
                      <tr className="border-b">
                        <th className="px-6 py-3  font- text-black  tracking-wider">
                          Name
                        </th>
                        <th className="px-6 py-3  font- text-black  tracking-wider">
                          Status
                        </th>
                        <th className="px-6 py-3  font- text-black  tracking-wider">
                          City
                        </th>
                        <th className="px-6 py-3  font- text-black  tracking-wider">
                          Address
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {newJoinedTutorList &&
                        newJoinedTutorList?.map((tutor, id) => (
                          <tr key={id} className="text-center capitalize overflow-auto">
                            <td className="px-6 py-3 whitespace-nowrap">
                              {tutor.name}
                            </td>
                            <td className="px-6 py-3 whitespace-nowrap">
                              {tutor?.kycStatus ? 'Done' : 'Pending'}
                            </td>
                            <td className="px-6 py-3 whitespace-nowrap">
                              {tutor?.city?.name ?? 'No Data'}
                            </td>
                            <td className="px-6 py-3 whitespace-nowrap">
                              {tutor?.address ?? 'No Data'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        }
      </section>

    </>
  );
};

export default KycTabel;
