/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { IoAdd, IoRemove } from 'react-icons/io5';
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { classAction } from '../../../../_actions/class.action';
import { boardAction, tutionAction } from '../../../../_actions';
import Select from "react-select";


const noOfClasses = ["Full Week", "Weekly 6 Days", "Weekly 5 Days", "Weekly 4 Days", "Weekly 3 Days", "Weekly 2 Days", "Once in a Week"];

function UpdateTuitionModel({ handleBack, allStudentList = [], updateDemoModel, inputChangeField, setInputChangeField, listPayload }) {
  const selector = useSelector(state => state)
  const [errorData, setErrorData] = useState({});
  const [fields, setFields] = useState([{ studentId: '', subjectId: [], board: '', classId: '' }]);
  const [selectedSubjectOptions, setSelectedSubjectOptions] = useState({});
  const [showDemoError, setShowDemoError] = useState(false)
  const [pinCodeOptions, setPinCodeOptions] = useState([])


  const dispatch = useDispatch()
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "demoDate") {
      setInputChangeField(prevState => ({
        ...prevState,
        [name]: moment(value).format("DD-MM-YYYY")
      }));
    } else if (name === "cityId") {
      setInputChangeField(prevState => ({
        ...prevState,
        cityId: value,
        pinCodeId: ""
      }));

    } else {
      setInputChangeField(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    setErrorData({})
    setShowDemoError(false)
  };

  useEffect(() => {
    const ClassList = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
    };
    dispatch(classAction.classGet(ClassList));
    let boardData = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
    };
    dispatch(boardAction.boardGet(boardData));
  }, [])

  const handelOnSelect = (e) => {
    const { name, value } = e.target;
    setInputChangeField(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleAddField = () => {
    setInputChangeField((pre) => {
      return {
        ...pre,
        bookFreeDemo: [...inputChangeField.bookFreeDemo, { studentId: '', subjectId: [], board: '', classId: '' }]
      }
    });
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...inputChangeField.bookFreeDemo];
    updatedFields.splice(index, 1);
    setInputChangeField((pre) => {
      return {
        ...pre,
        bookFreeDemo: updatedFields
      }
    });
  };

  const handleValidation = () => {
    const errors = {};
    if (!inputChangeField?.classDuration) {
      errors["classDuration"] = "Class Duration is required";
    } else if (inputChangeField.classDuration <= 0) {
      errors["classDuration"] = "Please enter a valid class duration.";
    }
    if (!inputChangeField?.tutorFee) {
      errors["tutorFee"] = "Tutor Fee is required";
    } else if (inputChangeField.tutorFee <= 0) {
      errors["tutorFee"] = "Please enter a valid tutor fee.";
    }
    if (!inputChangeField?.numberOfClasses) {
      errors["numberOfClasses"] = "Session details is required.";
    }
    if (!inputChangeField?.demoDate) {
      errors["demoDate"] = "Demo Date required";
    }
    // if (!inputChangeField?.demoTime) {
    //     errors["demoTime"] = "Demo Time required";
    // }
    if (!inputChangeField?.tuitionFee) {
      errors["tuitionFee"] = "Tuition Fee required";
    } else if (inputChangeField.tuitionFee <= 0) {
      errors["tuitionFee"] = "Please enter a valid tuition fee.";
    }
    if (!inputChangeField?.requiredGender) {
      errors["requiredGender"] = "Gender required";
    }
    if (!inputChangeField?.tuitionType) {
      errors["tuitionType"] = "Fee Type required";
    }
    if (!inputChangeField?.preferredTeachMode) {
      errors["preferredTeachMode"] = "Tuition Mode required";
    }
    if (!inputChangeField?.teachingLocation && inputChangeField?.preferredTeachMode !== "ONLINE_TUITION") {
      errors["teachingLocation"] = "Teaching Location  required";
    }
    if (!inputChangeField?.pinCodeId) {
      errors["pinCodeId"] = "PinCode required";
    }
    let bookFreeDemoErrors = [];
    let demoError = false
    inputChangeField.bookFreeDemo.forEach((item, index) => {
      let obj = {}
      if (!item.studentId) {
        obj.studentId = "Student is required.";
        demoError = true
      }

      if (Array.isArray(item.subjectId) && item.subjectId.length === 0) {
        obj.subjectId = "Subject is required.";
        demoError = true
      }

      // Check classId
      if (!item.classId) {
        obj.classId = "Class is required.";
        demoError = true
      }

      // Check board
      if (!item.board) {
        obj.board = "Board is required.";
        demoError = true
      }
      bookFreeDemoErrors.push(obj)
    })
    if (demoError) {
      errors["bookFreeDemo"] = bookFreeDemoErrors
      setShowDemoError(true)
    } else {
      setShowDemoError(false)
    }
    setErrorData(errors);
    return Object.keys(errors).length === 0 && !demoError;
  };

  const handleSubmitUpdateInfo = () => {
    if (handleValidation()) {
      let bookFreeDemo = inputChangeField.bookFreeDemo.map(item => ({
        studentId: item && item?.studentId && item?.studentId._id ? item?.studentId._id : item?.studentId,
        subjectId: item?.subjectId?.map((ele) => ele._id),
        board: item && item?.board && item?.board?._id ? item?.board?._id : item?.board,
        classId: item && item?.classId && item?.classId?._id ? item?.classId?._id : item?.classId,
      }));

      const resData = {
        demoId: inputChangeField._id,
        parentId: inputChangeField.parentId._id,
        bookFreeDemo: bookFreeDemo,
        demoDate: inputChangeField?.demoDate,
        demoTime: inputChangeField?.demoTime,
        pinCodeId: inputChangeField?.pinCodeId,
        address: inputChangeField?.address,
        requiredGender: inputChangeField?.requiredGender,
        preferredTeachMode: inputChangeField?.preferredTeachMode,
        numberOfClasses: inputChangeField?.numberOfClasses,
        teachingLocation: inputChangeField?.teachingLocation,
        tuitionFee: inputChangeField?.tuitionFee,
        tutorFee: inputChangeField?.tutorFee,
        classReference: inputChangeField?.classReference ?? "0",
        classDuration: inputChangeField?.classDuration ?? 0,
        remark: inputChangeField?.remark,
        tuitionType: inputChangeField.tuitionType,
        preferredTeachMode: inputChangeField.preferredTeachMode,
        classReference: inputChangeField.classReference,
        assignToEmployee: inputChangeField?.assignToEmployee?._id || inputChangeField?.assignToEmployee || "",
        cityId: inputChangeField?.cityId?._id || inputChangeField?.cityId || "",


      };
      dispatch(tutionAction.updateDemoInfoByAdmin(resData, listPayload ? listPayload : null));
      handleBack()
    }
  };

  const onSelect2 = (selectedList, selectedItem, index) => {
    const newFields = [...inputChangeField.bookFreeDemo];
    newFields[index].subjectId = selectedList;
    setInputChangeField((pre) => {
      return {
        ...pre,
        newFields
      }
    });
  };

  const onRemove2 = (selectedList, removedItem, index) => {
    const newFields = [...inputChangeField.bookFreeDemo];
    newFields[index].subjectId = selectedList;
    setInputChangeField((pre) => {
      return {
        ...pre,
        newFields
      }
    });
  };

  function handleClassChange(event, index) {
    const selectedClassId = event.target.value;
    const newFields = [...inputChangeField.bookFreeDemo];
    newFields[index].classId = selectedClassId;
    newFields[index].subjectId = [];
    setInputChangeField((pre) => {
      return {
        ...pre,
        newFields
      }
    });
    setSelectedSubjectOptions((prev) => ({
      ...prev,
      [index]: selector?.Class?.classList?.find(el => el._id === selectedClassId)?.subjectId ?? []
    }))
  }
  function handleBoardChange(event, index) {
    const selectedClassId = event.target.value;
    const newFields = [...inputChangeField.bookFreeDemo];
    newFields[index].board = selectedClassId;
    setInputChangeField((pre) => {
      return {
        ...pre,
        newFields
      }
    });
  }

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : "";

    setInputChangeField(prevState => ({
      ...inputChangeField,
      [name]: value
    }));

  };

  useEffect(() => {
    const cityId = inputChangeField?.cityId?._id || inputChangeField?.cityId || null;

    if (cityId) {
      const cityData = selector?.Citys?.cityGetList.find((ele) => ele._id === cityId)

      // setPinCodeOptions([
      //     ...(cityData?.pinCode?.map(ele => ({
      //         label: ele,
      //         value: ele
      //     })) || []),
      //     { label: "Other", value: "Other" }
      // ]);
      setPinCodeOptions(cityData?.pinCode ?? [])

    }

  }, [inputChangeField?.cityId])

  useEffect(() => {
    if (updateDemoModel) {
      inputChangeField?.bookFreeDemo?.forEach((item, index) => {
        const selectedSubjects = selector?.Class?.classList?.find(el => el._id === item?.classId?._id)?.subjectId ?? [];
        setSelectedSubjectOptions(prev => ({
          ...prev,
          [index]: selectedSubjects
        }));
      });
    } else {
      setSelectedSubjectOptions({});
    }
  }, [updateDemoModel]);


  return updateDemoModel &&
    <>

      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
        <div className="relative w-11/12 mx-auto lg:w-[980px]">

          <button
            className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none flex items-center"
            onClick={() => { setErrorData({}); setShowDemoError(false); handleBack() }}
          >
            <span><MdOutlineClose /></span> Close
          </button>
          <div className="form_class rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none border-2 h-[70vh] overflow-hidden overflow-y-auto Pokercardbar">
            <form>
              <div className="flex w-full">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full">
                  <h2 className="text-2xl font-semibold mb-6">Update Info</h2>
                  <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 gap-4">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Parent Name :
                      </label>
                      <input
                        type="text"
                        name="parentName"
                        value={inputChangeField?.parentId?.name ?? ''}
                        onChange={handleOnChange}
                        disabled
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Mobile Number :
                      </label>
                      <input
                        type="text"
                        name="mobileNumber"
                        disabled
                        value={inputChangeField?.parentId?.mobNo ?? ''}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Alternate Mobile Number :
                      </label>
                      <input
                        type="text"
                        name="secondaryNo"
                        disabled
                        value={inputChangeField?.parentId?.secondaryNo ?? ''}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        WhatsApp Number :
                      </label>
                      <input
                        type="text"
                        name="whatsappNo"
                        disabled
                        value={inputChangeField?.parentId?.whatsappNo ?? ''}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Email :
                      </label>
                      <input
                        type="text"
                        name="mobileNumber"
                        disabled
                        value={inputChangeField?.parentId?.email ?? ''}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Preferred Gender :
                      </label>
                      <select
                        name="requiredGender"
                        value={inputChangeField.requiredGender}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="">Select</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="NO_PREFERENCE">
                          No Preference
                        </option>
                      </select>
                      {errorData.requiredGender && <span className="text-red-500">{errorData.requiredGender}</span>}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Session Details :
                      </label>
                      <select
                        name="numberOfClasses"
                        value={inputChangeField.numberOfClasses}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="">Select</option>
                        {noOfClasses.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      {errorData.numberOfClasses && <span className="text-red-500">{errorData.numberOfClasses}</span>}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Class Duration (Minutes) :
                      </label>
                      <input
                        type="number"
                        name="classDuration"
                        value={inputChangeField.classDuration}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {errorData.classDuration && <span className="text-red-500">{errorData.classDuration}</span>}
                    </div>
                    {/* ----fee Type */}
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Fee Type :
                      </label>
                      <select
                        name="tuitionType"
                        value={inputChangeField.tuitionType}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="">Select</option>
                        <option value="Per Month">Per Month</option>
                        <option value="For Course">For Course</option>
                        <option value="Per Class">Per Class</option>
                      </select>
                      {errorData.tuitionType && <span className="text-red-500">{errorData.tuitionType}</span>}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Tuition Fee :
                      </label>
                      <input
                        type="number"
                        name="tuitionFee"
                        value={inputChangeField.tuitionFee}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {errorData.tuitionFee && <span className="text-red-500">{errorData.tuitionFee}</span>}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Tutor Fee :
                      </label>
                      <input
                        type="number"
                        name="tutorFee"
                        value={inputChangeField.tutorFee}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {errorData.tutorFee && <span className="text-red-500">{errorData.tutorFee}</span>}
                    </div>
                    {/* tuition mode  */}
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Tuition Mode :
                      </label>
                      <select
                        name="preferredTeachMode"
                        value={inputChangeField.preferredTeachMode}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="">Select</option>
                        <option value="HOME_TUITION">Home Tuition</option>
                        <option value="ONLINE_TUITION">Online Tuition</option>
                      </select>
                      {errorData.preferredTeachMode && <span className="text-red-500">{errorData.preferredTeachMode}</span>}
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Address :
                      </label>
                      <input
                        type="text"
                        name="address"
                        value={inputChangeField?.parentId?.address ?? ''}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        City :
                      </label>

                      <select
                        name="cityId"
                        value={inputChangeField?.cityId?._id || inputChangeField?.cityId || ""}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md capitalize"
                      >
                        {selector?.Citys?.cityGetList?.map((ele, index) => (
                          <option key={index} value={ele._id}>{ele?.name}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Pincode :
                      </label>
                      <input
                        id="pinCodeId"
                        name="pinCodeId"
                        list="pincode-options"
                        value={inputChangeField?.pinCodeId ?? ""}
                        onChange={handleOnChange}
                        placeholder="Enter or select pincode"
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        autoComplete="off"
                      />
                      <datalist id="pincode-options">
                        {pinCodeOptions.map((pinCode, index) => (
                          <option key={index} value={pinCode} />
                        ))}
                      </datalist>
                      {errorData.pinCodeId && (
                        <span className="text-red-500 text-sm">
                          {errorData.pinCodeId}
                        </span>
                      )}


                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Teaching Location {inputChangeField?.preferredTeachMode === "ONLINE_TUITION" ? <span className=" text-red-500">(Class is Online)</span> : null} :
                      </label>
                      <input
                        type="test"
                        name="teachingLocation"
                        value={inputChangeField.teachingLocation}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                        disabled={inputChangeField?.preferredTeachMode === "ONLINE_TUITION" ? true : false}

                      />
                      {errorData.teachingLocation && <span className="text-red-500">{errorData.teachingLocation}</span>}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Demo Date :
                      </label>
                      <input
                        type="date"
                        name="demoDate"
                        value={inputChangeField.demoDate ? moment(inputChangeField.demoDate, "DD-MM-YYYY").format("DD-MMM-YYYY") : ""}
                        onChange={handleOnChange}
                        className=" w-full p-2 border border-gray-300 rounded-md "
                      />
                      {errorData.demoDate && <span className="text-red-500">{errorData.demoDate}</span>}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Demo Time :
                      </label>
                      <input
                        type="time"
                        name="demoTime"
                        value={inputChangeField.demoTime
                          ? moment(inputChangeField.demoTime, "hh : mm a").format("HH:mm")
                          : ""}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Class Reference :
                      </label>
                      <input
                        type="text"
                        name="classReference"
                        value={inputChangeField.classReference}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md"
                      />
                      {errorData.classReference && <span className="text-red-500">{errorData.classReference}</span>}
                    </div>
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        Lead Manager :
                      </label>
                      <select
                        name="assignToEmployee"
                        value={inputChangeField?.assignToEmployee?._id || inputChangeField?.assignToEmployee || ""}
                        onChange={handleOnChange}
                        className="block w-full p-2 border border-gray-300 rounded-md capitalize"
                      >
                        <option value="">Select</option>
                        {selector?.tution?.getAllLeadManger?.data && Array.isArray(selector?.tution?.getAllLeadManger?.data) && selector?.tution?.getAllLeadManger?.data.length > 0 && selector?.tution?.getAllLeadManger?.data?.filter(lead => lead.city.includes(inputChangeField?.cityId?._id || inputChangeField?.cityId))?.map((ele, id) => (
                          <option key={id} value={ele._id}>{ele.name} ({ele.role.name})</option>
                        ))}
                      </select>
                      {errorData.assignToEmployee && <span className="text-red-500">{errorData.assignToEmployee}</span>}
                    </div>
                    <div className="xl:col-span-4 lg:col-span-4">
                      {inputChangeField?.bookFreeDemo?.map((field, index) => (
                        <div key={index} className="grid lg:grid-cols-4 gap-5">
                          <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-700">
                              Select Student :
                            </label>
                            <input
                              type="text"
                              name="studentName"
                              value={field.studentId}
                              onChange={(e) => {
                                const selectedStudentId = e.target.value;
                                const newFields = [...inputChangeField?.bookFreeDemo];

                                if (selectedStudentId === "") {
                                  newFields[index] = {
                                    studentId: "",
                                    board: "",
                                    classId: "",
                                    subjectId: []
                                  };
                                } else {
                                  const selectedStudentData = allStudentList.find((student) => student.studentFullName === selectedStudentId);
                                  if (selectedStudentData) {
                                    newFields[index] = {
                                      studentId: selectedStudentData.studentFullName,
                                      board: selectedStudentData.educationBoard,
                                      classId: selectedStudentData.grade,
                                      subjectId: []
                                    };
                                  } else {
                                    newFields[index] = {
                                      ...newFields[index],
                                      studentId: selectedStudentId
                                    };
                                  }

                                }

                                setInputChangeField((pre) => ({
                                  ...pre,
                                  bookFreeDemo: newFields
                                }));
                                setFields(newFields);
                              }}
                              placeholder="Enter Student Name"
                              className="p-1.5 border block w-full border-gray-300 rounded-md"
                              list="student-options"
                              autoComplete="off"
                            />
                            <datalist id="student-options">
                              {allStudentList?.map((student, index) => (
                                <option key={index} value={student.studentFullName} />
                              ))}
                            </datalist>
                            {/* <select
                              name={`student-${index}`}
                              className="block w-full p-2 border bg-white border-gray-300 rounded-md capitalize"
                              value={field.studentId._id}
                              onChange={(e) => {
                                const selectedStudentId = e.target.value;
                                const newFields = [...inputChangeField?.bookFreeDemo];

                                if (selectedStudentId === "") {
                                  newFields[index] = {
                                    studentId: "",
                                    board: "",
                                    classId: "",
                                    subjectId: []
                                  };
                                } else {
                                  const selectedStudentData = allStudentList.find((student) => student._id === selectedStudentId);
                                  newFields[index] = {
                                    studentId: selectedStudentId,
                                  };
                                }

                                setInputChangeField((pre) => ({
                                  ...pre,
                                  bookFreeDemo: newFields
                                }));
                                setFields(newFields);
                              }}
                            >
                              <option value="">{field.student ? field.student.label : 'Choose a student'}</option>
                              {allStudentList?.map((student, id) => (
                                <option key={id} value={student._id}>
                                  {student.studentFullName}
                                </option>
                              ))}
                            </select> */}
                            {showDemoError && errorData?.bookFreeDemo[index]?.studentId &&
                              <span className="text-red-500">
                                {errorData?.bookFreeDemo[index]?.studentId}
                              </span>
                            }
                          </div>
                          <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                              Class :
                            </label>
                            <select
                              name={`class-${index}`}
                              value={field?.classId?._id || field?.classId}
                              className="block w-full p-2 border border-gray-300 rounded-md capitalize"
                              onChange={(e) => handleClassChange(e, index)}
                            >
                              <option value="">{"Select Class"}</option>
                              {selector?.Class?.classList?.map((ele) => (
                                <option value={ele._id}>{ele.name}</option>
                              ))}
                            </select>
                            {showDemoError && errorData?.bookFreeDemo[index]?.classId &&
                              <span className="text-red-500">
                                {errorData?.bookFreeDemo[index]?.classId}
                              </span>
                            }
                          </div>
                          <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                              Subjects :
                            </label>
                            <Multiselect
                              id={`subjects-${index}`}
                              options={selectedSubjectOptions[index] ?? []}
                              selectedValues={field.subjectId}
                              onSelect={(selectedList, selectedItem) => onSelect2(selectedList, selectedItem, index)}
                              onRemove={(selectedList, removedItem) => onRemove2(selectedList, removedItem, index)}
                              displayValue="name"
                              className="z-50 min-h-[39px] max-h-[31px] capitalize"
                            />
                            {showDemoError && errorData?.bookFreeDemo[index]?.subjectId &&
                              <span className="text-red-500">
                                {errorData?.bookFreeDemo[index]?.subjectId}
                              </span>
                            }
                          </div>
                          <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                              Board :
                            </label>
                            <select
                              name={`board-${index}`}
                              value={field?.board?._id || field?.board}
                              className="block w-full p-2 border border-gray-300 rounded-md capitalize"
                              onChange={(e) => handleBoardChange(e, index)}
                            >
                              <option value="">{"Select Board"}</option>
                              {selector?.Board?.bordList?.list?.map((ele) => (
                                <option value={ele._id}>{ele.name}</option>
                              ))}
                            </select>
                            {showDemoError && errorData?.bookFreeDemo[index]?.board &&
                              <span className="text-red-500">
                                {errorData?.bookFreeDemo[index]?.board}
                              </span>
                            }
                          </div>


                          <div className='flex items-center gap-2 pb-2 col-span-4 justify-end'>

                            <div>
                              <button
                                type="button"
                                onClick={() => handleRemoveField(index)}
                                className={`w-8 h-8 px-1 py-1 text-sm text-white flex justify-center items-center bg-red-600 rounded-[6px] hover:bg-red-500 focus:outline-none cursor-pointer  ${inputChangeField?.bookFreeDemo.length === 1
                                  ? "opacity-100 cursor-not-allowed"
                                  : ""
                                  }`}
                                disabled={inputChangeField?.bookFreeDemo.length === 1}
                              >
                                <IoRemove />
                              </button>
                            </div>

                            <div className="">
                              <button
                                type="button"
                                onClick={handleAddField}
                                className="w-8 h-8 px-1 py-1 text-sm text-white  flex justify-center items-center  focus:outline-none bg-blue-500 rounded-[6px]"
                              >
                                <IoAdd />
                              </button>
                            </div>
                          </div>


                        </div>
                      ))}
                    </div>

                  </div>
                  <div className="flex justify-center py-6">
                    <button
                      type="button"
                      onClick={handleSubmitUpdateInfo}
                      className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
}

export default UpdateTuitionModel;
