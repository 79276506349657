import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAction } from "../../_actions/dashboard.action.js";
import Card from "./Card.js";
import ButtonFirst from "./ButtonFirst.js";
import ButtonSecond from "./ButtonSecond.js";
import ButtonThird from "./ButtonThird.js";
import ButtonFour from "./ButtonFour.js";
import Dashboardjson2 from "../dashboard/Dashboard2.json";
import ButtonCom from "./ButtonCom";
import KycHeader from "./KycHeader.js";
import KycTabel from "./KycTabel.js";
import "./Dashboard.css";
import DashBoardHeader from "./DashBoardHeader.js";

const Dashboard = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let [dashBoardCount, setDashBoardCount] = useState();
  const [showData, setShowdata] = useState();
  const [active, setShowActive] = useState(
    `Contact Seen Parents`
  );
  const [cardActive, setCardActive] = useState();

  useEffect(() => {
    dispatch(dashboardAction.getDashboardCountCreditBase());
  }, []);

  useEffect(() => {
    setDashBoardCount(selector?.Dashboard?.dashboardCreditBaseCount?.data)
  }, [selector]);

  const handleResponse = (data) => {
  }

  const buttonName = [
    `Contact Seen Parents`,
    `Contact Seen Tutors`,
    `New Referrals`,
    `Latest Issues`
  ];

  const handleBtnClick = (data) => {
    setShowdata(data);
    setShowActive(data);
  };

  const handleActiveCard = (val) => {
    setCardActive(val);
  };

  function dashBoardCountData(data) {
    switch (data) {
      case "Contact Seen Parents":
        return dashBoardCount?.contactSeenParents || 0
      case "Contact Seen Tutors":
        return dashBoardCount?.contactSeenTutor || 0
      case "New Referrals":
        return dashBoardCount?.newReferral || 0
      case "Latest Issues":
        return dashBoardCount?.newTicket || 0
      case "Registered Tutors":
        return dashBoardCount?.registeredTutor || 0
      case "Registered Parents":
        return dashBoardCount?.registeredParent || 0
      case "Requirements Posted":
        return dashBoardCount?.requirementPost || 0
      default:
        return null
    }
  }

  return (
    <>
      <DashBoardHeader />
      <section className="py-10 overflow-hidden overflow-y-auto">
        <div className="flex flex-col lg:flex-row gap-2 lg:gap-5 xl:gap-10 2xl:gap-10 lg:px-10">
          <div className="w-full lg:w-4/5 mx-auto lg:px-0 px-4">
            <section className="three-card-section flex flex-col md:flex-row gap-3 cursor-pointer justify-between">
              {Dashboardjson2.slice(0, 3).map((item, index) => (
                <div
                  key={index}
                  className={`rounded-3xl w-full md:w-1/3 ${cardActive === index
                    ? "bg-[#C60E6B] text-white"
                    : "bg-white text-[#023565]"
                    }`}
                  onClick={() => handleActiveCard(index)}
                >
                  <Card
                    index={cardActive}
                    Group3={item.img}
                    title={item.title}
                    numbers={dashBoardCountData(item.title)}
                    buttonName={item.buttonName}
                    onClick={() => handleResponse(item)}
                    link={item.url}
                  />
                </div>
              ))}
            </section>
            <section className="mt-8 w-full 2xl:w-[90%]">
              <div>
                <section className="flex flex-wrap section_button  justify-between text-center">
                  {buttonName.map((item, index) => (
                    <div
                      key={index}
                      className={`text-gray-100 font-bold py-2 px-3 sm:flex-row rounded-full text-sm mb-3 lg:mb-0 ${active === item ? "bg-[#023565]" : "bg-[#9C9EAB]"
                        }`}
                    >
                      <ButtonCom
                        onClick={() => handleBtnClick(item)}
                        name={item}
                        value={dashBoardCountData(item)}
                      />
                    </div>
                  ))}
                </section>
              </div>
            </section>
            <div className="mt-6 bg-white p-4 rounded-lg shadow-md">
              {active ===
                `Contact Seen Parents` && (
                  <ButtonFirst />
                )}
              {active ===
                `Contact Seen Tutors` && (
                  <ButtonSecond />
                )}
              {active === `New Referrals` && (
                <ButtonThird />
              )}
              {active === `Latest Issues` && (
                <ButtonFour />
              )}
            </div>
            {/* getNewTicketList */}
          </div>
          <div className="w-full lg:w-2/5 2xl:w-[25%]  lg:px-0 px-4">
            <div className="rounded-lg shadow-md">
              <div className="bg-[#D8D8D8] rounded-t-lg shadow-md py-2">
                <KycHeader />
              </div>
              <div className="bg-white rounded-b-lg shadow-md p-4 ">
                <KycTabel />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
