import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";

const ReferencePaid = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let referencePaidListData = selector?.Fee?.referencePaidList?.data?.list
  const location = useLocation();
  const feeReferenceData = location.state?.feeReferenceData;
  let monthId = feeReferenceData?.month

  useEffect(() => {

    let referencePaid = {
      "cityId": "",
      "isManagement": false,
      "month": monthId,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    dispatch(feeAction.getReferencePaidList(referencePaid))
  }, [])
  return (
    <div className='m-5'>
      <p className='text-center font-semibold text-orange-700 py-4'>ReferencePaid </p>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right ">
          <thead class="text-xs text-blue-700 bg-white">
            <tr className="py-4 border-b">
              <th scope="col" class="px-6 py-4">
                Parent name
              </th>
              <th scope="col" class="px-6 py-3">
                subject
              </th>
              <th scope="col" class="px-6 py-3">
                Class
              </th>
              <th scope="col" class="px-6 py-3">
                tution Fee
              </th>
              <th scope="col" class="px-6 py-3">
                Tutor Fee
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {referencePaidListData &&
              referencePaidListData?.map((item, id) => (
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="flex gap-3 pla">
                      <div>
                        <img
                          src={
                            item?.parentId?.image ??
                            "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          className="w-8 rounded-full"
                        />
                      </div>
                      <div>
                        <p>{item?.parentId?.name}</p>
                        <p>{item?.parentId?.mobNo}</p>
                      </div>
                    </div>
                  </th>
                  <td class="px-6 py-4">
                    {item?.bookFreeDemo?.map((subj, id) => (
                      <p key={id}>{subj?.subjectId?.name ?? "No Subject"}</p>
                    ))}
                  </td>
                  <td class="px-6 py-4">
                    {item?.classId?.map((item, index) => (
                      <p key={index}>{item?.name}</p>
                    ))}
                  </td>
                  <td class="px-6 py-4">{item?.tuitionFee ?? "0"}</td>
                  <td class="px-6 py-4">{item?.tutorFee ?? "0"}</td>

                  <td class="px-6 py-4 text-green-700 font-semibold">
                    {item?.status === 0
                      ? " pending"
                      : item?.status === 1
                        ? " recived"
                        : item?.status === 2
                          ? " advance paid"
                          : "Unknown status"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ReferencePaid
