import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";

const FeeRefund = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  let FeeRefundDataList = selector?.Fee?.feeRefundDataCb?.data?.list
  const location = useLocation();
  const feeRefundData = location.state?.feeRefundData;
  let monthId = feeRefundData?.month
  useEffect(() => {
    let feeRefundData = {
      "cityId": "",
      "month": monthId,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    dispatch(feeAction.getTotalRefundedAmountList(feeRefundData))
  }, [])
  return (
    <div className="m-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right ">
          <thead class="text-xs text-blue-700 bg-white">
            <tr className="py-4 border-b">
              <th scope="col" class="px-6 py-4">
                User
              </th>
              <th scope="col" class="px-6 py-3">
                Open Contact Details
              </th>
              <th scope="col" class="px-6 py-3">
                Payment
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {FeeRefundDataList &&
              FeeRefundDataList?.map((item, id) => (
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="flex gap-3 pla">
                      <div>
                        <img
                          src={
                            item?.userId?.image ??
                            "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          className="w-8 rounded-full"
                        />
                      </div>
                      <div>
                        <p>{item?.userId?.name ?? '-'}</p>
                        <p>{item?.userId?.mobNo ?? '-'}</p>
                        <p>{item?.userType ?? '-'}</p>
                      </div>
                    </div>
                  </th>
                  {/* <td class="px-6 py-4">
                  <p>{item?.userType ?? '-'}</p>
                </td> */}
                  <td class="px-6 py-4">
                    <p>{item?.parentId?.name ?? item?.tutorId?.name ?? '-'}</p>
                    <p>{item?.parentId?.mobNo ?? item?.tutorId?.mobNo ?? '-'}</p>
                    <p>{item?.userType == 'PARENT' ? 'TUTOR' : item?.userType == 'TUTOR' ? 'PARENT' : '-'}</p>
                  </td>

                  <td class="px-6 py-4">{item?.payment ?? "0"}</td>
                  <td class="px-6 py-4">
                    {item?.status === 0
                      ? " pending"
                      : item?.status === 1
                        ? " recived"
                        : item?.status === 2
                          ? " advance paid"
                          : "Unknown status"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FeeRefund
