import { userListConstants } from "../_constants";
export default function userList(state = {}, action) {
  switch (action.type) {
    case userListConstants.GET_USERLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_USERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action?.users,
      };
    case userListConstants.GET_USERLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.GETKYC_BYUSERID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GETKYC_BYUSERID_SUCCESS:
      return {
        ...state,
        loading: false,
        userKycListByUserId: action?.users,
      };
    case userListConstants.GETKYC_BYUSERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.GET_ONGOING_TUTION_LIST_BYID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_ONGOING_TUTION_LIST_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        getOnGoingTutionList: action?.users,
      };
    case userListConstants.GET_ONGOING_TUTION_LIST_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.GET_FREE_HISTORY_BYUSERID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_FREE_HISTORY_BYUSERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getFeeHistoryById: action?.users,
      };
    case userListConstants.GET_FREE_HISTORY_BYUSERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.GET_RATING_BYUSERID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_RATING_BYUSERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getRatingList: action?.users,
      };
    case userListConstants.GET_USERLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.BLOCK_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.BLOCK_BY_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userListConstants.BLOCK_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.GET_APPLIED_TUTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_APPLIED_TUTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        appliedTutionList: action?.users,
      };
    case userListConstants.GET_APPLIED_TUTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.GET_DEMO_DONELIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_DEMO_DONELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        demoDoneList: action?.users,
      };
    case userListConstants.GET_DEMO_DONELIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.GET_COMPLETE_TUTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_COMPLETE_TUTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        completeTutionList: action?.users,
      };
    case userListConstants.GET_COMPLETE_TUTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.KYC_REMINDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.KYC_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userListConstants.KYC_REMINDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.PACKAGE_REMINDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.PACKAGE_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userListConstants.PACKAGE_REMINDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case userListConstants.UPDATE_TUTOR_BYADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.UPDATE_TUTOR_BYADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userListConstants.UPDATE_TUTOR_BYADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };













    case userListConstants.GET_USER_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_USER_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserListForSearch: action?.users
      };
    case userListConstants.GET_USER_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };



    case userListConstants.GET_USER_TYPE_REQUEST2:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_USER_TYPE_SUCCESS2:
      return {
        ...state,
        loading: false,
        getUserListForSearch2: action?.users
      };
    case userListConstants.GET_USER_TYPE_FAILURE2:
      return {
        ...state,
        loading: false,
        error: action.error,
      };





    case userListConstants.GET_UPDATE_TUTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_UPDATE_TUTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        getTutorUpdateById: action?.users
      };
    case userListConstants.GET_UPDATE_TUTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };










    case userListConstants.GET_ALL_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_ALL_SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSubjectList: action?.users
      };
    case userListConstants.GET_ALL_SUBJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case userListConstants.GET_ALLTUTORLIST_WITH_NAMEORID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userListConstants.GET_ALLTUTORLIST_WITH_NAMEORID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTutorListWithNameOrId: action?.users
      };
    case userListConstants.GET_ALLTUTORLIST_WITH_NAMEORID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
