/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationAction, uploadImageAction } from "../../_actions";
import Button from "./Button";
import Loader from "../../components/Loader";
import { IoAddOutline } from "react-icons/io5";
import ImageModel from "./Models/ImageModel";
import AddNotification from "./Models/AddNotification";
import MobileTable from "./Tables/MobileTable";
import FullTable from "./Tables/FullTable";
import DateRangePicker from "../../components/Atoms/DateRangePicker";
import { FaFilterCircleXmark } from "react-icons/fa6";
const StudentsComponent = ({ searchText, headerCity }) => {
  const dispatch = useDispatch();
  const Selector = useSelector(state => state)
  const [imageData, setImage2Data] = useState();
  const [selectedCityIds, setSelectedCityIds] = useState([]);

  const [modal, setModal] = useState(false);
  const [NotificationData, setNotificationData] = useState()
  const [imageModal, setImageModal] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setNewMessage] = useState("");
  const [imgeData, setImageData] = useState([]);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [options, setOptions] = useState();
  const [errors, setErrors] = useState({ title: '', message: '', selectedCities: '' });
  const [allCityListData, setAllCityListData] = useState();
  const [cityId, setCityId] = useState("")


  const [dates, setDates] = useState({ fromDate: '', toDate: '' });

  const handleChange = (value, dateType) => {
    setDates((prevDates) => ({ ...prevDates, [dateType]: value }));
  };


  useEffect(() => {
    let notificationDetals = {
      "userType": "TUTOR",
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : null,
      ...dates,
      pageNo: currentPage,
      size: pageSize,
    }

    dispatch(notificationAction.getNotificationListByAdmin(notificationDetals))
  }, [currentPage, headerCity, searchText, dates])


  useEffect(() => {
    if (Selector?.notificationList?.notificationList?.data?.total) {
      setTotalPages(Math.ceil(Selector?.notificationList?.notificationList?.data?.total / pageSize));
    }
    setNotificationData(Selector?.notificationList?.notificationList?.data?.list)
    setOptions(Selector?.Citys?.cityGetList?.map(city => ({ name: city.name, id: city._id })))
    setImage2Data(Selector?.ImageUpload?.fileDetails?.imageUrl)
    setAllCityListData(Selector?.tution?.getAllCityList?.data);
    const cityList = Selector?.Citys?.cityGetList || [];
    const mappedCities = cityList.map(city => ({
      name: city.name,
      id: city._id
    }));

    setOptions(mappedCities);

  }, [Selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const viewImage = (data) => {
    setImageModal(true);
    setImageData([data]);
  };

  const handleClick = () => {
    setModal(true);
  };

  // const handleClose = () => {
  //   setModal(false);
  // };

  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem?.id === 'Select All') {
      // Select all cities
      const allCities = options.filter(option => option.id !== 'Select All');
      const allCityIds = allCities.map(city => city.id);

      setSelectedCities(allCities);
      handleCityChange(allCities);
      setSelectedCityIds(allCityIds);

      setErrors(prevErrors => ({ ...prevErrors, selectedCities: '' }));
    } else {
      const selectedCityIds = selectedList.map(city => city.id);

      setSelectedCities(selectedList);
      handleCityChange(selectedList);
      setSelectedCityIds(selectedCityIds);

      setErrors(prevErrors => ({ ...prevErrors, selectedCities: '' }));
    }
  };

  const onRemove = (selectedList, removedItem) => {
    if (removedItem?.id === 'Select All') {
      // Clear all selected cities
      setSelectedCities([]);
      handleCityChange([]);
      setSelectedCityIds([]);

      setErrors(prevErrors => ({ ...prevErrors, selectedCities: '' }));
    } else {
      const selectedCityIds = selectedList.map(city => city.id);

      setSelectedCities(selectedList);
      handleCityChange(selectedList);
      setSelectedCityIds(selectedCityIds);

      setErrors(prevErrors => ({ ...prevErrors, selectedCities: '' }));
    }
  };



  const handleCityChange = (selectedList) => {

  };


  const [error, setError] = useState("")
  const handleUpload = (e) => {
    const file = e.target.files[0];
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];

    if (file && !validFileTypes.includes(file.type)) {
      setError('Invalid file type. Only PNG, JPEG, JPG, and SVG images are allowed.');
      e.target.value = null; // Clear the selected file
    } else {
      setError('');
      dispatch(uploadImageAction.UploadImage(file));
      setUploadedImage(file);
    }
  };




  const handleSubmitNotification = (e) => {
    e.preventDefault();
    let valid = true;
    let newErrors = { title: '', message: '', selectedCities: '' };

    if (!title) {
      newErrors.title = 'Title is required';
      valid = false;
    }
    if (!message) {
      newErrors.message = 'Message is required';
      valid = false;
    }
    if (selectedCities.length === 0) {
      newErrors.selectedCities = 'At least one city must be selected';
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;
    let notificationDetails = {
      "userType": "TUTOR",
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : null,
      ...dates,
      pageNo: currentPage,
      size: pageSize,
    };

    let notificationData = {
      cityId: selectedCityIds,
      msg: message,
      title: title,
      image: imageData ?? null,
      userType: "TUTOR"
    };

    dispatch(notificationAction.createCustomNotification(notificationData, notificationDetails));
    setModal(false);
    setTitle("");
    setSelectedCities([])
    setNewMessage("");
  };
  const handleClose = () => {
    setModal(false);
    setTitle("");
    setNewMessage("");
    setImageData("");
    setSelectedCities([]);

    // Clear the selectedCities error
    setErrors((prevErrors) => ({
      ...prevErrors,
      title: '',
      message: '',
      selectedCities: '',

    }));
  };


  return (
    <>
      <div className="md:justify-end flex items-center space-x-3 lg:py-0 pt-6 lg:-mt-10 md:-mt-16">
        <div className="flex items-center gap-3">
          <DateRangePicker
            fromDate={dates.fromDate}
            toDate={dates.toDate}
            handleChange={handleChange}
          />
          <button className="text-center font-bold pt-6">
            <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" onClick={() => {
              setDates({ fromDate: '', toDate: '' }); setCityId("")
            }} />
          </button>
        </div>
        <section
          className=" flex  justify-center w-40 items-center font-bold text-[#C60E6B] border bg-white rounded-lg px-2 py-2 mt-6"
          onClick={() => handleClick()}
        >  <span>
            <IoAddOutline className="text-xl mx-1" />
          </span>
          <Button name="Create New" />
        </section>
      </div>
      <div className="py-4 overflow-hidden overflow-y-auto">
        <Loader loading={Selector?.notificationList?.loading} />
        <Loader loading={Selector?.ImageUpload?.loading} />

        <FullTable
          currentPage={currentPage}
          NotificationData={NotificationData}
          totalPages={totalPages}
          pageSize={pageSize}
          handlePreviousPage={(e) => handlePreviousPage(e)}
          handleNextPage={(e) => handleNextPage(e)}
          viewImage={(e) => viewImage(e)}
        />
        <AddNotification
          modal={modal}
          handleClose={(e) => handleClose(e)}
          handleSubmitNotification={(e) => handleSubmitNotification(e)}
          options={options}
          selectedCities={selectedCities}
          onSelect={(a, b) => onSelect(a, b)}
          onRemove={(a, b) => onRemove(a, b)}
          errors={errors}
          setTitle={(e) => setTitle(e)}
          title={title}
          setNewMessage={(e) => setNewMessage(e)}
          message={message}
          handleUpload={(e) => handleUpload(e)}
          error={error}
          setErrors={setErrors}
        />

        <ImageModel
          setImageModal={setImageModal}
          imgeData={imgeData}
          imageModal={imageModal}
        />
        <MobileTable
          NotificationData={NotificationData}
          viewImage={(e) => viewImage(e)}
        />

      </div>
    </>
  )
}

export default StudentsComponent