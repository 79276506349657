import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineClose } from 'react-icons/md';
import { tutionAction } from '../../../../_actions';

const ViewMoreTuitionModel = (props) => {
    let { isViewMoreModel, setIsViewMoreModel, tuitionId } = props;
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch(tutionAction.getDemoById({ demoId: tuitionId }))
    }, []);

    useEffect(() => {
        setData(selector?.tution?.getDemoById?.data || null)
    }, [selector]);

    return (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                    className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                    onClick={() => setIsViewMoreModel(false)}
                >
                    <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h2 className="text-lg font-semibold">Tuition Details</h2>
                    </div>
                    {data &&
                        <div className="relative lg:h-[350px] h-[300px] overflow-y-auto">
                            <div className="space-y-1 p-4">
                                <div className="flex flex-col space-y-4">
                                    {/* Parent Name */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Parent (Name, Mobile)
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {`${data?.parentId?.name}, ${data?.parentId?.mobNo}`}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Tutor Name */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Tutor (Name, Mobile)
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {`${data?.tutorId?.name}, ${data?.tutorId?.mobNo}`}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col space-y-4">
                                        {data?.bookFreeDemo.map((demo, index) => (
                                            <div key={index} className="flex justify-between w-full">
                                                <div className="w-full">
                                                    <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                        Demo (Student,Class,Subjects,Board)
                                                    </p>
                                                </div>
                                                <div className="w-full">
                                                    <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                        {`${demo?.studentId?.studentFullName}, ${demo?.classId?.name}, ${demo?.subjectId.map(subject => subject?.name).join(", ")}, ${demo?.board?.name}`}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Tutor Applied (Name)
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.tutorAppliedList.map(tutor => tutor?.name).join(", ")}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Tutor Rejected (Name)
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.tutorRejectedList.map(tutor => tutor?.tutorId?.name).join(", ") || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Demo Start Date */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Demo Start Date
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.startDate || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Preferred Teaching Mode */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Preferred Teaching Mode
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.preferredTeachMode ? data.preferredTeachMode === "HOME_TUITION" ? "Home Tuition" : data.preferredTeachMode === "ONLINE_TUITION" ? "Online" : "---" : "---"}
                                            </p>
                                        </div>
                                    </div>


                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                City
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.cityId?.name || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Pin Code */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Pin Code
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.pinCodeId || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Teaching Location */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Teaching Location
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.teachingLocation || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Demo Date */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Demo Date
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.demoDate || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Demo Time */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Demo Time
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.demoTime || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Address */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Address
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.address || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Tuition Type */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Fee Type
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.tuitionType || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Required Gender */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Preferred Gender
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.requiredGender || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Number of Classes */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Session Details
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.numberOfClasses || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* In-Hand Fees */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                In-Hand Fees
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.inHandFees || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Remark */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Remark
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.remark || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Class Reference */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Class Reference
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.classReference || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Reason */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Reason
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.reason || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Reference Fee */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Reference Fee
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.referenceFee || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Tutor Fee */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Tutor Fee
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.tutorFee || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Company Share */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Company Share
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.companyShare || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Class Duration */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Class Duration (In minutes)
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.classDuration || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Lead Source */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Lead Source
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.leadSource ? data.leadSource === "CREATED_SELF" ? "App" : data.leadSource === "CREATED_ADMIN" ? "Admin" : "---" : "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Lead Manager */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Lead Manager
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.assignToEmployee ? data?.assignToEmployee?.name ? data?.assignToEmployee?.name : "---" : "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Created At */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Created At
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.createdAt ? new Date(data.createdAt).toLocaleDateString() : "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Payment Mode */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Payment Mode
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.paymentMode || "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Demo Done Date */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Demo Done Date
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.demoDoneDate ? new Date(data.demoDoneDate).toLocaleDateString() : "---"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Due Date */}
                                    <div className="flex justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[14px] font-[700] text-left text-[#313131]">
                                                Due Date
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <p className="text-[12px] font-[400] text-left text-[#313131]">
                                                {data?.dueDate || "---"}
                                            </p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>)
}

export default ViewMoreTuitionModel