/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { FaAngleRight, FaChevronCircleRight, FaChevronLeft } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { alertActions, parentAction, userActions, userListAction } from "../../_actions";
import { cityAction } from "../../_actions/city.action";
import { useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { tutionAction } from "../../_actions";
import Loader from "../../components/Loader";
import UpdateParent from "./model/UpdateParent";
import ImagePreviewModal from "../Tutor-Profile/Model/ImagePreview";
import BlockPopUp from "../Tutor-Profile/Model/BlockPopUp";
import ViewUpdate from "./model/ViewUpdate";
import StudentDetail from "./model/StudentDetail";
import OngoingBatchView from "./model/OngoingBatchView";
import { FaFilterCircleXmark } from "react-icons/fa6";

import moment from "moment"
import AddEditStudentModel from "./model/AddEditStudentModel";

const mergeAndAddStatus = (firstArray, secondArray) => {
  return secondArray.map(item => {
    // Check if the student exists in the first array
    const found = firstArray.find(firstItem => firstItem.studentId === item.studentId);

    // Add the status key based on the existence of the student in the first array
    return {
      ...item,
      status: !!found,
      // If found, copy over the subjectDetails from the first array
      subjectDetails: found ? found.subjectDetails : item.subjectDetails
    };
  });
};

const studentInitialState = {
  studentFullName: "",
  dob: "",
  grade: { label: 'Select Grade', value: '' },
  schoolName: "",
  educationBoard: { label: 'Select Board', value: '' },
  medium: "",
  gender: "",
  studentId: ""
}


export const ActiveParent = ({ headerCity, searchText, handleReset }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const navigate = useNavigate()

  const [activeParentUserList, setactiveParentUserList] = useState()
  const [studentListdata, setStudentListData] = useState();
  const [feePaymentListdata, setFeePaymentListData] = useState();
  const [onGoingTutuionListById, setOnGoingTutuionListById] = useState()
  const [blockedPoup, setBlockpouUp] = useState(false);
  const [blockedData, setNewblockedData] = useState({});
  const [name, setName] = useState("");
  const [fullDetails, setFullDetails] = useState(false);
  const [DetailsData, setFullDetailsData] = useState();
  const [actionItem, setActionItem] = useState(null);
  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [feepaymentModal, setFeePaymentModal] = useState(false);
  const [parentUpdateModal, setParentUpdateModal] = useState(false);
  const [onGointTutionList, setOnGoingTutionList] = useState(false);
  const [initialParentData, setInitialParentData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10
  const menuRef = useRef(null);
  const mobileRef = useRef(null)
  const [isFetching, setIsFetching] = useState(false)
  const [pinCode, setPinCode] = useState("")
  const [isOpenAddEditModel, setIsAddEditModel] = useState(false)
  const [addEditData, setAddEditData] = useState({
    ...studentInitialState,
    parentId: "",
  })


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setActionItem(null);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    let parentUserList = {
      status: "ACTIVE",
      userType: "PARENT",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : [],
      pinCode: pinCode
    };

    let cityDetails = {
      isManagement: true
    };
    dispatch(parentAction.getUserList(parentUserList));
    dispatch(cityAction.cityGet(cityDetails));
    dispatch(tutionAction.getAllCityList())
  }, [currentPage, searchText, headerCity, pinCode]);





  useEffect(() => {
    if (selector?.Parent?.parentuserList?.data?.total) {
      setTotalPages(Math.ceil(selector?.Parent?.parentuserList?.data?.total / pageSize));
    }
    setactiveParentUserList(() => selector?.Parent?.parentuserList?.data?.demoList)
    setFeePaymentListData(selector?.Parent?.getFeeHistoryById?.data)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };



  const handleFullDetails = (data) => {
    setAddEditData((prev) => ({
      ...prev,
      parentId: data._id
    }))
    userActions.getUserByPromiseId({ id: data._id }).then((res) => {
      setFullDetails(true);
      setFullDetailsData(res?.data);
    })
  };

  const fetchStudents = (data) => {
    let students = [];
    parentAction.getStudentListByParentIdPromise(data).then((resData) => {
      students = resData.data ?? []
    }).catch((err) => {
    });
    parentAction.getStudentListByParentIdForDemoPromise(data)
      .then((resData) => {

        const list = (resData?.data || []).map((item) => ({
          subjectDetails: [],
          studentId: item?._id ?? "",
          studentDetails: {
            _id: item?._id ?? "",
            studentFullName: item?.studentFullName ?? "",
            dob: item?.dob ?? "",
            gender: item?.gender ?? "",
            schoolName: item?.schoolName ?? "",
            medium: item?.medium ?? "",
          },
          classDetails: {
            _id: item?.grade?._id ?? "",
            name: item?.grade?.name ?? "",
          },
          boardDetails: item?.educationBoard ?? {}
        }));
        const mergedArray = mergeAndAddStatus(students, list);
        setStudentListData(() => mergedArray)
      })
      .catch((err) => {
      });
    setViewStudentModal(true);
  }

  const viewStudent = (data) => {
    let studentListDetails = {
      parentId: data._id,
    };
    setAddEditData((prev) => ({
      ...prev,
      parentId: data._id,
    }))
    fetchStudents(studentListDetails)
  };
  const handleOnGoingTution = (data) => {
    let parentData = {
      parentId: data._id,
    };
    setOnGoingTutionList(true);
    setIsFetching(true)
    parentAction.getOnGoingTutionListPromise(parentData).then((res) => {
      setOnGoingTutuionListById(res.data)
      setIsFetching(false)
    }).catch(err => {
      setIsFetching(false)

    })
  };

  const handleFeeHistory = (data) => {
    setFeePaymentModal(true);
    let feeDetails = {
      userId: data._id,
    };
    dispatch(parentAction.getFeeHistoryByUserId(feeDetails));
  };

  const handleAction = (id) => {
    setActionItem(actionItem === id ? null : id);
  };

  const [nameBlock, setNameBlock] = useState("")

  const handleBlockParent = (data) => {
    setBlockpouUp(true);

    let blockedData = {
      status: "BLOCK",
      id: data._id,
    };
    setNameBlock(data.name)
    setNewblockedData(blockedData);
  };

  const handleSubmit = () => {
    let resData = {
      status: "ACTIVE",
      userType: "PARENT",
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : [],
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      pinCode: pinCode
    };
    let blockednEWData = {
      ...blockedData,
      reason: name,
    };

    dispatch(parentAction.blockByAdmin(blockednEWData, resData));
    setBlockpouUp(false);
    setActionItem(null);
    setName("");
  };


  const handleParentUpdate = (data) => {
    setParentUpdateModal(true)
    setInitialParentData({ ...data, city: data?.city?.id })
  };


  const handleViewHistory = (data) => {
    navigate(`/app/parentviewhistory/${data?._id}`)
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState("")
  const handleCloseFunctionImageModel = (data) => {
    if (data) {
      setImage(() => data)
    }
    else {
      setImage("")
    }
    setIsModalVisible(!isModalVisible);
  };

  const handleResetFilters = () => {
    handleReset();
    setPinCode("")
  }

  const handleAddEditDataChange = (e) => {
    const { name, value } = e.target
    setAddEditData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleCloseAddEditModal = () => {
    fetchStudents({ parentId: addEditData.parentId })
    setAddEditData((prev) => ({
      ...prev, // Spread the previous state
      ...studentInitialState,
    }));
    setIsAddEditModel(false)
    // setViewStudentModal(true);


  }

  const handleOpenAddEditModal = (data) => {
    if (Object.keys(data).length) {
      const values = {
        studentFullName: data?.studentDetails?.studentFullName,
        dob: data?.studentDetails?.dob,
        grade: { label: data?.classDetails?.name, value: data?.classDetails?._id },
        schoolName: data?.studentDetails?.schoolName,
        educationBoard: { label: data?.boardDetails?.name, value: data?.boardDetails?._id },
        medium: data?.studentDetails?.medium,
        gender: data?.studentDetails?.gender,
        studentId: data.studentId
      }
      setAddEditData(prev => ({
        ...prev,
        ...values
      }))
      setIsAddEditModel(true)
    } else {
      setIsAddEditModel(true)
    }
    setViewStudentModal(false);

  }

  const handleSubmitAddEditModal = () => {
    let studentData = {
      ...addEditData,
      educationBoard: addEditData.educationBoard.value,
      grade: addEditData.grade.value
    }

    parentAction.addEditStudentByAdminPromise(studentData)
      .then(res => {

        fetchStudents({ parentId: addEditData.parentId });

        setAddEditData((prev) => ({
          ...prev, // Spread the previous state
          ...studentInitialState, // Reset to initial state
        }));

        // Close the modal
        setIsAddEditModel(false);
        dispatch(alertActions.success(res.data))
      })
      .catch(err => {
        dispatch(alertActions.error(err === "Server error." ? "Please try again later." : "Student name already exist.")); // Show error message to the user
        fetchStudents({ parentId: addEditData.parentId });

        setAddEditData((prev) => ({
          ...prev, // Spread the previous state
          ...studentInitialState, // Reset to initial state
        }));

        // Close the modal
        setIsAddEditModel(false);
      });


  }


  return (
    <section className="">
      <Loader loading={selector?.Parent?.loading} />

      <div className="lg:flex justify-end lg:-mt-9 mt-6">
        <div className="filter flex items-center space-x-4">
          <select onChange={(e) => setPinCode(e.target.value)} className="block lg:w-40 w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
            value={pinCode}>
            <option value=''>PinCode</option>
            {headerCity &&
              selector?.Citys?.cityGetList
                ?.filter(city => city._id === headerCity)?.[0]?.pinCode
                ?.map(pin => (
                  <option value={pin} key={pin}>
                    {pin}
                  </option>
                ))
            }
          </select>
          <button className="text-center font-bold" onClick={() => handleResetFilters()}>
            <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" />
          </button>
        </div>
      </div>


      <section className="lg:block hidden">
        <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
          <table className="min-w-full table-autorounded-xl overflow-x-auto">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-black">
                <th className="px-6 py-3 whitespace-nowrap text-left text-sm">Parent Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Student Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">City</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Ongoing Tuitions</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Fee Payment History</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {activeParentUserList && activeParentUserList.length > 0 ? (
                activeParentUserList?.map((item, id) => (
                  <tr key={id} className="border-b border-[#D1D1D1] capitalize">
                    <td className="px-4 py-4 text-sm text-gray-900">
                      <div className="flex items-center gap-2">
                        <span onClick={item?.image ? () => handleCloseFunctionImageModel(item?.image) : null}>
                          {item?.image && item?.image ?
                            <img className="rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
                              src={
                                item?.image}
                              alt="User"
                            /> : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                              <span className="text-base font-semibold text-[#033565]"> {item?.name ? (item.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                            </div>}
                        </span>
                        <div className="w-24">
                          <p className="font-semibold whitespace-nowrap text-[#023565]">{item?.name ?? "N/A"}</p>
                          <p className="text-gray-500 whitespace-nowrap">{item?.mobNo ?? "N/A"}</p>
                          <div className="flex items-center gap-1 cursor-pointer pt-1" onClick={() => handleFullDetails(item)}>
                            <p className="text-base font-normal cursor-pointer">More</p>
                            <FaChevronCircleRight
                              className="text-[#023565] text-base cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">
                      <span className="hover:text-[#CD0C5C] text-center flex items-center justify-center" onClick={() => viewStudent(item)}>  <FaEye size={24} /></span>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item?.city?.name ?? "No City"}</td>


                    <td className="px-4 py-4 text-gray-900 relative">
                      <div onClick={() => handleOnGoingTution(item)} className="font-semibold text-sm flex justify-center items-center cursor-pointer hover:text-red-500 underline">View</div>
                    </td>

                    <td className="px-4 py-4 text-gray-900 relative">
                      <div onClick={() => handleFeeHistory(item)} className="font-semibold text-sm flex justify-center items-center cursor-pointer hover:text-red-500 underline">View</div>
                    </td>


                    <td className="px-4 py-4 text-gray-900 relative">
                      <span className="flex justify-center items-center">
                        <BsThreeDotsVertical onClick={() => handleAction(item._id)} className="text-2xl cursor-pointer text-red-500 " />
                      </span>
                      {actionItem === item._id && (
                        <div ref={menuRef} className="absolute right-16 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                          <div className="py-1 font-semibold text-[#023565]">
                            <p className=" px-4 py-2 flex justify-between place-items-center border-b text-sm cursor-pointer hover:bg-gray-100 hover:text-[#E4006F] " onClick={() => handleParentUpdate(item)}>  View & Update Info  <FaAngleRight className="hover:text-[#C60E6B]" />
                            </p>
                            <p className=" px-4 py-2 flex justify-between place-items-center border-b text-sm hover:text-[#E4006F]  cursor-pointer hover:bg-gray-100 " onClick={() => handleBlockParent(item)}>
                              Block Parent
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </p>
                            <p className=" px-4 py-2 flex justify-between place-items-center  text-sm hover:text-[#E4006F]  cursor-pointer hover:bg-gray-100" onClick={() => handleViewHistory(item)}>
                              View History
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </p>
                          </div>
                        </div>
                      )}
                    </td>

                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>


      <BlockPopUp
        blockedPoup={blockedPoup} setBlockpouUp={setBlockpouUp} name={name} setName={setName} handleSubmit={handleSubmit} nameBlock={nameBlock}
      />


      <ViewUpdate
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
      />


      <OngoingBatchView
        data={onGoingTutuionListById}
        closeModal={() => { setOnGoingTutionList(false); setOnGoingTutuionListById(null) }}
        open={onGointTutionList}
        isFetching={isFetching}
      />


      {feepaymentModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[850px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setFeePaymentModal(false)}
              >
                <span><MdOutlineClose /></span> Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Fee Payment History </h2>
                </div>
                <div className="relative lg:h-[400px] overflow-y-auto">
                  <div className="overflow-x-auto overflow-hidden p-4">
                    <table className="table_full w-full border ">
                      <thead>
                        <tr className="text-center bg-gray-100 border-b">
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Student Name</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Class</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Subject</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Tutor Name</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Fee</th>
                          <th className="py-2 px-4 text-left border whitespace-nowrap">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feePaymentListdata && feePaymentListdata.length > 0 ?
                          feePaymentListdata?.map((fee, id) => (
                            <tr key={id} className="border-b capitalize">
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.demoClassId?.bookFreeDemo?.map(stud => stud?.studentId?.studentFullName).join(', ') ?? "N/A"}
                              </td>
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.demoClassId?.bookFreeDemo?.map(stud => stud?.classId?.name).join(', ') ?? "N/A"}
                              </td>
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.demoClassId?.bookFreeDemo?.map(stud => stud?.subjectId?.map(sub => sub.name).join(', ')).join(', ') ?? "N/A"}
                              </td>
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.demoClassId?.tutorId?.name ?? "N/A"}
                              </td>
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {fee?.fee ?? "No Fee"}
                              </td>
                              <td
                                className={`py-2 px-4 border whitespace-nowrap ${fee?.status === 0 ? "text-red-600" : "text-blue-600"
                                  } font-bold sm:px-4 sm:py-2`}
                              >
                                {/* {fee?.status === 0 ? "Pending" : "Done"} */}
                                <p>
                                  {`Payment date: ${moment(fee?.payDate).format('DD-MMM-YYYY')}`}
                                </p>
                                {fee?.status === 3 ? <span>
                                  <p>Refunded date: {moment(fee?.payDate).format('DD-MMM-YYYY')}</p>
                                  <p>Refunded amount: {fee.feeRefunded}</p>
                                </span>
                                  : ""}

                                <p>
                                  {fee && fee.adminId && fee.adminId.roleId && fee.adminId.roleId === 1 ? `Done by: Super Admin` : fee && fee.adminId && fee.adminId.roleId && fee.adminId.roleId !== 1 ? `Done by: Sub Admin` : fee && fee.userId && fee.userId.name ? `Done by: Parent` : null}
                                </p>
                                <p>
                                  {fee?.adminId?.userName ? `Login Id: ${fee?.adminId?.userName}` : fee?.userId?.name ? `Login Id: ${fee?.userId?.name}` : null}
                                </p>
                              </td>
                            </tr>
                          )) : <p className="text-center">Data Not Found</p>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <StudentDetail
        setViewStudentModal={setViewStudentModal}
        viewStudentModal={viewStudentModal}
        setStudentListData={setStudentListData}
        studentListdata={studentListdata}
        isAction={true}
        openModal={handleOpenAddEditModal}
      />


      <UpdateParent
        setParentUpdateModal={setParentUpdateModal}
        parentUpdateModal={parentUpdateModal}
        initialParentData={initialParentData}
        currentPage={currentPage}
        pageSize={pageSize}
        status={"ACTIVE"}
      />

      <ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />
      <section className="space-y-5 py-6 lg:hidden block">
        {activeParentUserList && activeParentUserList.length > 0 ? (
          activeParentUserList?.map((item, id) => (
            <div className=' bg-white p-4 rounded-[10px] '>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Parent Name</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          item?.image ??
                          "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                        }
                        alt="User"
                      />
                      <div>
                        <div className="text-xs font-medium text-black ">{item?.name ?? "No Data"}</div>
                        <div className="text-xs text-gray-500">{item?.mobNo || 'No Number'}</div>
                        <FaChevronCircleRight
                          className="text-[#023565] cursor-pointer"
                          onClick={() => handleFullDetails(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Student Name</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => viewStudent(item)}
                    >
                      view</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.city?.name ?? "No City"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Ongoing Tuitions</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleOnGoingTution(item)}
                    >
                      View</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Fee History</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleFeeHistory(item)}
                    >
                      View
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <BsThreeDotsVertical
                        className="cursor-pointer text-[#C60E6B]"
                        onClick={() => handleAction(item._id)} size={24}
                      />
                      {actionItem === item._id && (
                        <div className="absolute right-0 mt-2 w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40" ref={mobileRef}>
                          <div className="py-1 font-semibold">
                            <a
                              href="#view-update-info"
                              className="block px-4 py-2 text-sm text-pink-600 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleParentUpdate(item)}
                            >
                              View & Update Info
                            </a>
                            <a
                              href="#block-parent"
                              className="block px-4 py-2  text-sm text-[#023565] cursor-pointer hover:bg-gray-100"
                              onClick={() => handleBlockParent(item)}
                            >
                              Block Parent
                            </a>
                            {/* <a
                              href="#block-parent"
                              className="block px-4 py-2  text-sm text-[#023565] cursor-pointer hover:bg-gray-100"
                              onClick={() => handleInactive(item)}
                            >
                              Inactive Parent
                            </a> */}
                            <a

                              className="block px-4 py-2  text-sm text-[#023565] cursor-pointer hover:bg-gray-100"
                              onClick={() => handleViewHistory(item)}
                            >
                              View History
                            </a>
                          </div>
                        </div>
                      )}</p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      <AddEditStudentModel
        isView={isOpenAddEditModel}
        data={addEditData}
        handleChange={handleAddEditDataChange}
        handleCloseModal={handleCloseAddEditModal}
        handleSubmitModal={handleSubmitAddEditModal}
      />
    </section>
  );
};
