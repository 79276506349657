import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import Loader from '../../components/Loader';
import { FaLongArrowAltRight } from 'react-icons/fa';

const ButtonFour = ({ newTicketList }) => {

  const selector = useSelector(state => state);
  const navigate = useNavigate()



  return (
    <div className=''>
      <Loader loading={selector?.Dashboard?.loading} />
      <section className='lg:block hidden'>
        <div className='flex justify-between items-center p-2 bg-[#033565] rounded-t-lg'>
          <h2 className='text-base font-semibold text-white'>Latest Issues</h2>
          <button className="text-end font-semibold bg-white rounded px-4 py-2 text-blue-900 cursor-pointer hover:scale-95 transition-all duration-300 ease-in-out flex items-center gap-2" onClick={() => navigate("/app/help&support")}>View All <span><FaLongArrowAltRight /></span></button>
        </div>

        <table class="w-full text-left rtl:text-right bg-white text-[#313131] rounded-b-lg">
          <thead class=" text-[#313131] font-semibold border-b border-[#D1D1D1]">
            <tr className="border-b">
              <th className="px-6 py-4 whitespace-nowrap">
                User
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                City
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Subject
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Status
              </th>
            </tr>
          </thead>
          <tbody className='rounded-lg'>
            {
              newTicketList && newTicketList.length > 0 ? (newTicketList?.map((item, id) => (
                <tr className="bg-white capitalize" key={id}>
                  <td className="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                    {item?.userId?.name ?? 'No Data'} <br></br> {item?.userId?.mobNo ?? 'No Data'}
                  </td>
                  <td className="px-6 py-3 text-[12px]">
                    {item?.cityId?.name ?? 'No Data'}
                  </td>
                  <td className="px-6 py-3 text-[12px]">
                    {item?.title ?? 'No Title'}
                  </td>
                  <td className="px-6 py-3 text-[12px]">
                    {item.status === 0 ? "Pending" : item.status === 1 ? "Resolved" : item.status === 2 ? "Rejected" : "-"}
                  </td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={5} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </section>

      <section className="space-y-5 py-10 lg:hidden block">

        <div className='flex justify-between items-center p-2 bg-[#033565] rounded-t-lg'>
          <h2 className='text-base font-semibold text-white'>Latest Issues</h2>
          <button className="text-end font-semibold bg-white rounded px-4 py-2 text-blue-900 cursor-pointer hover:scale-95 transition-all duration-300 ease-in-out flex items-center gap-2" onClick={() => navigate("/app/help&support")}>View All <span><FaLongArrowAltRight /></span></button>
        </div>

        {newTicketList && newTicketList.length > 0 ? (
          newTicketList?.map((item) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>	User </p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.userId?.name ?? 'No Data'}</p>
                    <p className='text-[12px]'>{item?.userId?.mobNo ?? 'No Data'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>	City</p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.cityId?.name ?? 'No Data'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Subject
                    </p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.title ?? 'No Title'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Status
                    </p>
                  </div>
                  <div className='w-full'>
                    <p>{item.status === 0 ? "Pending" : item.status === 1 ? "Resolved" : item.status === 2 ? "Rejected" : "-"}</p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Available</p>
        )

        }

      </section>


    </div>
  )
}

export default ButtonFour
