import { referralConstants, classConstants } from '../_constants'
import { APIcallFunction, headerForPrivateAPI } from "../_helpers"
import { alertActions } from './alert.actions';
export const referralAction = {
    referralGet,
    referralDelete,
    createReferral,
    updateReferral,
    EnaDisReferral
}

function referralGet(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "referral/getReferralList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: referralConstants.GET_REFERRAL_LIST_REQUEST } }
    function success(users) { return { type: referralConstants.GET_REFERRAL_LIST_SUCCESS, users } }
    function failure(error) { return { type: referralConstants.GET_REFERRAL_LIST_FAILURE, error } }


};

function referralDelete(data, ReferralList) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "referral/delete"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(referralGet(ReferralList))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: referralConstants.DELETE_REFERRAL_LIST_REQUEST } }
    function success(users) { return { type: referralConstants.DELETE_REFERRAL_LIST_SUCCESS, users } }
    function failure(error) { return { type: referralConstants.DELETE_REFERRAL_LIST_FAILURE, error } }


};

function createReferral(data, ReferralList) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "referral/create"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(alertActions.success("Referral created"));
                dispatch(success(users))
                dispatch(referralGet(ReferralList))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: referralConstants.CREATE_REFERRAL_LIST_REQUEST } }
    function success(users) { return { type: referralConstants.CREATE_REFERRAL_LIST_SUCCESS, users } }
    function failure(error) { return { type: referralConstants.CREATE_REFERRAL_LIST_FAILURE, error } }


};

function updateReferral(data, referralDetails) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "referral/update"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(referralGet(referralDetails))
                dispatch(alertActions.success("Referral Updated"))

            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: referralConstants.UPDATE_REFERRAL_LIST_REQUEST } }
    function success(users) { return { type: referralConstants.UPDATE_REFERRAL_LIST_SUCCESS, users } }
    function failure(error) { return { type: referralConstants.UPDATE_REFERRAL_LIST_FAILURE, error } }


};


function EnaDisReferral(data, referralDetails) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "referral/enableDisable"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(alertActions.success("Action..."))
                dispatch(success(users))
                dispatch(referralGet(referralDetails))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: referralConstants.ENA_DIS_REFERRAL_LIST_REQUEST } }
    function success(users) { return { type: referralConstants.ENA_DIS_REFERRAL_LIST_SUCCESS, users } }
    function failure(error) { return { type: referralConstants.ENA_DIS_REFERRAL_LIST_FAILURE, error } }


};