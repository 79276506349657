import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userListAction, walletAction } from "../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./Wallet.css";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../../components/Loader";
import { FaFilterCircleXmark } from "react-icons/fa6";
import Select from "react-select";

const RedemptionData = ({
  searchText,
  headerCity,
  userOptions,
  customStyles,
  handleReset,
}) => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [walletData, setWalletData] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [openItemId, setOpenItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const pageSize = 10;
  const [userId, setUserId] = useState({ label: "Select User", value: "" });

  useEffect(() => {
    let walletDetails = {
      userId: "",
      type: "REDEEM",
      keyWord: searchText,
      cityId: headerCity !== "" ? [headerCity] : null,
      userId: userId.value != "" ? [userId.value] : null,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(walletAction.getWalletHistoryList(walletDetails));
  }, [currentPage, searchText, headerCity, userId]);
  useEffect(() => {
    setWalletData(() => selector?.Wallet?.walletHistoryList?.data?.list);
  }, [selector]);

  useEffect(() => {
    if (selector?.Wallet?.walletList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.Wallet?.walletHistoryList?.data?.total / pageSize)
      );
    }
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleView = (data) => {
    setView((prevdata) => (prevdata === data._id ? null : data));
    setSelectedItem(data);
  };

  const openToggle = (itemId) => {
    setOpenItemId((prevItemId) => (prevItemId === itemId ? null : itemId));
  };

  const handleMarkPaid = (id, status) => {
    let walletDetails = {
      userId: "",
      type: "REDEEM",
      keyWord: searchText,
      cityId: headerCity !== "" ? [headerCity] : null,
      userId: userId.value != "" ? [userId.value] : null,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
    };

    let statusUpdate = {
      id: id.id,
      status: id.status,
    };

    confirmAlert({
      title: "Confirm to Mark Paid?",
      message: `Are you sure to Mark Paid ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              walletAction.updateWalletStatus(statusUpdate, walletDetails)
            ),
        },
        {
          label: "No",
        },
      ],
    });

    setOpenItemId(false);
  };

  const handlePayCustom = (id, status) => {
    let walletDetails = {
      userId: "",
      type: "REDEEM",
      keyWord: searchText,
      cityId: headerCity !== "" ? [headerCity] : null,
      userId: userId.value != "" ? [userId.value] : null,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
    };
    let payStatus = {
      id: id.id,
      status: id.status,
    };
    confirmAlert({
      title: "Confirm to Pay Custom?",
      message: `Are you sure to Pay Custom ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(walletAction.updateWalletStatus(payStatus, walletDetails)),
        },
        {
          label: "No",
        },
      ],
    });
    setOpenItemId(false);
  };

  const handleRejectRequest = (id) => {
    let walletDetails = {
      userId: "",
      type: "REDEEM",
      keyWord: searchText,
      cityId: headerCity !== "" ? [headerCity] : null,
      userId: userId.value != "" ? [userId.value] : null,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
    };
    let rejectStatus = {
      id: id.id,
      status: id?.status,
    };
    confirmAlert({
      title: "Confirm to Reject ?",
      message: `Are you sure to Reject ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              walletAction.updateWalletStatus(rejectStatus, walletDetails)
            ),
        },
        {
          label: "No",
        },
      ],
    });
    setOpenItemId(false);
  };

  const handleOnChange = (e) => {
    let userType = e.target.value;
    let walletDetails = {
      userId: "",
      type: "REDEEM",
      keyWord: searchText,
      cityId: headerCity !== "" ? [headerCity] : null,
      userId: userId.value != "" ? [userId.value] : null,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      userType: userType,
      pageNo: currentPage,
      size: pageSize,
    };
    setTimeout(() => {
      dispatch(walletAction.getWalletHistoryList(walletDetails));
    }, 500);
  };

  const handleResetFilters = () => {
    handleReset();
    setUserId({ label: "Select User", value: "" });
  };
  return (
    <>
      <Loader loading={selector?.Wallet?.loading} />
      <div className="flex md:justify-end justify-center items-center gap-5 mx-9 my-4 lg:-mt-9">
        <Select
          id="user"
          name="user"
          value={userId}
          options={[{ label: "Select User", value: "" }, ...userOptions]}
          className="capitalize text-black text-sm"
          styles={customStyles}
          classNamePrefix="react-select"
          placeholder="User Name"
          onChange={(selectedOption, actionMeta) => {
            setUserId(selectedOption);
          }}
        />
        <select
          onChange={handleOnChange}
          className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg block lg:w-44  p-2.5"
        >
          <option value="">Select Profile</option>
          <option value="PARENT">Parent</option>
          <option value="TUTOR">Tutor</option>
        </select>
        <button className="text-center font-bold">
          <FaFilterCircleXmark
            size={24}
            className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer"
            onClick={() => {
              handleResetFilters();
            }}
          />
        </button>
      </div>

      <section className="lg:block hidden">
        <div className=" bg-white border border-[#D3D3D3] rounded-xl  overflow-x-auto">
          <table className="min-w-full  table-auto  ">
            <thead className="table-auto">
              <tr className="border-b capitalize">
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">User Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">City</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Total Rewards</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Requested Rewards Added</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Status</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Redemption Request</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Action</th>
              </tr>
            </thead>

            <tbody className=" divide-y divide-gray-200">
              {walletData && walletData.length > 0 ? (
                walletData?.map((item, id) => (
                  <tr className="text-[#313131]" key={id} style={{ textTransform: "capitalize" }}>
                    <td className="px-4 py-4 flex items-center gap-4 text-sm text-gray-900">
                      <div className="flex items-center justify-center gap-2">
                        <span>
                          {item?.userId && item?.userId?.image ?
                            <img
                              alt=""
                              src={
                                item?.userId && item?.userId?.image
                                  ? item?.userId?.image
                                  : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              onError={(e) => {
                                e.target.src =
                                  "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                              }}
                              className="rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                            />
                            : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                              <span className="text-base font-semibold text-[#033565]"> {item?.userId?.name ? (item.userId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                            </div>}
                        </span>
                        <div className="w-24">
                          <p className="font-semibold whitespace-nowrap text-[#023565]">
                            {item && item?.userId?.name ? item?.userId?.name : "No Data"}
                          </p>
                          <p className="text-gray-500 whitespace-nowrap">
                            {item && item?.userId?.mobNo ? item?.userId?.mobNo : null}
                          </p>
                          <p className={`text-[#313131] whitespace-nowrap ${item?.userId?.userType === "TUTOR" ? "text-red-600" : "text-sky-500"}`}>
                            {item?.userId?.userType === "TUTOR" ? "Tutor" : item?.userId?.userType === "PARENT" ? "Parent" : "--"}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item?.city?.name ? item?.city?.name : "N/A"}</td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item?.totalBalance}</td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item?.balance ?? "0"}</td>
                    <td className="px-6 py-4 text-sm text-gray-900 text-center font-semibold">
                      <p className={item?.status === "1" ? "text-green-600" : item?.status === "2" ? "text-green-600" : item?.status === "3" ? "text-red-600" : "text-[#313131]"}>
                        {item?.status && item?.status == "1" ? "PAID" : item?.status && item?.status == "2" ? "CUSTOM PAY" : item?.status && item?.status == "3" ? "REJECT" : "PENDING"}
                      </p>
                    </td>

                    <td className="px-6 py-4 text-sm text-center text-gray-900">
                      <div className="flex justify-center items-center text-[#023565]">
                        <button className="font-bold" onClick={() => handleView(item)}>View</button>
                      </div>
                    </td>

                    <td className="px-6 py-4 text-sm text-center text-gray-900 ">
                      <button className="font-bold">
                        <BsThreeDotsVertical
                          className="text-2xl text-[#C60E6B]"
                          onClick={() => openToggle(item._id)}
                        />
                      </button>
                      {openItemId && openItemId === item._id && (
                        <div className="fixed right-16 mt-1 rounded-xl bg-white w-40 border shadow-lg">
                          <ul className="text-start text-[#023565]">
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 hover:text-[#E4006F] border-b"
                              onClick={() => {
                                handleMarkPaid({ id: item._id, status: 1 });
                                setOpen(false);
                              }}
                            >
                              <span className=" font-bold">Mark Paid</span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 border-b hover:text-[#E4006F] text-[#023565]"
                              onClick={() => {
                                handlePayCustom({ id: item._id, status: 2 });
                                setOpen(false);
                              }}
                            >
                              <span className=" font-bold">Pay Custom</span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 hover:text-[#E4006F] text-[#023565]"
                              onClick={() => {
                                handleRejectRequest({
                                  id: item._id,
                                  status: 3,
                                });
                                setOpen(false);
                              }}
                            >
                              <span className=" font-bold">Reject Request</span>
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )}
              <hr />
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2 px-4">
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black  rounded">
                {currentPage}
              </span>{" "}
              of{" "}
              <span className="border px-4 py-2 text-black  rounded">
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : ""
                }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </section>

      {view && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[600px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setView(false)}
              >
                <span>
                  <MdOutlineClose />
                </span>{" "}
                Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Redemption Request </h2>
                </div>
                <div className="relative lg:h-[500px] overflow-y-auto">
                  <table className=" bg-white border mx-auto m-2">
                    <thead>
                      <tr className="border text-left">
                        <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">
                          Beneficiary Name
                        </th>
                        <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">
                          Account Number
                        </th>
                        <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">
                          IFSC Code
                        </th>

                        <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">
                          UPI ID
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border capitalize">
                        <td className="px-4 py-5   border whitespace-nowrap">
                          {selectedItem?.userId?.name}
                        </td>
                        <td className="px-4 py-2   border whitespace-nowrap">
                          {selectedItem && selectedItem?.accountNo
                            ? selectedItem?.accountNo
                            : "---"}
                        </td>
                        <td className="px-4 py-2   border whitespace-nowrap">
                          {" "}
                          {selectedItem && selectedItem?.ifscCode
                            ? selectedItem?.ifscCode
                            : "---"}
                        </td>

                        <td className="px-4 py-2   border whitespace-nowrap">
                          {" "}
                          {selectedItem && selectedItem.upi
                            ? selectedItem.upi
                            : "---"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <section className="space-y-5 py-10 lg:hidden block">
        {walletData && walletData.length > 0 ? (
          walletData?.map((item, id) => (
            <div key={id} className=" bg-white p-4 rounded-[10px]">
              <>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[16px] font-[700] text-left">
                      Parent Name
                    </p>
                  </div>
                  <div className="w-full">
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          item?.userId?.image ??
                          "https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg"
                        }
                        alt="User"
                      />
                      <div>
                        <p className="text-xs font-medium text-black ">
                          {item?.userId?.name}
                        </p>
                        <div className="text-xs text-gray-500">
                          {item?.userId?.mobNo}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[16px] font-[700] text-left">City</p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]">
                      {" "}
                      {item?.city?.name ? item?.city?.name : "No Data"}{" "}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[16px] font-[700] text-left">
                      Requested Rewards
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]">
                      {" "}
                      {item?.balance ?? "0"}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[16px] font-[700] text-left">Status</p>
                  </div>
                  <div className="w-full">
                    <p
                      className={
                        item?.status === "1"
                          ? "text-green-600 text-[14px] font-[400]"
                          : item?.status === "2"
                            ? "text-green-600 text-[14px] font-[400]"
                            : item?.status === "3"
                              ? "text-red-600 text-[14px] font-[400]"
                              : "text-[#313131] text-[14px] font-[400]"
                      }
                    >
                      {" "}
                      {item?.status && item?.status == "1"
                        ? "PAID"
                        : item?.status && item?.status == "2"
                          ? "CUSTOMER_PAID"
                          : item?.status && item?.status == "3"
                            ? "REJECT"
                            : "PENDING"}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[16px] font-[700] text-left">
                      Redemption Request
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400]">
                      <button
                        className="font-bold underline"
                        onClick={() => handleView(item)}
                      >
                        View
                      </button>
                    </p>
                  </div>
                </div>
                <div className="flex justify-between p-2 w-full">
                  <div className="w-full">
                    <p className="text-[16px] font-[700] text-left">Action</p>
                  </div>
                  <div className="w-full">
                    <p className="text-[14px] font-[400] relative">
                      <BsThreeDotsVertical
                        className="text-2xl text-[#C60E6B]"
                        onClick={() => openToggle(item._id)}
                      />
                      {openItemId && openItemId === item._id && (
                        <div className="absolute  mt-2 rounded-xl bg-white w-40 border shadow-lg">
                          <ul className="text-start">
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 border-b"
                              onClick={() => {
                                handleMarkPaid({ id: item._id, status: 1 });
                                setOpen(false);
                              }}
                            >
                              <span className="text-[#C60E6B] font-bold">
                                Mark Paid
                              </span>
                              <img
                                src="/Group 1000001172.png"
                                alt="Mark Paid Icon"
                              />
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 border-b text-[#023565]"
                              onClick={() => {
                                handlePayCustom({ id: item._id, status: 2 });
                                setOpen(false);
                              }}
                            >
                              <span className="text-[#023565] font-bold">
                                Pay Custom
                              </span>
                              <img
                                src="/Group 1000001230.png"
                                alt="Pay Custom Icon"
                              />
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 text-[#023565]"
                              onClick={() => {
                                handleRejectRequest({
                                  id: item._id,
                                  status: 3,
                                });
                                setOpen(false);
                              }}
                            >
                              <span className="text-blue-900 font-bold">
                                Reject Request
                              </span>
                              <img
                                src="/Group 1000001230.png"
                                alt="Reject Request Icon"
                              />
                            </li>
                          </ul>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </>
            </div>
          ))
        ) : (
          <p className="text-center">Data Not Found</p>
        )}
      </section>
    </>
  );
};

export default RedemptionData;
