import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import ParentComponente from "./ParentComponente";
import StudentsComponent from "./StudentsComponent";
import NotificationHeader from "./NotificationHeader";
import { notificationAction } from "../../_actions";
import { useLocation, useNavigate } from "react-router";
import { IoMdAdd } from "react-icons/io";
import { IoAddOutline } from "react-icons/io5";
import { cityAction } from "../../_actions/city.action";
import DateRangePicker from "../../components/Atoms/DateRangePicker";
import { FaFilterCircleXmark } from "react-icons/fa6";

function Notification() {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch()
  const [active, setActive] = useState("Parent");
  const NotificationBtnName = ["Parent", "Tutor"];
  const navigator = useNavigate()
  const handleClick = (data) => {
    setActive(data);
    setSearchText("")
    navigator(`?tab=${data}`)
  };

  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab');
    if (activeTab) {
      setActive(activeTab);
      navigator(`?tab=${activeTab}`)
    } else {
      setActive("Parent");
      navigator(`?tab=${"Parent"}`)
    }
  }, []);

  useEffect(() => {
    dispatch(cityAction.cityGet({ isManagement: true }))

  }, [])

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [searchText, setSearchText] = useState('')
  const [dates, setDates] = useState({ fromDate: '', toDate: '' });
  const [headerCity, setHeaderCity] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")

  const handleChange = (value, dateType) => {
    setDates((prevDates) => ({ ...prevDates, [dateType]: value }));
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  const handleCityOnSelect = (e) => {
    e.preventDefault()
    setHeaderCity(e.target.value)

  };
  const [options, setOptions] = useState();
  const [modal, setModal] = useState(false);
  const selector = useSelector(state => state);


  const handleInnerClick = () => {
    setModal(true);
  };

  const handleCitySearch = (e) => {
    setHeaderCity(e.target.value)
  }


  return (
    <>
      <NotificationHeader handleSearch={handleSearch} searchText={searchText} headerCity={headerCity} handleCitySearch={handleCitySearch} roleId={roleId} />
      <section className="overflow-hidden overflow-y-auto h-screen">
        <div className="m-5">
          <section className="flex gap-3">
            {NotificationBtnName.map((item, index) => (
              <div key={index} className={`text-white px-10 py-2 rounded-full font-semibold ${active === item ? "bg-[#023565]" : "bg-gray-500"}`} onClick={() => handleClick(item)}>
                <Button name={item} />
              </div>
            ))}
          </section>
          <>
            {active === "Parent" &&
              <div className="flex items-center flex-wrap md:justify-end md:space-x-3 space-x-0 lg:py-0 pt-6 lg:-mt-10 md:-mt-0">
                <div className="flex items-center gap-3">
                  <DateRangePicker fromDate={dates.fromDate} toDate={dates.toDate} handleChange={handleChange} />
                  <button className="text-center font-bold pt-5">
                    <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" onClick={() => {
                      setDates({ fromDate: '', toDate: '' }); setHeaderCity(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")
                    }} />
                  </button>
                </div>

                <section className="flex w-40 mt-5 items-center font-bold text-[#C60E6B] border bg-white rounded-lg px-2 py-2" onClick={handleInnerClick}>
                  <span>
                    <IoAddOutline className="text-xl mx-1 text-[#C60E6B]" />
                  </span>
                  <Button name="Create New" />
                </section>
              </div>
            }
          </>

          {active === "Parent" && <ParentComponente modal={modal} setModal={setModal} searchText={searchText} headerCity={headerCity} dates={dates} />}
          {active === "Tutor" && <StudentsComponent searchText={searchText} headerCity={headerCity} />}
        </div>
      </section>
    </>
  );
}

export default Notification;
