import { assignmentConstant } from "../_constants";
export default function assignment(state = {}, action) {
    switch (action.type) {
        case assignmentConstant.GET_ASSIGNMENT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case assignmentConstant.GET_ASSIGNMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                assignment: action?.user


            };
        case assignmentConstant.GET_ASSIGNMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case assignmentConstant.CREATE_ASSIGNMENT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case assignmentConstant.CREATE_ASSIGNMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case assignmentConstant.CREATE_ASSIGNMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case assignmentConstant.DELETE_ASSIGNMENT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case assignmentConstant.DELETE_ASSIGNMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,



            };
        case assignmentConstant.DELETE_ASSIGNMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case assignmentConstant.UPDATE_ASSIGNMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case assignmentConstant.UPDATE_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                loading: false,



            };
        case assignmentConstant.UPDATE_ASSIGNMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }




        case assignmentConstant.GET_ALL_TUITION_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case assignmentConstant.GET_ALL_TUITION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getAssignmentTuitionList: action?.user


            };
        case assignmentConstant.GET_ALL_TUITION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }






        case assignmentConstant.GET_ASSIGNMENT_CITY_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case assignmentConstant.GET_ASSIGNMENT_CITY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getAssignmentCityList: action?.user


            };
        case assignmentConstant.GET_ASSIGNMENT_CITY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }


        case assignmentConstant.
            GET_ASSIGNMENT_CLASS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case assignmentConstant.
            GET_ASSIGNMENT_CLASS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getAssignmentClassList: action?.user


            };
        case assignmentConstant.
            GET_ASSIGNMENT_CLASS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }








        default:
            return state;
    }


}
