import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { walletAction } from "../../_actions";
import moment from "moment/moment";
import './Wallet.css'
import Loader from "../../components/Loader";
import { MdOutlineClose } from "react-icons/md";


const ParentStudentData = ({ searchText }) => {

  const dispatch = useDispatch();
  const selector = useSelector(state => state)
  let walletData = selector?.Wallet?.walletList?.data?.list;
  let getWallrtHistory = selector?.Wallet?.walletHistoryList?.data?.list;

  const [updateModal, setUpdateModal] = useState(false)
  const [updateVal, setUpdateVal] = useState('')


  const [addBonusModal, setAddBonusModal] = useState(false)
  const [historyModal, setHistoryModal] = useState(false)
  const [userId, setUserId] = useState('')
  const [bonus, setBonus] = useState('')

  useEffect(() => {
    let walletDetails = {
      "userType": "PARENT",
      "isManagement": false,
      "keyWord": searchText ?? "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "",
      "pageNo": 1,
      "size": 10
    }
    dispatch(walletAction.getWalletList(walletDetails))
  }, [])

  const handleAddBonus = (data) => {
    setAddBonusModal(true)
    setUserId(data.userId?._id)
  }

  const handleBonusUpdateForm = () => {

    let updateBonus = {
      userId: userId,
      bonus: bonus
    }
    let walletDetails = {
      "userType": "PARENT",
      "isManagement": false,
      "keyWord": searchText ?? "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "",
      "pageNo": 1,
      "size": 10
    }

    dispatch(walletAction.addBonusUserWallet(updateBonus, walletDetails))
    setBonus('')
    setBonus('')
    setAddBonusModal(false)
  }

  const handleViewHistory = (data) => {
    setHistoryModal(true)

    let walletHistoryDetails = {
      userId: data?.userId?._id,
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    }
    dispatch(walletAction.getWalletHistoryList(walletHistoryDetails))
  }




  const handleUpdateWallet = (data) => {
    setUpdateModal(true)
    setUpdateVal(data)
  }

  const handleonChnage = (e) => {
    const { id, value } = e.target;
    setUpdateVal((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleUpdateform = () => {
    let walletDetails = {
      userType: "TUTOR",
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };

    let updatedDetails = {
      "id": updateVal._id,
      "balance": updateVal.balance,
      "totalBalance": updateVal.totalBalance,
      "rewards": updateVal.rewards,
      "bonus": updateVal.bonus
    }
    dispatch(walletAction.updateWallet(updatedDetails, walletDetails))
    setUpdateModal(false)
  };


  return (
    <>
      <section className="py-10  lg:block hidden">
        <Loader loading={selector?.Wallet?.loading} />

        <div className=" bg-white border border-[#D3D3D3] rounded-xl  overflow-x-auto">
          <table className="min-w-full  table-auto  ">
            <thead className="">
              <tr className="border-b capitalize">
                <th className="px-6 py-4  text-left text-[#313131] table_head  whitespace-nowrap">
                  Parent Name
                </th>
                <th className="px-6 py-3  text-center text-[#313131] table_head  whitespace-nowrap">
                  City
                </th>
                <th className="px-6 py-3 ttext-center text-[#313131] table_head  whitespace-nowrap">
                  Rewards Earned By  Referral
                </th>
                <th className="px-6 py-3  text-center text-[#313131] table_head  whitespace-nowrap">
                  Rewards Added By Company
                </th>
                <th className="px-6 py-3 text-center text-[#313131] table_head  whitespace-nowrap">
                  Total Rewards
                </th>
                <th className="px-6 py-3text-center  text-[#313131] table_head  whitespace-nowrap">
                  Reward History
                </th>
                <th className="px-6 py-3 text-center text-[#313131] table_head whitespace-nowrap">
                  Update Wallet
                </th>
              </tr>
            </thead>
            <tbody className=" divide-y divide-gray-200">
              {
                walletData && walletData?.map((item, id) => (
                  <tr className="capitalize" key={id}>
                    <td className="flex items-center gap-2 px-6 py-4 whitespace-nowrap text-[#313131]  forfontsmall ">
                      <img
                        className="w-10 h-10 rounded-full"
                        src={item?.userId?.image ?? 'https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg'}
                        alt="Parent"
                      />
                      <div>
                        <div className="font-medium  text-[#313131]  forfontsmall ">{item?.userId?.name}</div>
                        <div className=" text-[#313131]  forfontsmall ">{item?.userId?.mobNo}</div>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center text-[#313131]  forfontsmall  whitespace-nowrap">
                      {item?.citt ? item?.city === null : 'No Data'}
                    </td>
                    <td className="px-6 py-4 text-center text-[#313131]  forfontsmall  whitespace-nowrap">
                      {item?.rewards}
                    </td>
                    <td className="px-6 py-4  text-[#313131]  forfontsmall  text-center whitespace-nowrap">
                      <p>{item?.bonus}</p>
                      <button className="font-bold underline" onClick={() => handleAddBonus(item)}>Add Bonus</button>
                    </td>
                    <td className="px-6 py-4 text-center  text-[#313131]  forfontsmall  whitespace-nowrap">
                      {item?.totalBalance}
                    </td>
                    <td className="px-6 py-4 text-center  text-[#313131]  forfontsmall whitespace-nowrap">
                      <div className="flex underline justify-center">

                        <button className="font-bold" onClick={() => handleViewHistory(item)}>View </button>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center  text-gray-900  whitespace-nowrap">
                      <button className="font-bold underline">Update</button>
                    </td>
                  </tr>
                ))
              }
              <hr />
            </tbody>
          </table>

        </div>
      </section>

      {
        addBonusModal && (
          <>
            {/* <div>
                  <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50">
                    <div className="bg-white p-5 rounded-md shadow-lg">
                      <div className="text-center mb-4">
                        <h2 className="text-lg font-semibold">Add Bonus</h2>
                      </div>
                      <div>
                        <div className="board_name">
                          <label
                            htmlFor="boardName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Add Bonus:
                          </label>
                          <input
                            type="text"
                            id="boardName"
                            value={bonus}
                            onChange={(e) => setBonus(e.target.value)}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          />
                        </div>


                        <div className="mt-4 flex justify-between">
                          <button
                            className="bg-[#023565] text-white px-4 py-2 rounded-md"
                            onClick={handleBonusUpdateForm}
                          >
                            Add
                          </button>
                          <button
                            className="bg-red-600 text-white px-4 py-2 rounded-md"
                            onClick={() => setAddBonusModal(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>


                </div> */}


            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setAddBonusModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Add Bonu </h2>
                  </div>
                  <div className="relative overflow-y-auto">
                    <form className="space-y-2 p-4">
                      <div className="board_name">
                        <label
                          htmlFor="boardName"
                          className="block text-[#023565] table_head "
                        >
                          Add Bonus:
                        </label>
                        <input
                          type="text"
                          id="boardName"
                          value={bonus}
                          onChange={(e) => setBonus(e.target.value)}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="flex justify-center items-center pt-2">
                        <button
                          onClick={handleBonusUpdateForm}
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </>
        )
      }
      {
        historyModal && (
          <>
            {/* <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
                  <div className="relative w-full bg-white rounded-lg shadow-lg max-w-4xl mx-auto my-8">
                    <div className="flex justify-end p-4 border-b border-gray-200">
                      <span
                        className="bg-red-500 text-white p-2 cursor-pointer rounded"
                        onClick={() => setHistoryModal(false)}
                      >
                        Close
                      </span>
                    </div>

                    <div className="p-4 overflow-x-auto">
                      <table className="min-w-full bg-white">
                        <thead>
                          <tr className="border-b">
                            <th className="px-4 py-2">Name</th>
                            <th className="px-4 py-2">Mobile Number</th>
                            <th className="px-4 py-2">Email</th>

                            <th className="px-4 py-2">Redm Point</th>

                            <th className="px-4 py-2">Date</th>
                            <th className="px-4 py-2"> Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getWallrtHistory && getWallrtHistory?.map((item, id) => (
                            <tr key={id} className="border-b ">
                              <td className="px-4 py-5 text-center">{item?.userId?.name}</td>
                              <td className="px-4 py-2 text-center">{item?.userId?.mobNo}</td>
                              <td className="px-4 py-2 text-center">{item?.userId?.email}</td>

                              <td className="px-4 py-2 text-center">{item?.balance}</td>

                              <td className="px-4 py-2 text-center">{moment(item?.createdAt).format('YYYY-MM-DD / HH:mm:ss')}</td>
                              <td className="px-4 py-2 text-center text-green-600">{item?.type}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}

            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[950px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setHistoryModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Reward History </h2>
                  </div>
                  <div className="relative lg:h-[500px] overflow-y-auto">
                    <table className=" bg-white border mx-auto m-2">
                      <thead>
                        <tr className="border text-left">
                          <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">Name</th>
                          <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">Mobile Number</th>
                          <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">Email</th>

                          <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">Redm Point</th>

                          <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap">Date</th>
                          <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap"> Type</th>
                          <th className="px-4 py-2 text-[#023565] table_head border whitespace-nowrap"> Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getWallrtHistory && getWallrtHistory?.map((item, id) => (
                          <tr key={id} className="border capitalize">
                            <td className="px-4 py-5  forfontsmall border whitespace-nowrap">{item?.userId?.name}</td>
                            <td className="px-4 py-2  forfontsmall border whitespace-nowrap">{item?.userId?.mobNo}</td>
                            <td className="px-4 py-2  forfontsmall border whitespace-nowrap">{item?.userId?.email}</td>

                            <td className="px-4 py-2  forfontsmall border whitespace-nowrap">{item?.balance}</td>

                            <td className="px-4 py-2  forfontsmall border whitespace-nowrap">{moment(item?.createdAt).format('YYYY-MM-DD')}</td>
                            <td className="px-4 py-2  text-green-600 forfontsmall border whitespace-nowrap">{item?.type}</td>
                            {item?.status == 1 ? <td className="px-4 py-2  text-green-600 forfontsmall border whitespace-nowrap">PAID</td>
                              : item?.status == 2 ?
                                <td className="px-4 py-2  text-blue-600 forfontsmall border whitespace-nowrap">PAID CUSTOM</td>
                                : item?.status == 3 ?
                                  <td className="px-4 py-2  text-red-600 forfontsmall border whitespace-nowrap">REJECT</td>
                                  :
                                  <td className="px-4 py-2  text-yellow-600 forfontsmall border whitespace-nowrap">PENDING</td>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </>
        )
      }


      {
        updateModal && (
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[600px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setUpdateModal(false)}
              >
                <span><MdOutlineClose /></span> Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Update </h2>
                </div>
                <div className="relative overflow-y-auto">
                  <form className="space-y-2 p-4">
                    <div className="board_name">
                      <label htmlFor="bonus" className="block text-sm font-medium text-gray-700">
                        Bonus:
                      </label>
                      <input
                        type="text"
                        id="bonus"
                        value={updateVal?.bonus}
                        onChange={handleonChnage}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="board_name">
                      <label
                        htmlFor="balance"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Balance:
                      </label>
                      <input
                        type="text"
                        id="balance"
                        value={updateVal.balance}
                        onChange={handleonChnage}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="board_name">
                      <label
                        htmlFor="rewards"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Rewards:
                      </label>
                      <input
                        type="text"
                        id="rewards"
                        value={updateVal?.rewards}
                        onChange={handleonChnage}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="board_name">
                      <label
                        htmlFor="totalBalance"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Total Balance:
                      </label>
                      <input
                        type="text"
                        id="totalBalance"
                        value={updateVal.totalBalance}
                        onChange={handleonChnage}
                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="flex justify-center items-center pt-2">
                      <button
                        onClick={handleUpdateform}
                        className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        )
      }




      <section className="space-y-5 py-10 lg:hidden block">
        {
          walletData && walletData.length > 0 ? (walletData?.map((item, id) => (
            <div key={id} className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Parent Name</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={item?.userId?.image ?? 'https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg'}
                        alt="User"
                      />
                      <div>
                        <p className="text-xs font-medium text-black ">{item?.userId?.name}</p>
                        <div className="text-xs text-gray-500">{item?.userId?.mobNo}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'> {item?.city?.name ? item?.city?.name : 'No Data'} </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Rewards Earned By Referral</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>  {item?.rewards}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Rewards Added By Company</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.bonus}</p>
                    <p className='text-[14px] font-[400]'> <button
                      className="font-bold underline"
                      onClick={() => handleAddBonus(item)}
                    >
                      Add Bonus
                    </button></p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Total Rewards</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.totalBalance}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Reward History	</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'><button
                      className="font-bold "
                      onClick={() => handleViewHistory(item)}
                    >
                      View
                    </button> </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Update Wallet</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'><button className="font-bold underline" onClick={() => handleUpdateWallet(item)}>Update</button>  </p>
                  </div>
                </div>
              </>
            </div>

          ))

          ) : (
            <p className="text-center">Data Not Found</p>
          )

        }
      </section>
    </>
  )
}

export default ParentStudentData