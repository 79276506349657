import React from 'react';

const DateRangePicker = ({ fromDate, toDate, handleChange }) => {

  return (
    <>
      <section className='flex justify-center items-center space-x-1'>
        <div className="md:w-56 w-full overflow-hidden">
          <label htmlFor="formDate">From Date</label>
          <input
            type="date"
            id="formDate"
            value={fromDate}
            onChange={(e) => handleChange(e.target.value, 'fromDate')}
            className="appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline uppercase"
            max={toDate || undefined}
          />
        </div>
        <div className="md:w-56 w-full overflow-hidden">
          <label htmlFor="toDate">To Date</label>
          <input
            type="date"
            value={toDate}
            onChange={(e) => handleChange(e.target.value, 'toDate')}
            className="appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline uppercase"
            min={fromDate || undefined}
          />
        </div>
      </section>
    </>
  );
};

export default DateRangePicker;
