import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronCircleRight, FaEye } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { parentAction, userActions } from "../../_actions";
import { cityAction } from "../../_actions/city.action";
import { pincodeAction } from "../../_actions/pincode.action";
import { MdOutlineClose } from "react-icons/md";
import moment from "moment";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import ViewParent from "./Model/ViewParent";

export const ActiveParent = ({ searchText, filters }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  // let cityData = selector?.Citys?.cityGetList;
  let activeParentUserList = selector?.Parent?.parentuserList?.data?.demoList;
  let studentListdata = selector?.Parent?.studentListByid?.data;
  let feePaymentListdata = selector?.Parent?.getFeeHistoryById?.data;
  let onGoingTutuionListById = selector?.userList?.getOnGoingTutionList?.data;


  const [cityData, setCityData] = useState([]);
  const [pincodeData, setPincodeData] = useState([]);
  const [onGointTutionList, setOnGoingTutionList] = useState(false);
  const [blockedPoup, setBlockpouUp] = useState(false);
  const [blockedData, setNewblockedData] = useState({});
  const [name, setName] = useState("");
  const [inActivePoup, setInactivePopup] = useState(false);
  const [inactiveResion, setInactiveResion] = useState("");
  const [inactivedata, setInactiveData] = useState({});
  const [fullDetails, setFullDetails] = useState(false);
  const [DetailsData, setFullDetailsData] = useState();
  const [actionItem, setActionItem] = useState(null);
  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [feepaymentModal, setFeePaymentModal] = useState(false);
  const [parentUpdateModal, setParentUpdateModal] = useState(false);
  const [parentUpdateData, setParentUpdateData] = useState({
    mobNo: "",
    secondaryNo: "",
    name: "",
    whatsappNo: "",
    address: "",
    updatedCity: "",
    updatedPinCode: "",
  });
  const [updateId, setUpdateId] = useState("");
  const [updatedCity, setUpdatedCity] = useState([]);

  const [errors, setErrors] = useState({})
  const menuRef = useRef(null)

  useEffect(() => {
    let parentUserList = {
      status: "ACTIVE",
      userType: "PARENT",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
      ...filters
    };

    let cityDetails = {
      // toDate: "",
      // sortOrder: "asc",
      // sortBy: "createdAt",
      // pageNo: 1,
      // size: 100,
      isManagement: false
    };
    let pincodeData = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
    };
    dispatch(parentAction.getUserList(parentUserList));
    dispatch(cityAction.cityGet(cityDetails));
    // dispatch(pincodeAction.picodeGet(pincodeData));
    // dispatch(pincodeAction.picodeGet(parentUserList))
  }, [filters]);

  useEffect(() => {
    setCityData(() => selector?.Citys?.cityGetList);
  }, [selector]);


  const handleFullDetails = (data) => {
    userActions.getUserByPromiseId({ id: data._id }).then((res) => {
      setFullDetails(true);
      setFullDetailsData(res?.data);
    })
  };


  const viewStudent = (data) => {
    setViewStudentModal(true);
    let studentListDetails = {
      // id: data._id,
      parentId: data._id,
    };
    dispatch(parentAction.getStudentListByParentId(studentListDetails));
  };

  const handleOnGoingTution = (data) => {
    let parentData = {
      // tutorId: data._id,
      parentId: data._id,
    };
    setOnGoingTutionList(true);
    dispatch(parentAction.getOnGoingTutionList(parentData));
  };

  const handleFeeHistory = (data) => {
    setFeePaymentModal(true);
    let feeDetails = {
      userId: data._id,
    };
    dispatch(parentAction.getFeeHistoryByUserId(feeDetails));
  };

  const handleAction = (id) => {
    setActionItem(actionItem === id ? null : id);
  };

  const handleBlockParent = (data) => {
    setBlockpouUp(true);

    let blockedData = {
      status: "BLOCK",
      id: data._id,
    };

    setNewblockedData(blockedData);
  };

  const handleSubmit = () => {
    let resData = {
      status: "ACTIVE",
      userType: "PARENT",
      isManagement: false,
      // 'TUTOR':'PARENT',
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
      ...filters

    };
    let blockednEWData = {
      ...blockedData,
      reason: name,
    };

    dispatch(parentAction.blockByAdmin(blockednEWData, resData));
    setBlockpouUp(false);
    setActionItem(null);
    setName("");
  };

  const handleInactive = (data) => {
    setInactivePopup(true);
    let inActiveData = {
      status: "INACTIVE",
      id: data._id,
    };
    setInactiveData(inActiveData);
  };

  const handleSubmitInactive = () => {
    let resData = {
      status: "ACTIVE",
      userType: "PARENT",
      isManagement: false,
      // 'TUTOR':'PARENT',
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
      ...filters

    };
    let inActiveNewData = {
      ...inactivedata,
      reason: inactiveResion,
    };

    dispatch(parentAction.blockByAdmin(inActiveNewData, resData));
    setInactivePopup(false);
    setInactiveResion("");
  };
  ///Update Parent ------------------------------->>>>>>>>>>>>>>>>>>>>>>>

  const handleParentUpdate = (data) => {
    setParentUpdateData(() => ({
      ...data,
      city: data?.city?.id || ""
    }));
    setUpdateId(data._id);
    setUpdatedCity(data?.city?.name);
    const pincode = cityData.find((item) => String(item._id) === String(data?.city?.id));

    setPincodeData(pincode?.pinCode || [])
    setParentUpdateModal(true);
    setErrors({})
  };

  const handleOnChangeUpdate = (e) => {
    const { name, value } = e.target;
    if (name == "city") {
      const pincode = cityData.find((item) => String(item._id) === String(value));
      setPincodeData(pincode?.pinCode || [])
      setParentUpdateData({
        ...parentUpdateData,
        [name]: value,
        ["pinCode"]: ""
      });

    } else if (name == "pinCode") {
      setParentUpdateData({
        ...parentUpdateData,
        [name]: [value],
      });
    } else {
      setParentUpdateData({
        ...parentUpdateData,
        [name]: value,
      });
    }

  };

  const validateForm = () => {
    let errorsData = {}

    // Validate mobile number
    if (!parentUpdateData.mobNo) {
      errorsData.mobNo = 'Mobile number is required';
    } else if (!/^[0-9]{10}$/.test(parentUpdateData.mobNo)) {
      errorsData.mobNo = 'Mobile number must be a valid 10-digit number';
    }

    // Validate name
    if (!parentUpdateData.name) {
      errorsData.name = 'Name is required';
    } else if (parentUpdateData.name.length < 2) {
      errorsData.name = 'Name must be at least 2 characters long';
    } else if (parentUpdateData.name.length > 50) {
      errorsData.name = 'Name must be at most 50 characters long';
    }



    // Validate address
    if (!parentUpdateData.address) {
      errorsData.address = 'Address is required';
    } else if (parentUpdateData.address.length < 5) {
      errorsData.address = 'Address must be at least 5 characters long';
    } else if (parentUpdateData.address.length > 100) {
      errorsData.address = 'Address must be at most 100 characters long';
    }

    // Validate city
    if (!parentUpdateData.city) {
      errorsData.city = 'City is required';
    }

    // Validate pincode
    if (!parentUpdateData.pinCode || parentUpdateData.pinCode.length === 0) {
      errorsData.pinCode = 'At least one pincode is required';
    } else if (!/^[0-9]{6}$/.test(parentUpdateData.pinCode[0])) {
      errorsData.pinCode = 'Pincode must be a valid 6-digit number';
    }

    setErrors(errorsData)

    return Object.keys(errorsData).length === 0;
  }

  const handleUpdateParent = () => {

    if (!validateForm()) {
      return
    }


    let dataUpdated = {
      id: updateId,
      mobNo: parentUpdateData.mobNo,
      // secondaryNo: "",
      name: parentUpdateData.name,
      whatsappNo: parentUpdateData.whatsappNo,
      address: parentUpdateData.address,
      city: parentUpdateData.city,
      pinCode: parentUpdateData.pinCode,
    };

    let parentUserList = {
      status: "ACTIVE",
      userType: "PARENT",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
      ...filters

    };

    dispatch(parentAction.updateParentByAdmin(dataUpdated, parentUserList));
    setParentUpdateModal(false);
  };

  const handleViewHistory = (data) => {
    navigate(`/app/parentviewhistory/${data?._id}`)
  }

  const handleClickOutside = (event) => {


    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActionItem(null)
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Loader loading={selector?.userList?.loading} />
      <section className="lg:block hidden py-10">
        <div className=" border border-[#D3D3D3] rounded-xl overflow-x-auto">
          <table className="min-w-full bg-white rounded-xl">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-[#313131]">
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Parent Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Student Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  City
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Ongoing Tuitions
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Fee Payment History
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {activeParentUserList && activeParentUserList.length ?
                activeParentUserList?.map((item, id) => (
                  <tr key={id} className="border-b border-[#D1D1D1] capitalize">
                    <td className="flex items-center gap-4 px-6 py-4 text-sm text-gray-900 ">
                      <div className="flex items-center justify-center gap-2">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={
                            item?.image ??
                            "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          alt="User"
                        />
                        <div className="w-24">
                          <p className="font-medium whitespace-nowrap">{item?.name ?? "No Data"}</p>
                          <p className="text-gray-500 whitespace-nowrap">{item?.mobNo ?? "No Contact"}</p>
                        </div>
                      </div>
                      <FaChevronCircleRight
                        className="text-[#023565] text-lg cursor-pointer"
                        onClick={() => handleFullDetails(item)}
                      />
                    </td>



                    <td className="px-6 py-4   text-gray-900">
                      <button
                        className="underline font-bold hover:text-[#CD0C5C]"
                        onClick={() => viewStudent(item)}
                      >
                        <FaEye size={24} />

                      </button>
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      {item?.city?.name ?? "No City"}
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      <button
                        className="underline font-bold"
                        onClick={() => handleOnGoingTution(item)}
                      >
                        View
                      </button>
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      <button
                        className="underline font-bold"
                        onClick={() => handleFeeHistory(item)}
                      >
                        View
                      </button>
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      <BsThreeDotsVertical
                        className="cursor-pointer text-[#C60E6B]"
                        onClick={() => handleAction(item._id)}
                      />
                      {actionItem === item._id && (
                        <div className="absolute right-0 mt-2 w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10" ref={menuRef}>
                          <div className="py-1 font-semibold">
                            <span
                              // href="#view-update-info"
                              className="block px-4 py-2 text-sm text-pink-600 hover:bg-gray-100"
                              onClick={() => handleParentUpdate(item)}
                            >
                              View & Update Info
                            </span>
                            <span
                              // href="#block-parent"
                              className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                              onClick={() => handleBlockParent(item)}
                            >
                              Block Parent
                            </span>
                            <span
                              // href="#block-parent"
                              className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                              onClick={() => handleInactive(item)}
                            >
                              Inactive Parent
                            </span>
                            <span
                              // href="#view-history"
                              className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                              onClick={() => handleViewHistory(item)}
                            >
                              View History
                            </span>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                )) : <td className="text-center">Data not found.</td>}
            </tbody>
          </table>
        </div>
      </section>
      {blockedPoup && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[600px]">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setBlockpouUp(false)}
            >
              <span><MdOutlineClose /></span> Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Reason </h2>
              </div>
              <div className="relative lg:h-[200px] overflow-y-auto">
                <form className="space-y-2 p-4">
                  <div>
                    <label
                      htmlFor="subjectName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Reason:
                    </label>
                    <textarea
                      type="text"
                      id="subjectName"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <div className="flex justify-center items-center pt-2">
                    <button
                      onClick={handleSubmit}
                      className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                    >
                      Blocked
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )
      }

      {
        inActivePoup && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setInactivePopup(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">InActive Reason </h2>
                  </div>
                  <div className="relative lg:h-[200px] overflow-y-auto">
                    <form className="space-y-2 p-4">
                      <div>
                        <label
                          htmlFor="subjectName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          InActive Reason:
                        </label>
                        <textarea
                          type="text"
                          id="subjectName"
                          value={inactiveResion}
                          onChange={(e) => setInactiveResion(e.target.value)}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          required
                        />
                      </div>
                      <div className="flex justify-center items-center pt-2">
                        <button
                          onClick={handleSubmitInactive}
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          InActive
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

      <ViewParent
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
      />


      {
        viewStudentModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[850px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setViewStudentModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Student Details </h2>
                  </div>
                  <div className="relative lg:h-[400px] overflow-y-auto">
                    <div className="overflow-x-auto overflow-hidden p-4">
                      <table className="table_full w-full border border-gray-200">
                        <thead>
                          <tr className="text-center bg-gray-100 border-b">
                            <th className="py-2 px-4 text-left border whitespace-nowrap">
                              Student Name
                            </th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">
                              Gender
                            </th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">
                              School Name
                            </th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">
                              Medium
                            </th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">
                              Date Of Birth
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentListdata &&
                            studentListdata.map((std, id) => (
                              <tr key={id} className="border-b capitalize">
                                <td className="py-2 px-4 border whitespace-nowrap">
                                  {std?.studentDetails?.studentFullName ?? "No Name"}
                                </td>
                                <td className="py-2 px-4 border whitespace-nowrap">
                                  {std?.studentDetails?.gender ?? "No Gender"}
                                </td>
                                <td className="py-2 px-4 border whitespace-nowrap">
                                  {std?.studentDetails?.schoolName ?? "No School"}
                                </td>
                                <td className="py-2 px-4 border whitespace-nowrap">
                                  {std?.studentDetails?.medium ?? "No Medium"}
                                </td>
                                <td className="py-2 px-4 border whitespace-nowrap">
                                  {std?.studentDetails?.dob ? moment(std?.dob).format("DD-MM-YYYY") : "No DOB"}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

      {
        feepaymentModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[850px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setFeePaymentModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Fee Payment History </h2>
                  </div>
                  <div className="relative lg:h-[400px] overflow-y-auto">
                    <div className="overflow-x-auto overflow-hidden p-4">
                      <table className="table_full w-full border ">
                        <thead>
                          <tr className="text-center bg-gray-100 border-b">
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Fee</th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {feePaymentListdata && feePaymentListdata.length > 0 ?
                            feePaymentListdata?.map((fee, id) => (
                              <tr key={id} className="border-b capitalize">
                                <td className="py-2 px-4 border whitespace-nowrap">
                                  {fee?.fee ?? "No Fee"}
                                </td>
                                <td
                                  className={`py-2 px-4 border whitespace-nowrap ${fee?.status === 0 ? "text-red-600" : "text-blue-600"
                                    } font-bold sm:px-4 sm:py-2`}
                                >
                                  {fee?.status === 0 ? "Pending" : "Done"}
                                </td>
                              </tr>
                            ))
                            : <tr className="border-b capitalize justify-center">
                              <td className="text-center">Data not found.</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }

      {
        parentUpdateModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[850px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => { setParentUpdateModal(false); setErrors({}) }}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Update Parent</h2>
                  </div>
                  <div className="relative lg:h-[550px] overflow-y-auto">
                    <div className="p-4 space-y-2">
                      <div className="space-y-1">
                        <label
                          htmlFor="subjectName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Parent Name:
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={parentUpdateData.name}
                          onChange={handleOnChangeUpdate}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        />
                        {errors.name && <span className="text-red-500">{errors.name}</span>}
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="subjectName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mobile Number:
                        </label>
                        <input
                          type="text"
                          name="mobNo"
                          value={parentUpdateData.mobNo}
                          onChange={handleOnChangeUpdate}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        />
                        {errors.mobNo && <span className="text-red-500">{errors.mobNo}</span>}

                      </div>
                      <div className="space-y-1">
                        <label
                          htmlFor="subjectName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Whatsapp Number:
                        </label>
                        <input
                          type="text"
                          id="subjectName"
                          name="whatsappNo"
                          value={parentUpdateData.whatsappNo}
                          onChange={handleOnChangeUpdate}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="space-y-1">
                        <label
                          htmlFor="subjectName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Address:
                        </label>
                        <input
                          type="text"
                          name="address"
                          value={parentUpdateData.address}
                          onChange={handleOnChangeUpdate}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        />
                        {errors.address && <span className="text-red-500">{errors.address}</span>}

                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700 capitalize"
                        >
                          City:
                        </label>
                        <select
                          id="city"
                          name="city"
                          value={parentUpdateData?.city || ""}
                          onChange={handleOnChangeUpdate}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md capitalize"
                        >
                          <option value="">Choose a City</option>
                          {cityData?.map((city) => (
                            <option key={city?._id} value={city?._id}>
                              {city?.name}
                            </option>
                          ))}
                        </select>
                        {errors.city && <span className="text-red-500">{errors.city}</span>}

                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="pinCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pincode:
                        </label>
                        <select
                          id="pinCode"
                          name="pinCode"
                          value={parentUpdateData?.pinCode?.length > 0 ? parentUpdateData?.pinCode[0] : ""}
                          onChange={handleOnChangeUpdate}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                        >
                          <option value="">Choose a Pincode</option>
                          {
                            pincodeData?.map((pin) => {
                              return (
                                <option value={pin} className="text-black">
                                  {pin}
                                </option>
                              );
                            })}
                        </select>
                        {errors.pinCode && <span className="text-red-500">{errors.pinCode}</span>}

                      </div>
                      <div className="mt-4 flex justify-center pt-4">
                        <button
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                          onClick={handleUpdateParent}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )
      }

      <section className="space-y-5 py-10 lg:hidden block">
        {activeParentUserList && activeParentUserList.length > 0 ? (
          activeParentUserList?.map((item, id) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Parent Name</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          item?.image ??
                          "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                        }
                        alt="User"
                      />
                      <div>
                        <div className="text-xs font-medium text-black ">{item?.name ?? "No Data"}</div>
                        <div className="text-xs text-gray-500">{item?.mobNo || 'No Number'}</div>
                        <FaChevronCircleRight
                          className="text-[#023565] cursor-pointer"
                          onClick={() => handleFullDetails(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Student Name</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => viewStudent(item)}
                    >
                      view</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.city?.name ?? "No City"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Ongoing Tuitions</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleOnGoingTution(item)}
                    >
                      View</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Fee History</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleFeeHistory(item)}
                    >
                      View
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <BsThreeDotsVertical
                        className="cursor-pointer text-[#C60E6B]"
                        onClick={() => handleAction(item._id)} size={24}
                      />
                      {actionItem === item._id && (
                        <div className="absolute right-50 mt-2 w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40">
                          <div className="py-1 font-semibold">
                            <a
                              href="#view-update-info"
                              className="block px-4 py-2 text-sm text-pink-600 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleParentUpdate(item)}
                            >
                              View & Update Info
                            </a>
                            <a
                              href="#block-parent"
                              className="block px-4 py-2  text-sm text-[#023565] cursor-pointer hover:bg-gray-100"
                              onClick={() => handleBlockParent(item)}
                            >
                              Block Parent
                            </a>
                            <a
                              href="#block-parent"
                              className="block px-4 py-2  text-sm text-[#023565] cursor-pointer hover:bg-gray-100"
                              onClick={() => handleInactive(item)}
                            >
                              Inactive Parent
                            </a>
                            <a
                              className="block px-4 py-2  text-sm text-[#023565] cursor-pointer hover:bg-gray-100"
                              href="#view-history"
                            >
                              View History
                            </a>
                          </div>
                        </div>
                      )}</p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>
      {onGointTutionList && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[800px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => setOnGoingTutionList(false)}
              >
                <span><MdOutlineClose /></span> Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Ongoing Tuition</h2>
                </div>
                <div className="relative lg:h-[200px] overflow-y-auto">
                  <div className="overflow-x-auto overflow-hidden p-4">
                    <table className="w-full bg-white table-auto border rounded-lg">
                      <thead className="border-b">
                        <tr className="text-center bg-[#F3F4F6]">
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Student Name / Subject</td>
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Location</td>
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Tution Fee</td>
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Tution Duration</td>
                          <td className="py-2 px-4 text-left border whitespace-nowrap">Created At</td>
                        </tr>
                      </thead>
                      <tbody>
                        {onGoingTutuionListById && onGoingTutuionListById.length ?
                          onGoingTutuionListById?.map((item, id) => (
                            <tr
                              className="text-center"
                              key={id}
                              style={{ textTransform: "capitalize" }}
                            >
                              <td className="py-2 px-4 border whitespace-nowrap">
                                {item?.bookFreeDemo?.map((std, id) => (
                                  <div>
                                    <span className="text-green-600 font-semibold cursor-pointer hover_show">
                                      view
                                    </span>
                                    <div className=" w-full hover_clss">
                                      <table className="border table-auto ">
                                        <thead>
                                          <tr className="text-center">
                                            <td>Student</td>
                                            <td>Subject</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <span>
                                                {std?.studentId?.studentFullName}
                                              </span>
                                            </td>
                                            <td>
                                              <span>{std?.subjectId?.name}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ))}
                              </td>
                              <td>{item?.location}</td>
                              <td>{item?.tuitionFee}</td>
                              <td className="">
                                <p>

                                  <span> Start:-</span>
                                  {item?.startTime ?? "Not  Available"}
                                </p>
                                <p>

                                  <span> End:-</span>
                                  {item?.endTime ?? "Not  Available"}
                                </p>
                              </td>
                              <td>
                                {moment(item?.createdAt).format("YYYY-MM-DD ")}
                              </td>
                            </tr>
                          ))
                          :
                          <tr className="text-center">
                            <td className="py-2 px-4 text-center">Data not found.</td>
                          </tr>


                        }

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
