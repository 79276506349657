import React from "react";
import "./Dashboard.css";
import { registerParentsSvg, registerdTutorSvg, requirementPostes} from "../../_helpers/svgFunction";
import { useNavigate } from "react-router";

const Card = ({index, title, numbers, buttonName, onClick, Group3, link}) => {
  const navigate = useNavigate()

  const ImageGroup = {
    img1: registerdTutorSvg(index == 0 ? "#C60E6B" : "#023565"),
    img2: registerParentsSvg(index == 1 ? "#C60E6B" : "#023565"),
    img3: requirementPostes(index == 2 ? "#C60E6B" : "#023565"),
  };

  let image = ImageGroup[Group3];
  const handelClickRedirect = (url) => {
    navigate(url)
  }

  return (
    <>
      <div className="card card_small flex flex-col flex-1  text-center rounded-3xl">
        <div className="card_heading flex  justify-between  mx-3 my-2 ">
          <div className=" w-16 h-16 card_img border border-[#023565] rounded-full flex justify-center items-center bg-white">
            {image}
          </div>
          <div className=" p-3">
            <h1 className="text-2xl number font-bold text-center ">
              {numbers}
            </h1>
          </div>
        </div>
        <div className="buttton card_bottom   text-end mb-5 px-6">
          <h3 className="text-lg title ">{title}</h3>
          <button className="btn underline viewCard font-bold" onClick={() => handelClickRedirect(link)}>
            {buttonName}
          </button>
        </div>
      </div>
    </>
  );
};

export default Card;
