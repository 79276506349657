import { boardConstants, classConstants } from '../../src/_constants'
import { APIcallFunction, headerForPrivateAPI } from "../_helpers"
import { alertActions } from './alert.actions';
export const boardAction = {
    boardGet,
    boardDelete,
    createBoard,
    updateBoard,
    EnaDisBoard,
    // classGet,
    // classDelete,
    // classCreate,
    // classEdit,
    // classEnaDis
}

function boardGet(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "board/getBoardList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: boardConstants.GET_BOARD_LIST_REQUEST } }
    function success(users) { return { type: boardConstants.GET_BOARD_LIST_SUCCESS, users } }
    function failure(error) { return { type: boardConstants.GET_BOARD_LIST_FAILURE, error } }


};

function boardDelete(data, BoardList) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "board/delete"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(boardGet(BoardList))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: boardConstants.DELETE_BOARD_LIST_REQUEST } }
    function success(users) { return { type: boardConstants.DELETE_BOARD_LIST_SUCCESS, users } }
    function failure(error) { return { type: boardConstants.DELETE_BOARD_LIST_FAILURE, error } }


};

function createBoard(data, BoardList) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "board/create"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(alertActions.success("Board created"));
                dispatch(success(users))
                dispatch(boardGet(BoardList))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: boardConstants.CREATE_BOARD_LIST_REQUEST } }
    function success(users) { return { type: boardConstants.CREATE_BOARD_LIST_SUCCESS, users } }
    function failure(error) { return { type: boardConstants.CREATE_BOARD_LIST_FAILURE, error } }


};

function updateBoard(data, boardDetails) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "board/update"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(boardGet(boardDetails))
                dispatch(alertActions.success("Board Updated"))

            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: boardConstants.UPDATE_BOARD_LIST_REQUEST } }
    function success(users) { return { type: boardConstants.UPDATE_BOARD_LIST_SUCCESS, users } }
    function failure(error) { return { type: boardConstants.UPDATE_BOARD_LIST_FAILURE, error } }


};


function EnaDisBoard(data, boardDetails) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "board/enableDisable"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                // dispatch(alertActions.success(""))
                dispatch(success(users))
                dispatch(boardGet(boardDetails))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: boardConstants.ENA_DIS_BOARD_LIST_REQUEST } }
    function success(users) { return { type: boardConstants.ENA_DIS_BOARD_LIST_SUCCESS, users } }
    function failure(error) { return { type: boardConstants.ENA_DIS_BOARD_LIST_FAILURE, error } }


};



// function classGet(data) {

//     const credentials = {
//         header: headerForPrivateAPI(),
//         method: "POST",
//         body: data ?? {},
//         endPoint: "class/getClassList"
//     };
//     return (dispatch) => {
//         dispatch(request(data));
//         APIcallFunction(credentials).then(
//             (users) => {

//                 dispatch(success(users))
//             },
//             (error) => {

//                 dispatch(failure(error))
//             }
//         )

//     }


//     function request() { return { type: classConstants.GET_CLASS_LIST_REQUEST } }
//     function success(users) { return { type: classConstants.GET_CLASS_LIST_SUCCESS, users } }
//     function failure(error) { return { type: classConstants.GET_CLASS_LIST_FAILURE, error } }


// };

// function classDelete(data, ClassList) {

//     const credentials = {
//         header: headerForPrivateAPI(),
//         method: "POST",
//         body: data ?? {},
//         endPoint: "class/delete "
//     };
//     return (dispatch) => {
//         dispatch(request(data));
//         APIcallFunction(credentials).then(
//             (users) => {
//                 dispatch(alertActions.success("Class Deleted"))
//                 dispatch(success(users))
//                 dispatch(classGet(ClassList))
//             },
//             (error) => {

//                 dispatch(failure(error))
//             }
//         )

//     }


//     function request() { return { type: classConstants.DELETE_CLASS_LIST_REQUEST } }
//     function success(users) { return { type: classConstants.DELETE_CLASS_LIST_SUCCESS, users } }
//     function failure(error) { return { type: classConstants.DELETE_CLASS_LIST_FAILURE, error } }


// };

// function classCreate(data, ClassList) {

//     const credentials = {
//         header: headerForPrivateAPI(),
//         method: "POST",
//         body: data ?? {},
//         endPoint: "class/create"
//     };
//     return (dispatch) => {
//         dispatch(request(data));
//         APIcallFunction(credentials).then(
//             (users) => {
//                 dispatch(alertActions.success("Class Created"))
//                 dispatch(success(users))
//                 dispatch(classGet(ClassList))
//             },
//             (error) => {

//                 dispatch(failure(error))
//             }
//         )

//     }


//     function request() { return { type: classConstants.DELETE_CLASS_LIST_REQUEST } }
//     function success(users) { return { type: classConstants.DELETE_CLASS_LIST_SUCCESS, users } }
//     function failure(error) { return { type: classConstants.DELETE_CLASS_LIST_FAILURE, error } }


// };

// function classEdit(data, ClassList) {

//     const credentials = {
//         header: headerForPrivateAPI(),
//         method: "POST",
//         body: data ?? {},
//         endPoint: "class/update"
//     };
//     return (dispatch) => {
//         dispatch(request(data));
//         APIcallFunction(credentials).then(
//             (users) => {
//                 dispatch(alertActions.success("Class Updated"))
//                 dispatch(success(users))
//                 dispatch(classGet(ClassList))
//             },
//             (error) => {

//                 dispatch(failure(error))
//             }
//         )

//     }


//     function request() { return { type: classConstants.CREATE_CLASS_LIST_REQUEST } }
//     function success(users) { return { type: classConstants.CREATE_CLASS_LIST_SUCCESS, users } }
//     function failure(error) { return { type: classConstants.CREATE_CLASS_LIST_FAILURE, error } }


// };

// function classEnaDis(data, ClassList) {

//     const credentials = {
//         header: headerForPrivateAPI(),
//         method: "POST",
//         body: data ?? {},
//         endPoint: "class/enable-disable "
//     };
//     return (dispatch) => {
//         dispatch(request(data));
//         APIcallFunction(credentials).then(
//             (users) => {
//                 dispatch(alertActions.success("Class Action Successfully"))
//                 dispatch(success(users))
//                 dispatch(classGet(ClassList))
//             },
//             (error) => {

//                 dispatch(failure(error))
//             }
//         )

//     }


//     function request() { return { type: classConstants.ENA_DIS_CLASS_LIST_REQUEST } }
//     function success(users) { return { type: classConstants.ENA_DIS_CLASS_LIST_SUCCESS, users } }
//     function failure(error) { return { type: classConstants.ENA_DIS_CLASS_LIST_FAILURE, error } }


// };