import React, { useEffect, useState } from "react";
import Button from "./Button";
import InActiveTable from "./InActiveTable";
import ActiveTable from "./ActiveTable";
import './tution.css';
import { tutionAction } from "../../../_actions";
import { useDispatch } from "react-redux";
import { CiCirclePlus } from 'react-icons/ci';
import TuitionFilters from "./TuitionFilters";
import { useLocation, useNavigate } from "react-router";

const ButtonSecondTabel = ({ searchText, newStateStatus, headerCity }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [filters, setFilters] = useState({ tuitionFee: "", tutorFee: "", preferredTeachMode: "", tutorId: "", classId: "", subjectId: "", classReference: "", demoDate: "", parentId: "", })
  const [changeOnClick, setChangeOnClick] = useState("Leads");
  const handleOnChange = (data) => {
    if (data === "New Leads") {
      handleCreateNewLead();
    } else {
      setFilters({
        tuitionFee: "",
        tutorFee: "",
        preferredTeachMode: "",
        tutorId: "",
        classId: "",
        subjectId: "",
        classReference: "",
        demoDate: "",
        parentId: "",
      })
      setChangeOnClick(data);
      navigate(`?tab=demo-request&button=demo&menu=${data == "Leads" ? "leads" : "active-on-app"}`)

    }
  };

  const dispatch = useDispatch();

  const [createModal, setCreateLeadModal] = useState(false);


  const handleCreateNewLead = () => {
    setCreateLeadModal(true);
    dispatch(tutionAction.getAllCityList());
    dispatch(tutionAction.gitAllClassListByAdmin());
    dispatch(tutionAction.getAllBoardByAdmin());
  };

  const ActiveInactiveButton = [
    { name: "Leads" },
    { name: "New Leads", icon: <CiCirclePlus className="text-[#C60E6B] bg-white rounded-full text-2xl " /> },
    { name: "Active on App" }
  ];
  const ActiveInactiveButton2 = [
    { name: "Leads" },
    { name: "Active on App" }
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const menu = queryParams.get('menu');
    console.log("queryParams", queryParams)
    if (menu) {
      setChangeOnClick(menu == "leads" ? "Leads" : "Active on App");
    } else {
      setChangeOnClick("Leads");
    }
    let newUrl = `?tab=demo-request&button=demo&menu=leads`
    if (menu) {
      newUrl = `?tab=demo-request&button=demo&menu=${menu && menu == "leads" ? "leads" : "active-on-app"}`
    }
    if (location.search !== newUrl) {
      navigate(newUrl);
    }

  }, [])


  return (
    <div className="">
      <TuitionFilters filters={filters} setFilters={setFilters} />
      <section className="flex flex-wrap gap-2 w-full text-[#C60E6B]   md:space-y-0 pt-4">
        {(changeOnClick === "Leads" ? ActiveInactiveButton : ActiveInactiveButton2).map((item, index) => (
          <div key={index} onClick={() => handleOnChange(item.name)} className={`text-base py-2 font-bold ${changeOnClick === item.name ? "rounded-tr-[6px] rounded-tl-[px] text-white bg-[#023565]" : ""}`}>
            {item.name === "New Leads" ? (
              <button className="flex items-center">{item.icon}</button>
            ) : (
              <Button key={index} name={item.name} icon={item.icon} />
            )}
          </div>
        ))}
      </section>

      <section>
        {changeOnClick === "Leads" && (
          <InActiveTable createModal={createModal} setCreateLeadModal={setCreateLeadModal} searchText={searchText} newStateStatus={newStateStatus} filters={filters} headerCity={headerCity} />
        )}
        {changeOnClick === "Active on App" && (
          <ActiveTable newStateStatus={newStateStatus} filters={filters} headerCity={headerCity} searchText={searchText} />
        )}
      </section>
    </div>
  );
};

export default ButtonSecondTabel;
