import React from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { noOfClasses } from '../../../../_helpers/dataInitialState'

function UpdateFeeModel({ updateFeeModal, setUpdateFeeModal, handleFeeDataSubmit, updatedFeeData, handleupdateFeeData }) {
    return updateFeeModal && (
        <div
            role="dialog"
            aria-modal="true"
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-[100]"
        >
            <div className="relative w-11/12 mx-auto lg:w-[600px] bg-white rounded-lg shadow-lg">
                <button
                    className="absolute flex place-items-center top-4 right-4 p-1 bg-transparent border-0 text-black text-lg font-semibold"
                    onClick={() => setUpdateFeeModal(false)}
                >
                    <span><MdOutlineClose /></span> Close
                </button>
                <div className="p-5 border-b border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold" >Update </h2>
                </div>
                <form className="p-4 grid grid-cols-2 border" onSubmit={handleFeeDataSubmit}>
                    <div className="mb-4">
                        <label htmlFor="numberOfClasses" className="block text-gray-700 font-bold mb-2 capitalize">Session Details</label>
                        <select id="numberOfClasses" name="numberOfClasses" className="border border-gray-300 p-2 rounded bg-white w-11/12" onChange={handleupdateFeeData} value={updatedFeeData?.numberOfClasses}>
                            <option> Select No. Classes In Week</option>
                            {noOfClasses.map((ele, id) => {
                                return <option key={id} value={ele}>{ele}</option>
                            })}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="tuitionFee" className="block text-gray-700 font-bold mb-2 capitalize">Tuition Fee</label>
                        <input
                            id="tuitionFee"
                            name="tuitionFee"
                            type="number"
                            value={updatedFeeData?.tuitionFee || ''}
                            onChange={handleupdateFeeData}
                            className="border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="referenceFee" className="block text-gray-700 font-bold mb-2 capitalize">Reference Fee</label>
                        <input
                            id="referenceFee"
                            name="referenceFee"
                            type="number"
                            value={updatedFeeData?.referenceFee || ''}
                            onChange={handleupdateFeeData}
                            className="border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="companyShare" className="block text-gray-700 font-bold mb-2 capitalize">Company Share</label>
                        <input
                            id="companyShare"
                            name="companyShare"
                            type="number"
                            disabled
                            value={updatedFeeData?.tuitionFee - updatedFeeData?.referenceFee - updatedFeeData?.tutorFee || 0}
                            onChange={handleupdateFeeData}
                            className="border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="tutorFee" className="block text-gray-700 font-bold mb-2 capitalize">Tutor Fee</label>
                        <input
                            id="tutorFee"
                            name="tutorFee"
                            type="number"
                            value={updatedFeeData?.tutorFee || ''}
                            onChange={handleupdateFeeData}
                            className="border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="classDuration" className="block text-gray-700 font-bold mb-2 capitalize">Class Duration</label>
                        <input
                            id="classDuration"
                            name="classDuration"
                            type="text"
                            value={updatedFeeData?.classDuration || ''}
                            onChange={handleupdateFeeData}
                            className="border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            for="first_name"
                            className="block text-gray-700 font-bold mb-2 capitalize"                        >
                            Student Fee Due Date :
                        </label>
                        <select name="dueDate" onChange={handleupdateFeeData}
                         className="border border-gray-300 p-2 rounded bg-white w-11/12"
                            value={updatedFeeData && updatedFeeData['dueDate'] ? updatedFeeData['dueDate'] : ""}
                        >
                            <option value=''>Select Due Date</option>
                            {Array.from(Array(31), (_, i) => (
                                <option value={i + 1}>{i + 1}</option>
                            ))}
                        </select>
                        {/* {error.dueDate && <p className="text-red-500 text-xs">{error.dueDate}</p>} */}
                    </div>
                    <div className="mb-4">
                        <label
                            for="first_name"
                            className="block text-gray-700 font-bold mb-2 capitalize"                        >
                            Tutor Fee Due Date :
                        </label>
                        <select name='tutorDueDate' onChange={handleupdateFeeData} className="border border-gray-300 p-2 rounded bg-white w-11/12"
                            value={updatedFeeData && updatedFeeData['tutorDueDate'] ? updatedFeeData['tutorDueDate'] : ""}
                        >
                            <option value=''>Select Due Date</option>
                            {Array.from(Array(31), (_, i) => (
                                <option value={i + 1}>{i + 1}</option>
                            ))}
                        </select>
                        {/* {error.tutorDueDate && <p className="text-red-500 text-xs">{error.tutorDueDate}</p>} */}
                    </div>

                    <div className="mb-4">
                        <label
                            for="countries"
                            className="block text-gray-700 font-bold mb-2 capitalize"                        >
                            Fee Type :
                        </label>
                        <select
                            id="tuitionType"
                            name="tuitionType"
                            className="border border-gray-300 p-2 rounded bg-white w-11/12"
                            value={updatedFeeData?.tuitionType ? updatedFeeData?.tuitionType : ""}
                            onChange={handleupdateFeeData}
                        >

                            <option value="">Select</option>
                            <option value="Per Month">Per Month</option>
                            <option value="For Course">For Course</option>
                            <option value="Per Class">Per Class</option>
                        </select>
                        {/* {error.tuitionType && <p className="text-red-500 text-xs">{error.tuitionType}</p>} */}
                    </div>
                    <div className=" col-span-2 flex justify-center">
                        <button
                            type="submit"
                            className="w-60 bg-[#023565] text-white font-semibold py-2 px-4 rounded-full"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UpdateFeeModel