import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CalenderView.module.css'; // Import your CSS file for custom styles
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';

const CustomToolbar = ({ label, onNavigate, onView }) => {
  return (
    <div className="flex justify-center items-center gap-6 py-2">
      <button className="text-[#023565] text-[18px] font-[700]" onClick={() => onNavigate('PREV')}><GoChevronLeft color='#023565' size={20} />
      </button>
      <span className="text-[#023565] text-[18px] font-[700]">{label}</span>
      <button className="text-[#023565] text-[18px] font-[700]" onClick={() => onNavigate('NEXT')}><GoChevronRight color='#023565' size={20} />
      </button>

    </div>
  );
};

export default CustomToolbar;
