import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { walletAction } from "../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import './Wallet.css'
import Loader from "../../components/Loader";

const RedemptionData = ({ searchText }) => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let walletData = selector?.Wallet?.walletHistoryList?.data?.list;

  const [addBonusModal, setAddBonusModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openItemId, setOpenItemId] = useState(null);
  const [userId, setUserId] = useState("");
  const [bonus, setBonus] = useState("");

  useEffect(() => {
    let walletDetails = {
      userId: "",
      type: "REDEEM",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };
    dispatch(walletAction.getWalletHistoryList(walletDetails));
  }, []);

  const handleView = (data) => {
    setView((prev) => !prev);
    setSelectedItem(data);
  };

  const openToggle = (itemId) => {
    setOpenItemId((prevItemId) => (prevItemId === itemId ? null : itemId));
  };

  const handleMarkPaid = (id, status) => {
    let statusUpdate = {
      id: id.id,
      status: id.status,
    };
    dispatch(walletAction.updateWalletStatus(statusUpdate));
  };

  const handlePayCustom = (id, status) => {
    let payStatus = {
      id: id.id,
      status: id.status,
    };
    dispatch(walletAction.updateWalletStatus(payStatus));
  };

  const handleRejectRequest = (id) => {
    let rejectStatus = {
      id: id.id,
      status: id?.status,
    };
    dispatch(walletAction.updateWalletStatus(rejectStatus));
  };

  return (
    <>
      <section className="py-10  lg:block hidden">
        <Loader loading={selector?.Wallet?.loading} />

        <div className=" bg-white border border-[#D3D3D3] rounded-xl  overflow-x-auto">
          <table className="min-w-full  table-auto  ">
            <thead className="">
              <tr className="border-b">
                <th className="px-6 py-5 text-start text-[#313131] table_head sm:px-4 sm:py-4">
                  User Name
                </th>
                <th className="px-6 py-5 text-center text-[#313131] table_head sm:px-4 sm:py-2">
                  City
                </th>
                <th className="px-6 py-3 text-center text-[#313131] table_head sm:px-4 sm:py-2">
                  Requested Rewards
                </th>
                <th className="px-6 py-3 text-center text-[#313131] table_head sm:px-4 sm:py-2">
                  Redemption Request
                </th>
                <th className="px-6 py-3 text-center text-[#313131] table_head sm:px-4 sm:py-2">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {walletData &&
                walletData?.map((item, id) => (
                  <tr className="capitalize" key={id}>
                    <td className="flex items-center text-[#313131] justify-start gap-2 px-6 py-4 sm:px-4 sm:py-2">
                      <img
                        className="w-10 h-10 rounded-full"
                        src={
                          item?.userId?.image ??
                          "https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg"
                        }
                        alt="Parent"
                      />
                      <div>
                        <div className="font-medium text-black">
                          {item?.userId?.name ?? 'No Data'}
                        </div>
                        <div className="text-gray-500">{item?.userId?.mobNo ?? 'No Data'}</div>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-center text-[#313131] sm:px-4 sm:py-2">
                      {item && item?.city && item?.city?.name ? item.city?.name : "no Data"}
                    </td>
                    <td className="px-6 py-4 text-sm text-center text-[#313131] sm:px-4 sm:py-2">
                      <p>{item?.balance ?? '0'}</p>
                    </td>
                    <td className="px-6 py-4 text-sm text-center text-[#313131] relative sm:px-4 sm:py-2">
                      <button
                        className="font-bold underline"
                        onClick={() => handleView(item)}
                      >
                        View
                      </button>
                      {view === item._id && (
                        <div className="absolute right-6 rounded-sm bg-white w-40 border shadow-lg">
                          <ul className="bg-white">
                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                              Beneficiary Name
                            </li>
                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                              Account Number
                            </li>
                            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                              FSC Code <br />
                              OR UPI ID
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm text-center relative text-gray-900 sm:px-4 sm:py-2">
                      <button className="font-bold">
                        <BsThreeDotsVertical className="text-2xl text-[#C60E6B]" onClick={() => openToggle(item._id)} />
                      </button>
                      {openItemId && openItemId === item._id && (
                        <div className="absolute right-0 mt-2 rounded-xl bg-white w-40 border shadow-lg z-40">
                          <ul className="text-start">
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 border-b"
                              onClick={() => {
                                handleMarkPaid({ id: item._id, status: 1 });
                                setOpen(false);
                              }}
                            >
                              <span className="text-[#C60E6B] font-bold">Mark Paid</span>
                              <img src="/Group 1000001172.png" alt="Mark Paid Icon" />
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 border-b text-[#023565]"
                              onClick={() => {
                                handlePayCustom({ id: item._id, status: 2 });
                                setOpen(false);
                              }}
                            >
                              <span className="text-[#023565] font-bold">Pay Custom</span>
                              <img src="/Group 1000001230.png" alt="Pay Custom Icon" />
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 text-[#023565]"
                              onClick={() => {
                                handleRejectRequest({ id: item._id, status: 0 });
                                setOpen(false);
                              }}
                            >
                              <span className="text-blue-900 font-bold">Reject Request</span>
                              <img src="/Group 1000001230.png" alt="Reject Request Icon" />
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
      {view && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
          <div className="relative w-2/3 bg-white rounded-lg shadow-lg">
            <div className="flex justify-end p-4 border-b border-gray-200">
              <button
                className="cursor-pointer p-2 bg-red-600 text-white rounded"
                onClick={() => setView(false)}
              >
                Close
              </button>
            </div>
            <table className="table-auto w-full">
              <thead>
                <tr className="border-b">
                  <th className="py-2">Beneficiary Name</th>
                  <th>Account Number</th>
                  <th>IFSC Code</th>
                  <th>UPI ID</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className="capitalize">
                  <td className="text-center">{selectedItem?.userId?.name}</td>
                  <td className="text-center">
                    {selectedItem?.accountNo || "No data"}
                  </td>
                  <td className="text-center">
                    {selectedItem?.ifscCode || "No data"}
                  </td>
                  <td className="text-center">
                    {selectedItem?.upi || "No data"}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="p-4">
              <div className="font-medium text-black"></div>
              <div className="px-6 py-4 text-center text-gray-900 sm:px-4 sm:py-2">
                {selectedItem?.city}
              </div>
            </div>
          </div>
        </div>
      )}


      {/* {view && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
              <div className="relative w-2/3 bg-white rounded-lg shadow-lg">
                <div className="flex justify-end p-4 border-b border-gray-200">
                  <button
                    className="cursor-pointer p-2 bg-red-600 text-white rounded"
                    onClick={() => setView(false)}
                  >
                    Close
                  </button>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr className="border-b">
                      <th className="py-2">Beneficiary Name</th>
                      <th>Account Number</th>
                      <th>IFSC Code</th>
                      <th>UPI ID</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="capitalize">
                      <td className="text-center">{selectedItem?.userId?.name}</td>
                      <td className="text-center">
                        {selectedItem && selectedItem?.accountNo
                          ? selectedItem?.accountNo
                          : "No data"}
                      </td>
                      <td className="text-center">
                        {selectedItem && selectedItem?.ifscCode
                          ? selectedItem?.ifscCode
                          : "No data"}
                      </td>
                      <td className="text-center">
                        {selectedItem && selectedItem.upi ? selectedItem.upi : "No data"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="p-4">
                  <div className="font-medium text-black"></div>
                  <div className="px-6 py-4 text-center text-gray-900 sm:px-4 sm:py-2">
                    {selectedItem?.city}
                  </div>
                </div>
              </div>
            </div>
          )} */}


      <section className="space-y-5 py-10 lg:hidden block">
        {
          walletData && walletData.length > 0 ? (walletData?.map((item, id) => (
            <div key={id} className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Parent Name</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={item?.userId?.image ?? 'https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg'}
                        alt="User"
                      />
                      <div>
                        <p className="text-xs font-medium text-black ">{item?.userId?.name}</p>
                        <div className="text-xs text-gray-500">{item?.userId?.mobNo}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'> {item?.city?.name ? item?.city?.name : 'No Data'} </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Requested Rewards</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'> {item?.balance ?? '0'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Status</p>
                  </div>
                  <div className='w-full'>
                    <p className={item?.status === '1' ? 'text-green-600 text-[14px] font-[400]' : item?.status === '2' ? 'text-green-600 text-[14px] font-[400]' : item?.status === '3' ? 'text-red-600 text-[14px] font-[400]' : 'text-[#313131] text-[14px] font-[400]'}>  {item?.status && item?.status == '1' ? 'PAID' : item?.status && item?.status == '2' ? 'CUSTOMER_PAID' : item?.status && item?.status == '3' ? 'REJECT' : 'PENDING'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Redemption Request</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      <button
                        className="font-bold underline"
                        onClick={() => handleView(item)}
                      >
                        View
                      </button>
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <BsThreeDotsVertical className="text-2xl text-[#C60E6B]" onClick={() => openToggle(item._id)} />
                      {openItemId && openItemId === item._id && (
                        <div className="absolute  mt-2 rounded-xl bg-white w-40 border shadow-lg" >
                          <ul className="text-start">
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 border-b"
                              onClick={() => {
                                handleMarkPaid({ id: item._id, status: 1 });
                                setOpen(false);
                              }}
                            >
                              <span className="text-[#C60E6B] font-bold">Mark Paid</span>
                              <img src="/Group 1000001172.png" alt="Mark Paid Icon" />
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 border-b text-[#023565]"
                              onClick={() => {
                                handlePayCustom({ id: item._id, status: 2 });
                                setOpen(false);
                              }}
                            >
                              <span className="text-[#023565] font-bold">Pay Custom</span>
                              <img src="/Group 1000001230.png" alt="Pay Custom Icon" />
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-100 text-[#023565]"
                              onClick={() => {
                                handleRejectRequest({ id: item._id, status: 3 });
                                setOpen(false);
                              }}
                            >
                              <span className="text-blue-900 font-bold">Reject Request</span>
                              <img src="/Group 1000001230.png" alt="Reject Request Icon" />
                            </li>
                          </ul>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </>
            </div>

          ))

          ) : (
            <p className="text-center">Data Not Found</p>
          )

        }
      </section>

    </>
  );
};

export default RedemptionData;
