import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardAction } from '../../_actions/dashboard.action'
import moment from 'moment'

const ButtonThird = () => {

    const dispatch = useDispatch();
    const selector = useSelector(state => state);

    let nlatestReferalListData = selector?.Dashboard?.getLatestReferralList?.data?.list
    useEffect(() => {
        let newReferralDetails = {
            "cityId": "",
            "isManagement": false,
            "keyWord": "",
            "pageNo": 1,
            "size": 7
        }

        dispatch(dashboardAction.getNewReferralsList(newReferralDetails))
    }, [])
    return (
        <div className='overflow-x-auto'>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-6">
                <thead class=" text-[#313131] text-center font-bold  border-b-2 border-[#D1D1D1]">
                    <tr className="border-b">
                        <th scope="col" class="px-6 py-3">
                            User
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Referred By
                        </th>
                        <th scope="col" class="px-6 py-3">
                            City
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Referred amount
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Type
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Date
                        </th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        nlatestReferalListData && nlatestReferalListData?.map((item, id) => (
                            <tr class="bg-white capitalize">
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    <p> {item?.refFrom?.name ?? '-'} </p>
                                    <p> {item?.refFrom?.mobNo ?? '-'} </p>
                                </td>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    <p> {item?.userId?.name ?? '-'} </p>
                                    <p> {item?.userId?.mobNo ?? '-'} </p>
                                </td>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    {item?.city?.name ?? '-'}
                                </td>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    {item?.balance ?? '-'}
                                </td>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    {item?.type ?? '-'}
                                </td>
                                <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                                    {moment(item?.createdAt).format('DD-MMM-YYYY') ?? '-'}
                                </td>

                            </tr>
                        ))
                    }



                </tbody>
            </table>
        </div>
    )
}

export default ButtonThird
