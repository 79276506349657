import react, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { dashboardAction } from '../../_actions/dashboard.action';
import moment from 'moment';
import "./Dashboard.css";


const ButtonFirst = () => {

  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  let demoDoneListdata = selector?.Dashboard?.getContactList?.data?.list

  useEffect(() => {
    let demoDoneListData = {
      "cityId": "",
      "keyWord": "",
      "pageNo": 1,
      "size": 7,
      "status": null,
      "userType": 'TUTOR'
    }
    dispatch(dashboardAction.getContactSeenList(demoDoneListData))
  }, [])
  return (
    <>
      <div class="relative first_table overflow-x-auto bg-white">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-6">
          <thead class=" text-[#313131] text-center font-bold  border-b-2 border-[#D1D1D1]">
            <tr className="border-b">
              <th scope="col" class="px-6 py-3 ">
                User
              </th>
              <th scope="col" class="px-6 py-3">
                Open Contact Details
              </th>
              <th scope="col" class="px-6 py-3">
                City
              </th>
              <th scope="col" class="px-6 py-3 font-bold">
                Date
              </th>
            </tr>
          </thead>
          <tbody className="text-center ">
            {
              demoDoneListdata && demoDoneListdata?.map((item, id) => (
                <tr class="bg-white capitalize">
                  <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                    <p>{item?.userId?.name ?? 'No User'}</p>
                    <p>{item?.userId?.mobNo ?? 'No User'}</p>
                    <p>{item?.userType ?? 'No User'}</p>
                  </td>
                  <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                    <p>{item?.tutorId?.name ?? 'No User'}</p>
                    <p>{item?.tutorId?.mobNo ?? 'No User'}</p>
                    <p>{'TUTOR'}</p>
                  </td>
                  <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">{item?.cityId?.name ?? '-'}</td>
                  <td class="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">{moment(item?.createdAt).format('DD-MMM-YYYY') ?? '-'}</td>

                </tr>
              ))
            }

          </tbody>
        </table>

        <div className='lg:hidden block '>

          <div className='flex justify-between p-2 w-full'>
            <div className='w-full'>
              <p className='text-[16px] font-[700] text-left'>Parent/Student</p>
            </div>
            <div className='w-full'>
              <p className='text-[14px] font-[400] text-right'>Amit Kumar/Anand</p>
            </div>
          </div>
          <div className='flex justify-between p-2 w-full'>
            <div className='w-full'>
              <p className='text-[16px] font-[700] text-left'>Class/Subjects</p>
            </div>
            <div className='w-full'>
              <p className='text-[14px] font-[400] text-right'>9th/Maths, Science</p>
            </div>
          </div>
          <div className='flex justify-between p-2 w-full'>
            <div className='w-full'>
              <p className='text-[16px] font-[700] text-left'>City</p>
            </div>
            <div className='w-full'>
              <p className='text-[14px] font-[400] text-right'>Jabalpur</p>
            </div>
          </div>
          <div className='flex justify-between p-2 w-full'>
            <div className='w-full'>
              <p className='text-[16px] font-[700] text-left'>Tutor Name</p>
            </div>
            <div className='w-full'>
              <p className='text-[14px] font-[400] text-right'>Ashutosh Mishra</p>
            </div>
          </div>
          <div className='flex justify-between p-2 w-full'>
            <div className='w-full'>
              <p className='text-[16px] font-[700] text-left'>Action</p>
            </div>
            <div className='w-full'>
              <ul className="flex gap-2 justify-center  ">
                <li className="cursor-pointer"><img src="/writing.png" /></li>
                <li className="cursor-pointer"><img src="/delete.png" /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ButtonFirst;
