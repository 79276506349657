/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feedbackAction } from "../../_actions/feedback.action";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import Loader from "../../components/Loader";
import { positiveNegativeAction, userListAction } from "../../_actions";
import moment from "moment";
import FeedbackModel from "./Models/FeedbackModel";
import FeedBackIssueModel from "./Models/FeedBackIssueModel";
import { FaFilterCircleXmark } from "react-icons/fa6";
import Select from "react-select";
import DateRangePicker from "../../components/Atoms/DateRangePicker";


const ParentFeedback = ({ searchText }) => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);

  const [feedbackData, setFeedbackData] = useState([]);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackIssueModal, setFeedbackIssueModal] = useState(false);
  const [newIssueResolve, setNewIssueResolve] = useState({});
  const [newRemark, setNewRemark] = useState("");
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [newState, setNewState] = useState({});
  const [name, setName] = useState("");
  const [actionModal, setActionModal] = useState(null);
  // const menuRef = useRef(null);
  const [filter, setFilter] = useState({
    data: {},
    value: ""
  })
  const [tutorId, setTutorId] = useState({ label: "Select Tutor", value: "" })
  const [parentId, setParentId] = useState({ label: "Select Parent", value: "" })
  const [dates, setDates] = useState({ fromDate: '', toDate: '' });

  const handleChange = (value, dateType) => {
    setDates((prevDates) => ({ ...prevDates, [dateType]: value }));
  };

  useEffect(() => {

    let positiveNegativeData = {
      "keyWord": searchText,
      ...dates,
      "sortOrder": "asc",
      "sortBy": "createdAt",
      pageNo: currentPage,
      size: pageSize,
      ...filter.data,
      tutorId: tutorId.value != "" ? [tutorId.value] : [],
      parentId: parentId.value != "" ? [parentId.value] : []
    };
    dispatch(
      positiveNegativeAction.getPositiveNegativeFeedback(positiveNegativeData)
    );
  }, [currentPage, dispatch, filter, searchText, parentId, tutorId, dates]);

  useEffect(() => {
    if (selector?.PositiveNegative?.positiveNagativeStatusList?.data?.total) {
      setTotalPages(Math.ceil(selector?.PositiveNegative?.positiveNagativeStatusList?.data?.total / pageSize));
    }
    setFeedbackData(selector?.PositiveNegative?.positiveNagativeStatusList?.data?.list || []);
  }, [selector]);


  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const getStatusMessage = (rating) => {
    if (rating >= 3) {
      return "Positive";
    } else if (rating < 3) {
      return "Negative";
    } else {
      return "";
    }
  };

  const handleActionModal = (id) => {
    setActionModal(actionModal === id ? null : id);
  };

  const handleFeedbackDone = (resDataId) => {
    setFeedbackModal(true);
    setNewState({ id: resDataId._id });
  };

  const handlePositive = () => {
    const feedbackData = {
      "keyWord": searchText,
      ...dates,
      ...filter.data,
      "sortOrder": "asc",
      "sortBy": "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const dataRating = {
      ...newState,
      rating,
      remark: newRemark,
    };
    dispatch(feedbackAction.updateFeedbackStatus(dataRating, feedbackData));
    setFeedbackModal(false);
  };

  const handleIssueResolved = (data) => {
    setFeedbackIssueModal(true);
    setNewIssueResolve({ id: data._id, status: 1 });
  };

  const handleIssueResolveSubmit = (e) => {
    e.preventDefault();
    const feedbackData = {
      "keyWord": searchText,
      ...dates,
      ...filter.data,
      "sortOrder": "asc",
      "sortBy": "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const issueResolveDetails = {
      ...newIssueResolve,
      remark: name,
    };
    dispatch(feedbackAction.updateIssueResolve(issueResolveDetails, feedbackData));
    setFeedbackIssueModal(false);
    setName("");
  };

  const menuRef = useRef(null);


  const mobileRef = useRef(null)


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setActionModal(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  const handleFeedbackChange = (e) => {
    e.preventDefault();
    switch (e.target.value) {
      case "POSITIVE":
        return setFilter((prev) => ({
          ...prev,
          data: { status: "0", type: "POSITIVE", value: 3 },
          value: e.target.value
        }))
      case "NEGATIVE":
        return setFilter((prev) => ({
          ...prev,
          data: { status: "0", type: "NEGATIVE", value: 3 },
          value: e.target.value
        }))
      case "RESOLVED":
        return setFilter((prev) => ({
          ...prev,
          data: { status: "1" },
          value: e.target.value
        }))
      default:
        return setFilter((prev) => ({
          ...prev,
          data: {},
          value: e.target.value
        }))
    }
  }
  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000',
      },
      borderRadius: '5px',
    }),
    menu: (provided) => ({
      ...provided,

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000 !important',
    }),
  };

  useEffect(() => {
    dispatch(userListAction.getUserTypeList({ isManagement: true }))
  }, [])

  const tutorOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
      .filter((ele) => ele?.userType === "TUTOR")
      .map((ele) => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`,
      }))
    : [];

  const parentOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
      .filter((ele) => ele?.userType === "PARENT")
      .map((ele) => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`,
      }))
    : [];

  const handleResetFilters = () => {
    setParentId({ label: "Select Parent", value: "" })
    setTutorId({ label: "Select Tutor", value: "" })
    setFilter((prev) => ({
      ...prev,
      value: "",
      data: {}
    }))
    setDates({ fromDate: '', toDate: '' })

  }

  return (
    <section className="overflow-hidden overflow-y-auto h-screen">
      <Loader loading={selector?.PositiveNegative?.loading} />

      <div className="flex flex-wrap items-center gap-4 justify-end">
        <DateRangePicker fromDate={dates.fromDate} toDate={dates.toDate} handleChange={handleChange} />

        <div className="grid md:grid-cols-3 grid-cols-2 gap-2 xl:pt-6 pt-0">
          <Select id="parentId" name="parentId" value={parentId} options={[{ label: "Select Parent", value: "" }, ...parentOptions]} className="apitalize text-black text-sm w-48" styles={customStyles} classNamePrefix="react-select" placeholder="Parent Name" onChange={(selectedOption, actionMeta) => { setParentId(selectedOption) }} />

          <Select id="tutor" name="tutor" value={tutorId} options={[{ label: "Select Tutor", value: "" }, ...tutorOptions]} className="apitalize text-black text-sm w-48" styles={customStyles} classNamePrefix="react-select" placeholder="Tutor Name" onChange={(selectedOption, actionMeta) => { setTutorId(selectedOption) }} />

          <select className="flex items-center justify-center bg-white border-2 text-[#000] rounded-lg w-full px-2 py-2" value={filter.value} onChange={(e) => handleFeedbackChange(e)}>
            <option value="">Select feedback</option>
            <option value="POSITIVE">Positive</option>
            <option value="NEGATIVE">Negative</option>
            <option value="RESOLVED">Resolved</option>
          </select>
        </div>
        <button className="text-center font-bold xl:pt-5 md:pt-0 md:mt-0 -mt-16" onClick={() => handleResetFilters()}>
          <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" />
        </button>
      </div>

      <section className="py-6 md:block hidden">
        <div className="relative border rounded-lg bg-white overflow-auto max-h-[80vh]">
          <table className="w-full text-sm text-left rtl:text-right text-black shadow-md">
            <thead className="bg-white">
              <tr className="border-b">
                <th scope="col" className="px-8 py-4">S.No</th>
                <th scope="col" className="px-8 py-3">Parent Name</th>
                <th scope="col" className="px-8 py-3">Tutor Name</th>
                <th scope="col" className="px-8 py-3">Feedback Status</th>
                <th scope="col" className="px-8 py-3">Remark</th>
                <th scope="col" className="px-8 py-3">Date</th>
                <th scope="col" className="px-8 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {feedbackData && feedbackData.length > 0 ? (
                feedbackData.map((item, id) => (
                  <tr className="bg-white border-b capitalize" key={id}>
                    <th scope="row" className="px-6 py-4 text-gray-900 whitespace-nowrap">
                      {(currentPage - 1) * pageSize + id + 1}
                    </th>
                    <td className="px-8 py-4">
                      <tr className=" font-[700]">{item?.parentId?.name || item?.demoId?.parentId?.name}</tr>
                      <tr className="">{item?.parentId?.mobNo || item?.demoId?.parentId?.mobNo}</tr>
                    </td>
                    <td className="px-8 py-4">
                      <tr className=" font-[700]">{item?.tutorId?.name || item?.demoId?.tutorId?.name}</tr>
                      <tr className="">{item?.tutorId?.mobNo || item?.demoId?.tutorId?.mobNo}</tr>
                    </td>
                    <td className={`px-8 py-4 font-semibold text-[#00AFE2]`}>
                      {item.status === 1 ? "Resolved" : getStatusMessage(item.rating)}
                    </td>
                    <td className="px-8 py-4 capitalize">
                      {item.remark ? item.remark : "N/A"}
                    </td>
                    <td className="px-8 py-4 capitalize">
                      {item.createdAt ? moment(item.createdAt).format("DD MMM YYYY") : "N/A"}
                    </td>
                    <td className="px-8 py-4 relative cursor-pointer">
                      <BsThreeDotsVertical
                        className="text-2xl text-[#C60E6B]"
                        onClick={() => handleActionModal(item._id)}
                      />
                      {actionModal === item._id && (
                        <div className="fixed mt-2 right-4 w-44 bg-white rounded-2xl shadow-lg z-10 border" ref={menuRef}>
                          <ul className="py-1 text-black">
                            <li
                              className="cursor-pointer flex justify-between place-items-center px-4 py-2 hover:bg-gray-200 font-semibold text-[#C60E6B]"
                              onClick={() => {
                                handleFeedbackDone(item);
                                setActionModal(null);
                              }}
                            >
                              Feedback Done
                              <img src="/Group 1000001172.png" alt="Feedback Done" />
                            </li>
                            {item.status != 1 ?
                              <li
                                className="cursor-pointer flex justify-between place-items-center px-4 py-2 text-[#023565] hover:bg-gray-200 font-semibold"
                                onClick={() => {
                                  handleIssueResolved(item);
                                  setActionModal(null);
                                }}
                              >
                                Issue Resolved
                                <img src="/Group 1000001230.png" alt="Issue Resolved" />
                              </li>
                              : null}
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="px-6 py-4 text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      <FeedbackModel
        feedbackModal={feedbackModal}
        setFeedbackModal={setFeedbackModal}
        setRating={setRating}
        setNewRemark={setNewRemark}
        newRemark={newRemark}
        setHover={setHover}
        rating={rating}
        hover={hover}
        handlePositive={handlePositive}
      />


      <FeedBackIssueModel
        feedbackIssueModal={feedbackIssueModal}
        setFeedbackIssueModal={setFeedbackIssueModal}
        handleIssueResolveSubmit={handleIssueResolveSubmit}
        setName={setName}
        name={name}
      />

      <section className="py-10 overflow-y-auto space-y-5">
        {feedbackData && feedbackData.length > 0 ? (
          feedbackData.map((item, id) => (
            <div className='md:hidden block bg-white border-2 border-[#D3D3D3] rounded-lg' key={id}>

              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>S.No :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'> {id + 1}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Parent Name :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'> {item?.parentId?.name ?? "No Data"}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Contact Number :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'> {item?.parentId?.mobNo ?? "No Data"}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Feedback Status :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>    {getStatusMessage(item.rating)}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Remark :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>{item.remark ? item.remark : "N/A"}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Action</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left relative'>
                    <BsThreeDotsVertical
                      className="text-2xl text-[#C60E6B]"
                      onClick={() => handleActionModal(item._id)}
                    />
                    {actionModal === item._id && (
                      <div className="absolute mt-2 right-4 w-44 bg-white rounded-2xl shadow-lg z-10 border" ref={mobileRef}>
                        <ul className="py-1 text-black">
                          <li
                            className="cursor-pointer flex justify-between place-items-center px-4 py-2 hover:bg-gray-200 font-semibold text-[#C60E6B]"
                            onClick={() => {
                              handleFeedbackDone(item);
                              setActionModal(null);
                            }}
                          >
                            Feedback Done
                            <img src="/Group 1000001172.png" />
                          </li>
                          <li
                            className="cursor-pointer flex justify-between place-items-center px-4 py-2 text-[#023565] hover:bg-gray-200 font-semibold"
                            onClick={() => {
                              handleIssueResolved(item);
                              setActionModal(null);
                            }}
                          >
                            Issue Resolved
                            <img src="/Group 1000001230.png" />
                          </li>
                        </ul>
                      </div>
                    )}
                  </p>
                </div>
              </div>

            </div>
          ))
        ) : (
          <div className="md:hidden">
            <p>Data not Found</p>
          </div>
        )
        }
      </section>


      <div>
        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2">
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdOutlineKeyboardArrowLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
            </span>
            <button
              className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}



      </div>
    </section>
  );
};

export default ParentFeedback;

