import React, { useState, useEffect } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { parentAction } from '../../../_actions';

function UpdateParent({ setParentUpdateModal, parentUpdateModal, initialParentData, currentPage, pageSize, status }) {
  const dispatch = useDispatch()
  const [parentUpdateData, setParentUpdateData] = useState(initialParentData);
  const [updatedPinCodeId, setUpdatedPinCodeId] = useState("");
  const [cityData, setCityData] = useState();

  const [selectedCityId, setSelectedCityId] = useState(initialParentData.city || "");
  const [error, setError] = useState("");

  const selector = useSelector(state => state)

  useEffect(() => {
    if (parentUpdateModal) {
      setParentUpdateData(initialParentData);
      setSelectedCityId(initialParentData.city);
      setUpdatedPinCodeId(initialParentData.pinCode);
    }
  }, [parentUpdateModal, initialParentData]);

  const handleOnChangeUpdate = (e) => {
    const { name, value } = e.target;
    setParentUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdatePinCode = (e) => {
    setUpdatedPinCodeId(e.target.value);
  };



  const handleOnSelect = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = selector?.Citys?.cityGetList.find(city => city._id === selectedCityId);
    setSelectedCityId(selectedCityId);
    if (selectedCity) {
      setCityData({
        _id: selectedCity._id,
        name: selectedCity.name,
        pinCode: selectedCity.pinCode
      });
    }
  };

  const validateForm = () => {
    // Basic validation checks
    if (!parentUpdateData.name || !parentUpdateData.mobNo || !parentUpdateData.address) {
      setError("All fields are required");
      return false;
    }

    if (!/^\d{10}$/.test(parentUpdateData.mobNo)) {
      setError("Invalid mobile number");
      return false;
    }

    setError(""); // Clear error if validation passes
    return true;
  };

  const handleUpdateParent = () => {
    if (validateForm()) {
      let data = {
        id: parentUpdateData._id,
        mobNo: parentUpdateData.mobNo,
        secondaryNo: parentUpdateData?.secondaryNo,
        name: parentUpdateData.name,
        whatsappNo: parentUpdateData.whatsappNo,
        address: parentUpdateData.address,
        city: selectedCityId,
        pinCode: [updatedPinCodeId],
      };

      let parentUserList = {
        status: status,
        userType: "PARENT",
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "asc",
        sortBy: "createdAt",
        pageNo: currentPage,
        size: pageSize,
      };
      dispatch(parentAction.updateParentByAdmin(data, parentUserList));
      setParentUpdateModal(false);
    }
  };


  return parentUpdateModal && (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
        <div className="relative w-11/12 mx-auto lg:w-[850px]">
          <button
            className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
            onClick={() => setParentUpdateModal(false)}
          >
            <span><MdOutlineClose /></span> Close
          </button>
          <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h2 className="text-lg font-semibold">Update Parent</h2>
            </div>
            <div className="relative h-[450px] overflow-y-auto">
              <div className="p-4 space-y-2">
                <div className="grid lg:grid-cols-2 gap-4">
                  <div className="">
                    <label htmlFor="subjectName">Parent Name</label>
                    <input
                      type="text"
                      name="name"
                      id="subjectName"
                      value={parentUpdateData.name}
                      onChange={handleOnChangeUpdate}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="">
                    <label>Mob No</label>
                    <input
                      type="text"
                      name="mobNo"
                      value={parentUpdateData.mobNo}
                      onChange={handleOnChangeUpdate}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                  </div>
                  <div className="">
                    <label>Email</label>
                    <input
                      type="text"
                      name="mobNo"
                      value={parentUpdateData.email}
                      onChange={handleOnChangeUpdate}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                  </div>

                  <div>
                    <label>Whatsapp No</label>
                    <input
                      type="text"
                      name="whatsappNo"
                      value={parentUpdateData.whatsappNo}
                      onChange={handleOnChangeUpdate}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="lg:col-span-2">
                    <label>Address</label>
                    <textarea 
                      type="text"
                      name="address"
                      value={parentUpdateData.address}
                      onChange={handleOnChangeUpdate}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label>City</label>
                    <select
                      name="city"
                      value={selectedCityId}
                      onChange={handleOnSelect}
                      className="p-2 border border-gray-300 bg-white rounded-md capitalize"
                    >
                      {selector?.Citys?.cityGetList && selector?.Citys?.cityGetList.map((city, index) => (
                        <option key={index} value={city._id} className="capitalize">
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label>PinCode</label>
                    <select
                      onChange={handleUpdatePinCode}
                      value={updatedPinCodeId || parentUpdateData?.pinCode}
                      className="p-2 border border-gray-300 bg-white rounded-md capitalize"
                    >
                      {cityData?.pinCode && cityData?.pinCode.length > 0 && cityData.pinCode.map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mt-4 flex justify-center pt-4">
                  <button
                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                    onClick={handleUpdateParent}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateParent;
