import React from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function CustomDatePicker  ({ selectedDate, onDateChange, value }) {
  return (
    <div className="relative shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
      <DatePicker
        selected={selectedDate}
        onChange={(e) => onDateChange(e)}
        value={value}
        dateFormat="DD/MM/YYYY"
        className="focus:outline-none focus:shadow-outline w-full"
      />
      <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none" selected={selectedDate}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 8h14a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V10a2 2 0 012-2z"
          />
        </svg>
      </div>
    </div>
  );
};


export default CustomDatePicker