/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subjectAction } from "../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineAdd, MdOutlineClose } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Loader from "../../components/Loader";
import { IoAdd, IoRemove } from "react-icons/io5";


const SubjectComponent = ({ searchText, setRefresh }) => {
  const dispatch = useDispatch();
  const subjectList = useSelector((state) => state.Subject.SubjectList);
  const selector = useSelector(state => state)
  const [subjectModal, setSubjectModal] = useState(false);
  const [name, setName] = useState("");
  const [offset, setOffSet] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [size, setSize] = useState(10);
  const [edit, setEdit] = useState(false);
  const [inputEdit, setInputEdit] = useState({ name: "" });
  const [actionSubjectId, setActionSubjectId] = useState(null);


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [newSubjects, setNewSubjects] = useState([""])




  useEffect(() => {
    let subjData = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(subjectAction.subjectGet(subjData));
  }, [currentPage, searchText]);



  useEffect(() => {
    if (selector?.Subject?.SubjectTotal) {
      setTotalPages(Math.ceil(selector?.Subject?.SubjectTotal / pageSize));
    }
    // setPackageList(selector?.Package?.packageList?.data?.list)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const CreateSubject = () => {
    setSubjectModal(true);
  };

  const createNewSubject = (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    const subj = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const subjectName = { name: newSubjects };
    dispatch(subjectAction.subjectCreate(subjectName, subj));
    setSubjectModal(false);
    setRefresh(true)
    setNewSubjects([""])
  };

  const deleteSubject = (id) => {
    const subj = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const deleteId = { id };

    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => { dispatch(subjectAction.subjectDelete(deleteId, subj)); setRefresh(true) }

        },
        {
          label: 'No'
        }
      ]
    });

  };

  const editModal = (data) => {
    setEdit(true);
    setInputEdit(data);
  };

  const handleOnChange = (e) => {
    setInputEdit({ ...inputEdit, name: e.target.value });
  };

  const handleEdit = () => {
    const subj = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const updatedData = { id: inputEdit._id, name: inputEdit.name };
    dispatch(subjectAction.subjectUpdate(updatedData, subj));
    setEdit(false);
  };

  const handleAction = (id) => {
    const subj = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const action = { id: id._id };

    confirmAlert({
      title: `Confirm to ${id?.isDisable ? 'Active' : 'InActive'}?`,
      // message: `Are you sure to Action ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(subjectAction.subjectEnaDis(action, subj))

        },
        {
          label: 'No'
        }
      ]
    })



  };

  const handleThreedotAction = (subjectId) => {
    setActionSubjectId((prevSubjectId) => (prevSubjectId === subjectId ? null : subjectId));
  };

  const handleClose = () => {
    setSubjectModal(false)
    // setName('')
    // setErrorData('')
    setNewSubjects([""])
    setErrorData({})

  }


  const menuRef = useRef(null);


  const mobileRef = useRef(null)


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setActionSubjectId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const sortedSubjectList = subjectList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const [errorData, setErrorData] = useState({})
  const handleValidation = () => {
    const errors = {};
    newSubjects.forEach((subject, index) => {
      if (!subject) {
        errors[`name_${index}`] = `Subject ${index + 1} is required`;
      }
    });
    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubjectChange = (index, value) => {
    const updatedSubjects = [...newSubjects];
    updatedSubjects[index] = value;
    setNewSubjects(updatedSubjects);

    if (errorData && errorData[`name_${index}`]) {
      setErrorData((prevErrorData) => ({ ...prevErrorData, [`name_${index}`]: null }));
    }
  };

  const addSubjectField = () => {
    setNewSubjects([...newSubjects, ""]);
  };

  const removeSubjectField = (index) => {
    const updatedSubjects = newSubjects.filter((_, i) => i !== index);
    setNewSubjects(updatedSubjects);
  };


  return (
    <>
      <div className="cursor-pointer text-[#C60E6B] flex text-center justify-end items-center px-6 md:-mt-14">
        <span
          className="px-2 py-2 flex gap-2 place-items-center bg-white rounded-lg border font-semibold"
          onClick={CreateSubject}
        >
          <MdOutlineAdd className="text-2xl text-[#C60E6B]" />
          Add New
        </span>
      </div>

      <Loader loading={selector?.Subject?.loading} />

      <div className="m-5 ">

        <section className="lg:block hidden">
          <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
            <table className="min-w-full table-autorounded-xl overflow-x-auto">
              <thead className="border-b border-[#D1D1D1]">
                <tr className="text-black">
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">S.No</th>
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">Subject Name</th>
                  <th className="px-4 py-3 whitespace-nowrap text-sm text-left">Status</th>
                  <th className="px-4 py-3 whitespace-nowrap text-sm">Action</th>
                </tr>
              </thead>
              <tbody >
                {subjectList && subjectList.length > 0 ? (
                  subjectList.map((item, id) => (
                    <tr key={id} className=" border-b hover:bg-gray-50 capitalize">
                      <td className="px-4 py-4 text-sm text-gray-900 text-left">
                        {(currentPage - 1) * pageSize + id + 1}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-left">{item.name}</td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-left" onClick={() => handleAction(item._id)}>
                        <span className={`whitespace-nowrap font-semibold cursor-pointer underline ${item.isDisable ? "text-red-600" : "text-green-700"}`}>
                          {item.isDisable ? "Inactive" : "Active"}
                        </span>
                      </td>

                      <td className="px-4 py-4 text-gray-900 relative">
                        <span className="flex justify-center items-center">
                          <BsThreeDotsVertical onClick={() => handleThreedotAction(item._id)} className="text-2xl cursor-pointer text-red-500 " />
                        </span>
                        {
                          actionSubjectId && actionSubjectId === item._id && (
                            <div className="absolute right-20 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10" ref={menuRef}>
                              <ul className="text-[#023565]">
                                <li className="flex justify-between items-center px-2 py-1 hover:text-[#E4006F] border-b cursor-pointer hover:bg-gray-100 rounded" onClick={() => handleAction(item)}>
                                  {item.isDisable ? "Active" : "Inactive"}
                                  <FaAngleRight className="hover:text-[#C60E6B]" />
                                </li>
                                <li className="flex justify-between items-center px-2 py-1 hover:text-[#E4006F] border-b cursor-pointer hover:bg-gray-100 rounded" onClick={() => editModal(item)}>
                                  View & Update
                                  <FaAngleRight className="hover:text-[#C60E6B]" />
                                </li>
                                <li className="flex justify-between items-center px-2 py-1 hover:text-[#E4006F] border-b cursor-pointer hover:bg-gray-100 rounded" onClick={() => deleteSubject(item._id)}>
                                  Delete
                                  <FaAngleRight className="hover:text-[#C60E6B]" />
                                </li>
                              </ul>
                            </div>
                          )
                        }
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="p-4 text-center">No Subject Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
        {subjectModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={handleClose}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Create Subject</h2>
                  </div>
                  <div className="relative lg:max-h-[500px] overflow-y-auto Pokercardbar">
                    <form onSubmit={createNewSubject} className="space-y-2 w-full p-4">

                      {newSubjects.map((subject, index) => (
                        <div className="flex  items-center gap-2 w-full">
                          <div className="space-y-2 w-[90%]" key={index}>
                            <label htmlFor={`subjectName_${index}`} className="block text-sm font-medium text-gray-900">
                              Subject Name {index + 1}:
                            </label>
                            <input
                              type="text"
                              id={`subjectName_${index}`}
                              value={subject}
                              onChange={(e) => handleSubjectChange(index, e.target.value)}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              placeholder="Enter subject name..."
                            />
                            {errorData && errorData[`name_${index}`] ? (
                              <p className="text-red-500 mt-2">{errorData[`name_${index}`]}</p>
                            ) : null}
                          </div>
                          <div className='flex items-center gap-1  w-[10%]'>

                            <div className="space-y-2">
                              <label className="block invisible text-sm font-medium text-gray-900">
                                Plus
                              </label>
                              <button
                                type="button"

                                className={`px-1 py-1 text-sm text-white flex justify-center items-center bg-red-600 rounded-[6px] hover:bg-red-500 focus:outline-none cursor-pointer ${newSubjects.length === 1
                                  ? "opacity-100 cursor-not-allowed"
                                  : ""
                                  }`}
                                disabled={newSubjects.length === 1}
                                onClick={() => removeSubjectField(index)}
                              >
                                <IoRemove />
                              </button>
                            </div>

                            <div className="space-y-2">
                              <label className="block invisible text-sm font-medium text-gray-900">
                                Minus
                              </label>
                              <button
                                type="button"
                                className="px-1 py-1 text-sm text-white  flex justify-center items-center  focus:outline-none bg-blue-500 rounded-[6px]"
                                onClick={addSubjectField}
                              >
                                <IoAdd />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-between">
                        <button
                          type="submit"
                          className="bg-[#01284E] text-white px-3 py-2 rounded-md mt-4 w-full mr-2"
                        >
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {edit && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[400px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setEdit(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Edit Subject</h2>
                  </div>
                  <div className="relative ">
                    <form className="space-y-4 w-full p-4">
                      <div className="mb-4">
                        <label
                          htmlFor="subjectName"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          Subject Name:
                        </label>
                        <input
                          type="text"
                          id="editSubjectName"
                          value={inputEdit.name}
                          onChange={handleOnChange}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md capitalize"
                        />
                      </div>
                      <div className="flex justify-between">
                        <button
                          type="button"
                          className="bg-[#01284E] text-white px-3 py-2 rounded-md mt-4 w-full mr-2"
                          onClick={handleEdit}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <section className="md:hidden block py- space-y-5 overflow-hidden overflow-y-auto">
          {subjectList && subjectList.length > 0 ? (
            subjectList.map((item, id) => (
              <div className=' bg-white p-4 rounded-[10px]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>S.No</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>{offset + id + 1}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Subject</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item.name}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Status</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        <span
                          className={`font-semibold ${item.isDisable ? "text-red-600" : "text-[#00AFE2]"
                            }`}
                        >
                          {item.isDisable ? "Inactive" : "Active"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left relative' >
                        <BsThreeDotsVertical className="relative text-2xl cursor-pointer text-[#C60E6B] my-2" onClick={() => handleThreedotAction(item._id)} />
                        {
                          actionSubjectId && actionSubjectId === item._id && (
                            <div className="absolute  border py-1 px-2  bg-white  border-gray-300 rounded-lg shadow-lg z-10 " ref={mobileRef}>
                              <ul className="p-2 space-y-2">
                                <li>
                                  <li className="flex justify-between items-center font-semibold text-[#C60E6B] border-b cursor-pointer hover:bg-gray-100 rounded" onClick={() => handleAction(item._id)}>

                                    {item.isDisable ? "Enable" : "Disable"}
                                    <img src="/Group 1000001172.png" alt="icon" />
                                  </li>
                                  <li className="flex justify-between items-center font-semibold text-[#C60E6B] border-b cursor-pointer hover:bg-gray-100 rounded" onClick={() => editModal(item)}>

                                    Edit
                                    <img src="/Group 1000001230.png" alt="icon" />
                                  </li>
                                  <li className="flex justify-between items-center font-semibold text-[#C60E6B] border-b cursor-pointer hover:bg-gray-100 rounded" onClick={() => deleteSubject(item._id)}>

                                    Delete
                                    <img src="/Group 1000001230.png" alt="icon" />
                                  </li>

                                </li>
                              </ul>
                            </div>
                          )
                        }
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
            <div>
              <p className="text-center text-gray-500 py-4">
                Data Not Available
              </p>
            </div>
          )}
        </section>
        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2 px-4">
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black  rounded">
                {currentPage}
              </span>{" "}
              of{" "}
              <span className="border px-4 py-2 text-black  rounded">
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : ""
                }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SubjectComponent;
