import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { attendanceAction } from "../../../_actions/attendance.action";
import { alertActions, tutionAction, userListAction } from "../../../_actions";
import moment from "moment/moment";
import { FaEye } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { MdOutlineClose, MdOutlineShare } from "react-icons/md";
import Loader from "../../../components/Loader";
import { FaFilterCircleXmark } from "react-icons/fa6";

import * as XLSX from 'xlsx';

import Select from "react-select";
import { BiExport } from "react-icons/bi";


const ViewAll = ({ searchText, users, handleReset, city }) => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);

  const [demoData, setdemoData] = useState()
  let attendanceData = selector?.attendance?.getAdminViewAttendanceList?.data
  const [studentModal, setStudentModal] = useState(false);
  const [markAttendanceModal, setMarkAttendanceModal] = useState(false)
  const [markData, setMarkData] = useState("")
  const [remark, setRemark] = useState('')
  const [studentData, setStudentData] = useState();
  const [studentAttendance, setStudentAttendance] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;

  const [markAbsentModal, setMarkAbsentModal] = useState(false)
  const [reamrkAbesnt, setReamrkAbesnt] = useState('')
  const [dataForAbsent, setDataForAbsent] = useState('')
  const [tutorId, setTutorId] = useState({ label: "Select Tutor", value: "" })
  const [parentId, setParentId] = useState({ label: "Select Parent", value: "" })

  const [dispute, setDispute] = useState("")

  const [month, setMonth] = useState(moment().startOf('month').format('YYYY-MM'))

  const [errors, setErrors] = useState({})

  const [noteData, setNoteData] = useState({ open: false, remark: "" })

  const [processStatus, setProcessStatus] = useState("")


  useEffect(() => {
    let tutionData = {
      status: processStatus != "" ? [Number(processStatus)] : [9, 10, 11, 12],
      "keyWord": searchText,
      // parentId: users,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      tutorId: tutorId.value !== "" ? [tutorId.value] : null,
      parentId: parentId.value !== "" ? [parentId.value] : null,
      dispute,
      cityId: city != "" ? [city] : null
    };
    dispatch(tutionAction.getDemoClassListStatus(tutionData));
  }, [currentPage, searchText, users, tutorId, parentId, dispute, city, processStatus]);

  useEffect(() => {
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(Math.ceil(selector?.tution?.demoList?.data?.total / pageSize));
    }
    setdemoData(() => selector?.tution?.demoList?.data?.list || [])
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleStudent = (item) => {
    setStudentModal(true)
    setStudentData([item]);
  };

  const handleStudentAttendance = (item) => {
    setSelectedStudent(item);
    setStudentAttendance(true);
    let attendaceListData = {
      tuitionId: item._id,
      "currentDate": null,
      "nextDate": null,
      "calenderData": month,
      "cityId": ""
    }
    dispatch(attendanceAction.getAdminViewAttendanceByTuitionId(attendaceListData))

  };



  const handleUpdateAttendance = (data) => {
    setMarkAttendanceModal(true)
    let parentId = selectedStudent?.parentId?._id
    let tutorId = selectedStudent?.tutorId?._id
    let tuitionId = selectedStudent?._id
    const timeStamp = moment(data?.date).format('YYYY-MM-DD')
    let updateDetails = {

      "date": timeStamp,
      "parentId": parentId,
      "tutorId": tutorId,
      "tuitionId": tuitionId,
    }
    setMarkData(updateDetails)
  }
  const handleMarkSubmit = () => {



    let dataforadminview = {
      tuitionId: selectedStudent._id,
      "currentDate": 0,
      "nextDate": 0,
      "calenderData": "",
      "cityId": "",
    }

    let dataformark = {
      ...markData,
      remark: remark
    }

    dispatch(attendanceAction.checkInOutByAdmin(dataformark, dataforadminview))


    setMarkAttendanceModal(false)
    setRemark('')


  }

  const handleClose = () => {
    setMarkAttendanceModal(false)
    setMarkAbsentModal(false)
    setErrors({})
    setDataForAbsent({})
  }

  const handleUpdateMarkAbsent = (data) => {
    setMarkAbsentModal(true)
    setDataForAbsent(() => ({
      ...data,
      absentBy: ""
    }))

  }

  const handleMarkAbsent = (e) => {

    e.preventDefault()

    let errorsData = {}
    if (!dataForAbsent.remark) {
      errorsData.remark = "Please enter remark"
    }
    if (!dataForAbsent.absentBy) {
      errorsData.absentBy = "Please select absentBy"
    }

    setErrors(errorsData)
    if (Object.keys(errorsData).length === 0) {
      let viewData = {
        tuitionId: selectedStudent._id,
        "currentDate": 0,
        "nextDate": 0,
        "calenderData": "",
        "cityId": ""
      }

      let reqData = {
        attendanceId: dataForAbsent?.attendanceId,
        calendarId: dataForAbsent.id,
        tuitionId: selectedStudent._id,
        remark: dataForAbsent.remark,
        absentBy: dataForAbsent.absentBy,
        date: dataForAbsent.date,
      }

      attendanceAction.markAbsentByAdminByPromise(reqData).then(res => {
        setMarkAbsentModal(false)
        setDataForAbsent({})
        dispatch(alertActions.success("Success."))
        dispatch(attendanceAction.getAdminViewAttendanceByTuitionId(viewData))
      }).catch(err => {
        dispatch(alertActions.error("Please try agin later."))
        setMarkAbsentModal(false)
        setDataForAbsent({})
        dispatch(attendanceAction.getAdminViewAttendanceByTuitionId(viewData))
      })


    }



  }
  const handleChangeMonth = async (data) => {
    let attendanceListData = {
      tuitionId: selectedStudent?._id,
      "currentDate": null,
      "nextDate": null,
      "calenderData": data,
      "cityId": ""
    }
    dispatch(attendanceAction.getAdminViewAttendanceByTuitionId(attendanceListData))
  }

  const handleResetFilters = () => {
    handleReset()
    setParentId({ label: "Select Parent", value: "" })
    setTutorId({ label: "Select Tutor", value: "" })
    setDispute("")
    setProcessStatus("")
  }

  const handleDownloadExcel = () => {
    if (!attendanceData || attendanceData.length === 0) {
      alert('No data available for download');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(attendanceData.map(item => ({
      ParentName: selectedStudent?.parentId?.name ?? "N/A",
      Student: selectedStudent?.bookFreeDemo?.map(stud => stud?.studentId?.studentFullName).join(', ') ?? "N/A",
      AssignedTutor: selectedStudent?.tutorId?.name ?? "N/A",
      ClassStartDate: selectedStudent?.startDate ? moment(selectedStudent.startDate).format("DD MMM YYYY") : "N/A",
      ClassDuration: selectedStudent?.classDuration ?? '0',
      Date: item?.DMY ?? "N/A",
      Day: item?.dayName ?? "N/A",
      Status: item?.status && item?.status === true ? 'Present' : item?.status === false ? 'Absent' : 'N/A',
      AnyDispute: item?.dispute ? 'Yes' : 'No',
      Note: item?.remark ?? 'N/A',
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${attendanceData[0].DMY.split('-')[1]}`);
    XLSX.writeFile(workbook, 'Attendance.xlsx');
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000',
      },
      borderRadius: '5px',
    }),
    menu: (provided) => ({
      ...provided,

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000 !important',
    }),
  };

  useEffect(() => {
    dispatch(userListAction.getUserTypeList({ isManagement: true }))
  }, [])

  const tutorOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
      .filter((ele) => ele?.userType === "TUTOR")
      .map((ele) => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`,
      }))
    : [];

  const parentOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
      .filter((ele) => ele?.userType === "PARENT")
      .map((ele) => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`,
      }))
    : [];

  const handleSubmitNote = (e) => {
    e.preventDefault()

    if (!noteData.remark) {
      setErrors({ remark: "Please enter remark." })
    } else {
      dispatch(tutionAction.addUpdateRemark(
        { demoId: noteData.demoId, remark: noteData.remark },
        {
          status: [9], "keyWord": searchText, fromDate: "", toDate: "", sortOrder: "", sortBy: "", pageNo: currentPage, size: pageSize, tutorId: tutorId.value !== "" ? [tutorId.value] : null, parentId: parentId.value !== "" ? [parentId.value] : null, dispute, cityId: city != "" ? [city] : null

        }
      ))
      setNoteData({ open: false, remark: "" })
    }

  }

  const getTuitionType = (status) => {
    switch (status) {
      case 9:
        return "Ongoing Tuition";
      case 10:
        return "Request Other Teacher";
      case 11:
        return "Completed Tuition";
      case 12:
        return "Hold Tuition";
      default:
        return "";
    }
  };

  return (

    <>
      <div className="flex flex-wrap lg:justify-end items-center gap-5 lg:-mt-9 mt-4">
        <div className="flex space-x-3 items-center justify-center">
          <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
            <select className="block w-full py-2 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white capitalize"
              value={processStatus}
              onChange={(e) => setProcessStatus(e.target.value)}
            >
              <option value={""}>Select Type</option>
              <option value={9}>Ongoing Tuition</option>
              <option value={10}>Request Other Teacher</option>
              <option value={11}>Completed Tuition</option>
              <option value={12}>Hold Tuition</option>

            </select>
            <select className="block w-full py-2 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white capitalize"
              value={dispute}
              onChange={(e) => setDispute(e.target.value)}>
              <option value={""}>Select Dispute</option>
              <option value={true}>Any Dispute</option>
              <option value={false}>No Dispute</option>

            </select>
            <div className="w-full">
              <Select
                id="parentId"
                name="parentId"
                value={parentId}
                options={[{ label: "Select Parent", value: "" }, ...parentOptions]}
                className="capitalize text-black"
                styles={customStyles}
                classNamePrefix="react-select"
                placeholder="Parent Name"
                onChange={(selectedOption, actionMeta) => {
                  setParentId(selectedOption)
                }}
              />
            </div>
            <div className="w-full">
              <Select
                id="tutor"
                name="tutor"
                value={tutorId}
                options={[{ label: "Select Tutor", value: "" }, ...tutorOptions]}
                className="capitalize text-black"
                styles={customStyles}
                classNamePrefix="react-select"
                placeholder="Tutor Name"
                onChange={(selectedOption, actionMeta) => {
                  setTutorId(selectedOption)
                }}
              />
            </div>
          </div>
          <button className="text-center font-bold md:pt-0 pt-16 pr-3">
            <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" onClick={() => { handleResetFilters() }} />
          </button>
        </div>
      </div>

      <div className='overflow-x-auto py-6'>
        <Loader loading={selector?.tution?.loading} />

        <div className="bg-white border border-[#D3D3D3] rounded-md lg:block hidden">
          <table className="min-w-full table-auto divide-y divide-gray-200 ">
            <thead className="">
              <tr>
                <th className="px-6 py-3 whitespace-nowrap text-left text-sm text-black">Parent Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Student Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Assigned Tutor</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Class Start Date</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Attendance</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Tuition Type</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Note</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {
                demoData && demoData.length > 0 ? (demoData.map((item, id) => (
                  <tr className="" key={id}>
                    <td className="px-4 py-4 flex items-center gap-4 text-sm text-gray-900">
                      <div className="flex items-center justify-center gap-2">
                        <span>
                          {item?.parentId && item?.parentId?.image ?
                            <img
                              alt=""
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : ""
                              }

                              className="rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                            />
                            : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                              <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                            </div>}
                        </span>
                        <div className="w-24">
                          <p className="font-semibold whitespace-nowrap text-[#023565]">
                            {item?.parentId?.name ?? "Not Available"}
                          </p>
                          <p className="text-gray-500 whitespace-nowrap">
                            {item?.parentId?.mobNo ?? "Not Available"}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td className="px-4 py-4 text-sm">
                      <span className="flex items-center justify-center">
                        <FaEye size={24} className="cursor-pointer text-[#023565] hover:text-[#E4006F]" onClick={() => handleStudent(item)} />
                      </span>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900">
                      <span className="flex justify-center items-center text-center font-semibold">{item?.tutorId?.name ?? 'No Tutor'}</span>
                      <span className="flex justify-center items-center">{item?.tutorId?.mobNo}</span>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center">
                      {moment(item?.startDate).format('DD-MMM-YYYY')}
                    </td>
                    <td className="px-4 py-4 text-sm text-[#023565] font-bold text-center">
                      <button onClick={() => handleStudentAttendance(item)}> View</button>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">
                      {item?.processStatus ? getTuitionType(item?.processStatus) : "-"}
                    </td>
                    <td className="px-4 py-4 text-sm text-[#023565] text-center truncate font-bold" onClick={() => setNoteData({ open: true, remark: item?.reason ? item?.reason : "", demoId: item._id })}>
                      {item && item?.reason ? "View" : 'Add'}
                    </td>
                  </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={6} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                  </tr>
                )
              }
            </tbody>
          </table>

        </div>

        {studentAttendance && selectedStudent && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[1440px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => { setStudentAttendance(false); setMonth(moment().startOf('month').format('YYYY-MM')) }}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="lg:flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <div>
                      <h2 className="text-lg font-semibold">Student Attendance</h2>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        type="month"
                        name=""
                        className="py-1.5 px-2 bg-white border-2 text-[#000] rounded-lg shadow"
                        value={month}
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            setMonth(e.target.value);
                            handleChangeMonth(e.target.value);
                          } else {
                            const defaultMonth = moment().startOf('month').format('YYYY-MM');
                            setMonth(defaultMonth);
                            handleChangeMonth(defaultMonth);
                          }
                        }}
                        min={selectedStudent?.startDate ? moment(selectedStudent.startDate).format('YYYY-MM') : undefined}
                      />
                      <button className="bg-white px-3 py-1 border-2 border-black gap-2  flex justify items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out-center rounded-lg" type=""><MdOutlineShare size={24} /> </button>
                      <button className="bg-white px-3 py-1 border-2 border-black gap-2  flex justify items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out-center rounded-lg" onClick={handleDownloadExcel}>
                        <span>Export</span>
                        <span><BiExport size={20} /></span>
                      </button>
                    </div>

                  </div>
                  <div className="relative h-[440px] overflow-y-auto">

                    <div className="overflow-x-auto overflow-hidden p-4">
                      <table className="w-full border border-collapse">
                        <thead>
                          <tr className="border-b bg-gray-100">
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Parent</th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Student Name</th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Assigned Tutor</th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Class Start Date</th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Class Duration</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr className="border-b">
                            <td className="py-2 px-4 border whitespace-nowrap">{selectedStudent?.parentId?.name}</td>
                            <td className="py-2 px-4 border whitespace-nowrap">{selectedStudent?.bookFreeDemo?.map(stud => stud?.studentId?.studentFullName).join(', ')}</td>
                            <td className="py-2 px-4 border whitespace-nowrap">{selectedStudent?.tutorId?.name}</td>
                            <td className="py-2 px-4 border whitespace-nowrap">{selectedStudent?.startDate ? moment(selectedStudent.startDate).format("DD MMM YYYY") : "---"}</td>
                            <td className="py-2 px-4 border whitespace-nowrap">{selectedStudent?.classDuration ?? '0'}</td>

                          </tr>
                        </tbody>
                      </table>
                      <div className="py-10">
                        <table className="w-full border border-collapse">
                          <thead>
                            <tr className="border-b bg-gray-100">
                              <th className="py-2 px-4 text-left border whitespace-nowrap">Date</th>
                              <th className="py-2 px-4 text-left border whitespace-nowrap">Status</th>
                              <th className="py-2 px-4 text-left border whitespace-nowrap">Any Dispute?</th>
                              <th className="py-2 px-4 text-left border whitespace-nowrap">Check-In Time</th>
                              <th className="py-2 px-4 text-left border whitespace-nowrap">Check-Out Time</th>
                              <th className="py-2 px-4 text-left border whitespace-nowrap">Note</th>
                              <th className="py-2 px-4 text-left border whitespace-nowrap">Tutor</th>
                              <th className="py-2 px-4 text-center border whitespace-nowrap">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {attendanceData && attendanceData.map((item, id) => (
                              <tr className="border-b">
                                <td className="py-2 px-4 border whitespace-nowrap">
                                  <p> {item?.DMY}</p>
                                  <p> {item?.dayName}</p>
                                  <p className="text-red-600"> {item?.holiday}</p>
                                </td>
                                {/* <td className={`py-2 px-4 border font-semibold ${item?.status ? 'text-[#00AFE2]' : 'text-red-600'}`}>
                                  {item?.status && item?.status === true ? 'Present' : item?.status === false ? 'Absent' : '-'}
                                </td> */}
                                <td className={`py-2 px-4 border font-semibold ${(item?.isOffByStudent === true || item?.isMarkAbsent === true) ? 'text-red-600' : 'text-[#00AFE2]'}`}>
                                  {item?.isOffByStudent === true || item?.isMarkAbsent === true ? `Absent (${item?.isOffByStudent === true ? 'By Student' : 'By Tutor'})` : item?.isOffByStudent === false && item?.isMarkAbsent === false ? item.status ? "Present" : "-" : '-'}                                </td>
                                <td className="py-2 px-4 border whitespace-nowrap">{item?.dispute ? 'Yes' : 'No'}</td>
                                <td className="py-2 px-4 border whitespace-nowrap">{item?.checkInTime ? moment(item.checkInTime).format("hh:mm A") : '-'}</td>
                                <td className="py-2 px-4 border whitespace-nowrap">{item?.checkOutTime ? moment(item.checkOutTime).format("hh:mm A") : '-'}</td>
                                <td className="py-2 px-4 border whitespace-nowrap">{item?.remark ?? 'N/A'}</td>
                                <td className="py-2 px-4 border whitespace-nowrap">{item?.tutorId?.name ?? 'N/A'}</td>
                                <td className="flex flex-col gap-2 px-4 py-2">
                                  <button className="bg-green-600 text-white w-36 px-2 py-1 rounded" onClick={() => handleUpdateAttendance(item)}>Mark Present</button>
                                  <button className="bg-red-600 text-white w-36 px-2 py-1 rounded" onClick={() => handleUpdateMarkAbsent(item)}>Mark Absent</button>

                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {
              markAttendanceModal && (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                    <div className="relative w-11/12 mx-auto lg:w-[600px]">
                      <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={handleClose}
                      >
                        <span><MdOutlineClose /></span> Close
                      </button>
                      <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                          <h2 className="text-lg font-semibold">Mark Present </h2>
                        </div>
                        <div className="relative lg:h-[200px] overflow-y-auto">
                          <form onSubmit={handleMarkSubmit} className="space-y-2 p-4">
                            <div>
                              <label
                                htmlFor="subjectName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Remark
                              </label>
                              <textarea
                                type="text"
                                id="remark"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                required
                              />

                            </div>
                            <div className="flex justify-center items-center pt-2">
                              <button
                                type="submit"
                                className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }

            {
              markAbsentModal && (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                    <div className="relative w-11/12 mx-auto lg:w-[600px]">
                      <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={handleClose}
                      >
                        <span><MdOutlineClose /></span> Close
                      </button>
                      <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                          <h2 className="text-lg font-semibold">Mark Absent </h2>
                        </div>
                        <div className="relative lg:h-[200px] overflow-y-auto">
                          <form onSubmit={handleMarkAbsent} className="space-y-2 p-4">
                            <div>
                              <label
                                htmlFor="subjectName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Remark
                              </label>
                              <textarea
                                type="text"
                                id="remark"
                                value={dataForAbsent?.remark}
                                onChange={(e) => setDataForAbsent((prev) => ({ ...prev, "remark": e.target.value }))}
                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"

                              />
                              {errors.remark && <p className="text-red-500 text-xs">{errors.remark}</p>}

                            </div>
                            <div>
                              <label
                                htmlFor="subjectName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Absent By
                              </label>
                              <select
                                name="absentBy"
                                value={dataForAbsent?.absentBy}
                                onChange={(e) => setDataForAbsent((prev) => ({ ...prev, "absentBy": e.target.value }))}
                                className="block w-full py-2 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white capitalize"
                              >
                                <option value=''>Select Absent By</option>
                                <option value='TUTOR'>Tutor</option>
                                <option value='STUDENT'>Student</option>


                              </select>
                              {errors.absentBy && <p className="text-red-500 text-xs">{errors.absentBy}</p>}


                            </div>
                            <div className="flex justify-center items-center pt-2">
                              <button
                                type="submit"
                                className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }

          </>
        )}


        {studentModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[800px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setStudentModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Student Name</h2>
                  </div>
                  <div className="relative h-[400px] overflow-y-auto">
                    <div className="overflow-x-auto overflow-hidden p-4">
                      <table className="w-full border border-collapse">
                        <thead>
                          <tr className="border-b bg-gray-100">
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Parent</th>
                            <th className="py-2 px-4 text-left border whitespace-nowrap">Student Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            studentData && studentData?.map((student, id) => (
                              <tr key={id} className="border-b">
                                <td className="py-2 px-4 border whitespace-nowrap">{student?.parentId?.name}</td>
                                <td className="py-2 px-4 border whitespace-nowrap">
                                  {Array.isArray(student?.bookFreeDemo) ? (
                                    <span>
                                      {student.bookFreeDemo
                                        .map(std => std?.studentId?.studentFullName)
                                        .filter(name => name)
                                        .join(", ") || 'No Data'}
                                    </span>
                                  ) : (
                                    <span>No Data</span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>


        )}


        <section className="space-y-5 lg:hidden block">
          {
            demoData && demoData.length > 0 ? (demoData.map((item, id) => (
              <div key={id} className=' bg-white p-4 rounded-[10px] border border-[#033565]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Parent Name</p>
                    </div>
                    <div className='w-full'>
                      <div className="flex items-center  gap-2 capitalize">
                        {item?.parentId && item?.parentId?.image ?
                          <img
                            alt=""
                            src={
                              item?.parentId && item?.parentId?.image
                                ? item?.parentId?.image
                                : ""
                            }

                            className="rounded my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                          />
                          : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                            <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                          </div>}
                        <div>
                          <div className="font-medium text-black">{item?.parentId?.name ?? 'N/A'}</div>
                          <div className="text-gray-500">{item?.parentId?.mobNo ?? 'N/A'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Student Name</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[700] text-[#033565]' onClick={() => handleStudent(item)}>View</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Assigned Tutor</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>{item?.tutorId?.name ?? 'No Tutor'}</p>
                      <p className='text-[14px] font-[400]'>{item?.tutorId?.mobNo ?? 'No Tutor'}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Class Start Date</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'> {moment(item?.startDate).format('DD-MMM-YYYY')}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Attendance</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[700] text-[#023565]'>
                        <button onClick={() => handleStudentAttendance(item)}> View</button>
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Tuition Type</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.processStatus ? getTuitionType(item?.processStatus) : "-"}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Note</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[700] text-[#023565]' onClick={() => setNoteData({ open: true, remark: item?.reason ? item?.reason : "", demoId: item._id })}>
                        {item && item?.reason ? "View" : 'Add'}
                      </p>
                    </div>
                  </div>

                </>
              </div>

            ))

            ) : (
              <p className="text-center">Data Not Found</p>
            )

          }
        </section>



        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2 px-4">
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}

        {noteData.open &&
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[600px]">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={() => { setNoteData({ open: false, remark: "" }); setErrors({}) }}

              >
                <span>
                  <MdOutlineClose />
                </span>{" "}
                Close
              </button>
              <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Note</h2>
                </div>
                <div className="relative lg:h-[200px] overflow-y-auto">
                  <form className="space-y-2 p-4"
                    onSubmit={(e) => handleSubmitNote(e)}
                  >
                    <div>
                      <label
                        htmlFor="subjectName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Note:
                      </label>
                      <textarea
                        type="text"
                        id="subjectName"
                        value={noteData.remark}
                        onChange={(e) => { setNoteData((prev) => ({ ...prev, remark: e.target.value })); setErrors({}) }}

                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"

                      />
                      {errors.remark && <p className="text-red-500 text-xs">{errors.remark}</p>}

                    </div>
                    <div className="flex justify-center items-center pt-2">
                      <button
                        className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>

  );
};

export default ViewAll;
