import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaAngleRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { timeZoneReturnDateType } from '../../../_helpers'

function FullTable({ getCityList, handleAction, actionCityId, handleEditCity, menuRef, totalPages, handleEnaDis, handlePreviousPage, currentPage, handleNextPage }) {
  return (
    <>
      <section className="lg:block hidden">
        <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
          <table className="min-w-full table-autorounded-xl overflow-x-auto">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-black">
                <th className="px-6 py-3 whitespace-nowrap text-sm">S No</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">City Name </th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Work Mode</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Platform Fee</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Created Date</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Status</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {getCityList?.data &&
                getCityList?.data?.list.length > 0 ? (
                getCityList?.data?.list.sort((a, b) => a.name.localeCompare(b.name))
                  .map((item, id) => (
                    <tr className=" border-b capitalize" key={id}>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center">{id + 1}</td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center">{item.name}</td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center">{item.isManagement ? "Full Management" : "Credit Based"}</td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center"><p>   {item && item?.platformFee ? item.platformFee : 0} </p></td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center">{timeZoneReturnDateType(item?.createdAt)}</td>
                      <td className={`px-4 py-4 text-sm text-gray-900 text-center font-semibold ${item.isDisable ? 'text-red-600' : 'text-green-600'}`}>{item.isDisable ? 'Inactive' : 'Active'}</td>

                      <td className="px-4 py-4 text-gray-900 relative text-center">
                        <span className="flex justify-center items-center">
                          <BsThreeDotsVertical onClick={() => handleAction(item._id)} className="text-2xl cursor-pointer text-red-500 " />
                        </span>
                        {actionCityId === item._id && (
                          <div ref={menuRef} className="absolute right-16 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                            <ul className="text-[#023565]">
                              <li
                                className="px-3 py-2 flex justify-between items-center cursor-pointer border-b hover:bg-gray-100 rounded font-semibold hover:text-[#E4006F] "
                                onClick={() => handleEnaDis(item)}
                              >
                                <span>
                                  {item.isDisable ? "Active" : "InActive"}
                                </span>
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </li>

                              <li
                                className="px-3 py-2 flex justify-between border-b items-center cursor-pointer hover:bg-gray-100 rounded font-semibold thover:text-[#E4006F] "
                                onClick={() => handleEditCity(item)}
                              >
                                <span>Update</span>
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">Data Not Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
      {/* {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )} */}
    </>
  )
}

export default FullTable
