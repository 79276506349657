/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, assignmentAction, userListAction } from "../../_actions";
import { connect } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';
import { uploadImageAction } from "../../_actions";
import { MdOutlineKeyboardArrowLeft, MdOutlineClose } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { MdOutlineAdd } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import { FaRegFilePdf } from "react-icons/fa";
import { IoDownloadOutline } from "react-icons/io5";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import Loader from "../../components/Loader";
import moment from "moment/moment";
import AssignmentHeader from "./AssignmentHeader";
import Select from "react-select";
import { FaFilterCircleXmark } from "react-icons/fa6";
import DateRangePicker from "../../components/Atoms/DateRangePicker";


const Assignment = (props) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.assignment);
  const selector1 = useSelector(state => state)

  const newSelector = useSelector(state => state);
  let [AssignmentData, setAssignmentData] = useState();

  let [AllTuitionList, setAllTuitionList] = useState();

  const [createModal, setCreateModal] = useState(false);
  const [assignmentEditModal, setAssignmentEditModal] = useState(false);
  const [name, setName] = useState("");
  const [editInput, setEditInput] = useState({

  });
  const [updateId, setUpdateId] = useState()
  const [demosetId, setDemoSetId] = useState('')
  let uploadFileData = newSelector?.ImageUpload?.fileDetails?.imageUrl;

  const [selectedValues1, setSelectedValues1] = useState([]);
  const [selectedValues2, setSelectedValues2] = useState([]);
  const [selectedValues3, setSelectedValues3] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10
  const [sharedBy, setSharedBy] = useState({ label: "Shared By", value: "" })
  const [sharedTo, setSharedTo] = useState({ label: "Shared To", value: "" })
  const [dates, setDates] = useState({ fromDate: '', toDate: '' });

  const handleChange = (value, dateType) => {
    setDates((prevDates) => ({ ...prevDates, [dateType]: value }));
  };
  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000',
      },
      borderRadius: '5px',
    }),
    menu: (provided) => ({
      ...provided,

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000 !important',
    }),
  };

  const demoData = AllTuitionList?.map((itm, index) => {
    const classes = itm?.bookFreeDemo?.map((cls) => cls?.classId?.name).join(', ');
    return {
      id: itm._id,
      name: itm?.parentId?.name ?? `Parent${index}`,
      mob: itm?.parentId?.mobNo,
      class: classes,
      display: `${itm?.parentId?.name ?? `Parent${index}`} - ${itm?.parentId?.mobNo} - ${classes}`
    };
  }) || [];


  const { loading } = selector1?.ImageUpload ? selector1?.ImageUpload : {}

  const [assignmentCityList, setAssignmentCityList] = useState([]);
  const [assignmentClassList, setAssignmentClassList] = useState([]);
  const [selectedCityList, setSelectedCityList] = useState([]);




  const onSelectCityList = (selectedList, item) => {
    if (item._id === "Select") {
      setSelectedCityList(assignmentCityList);
      setSelectedValues1(assignmentCityList)
    }
    else {
      setSelectedCityList(selectedList);
      setSelectedValues1(selectedList);
    }
    setCityError('');
  };

  const onRemoveCityList = (selectedList) => {
    setSelectedCityList(selectedList);
    setSelectedValues1(selectedList);
    if (selectedList.length === 0) {
      setCityError('At least one city must be selected');
    }
  };

  const onSelectClassList = (selectedList) => {
    setSelectedValues2(selectedList);
    const classIds = selectedList.map(item => item._id);
    const cityIds = selectedCityList.map(item => item._id);

    const data = {
      classId: classIds,
      cityId: cityIds,
    };

    dispatch(assignmentAction.getAssignmentTuitionListList(data));
    setClassError('');
  };

  const onRemoveClassList = (selectedList) => {
    setSelectedValues2(selectedList);
    if (selectedList.length === 0) {
      setClassError('At least one class must be selected');
    }
  };

  const onSelectDemoList = (selectedList) => {
    setSelectedValues3(selectedList);
    setStudentError('');
  };

  const onRemoveDemoList = (selectedList) => {
    setSelectedValues3(selectedList);
    if (selectedList.length === 0) {
      setStudentError('At least one student must be selected');
    }
  };

  const [searchText, setSearchText] = useState('')
  useEffect(() => {
    let data = {
      keyWord: searchText,
      pageNo: currentPage,
      size: pageSize,
      tutorId: sharedBy.value != "" ? [sharedBy.value] : [],
      parentId: sharedTo.value != "" ? [sharedTo.value] : [],
      ...dates
    };
    dispatch(assignmentAction.assignmentGet(data));
  }, [currentPage, searchText, sharedBy, sharedTo, dates]);

  useEffect(() => {

    dispatch(assignmentAction.getAllTuitionList());
    dispatch(assignmentAction.getAssignmentCityList())
    dispatch(assignmentAction.getAssignmentClassList())
  }, []);



  useEffect(() => {
    if (selector?.assignment?.data?.total) {
      setTotalPages(Math.ceil(selector?.assignment?.data?.total / pageSize));
    }
    setAssignmentData(selector?.assignment?.data?.list)
    setAllTuitionList(selector1?.assignment?.getAssignmentTuitionList?.data)
    setAssignmentCityList(selector1?.assignment?.getAssignmentCityList?.data)
    setAssignmentClassList(selector1?.assignment?.getAssignmentClassList?.data)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const createAssignment = () => {
    setCreateModal(true);
  };

  const [error, setError] = useState('');

  const uploadImage = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setError('');
      dispatch(uploadImageAction.UploadImage(file))
    } else {
      setError('Invalid file type. Only PDF files are allowed.');
      e.target.value = null;
    }
    setErrorData((prevErrors) => ({ ...prevErrors, file: '' }));
  };


  const [cityError, setCityError] = useState('');
  const [classError, setClassError] = useState('');
  const [studentError, setStudentError] = useState('');




  const [errorData, setErrorData] = useState({});


  const handelError = () => {

    const errors = {}
    if (!name) {
      errors["name"] = "Please Enter Assignment Name"
    }
    if (!uploadFileData) {
      errors["file"] = "Please Add Assignment File"
    }
    setErrorData(errors);
    return Object.keys(errors).length === 0;
  }
  const handleAssignmentCreateForm = (e) => {
    e.preventDefault();
    if (selectedValues1.length === 0) {
      setCityError('At least one city must be selected');
    } else {
      setCityError('');
    }

    if (selectedValues2.length === 0) {
      setClassError('At least one class must be selected');
    } else {
      setClassError('');
    }

    if (selectedValues3.length === 0) {
      setStudentError('At least one student must be selected');
    } else {
      setStudentError('');
    }

    if (!handelError()) {
      return;
    }

    if (selectedValues1.length === 0 || selectedValues2.length === 0 || selectedValues3.length === 0) {
      return;
    }
    let resdata = {
      keyWord: "",
      pageNo: 1,
      size: 10,
    };
    let CreateAssignmentData = {
      name: name,
      assignmentPdf: uploadFileData,
      demoId: selectedValues3.map(item => item.id),
    }
    dispatch(assignmentAction.assignmentCreate(CreateAssignmentData, resdata));
    setName("");
    setCreateModal(false);
    setCityError('')
    setClassError('')
    setStudentError('')
    setSelectedValues1([])
    setSelectedValues2([])
    setSelectedValues3([])
    dispatch(alertActions.success("Assignment Create success"))
  };

  const handleDeleteAssignment = (id) => {
    let resData = {
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,

      tutorId: sharedBy.value != "" ? [sharedBy.value] : [],
      parentId: sharedTo.value != "" ? [sharedTo.value] : [],
    };
    let DeleteId = {
      id: id.id,
    };

    confirmAlert({
      title: 'Confirm to Delete Assignment?',
      message: `Are you sure you want to delete this assignment`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(assignmentAction.assignmentDelete(DeleteId, resData))

        },
        {
          label: 'No'
        }
      ]
    });
  }

  const handleAssignmentEdit = (data) => {
    setAssignmentEditModal(true);
    setEditInput(data);
    setUpdateId(data.id)
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "parentName") {
      setEditInput({ ...editInput, parentId: { ...editInput.parentId, name: value } });
    }
    else if (name === "tutorName") {
      setEditInput({ ...editInput, tutorId: { ...editInput.tutorId, name: value } })
    } else {
      setEditInput({ ...editInput, [name]: value });

    }
    setDemoSetId(e.target.value)


  };

  const handelOnClickCloseCreateModel = () => {

    setCreateModal(false)
    setName('')
    setCityError('')
    setClassError('')
    setStudentError('')
    setError('')
    setErrorData({})
    setSelectedValues1([])
    setSelectedValues2([])
    setSelectedValues3([])
  }



  const handleAssignmentUpdateForm = (e) => {
    let resData = {
      keyWord: "",
      pageNo: 1,
      size: 10,
    };
    e.preventDefault();
    let editData = {
      id: updateId,
      name: editInput.name,
      demoId: demosetId.toString(),
      assignmentPdf: uploadFileData
    }
    dispatch(assignmentAction.updateAssignment(editData, resData))
    setAssignmentEditModal(false)

  };

  const uploadEditImage = (e) => {
    dispatch(uploadImageAction.UploadImage(e.target.files[0]));
  };



  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    dispatch(userListAction.getUserTypeList({ isManagement: true }))
  }, [])

  const optionsSharedBy = selector1?.userList?.getUserListForSearch?.data
    ? selector1?.userList?.getUserListForSearch?.data
      .filter((ele) => ele?.userType === "TUTOR")
      .map((ele) => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`,
      }))
    : [];

  const optionsSharedTo = selector1?.userList?.getUserListForSearch?.data
    ? selector1?.userList?.getUserListForSearch?.data
      .filter((ele) => ele?.userType === "PARENT")
      .map((ele) => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`,
      }))
    : [];

  const handleResetFilters = () => {
    setSharedBy({ label: "Shared By", value: "" })
    setSharedTo({ label: "Shared To", value: "" })
    setDates({ fromDate: '', toDate: '' })
  }




  return (
    <>
      <AssignmentHeader handleSearch={handleSearch} searchText={searchText} />

      <section className="overflow-hidden overflow-y-auto h-screen">

        <Loader loading={selector?.loading} />
        <Loader loading={selector1?.ImageUpload?.loading} />


        <div className="p-5">
          <div className="flex flex-wrap items-center gap-4 justify-end">
            <DateRangePicker fromDate={dates.fromDate} toDate={dates.toDate} handleChange={handleChange} />

            <div className="grid md:grid-cols-3 grid-cols-2 gap-2 xl:pt-6 pt-0">
              <Select id="parentId" name="parentId" value={sharedBy} options={[{ label: "Shared By", value: "" }, ...optionsSharedBy]} className="capitalize text-black text-sm w-48" styles={customStyles} classNamePrefix="react-select" onChange={(selectedOption, actionMeta) => { setSharedBy(selectedOption) }} />

              <Select id="parentId" name="parentId" value={sharedTo} options={[{ label: "Shared To", value: "" }, ...optionsSharedTo]} className="capitalize text-black text-sm w-48" styles={customStyles} classNamePrefix="react-select" onChange={(selectedOption, actionMeta) => { setSharedTo(selectedOption) }} />

              <div className="flex bg-white justify-center place-items-center text-[#C60E6B] rounded-lg border border-[#D9D9D9]  px-3 py-2 cursor-pointer" onClick={() => createAssignment()}>
                <span>
                  <MdOutlineAdd className="text-xl mx-1 " />
                </span>
                Add Assignment
              </div>
            </div>
            <button className="text-center font-bold xl:pt-5 md:pt-0 md:mt-0 -mt-16" onClick={() => handleResetFilters()}>
              <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" />
            </button>
          </div>

          <section className="lg:block hidden">
            <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
              <table className="min-w-full table-autorounded-xl overflow-x-auto">
                <thead className="border-b border-[#D1D1D1]">
                  <tr className="text-black">
                    <th className="px-6 py-3 whitespace-nowrap text-sm">Parent Name</th>
                    <th className="px-6 py-3 whitespace-nowrap text-sm">Assignment Name</th>
                    <th className="px-6 py-3 whitespace-nowrap text-sm">Shared By</th>
                    <th className="px-6 py-3 whitespace-nowrap text-sm">Shared To Student</th>
                    <th className="px-6 py-3 whitespace-nowrap text-sm">
                      <span className="flex justify-center items-center">PDF<IoDownloadOutline className="text-lg text-black ml-1" /></span>
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-sm">Date</th>
                    <th className="px-6 py-3 whitespace-nowrap text-sm">Delete</th>
                  </tr>
                </thead>

                <tbody>
                  {AssignmentData && AssignmentData.length ? AssignmentData.map((item, id) => (
                    <tr className="border-b capitalize" key={id}>
                      <th className="px-4 py-4 text-sm text-gray-900 text-center whitespace-nowrap">
                        {item && item.parentId.name ? item.parentId.name : null}
                      </th>
                      <th className="px-4 py-4 text-sm text-gray-900 text-center whitespace-nowrap">
                        {item && item.name ? item.name : null}
                      </th>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center font-semibold">
                        {item && item.tutorId && item.tutorId.name ? item.tutorId.name : "Admin"}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center">
                        <ul>
                          <li>
                            {item?.parentId?.name ?? 'N/A'}
                          </li>
                          <li>
                            {item?.parentId?.mobNo ?? 'N/A'}
                          </li>
                        </ul>
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center flex justify-center">
                        <a
                          href={item.assignmentPdf}
                          target="_blank"
                          className="font-medium hover:underline"
                          rel="noreferrer"
                        >
                          <FaRegFilePdf className="text-lg text-red-600" />
                        </a>
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center">
                        {moment(item?.createdAt).format('DD-MMM-YYYY')}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-center" onClick={() => handleDeleteAssignment(item)}>
                        <img src="/delete.png" className="cursor-pointer mx-auto" alt="" />
                      </td>
                    </tr>
                  ))
                    : <tr className="item-center">
                      <td colSpan={8} className="px-6 py-4 text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                    </tr>
                  }
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="flex justify-end items-center py-2">
                  <button
                    className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    <MdOutlineKeyboardArrowLeft />
                  </button>
                  <span className="text-gray-700 mx-1">
                    <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
                  </span>
                  <button
                    className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              )}


            </div>
          </section>
          {createModal && (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                  <button
                    className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                    onClick={handelOnClickCloseCreateModel}
                  >
                    <span><MdOutlineClose /></span> Close
                  </button>
                  <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h2 className="text-lg font-semibold">Create Assignment</h2>
                    </div>
                    <div className="relative  overflow-y-auto">
                      <form onSubmit={handleAssignmentCreateForm} className="space-y-2 p-4">
                        <div className="space-y-2 w-full">
                          <label htmlFor="className" className="block text-sm font-medium text-gray-700">
                            Assignment name:
                          </label>
                          <input
                            type="text"
                            id="className"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value)
                              setErrorData((previous) => {
                                return {
                                  ...previous,
                                  name: ""
                                }
                              })
                            }}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                            placeholder="Assignment Name...."
                          />
                          {errorData && errorData["name"] && <p className="text-red-500 text-sm">{errorData["name"]}</p>}
                        </div>


                        <div className="space-y-2 w-full">
                          <div className="space-y-2 w-full">
                            <label htmlFor="selectCity" className="block text-sm font-medium text-gray-700">
                              Select City:
                            </label>
                            <Multiselect
                              options={[{ _id: "Select", name: "Select All" }, ...assignmentCityList]}
                              selectedValues={selectedValues1}
                              onSelect={onSelectCityList}
                              onRemove={onRemoveCityList}
                              displayValue="name"
                            />
                            {cityError && <p className="text-red-500 text-sm">{cityError}</p>}
                          </div>

                          <div className="space-y-2 w-full">
                            <label htmlFor="selectClass" className="block text-sm font-medium text-gray-700">
                              Select Class:
                            </label>
                            <Multiselect
                              options={assignmentClassList}
                              selectedValues={selectedValues2}
                              onSelect={onSelectClassList}
                              onRemove={onRemoveClassList}
                              displayValue="name"
                            />
                            {classError && <p className="text-red-500 text-sm">{classError}</p>}
                          </div>

                          <div className="space-y-2 w-full">
                            <label htmlFor="selectDemo" className="block text-sm font-medium text-gray-700">
                              Select Student:
                            </label>
                            <Multiselect
                              options={demoData}
                              selectedValues={selectedValues3}
                              onSelect={onSelectDemoList}
                              onRemove={onRemoveDemoList}
                              displayValue="display"
                            />
                            {studentError && <p className="text-red-500 text-sm">{studentError}</p>}
                          </div>
                        </div>
                        <div className="space-y-2 w-full">
                          <label htmlFor="parentId" className="block text-sm font-medium text-gray-700">
                            Assignment Pdf:
                          </label>
                          <input
                            type="file"
                            accept="application/pdf"
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                            onChange={uploadImage}
                          />
                          {error && <p className="text-red-500 mt-2">{error}</p>}
                          {errorData && errorData["file"] && <p className="text-red-500 text-sm">{errorData["file"]}</p>}

                        </div>

                        <div className="flex justify-center items-center pt-2">
                          <button
                            type="submit"
                            className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
              </div>
            </>
          )}

          {assignmentEditModal && (
            <>

              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                  <button
                    className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                    onClick={() => setAssignmentEditModal(false)}
                  >
                    <span><MdOutlineClose /></span> Close
                  </button>
                  <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h2 className="text-lg font-semibold">Update Assignment </h2>
                    </div>
                    <div className="relative lg:h-[320px] overflow-y-auto">
                      <form onSubmit={handleAssignmentUpdateForm} className="space-y-2 p-4">
                        <div className="space-y-2 w-full">
                          <label htmlFor="assignmentName" className="block text-sm font-medium text-gray-700">
                            Assignment name:
                          </label>
                          <input
                            type="text"
                            id="assignmentName"
                            name="name"
                            value={editInput.name}
                            onChange={handleOnChange}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                            required
                          />
                        </div>
                        <div className="space-y-2 w-full">
                          <label htmlFor="parentName" className="block text-sm font-medium text-gray-700">
                            demoId:
                          </label>
                          <select
                            value={editInput?.parentId?.name || ''}
                            onChange={handleOnChange}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                            name="parentName"
                          >
                            {demoData?.map((itm, index) => (
                              <option key={index} value={itm.id}>
                                {itm.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="space-y-2 w-full">
                          <div className="">
                            {loading && (
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span className="sr-only">Loading...</span>
                              </div>
                            )}
                            <input
                              type="file"
                              id="image"
                              accept="application/pdf"
                              onChange={uploadEditImage}
                              className="w-full border p-2 rounded-md text-sm text-gray-700"
                            />
                          </div>
                        </div>
                        <div className="flex justify-center items-center pt-2">
                          <button
                            type="submit"
                            className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <section className=" py-10 space-y-5">
            {AssignmentData && AssignmentData.map((item, id) => (
              <div className='md:hidden block bg-white p-4 rounded-[10px]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Name</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-end'>{item && item.name ? item.name : null}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Tutor</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-end'>{item && item.tutorId && item.tutorId.name ? item.tutorId.name : "Admin"}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Parent</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-end'>{item?.parentId?.name ?? 'N/A'}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <ul className="flex gap-4 justify-end  items-center ">
                        <a href={item.assignmentPdf} target="_blank" className="font-medium hover:underline" rel="noreferrer" >
                          <li className="cursor-pointer"><BsDownload className="text-lg text-black" size={22} /></li>
                        </a>
                        <li className="cursor-pointer" onClick={() => handleAssignmentEdit(item)}><img src="/writing.png" alt="" /></li>
                        <li className="cursor-pointer" onClick={() => handleDeleteAssignment(item)}><img src="/delete.png" alt="" /></li>
                      </ul>
                    </div>
                  </div>
                </>
              </div>
            ))}
          </section>
        </div>
      </section>

    </>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  assignmentAction: () => dispatch(assignmentAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assignment);
