/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { boardAction } from "../../_actions/board.action";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineAdd, MdOutlineClose } from "react-icons/md";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";

import moment from "moment";
import Loader from "../../components/Loader";

const BoardComponent = ({ searchText, setRefresh }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [boardTotal, setBoardTotal] = useState()
  const [offset, setoffset] = useState(0);
  const [boardModal, setBoardModal] = useState(false);
  const [boardName, setBoardName] = useState("");
  const [editBoardModal, setEditBoardModal] = useState(false);
  const [newEditBoardName, setNewEditBoardName] = useState({});
  const [boardActionId, setBoardActionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;


  useEffect(() => {
    let boardData = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(boardAction.boardGet(boardData));
  }, [currentPage, searchText]);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const menuRef = useRef(null);

  useEffect(() => {
    setBoardTotal(selector?.Board?.bordList)
  }, [selector])
  const handleBoardAction = (item) => {
    const resData = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const EnaDis = { id: item._id };

    confirmAlert({
      title: `Confirm to ${item?.isDisable === false ? 'Inactive' : 'Active'}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(boardAction.EnaDisBoard(EnaDis, resData))

        },
        {
          label: 'No'
        }
      ]
    })
      ;
    setBoardName("");
  };

  const DeleteBoard = (id) => {
    const BoardList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const DeleteId = { id: id };

    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => { dispatch(boardAction.boardDelete(DeleteId, BoardList)); setRefresh(true) }

        },
        {
          label: 'No'
        }
      ]
    });
  };

  const boardCreateFunction = () => {
    setBoardModal(true);
  };

  const handleBoardForm = (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    const BoardList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const boardCreateName = {
      name: boardName,
      // sortName: sortName,
    };
    dispatch(boardAction.createBoard(boardCreateName, BoardList));
    handleClose()
    setRefresh(true)
  };

  const handleBoardEditForm = (data) => {
    setEditBoardModal(true);
    setNewEditBoardName(data);
  };

  const handleEditBoard = (event) => {
    setNewEditBoardName({ ...newEditBoardName, name: event.target.value });
  };

  // const menuRef = useRef(null);


  const mobileRef = useRef(null)


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setBoardActionId(null);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleBoardEditFormSubmit = () => {
    const BoardList = {
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    const editBoardData = {
      id: newEditBoardName._id,
      name: newEditBoardName.name,
    };
    dispatch(boardAction.updateBoard(editBoardData, BoardList));
    setEditBoardModal(false);
  };


  const handleBoardMenuAction = (cityId) => {
    setBoardActionId((prevCityId) => (prevCityId === cityId ? null : cityId));
  };

  const handleClose = () => {
    setBoardModal(false)
    setBoardName("")
    setErrorData('')
  }


  useEffect(() => {
    if (selector?.Board?.bordTotal) {
      setTotalPages(Math.ceil(selector?.Board?.bordTotal / pageSize));
    }
    // setPackageList(selector?.Package?.packageList?.data?.list)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const [errorData, setErrorData] = useState({})

  const handleValidation = () => {
    const errors = {};
    if (!boardName) {
      errors["name"] = "Board name is required";
    }
    // Add more validation checks as needed

    setErrorData(errors);

    return Object.keys(errors).length === 0;
  };


  return (
    <>
      <div className="cursor-pointer  flex text-center justify-end items-center  px-6 md:-mt-14 ">

        <span className="bg-white flex gap-1 place-items-center  border rounded-lg px-3 py-1 text-[#C60E6B] ">
          <MdOutlineAdd className="text-2xl " />
          <span
            className=" font-semibold py-1 rounded-lg"
            onClick={boardCreateFunction}
          >
            Add New
          </span>
        </span>
      </div>

      <Loader loading={selector?.Board?.loading} />

      <div className="m-5  ">

        <section className="lg:block hidden">
          <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
            <table className="min-w-full table-autorounded-xl overflow-x-auto">
              <thead className="border-b border-[#D1D1D1]">
                <tr className="text-black">
                  <th className="px-4 text-left py-3 whitespace-nowrap text-sm">S.No</th>
                  <th className="px-4 text-left py-3 whitespace-nowrap text-sm">Board Name</th>
                  <th className="px-4 text-left py-3 whitespace-nowrap text-sm">Status</th>
                  <th className="px-4 text-left py-3 whitespace-nowrap text-sm">Created At</th>
                  <th className="px-4  py-3 whitespace-nowrap text-sm">Action</th>
                </tr>
              </thead>
              <tbody className="overflow-scroll">
                {boardTotal &&
                  boardTotal?.list.length > 0 ? (
                  boardTotal?.list?.map((item, id) => (
                    <tr key={id} className="border-b capitalize">
                      <td className="px-4 py-4 text-sm text-gray-900 text-left">  {(currentPage - 1) * pageSize + id + 1}</td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-left">{item.name}</td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-left">
                        <span className={`whitespace-nowrap font-semibold cursor-pointer ${item.isDisable ? "text-red-600" : "text-green-700"}`}>
                          {item.isDisable ? "Inactive" : "Active"}
                        </span>
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-900 text-left">  {item && item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD ") : null}</td>

                      <td className="px-4 py-4 text-gray-900 relative">
                        <span className="flex justify-center items-center">
                          <BsThreeDotsVertical onClick={() => handleBoardMenuAction(item._id)} className="text-2xl cursor-pointer text-red-500 " />
                        </span>
                        {boardActionId && boardActionId === item._id && (
                          <div className="absolute right-32 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10" ref={menuRef}>
                            <ul className="text-[#023565]">
                              <li
                                className="flex justify-between items-center px-2 py-1 hover:text-[#E4006F] border-b cursor-pointer hover:bg-gray-100 rounded"
                                onClick={() => handleBoardAction(item)}
                              >
                                {item.isDisable ? "Active" : "Inactive"}
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </li>
                              <li
                                className="flex justify-between items-center px-2 py-1 hover:text-[#E4006F] border-b cursor-pointer hover:bg-gray-100 rounded"
                                onClick={() => handleBoardEditForm(item)}
                              >
                                View & Update
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </li>
                              <li
                                className="flex justify-between items-center px-2 py-1 cursor-pointer hover:text-[#E4006F] hover:bg-gray-100 rounded"
                                onClick={() => DeleteBoard(item._id)}
                              >
                                Delete
                                <FaAngleRight className="hover:text-[#C60E6B]" />                            </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-b text-center">
                    <td colSpan="6">Data Not Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
        {boardModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 my-6 mx-auto lg:w-[400px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center" onClick={handleClose}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-lg font-semibold">Create New Board</h3>
                  </div>
                  <div className="relative p-4">
                    <form onSubmit={handleBoardForm}>
                      <div className="">
                        <label
                          htmlFor="boardName"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          Board Name:
                        </label>
                        <input
                          type="text"
                          id="boardName"
                          value={boardName}
                          onChange={(e) => {
                            setBoardName(e.target.value);
                            if (errorData && errorData['name']) {
                              setErrorData((prevErrorData) => ({ ...prevErrorData, name: null }))
                            }
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          placeholder="Enter board name..."
                        />
                        {errorData && errorData["name"] ? <p className="text-red-500 mt-2">{errorData["name"]}</p> : null}
                      </div>

                      <div className="flex justify-center items-center pt-2">
                        <button
                          type="submit"
                          className="w-40 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Create
                        </button>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}


        {editBoardModal && (
          <>

            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[400px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={() => setEditBoardModal(false)}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Edit Board</h2>
                  </div>
                  <div className="relative">
                    <form className="space-y-4 w-full p-4">
                      <div className="">
                        <label
                          htmlFor="subjectName"
                          className="block text-sm font-medium text-gray-900"
                        >
                          Board Name:
                        </label>
                        <input
                          type="text"
                          id="editBoardName"
                          value={newEditBoardName.name}
                          onChange={handleEditBoard}
                          className="mt-1 p-2 border block w-full border-gray-300 rounded-md capitalize"
                        />
                      </div>
                      <div className="flex justify-between">
                        <button
                          type="button"
                          className="bg-[#01284E] text-white px-3 py-2 rounded-md w-full"
                          onClick={handleBoardEditFormSubmit}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}



        <section className="md:hidden block space-y-5 overflow-hidden overflow-y-auto">
          {boardTotal &&
            boardTotal?.list.length > 0 ? (
            boardTotal?.list?.map((item, id) => (
              <div className=' bg-white p-4 rounded-[10px]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>S.No</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>{offset + id + 1}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Subject</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item.name}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Status</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        <span
                          className={`font-semibold ${item.isDisable ? "text-red-600" : "text-[#00AFE2]"
                            }`}
                        >
                          {item.isDisable ? "Inactive" : "Active"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Create At</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item && item.createdAt
                          ? moment(item.createdAt).format("YYYY-MM-DD ")
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'>
                        <BsThreeDotsVertical
                          className="text-2xl cursor-pointer  text-[#C60E6B] relative"
                          onClick={() => handleBoardMenuAction(item._id)}
                        />
                        {boardActionId && boardActionId === item._id && (
                          <div
                            className="absolute  border py-1 px-2  bg-white  border-gray-300 rounded-lg shadow-lg z-10 "
                            ref={mobileRef}
                          >
                            <ul className="w-full">
                              <li className="flex justify-between items-center font-semibold text-[#C60E6B] border-b cursor-pointer hover:bg-gray-100 rounded">
                                <span
                                  className={`cursor-pointer ${item.isDisable ? "text-red-600" : "text-green-600"}`}
                                  onClick={() => handleBoardAction(item)}
                                >
                                  {item.isDisable ? "Activate" : "Deactivate"}
                                </span>
                                <img src="/Group 1000001172.png" alt="icon" />
                              </li>
                              <li className="flex justify-between place-items-center text-[#023565] border-b font-semibold cursor-pointer hover:bg-gray-100 p-1 rounded" onClick={() => handleBoardEditForm(item)}>
                                <div className="p">View & Update</div>
                                <img src="/Group 1000001230.png" alt="icon" />
                              </li>
                              <li className="flex justify-between items-center gap-3 cursor-pointer text-[#023565] font-semibold hover:bg-gray-100 p-1 rounded" onClick={() => DeleteBoard(item._id)}>
                                <div className="p">Remove</div>
                                <img src="/Group 1000001230.png" alt="icon" />
                              </li>
                            </ul>
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
            <div>
              <p className="text-center text-gray-500 py-4">
                Data Not Available
              </p>
            </div>
          )}
        </section>
        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2 px-4">
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black  rounded">
                {currentPage}
              </span>{" "}
              of{" "}
              <span className="border px-4 py-2 text-black  rounded">
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : ""
                }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default BoardComponent;
