/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationAction, uploadImageAction } from "../../_actions";
import { tutionAction } from "../../_actions";

import Loader from "../../components/Loader";
import ImageModel from "./Models/ImageModel";
import AddNotification from "./Models/AddNotification";
import MobileTable from "./Tables/MobileTable";
import FullTable from "./Tables/FullTable";
import { cityAction } from "../../_actions/city.action";





function ParentComponent({ setModal, modal, searchText, headerCity, dates }) {
  const dispatch = useDispatch();
  const Selector = useSelector(state => state);
  const [NotificationData, setNotificationData] = useState();
  const [imageData, setImage2Data] = useState();
  const [errors, setErrors] = useState({ title: '', message: '', selectedCities: '', message: '' });


  const [imageModal, setImageModal] = useState(false);
  const [selectedCityIds, setSelectedCityIds] = useState([]);
  const [title, setTitle] = useState("");
  const [message, setNewMessage] = useState("");
  const [imgeData, setImageData] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [options, setOptions] = useState();







  useEffect(() => {
    let notificationDetails = {
      userType: "PARENT",
      pageNo: currentPage,
      size: pageSize,
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : null,
      ...dates
    };

    dispatch(notificationAction.getNotificationListByAdmin(notificationDetails));

    dispatch(tutionAction.getAllCityList())
    dispatch(cityAction.cityGetList())

  }, [currentPage, dispatch, searchText, headerCity, dates]);


  useEffect(() => {
    if (Selector?.notificationList?.notificationList?.data?.total) {
      setTotalPages(Math.ceil(Selector?.notificationList?.notificationList?.data?.total / pageSize));
    }
    setNotificationData(Selector?.notificationList?.notificationList?.data?.list)
    setImage2Data(Selector?.ImageUpload?.fileDetails?.imageUrl)
    const cityList = Selector?.Citys?.cityGetList || [];
    const mappedCities = cityList.map(city => ({
      name: city.name,
      id: city._id
    }));
    const optionsWithSelectAll = [
      { name: 'Select All', id: 'Select All' },
      ...mappedCities

    ];
    setOptions(optionsWithSelectAll);
  }, [Selector]);



  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };



  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem.id === 'Select All') {
      // Select all cities
      const allCities = options.filter(option => option.id !== 'Select All');
      const allCityIds = allCities.map(city => city.id);

      setSelectedCities(allCities);
      handleCityChange(allCities);
      setSelectedCityIds(allCityIds);

      setErrors(prevErrors => ({ ...prevErrors, selectedCities: '' }));
    } else {
      const selectedCityIds = selectedList.map(city => city.id);

      setSelectedCities(selectedList);
      handleCityChange(selectedList);
      setSelectedCityIds(selectedCityIds);

      setErrors(prevErrors => ({ ...prevErrors, selectedCities: '' }));
    }
  };

  const onRemove = (selectedList, removedItem) => {
    if (removedItem.id === 'Select All') {
      // Clear all selected cities
      setSelectedCities([]);
      handleCityChange([]);
      setSelectedCityIds([]);

      setErrors(prevErrors => ({ ...prevErrors, selectedCities: '' }));
    } else {
      const selectedCityIds = selectedList.map(city => city.id);

      setSelectedCities(selectedList);
      handleCityChange(selectedList);
      setSelectedCityIds(selectedCityIds);

      setErrors(prevErrors => ({ ...prevErrors, selectedCities: '' }));
    }
  };


  const handleCityChange = (selectedList) => {

  };



  // const handleClose = () => {
  //   setModal(false);
  // };

  const [error, setError] = useState("")


  const handleUpload = (e) => {
    const file = e.target.files[0];
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];

    if (file && !validFileTypes.includes(file.type)) {
      setError('Invalid file type. Only PNG, JPEG, JPG, and SVG images are allowed.');
      e.target.value = null; // Clear the selected file
    } else {
      setError('');
      dispatch(uploadImageAction.UploadImage(file));
      setUploadedImage(file);
    }
  };



  const viewImage = (data) => {
    setImageModal(true);
    setImageData([data]);
  };

  const handleSubmitNotification = (e) => {
    e.preventDefault();

    let valid = true;
    let newErrors = { title: '', message: '', selectedCities: '' };
    if (!title) {
      newErrors.title = 'Title is required';
      valid = false;
    }
    if (!message) {
      newErrors.message = 'Message is required';
      valid = false;
    }
    if (selectedCities.length === 0) {
      newErrors.selectedCities = 'Cities are required';
      valid = false;
    }


    setErrors(newErrors);

    if (!valid) return;

    let notificationDetails = {
      userType: "PARENT",
      pageNo: currentPage,
      size: pageSize,
      keyWord: searchText,
      cityId: headerCity != "" ? [headerCity] : null,
      ...dates
    };

    let notificationData = {
      cityId: selectedCities.length > 0 ? selectedCities.map(city => city.id) : options.map(city => city.id).filter(ele => ele !== "Select All"),
      msg: message,
      title: title,
      image: imageData ?? null,
      userType: "PARENT"
    };

    dispatch(notificationAction.createCustomNotification(notificationData, notificationDetails));
    handleClose();
  };

  const handleClose = () => {
    setModal(false);
    setTitle("");
    setNewMessage("");
    setImageData("");
    setSelectedCities([]);
    setError('')


    setErrors((prevErrors) => ({
      ...prevErrors,
      title: '',
      message: '',
      selectedCities: '',

    }));
  };

  let { notificationList, loading } = Selector ? Selector : {};
  let { data } = notificationList && notificationList?.notificationList ? notificationList && notificationList?.notificationList : {};
  let { list, total } = data ? data : {};


  return (
    <section className="py-4 overflow-hidden overflow-y-auto ">
      <Loader loading={Selector?.notificationList?.loading} />
      <Loader loading={Selector?.ImageUpload?.loading} />

      <FullTable
        list={list}
        total={total}
        currentPage={currentPage}
        NotificationData={NotificationData}
        totalPages={totalPages}
        pageSize={pageSize}
        handlePreviousPage={(e) => handlePreviousPage(e)}
        handleNextPage={(e) => handleNextPage(e)}
        viewImage={(e) => viewImage(e)}
      />
      <AddNotification
        modal={modal}
        handleClose={(e) => handleClose(e)}
        handleSubmitNotification={(e) => handleSubmitNotification(e)}
        options={options}
        selectedCities={selectedCities}
        onSelect={(a, b) => onSelect(a, b)}
        onRemove={(a, b) => onRemove(a, b)}
        errors={errors}
        setTitle={(e) => setTitle(e)}
        title={title}
        setErrors={setErrors}
        setNewMessage={(e) => setNewMessage(e)}
        message={message}
        handleUpload={(e) => handleUpload(e)}
        error={error}
      />

      <ImageModel
        setImageModal={setImageModal}
        imgeData={imgeData}
        imageModal={imageModal}
      />
      <MobileTable
        NotificationData={NotificationData}
        viewImage={(e) => viewImage(e)}
        totalPages={totalPages}
        pageSize={pageSize}
        handlePreviousPage={(e) => handlePreviousPage(e)}
        handleNextPage={(e) => handleNextPage(e)}
      />

    </section>
  );
}

export default ParentComponent;
