/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronLeft, FaEye } from "react-icons/fa";
import { tutionAction, userListAction, userActions, employprofileAction, parentAction, alertActions } from "../../../_actions";
import { connect } from "react-redux";
import { FaAngleRight, FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CgAsterisk } from "react-icons/cg";
import {
  MdOutlineClose,

} from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { timeStanderFollowFunction, truncateText } from "../../../_helpers";
import StudentDetail from "./Models/StudentDetail";
import ImagePreviewModal from "../../Tutor-Profile/Model/ImagePreview";
import UpdateNoteModel from "./Models/UpdateNoteModel";
import UpdateTuitionModel from "./Models/UpdateTuitionModel";
import ViewParent from "./Models/ViewParent";
import LeadCreateModel from "./Models/LeadCreateModel";
import AssignTutorPopUp from "./Models/AssignTutorPopUp";
import { confirmAlert } from "react-confirm-alert";

const InActiveTable = React.memo((props) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let { newStateStatus, filters, headerCity, searchText } = props;

  let allTutorDataList =
    props && props?.getAllTutorList && props?.getAllTutorList?.data
      ? props?.getAllTutorList?.data
      : [];
  const navigate = useNavigate();

  const [demoRequestData, setdemoRequestData] = useState();
  const [suggestedTutor, setSuggestedTutor] = useState();
  const [open, setOpen] = useState(false);
  const [assignTutorModal, setAssignTutorModal] = useState(false);
  const [demoIdForAssign, setDemoIdForAssign] = useState({});
  const [activeateModal, setActiveateModal] = useState(false);
  const [activateData, setActivateData] = useState("");
  const [assignTutorTab, setAssignTutorTab] = useState("Suggested Tutors");
  const [inputChangeField, setInputChangeField] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 8;
  const [rejectResionModal, setRejectResionModal] = useState(false);
  const [rejectResion, setRejectResion] = useState("");
  const [rejectData, setRejectData] = useState({});
  const menuRef = useRef(null);

  const mobileRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setSuggestedTutor(selector?.tution?.suggestedTutorList?.data);
  }, [selector]);

  const [errorInputChangeField, setErrorInputChangeField] = useState({});
  let suggestTutorBtn = ["Suggested Tutors", "All Tutors"];

  useEffect(() => {
    if (props?.isStateMangerRes) {
      setActiveateModal(false);
      setAssignTutorModal(false);
      setInputChangeField({});
      setOpen(null);
      setErrorInputChangeField({});
    }
  }, [props]);

  useEffect(() => {
    let demoData = {
      status: [0],
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : "",
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],

    };
    let employeeDetails = {
      "keyWord": "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "desc",
      "sortBy": "createdAt",
      pageNo: 1,
      size: 100,
    };
    dispatch(employprofileAction.getSubAdminList(employeeDetails));
    dispatch(tutionAction.getDemoClassListStatus(demoData));
    newStateStatus("INACTIVE");
  }, [currentPage, filters, headerCity, searchText]);

  useEffect(() => {
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.tution?.demoList?.data?.total / pageSize)
      );
    }
    setdemoRequestData(() => selector?.tution?.demoList?.data?.list);

  }, [selector]);


  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const openAction = (itemId) => {
    setOpen((prevItemId) => (prevItemId === itemId ? null : itemId));
  };

  const handleActivate = (data) => {
    setActiveateModal(true);
    setActivateData(data);
    setInputChangeField(data)
    setOpen(false);
  };


  ///// Redirect the page--------------------------->

  const viewAndUpdate = (data) => {
    navigate(`/app/viewandupdate/${data?._id}/${data?.parentId?._id}`);
  };

  const assignTutor = (data) => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode === 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }));
    dispatch(tutionAction.getAllTutorList(query));
    setAssignTutorModal(true);
    setDemoIdForAssign(data._id);
    setOpen(false);
  };

  const handleAssignTutor = (item) => {
    setAssignTutorTab(item);
  };

  const handleRejectMsg = (data) => {
    setRejectResionModal(true);
    let acceptedData = {
      status: "REJECTED",
      demoId: data._id,
    };
    setRejectData(acceptedData);
  };
  const viewHistory = (data) => {
    navigate(`/app/viewhistory/${data?._id}`);
    setOpen(false);
  };
  const handleAssign = (data) => {
    let demoData = {
      status: [0],
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : "",
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],
    };
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data,
    };
    dispatch(tutionAction.assignTutorByAdmin(assignTutorInfo, demoData));
    dispatch(tutionAction.getDemoClassListStatus(demoData));


  };

  ///Create copy=========================>>>>>>>>>>>
  const handleCreateCopy = (data) => {
    let resData = {
      demoId: data._id,
    };
    let demoData = {
      status: [0],
      keyWord: searchText,
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : "",
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],
    };

    dispatch(tutionAction.createCopyByAdmin(resData, demoData));
    dispatch(tutionAction.getTuitionCount());


    setOpen(false);
  };

  ///Get all City api call========================>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const handleTabChangeInput = (event) => {
    const { value, name } = event.target;
    const numericValue = name === "classDuration" ? value.replace(/\D/g, "") : value;

    setInputChangeField((prevFields) => ({
      ...prevFields,
      [name]: numericValue,
    }));

    setErrorInputChangeField((prevFields) => ({
      ...prevFields,
      [name]: "",
    }));
  };

  const handleValidate = () => {
    let valid = true;
    let errorInputChangeField = {};
    if (inputChangeField && !inputChangeField.tuitionFee) {
      errorInputChangeField["tuitionFee"] = `Can't be empty tuition fee.`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.tutorFee) {
      errorInputChangeField["tutorFee"] = `Can't be empty tutor fee .`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.classDuration) {
      errorInputChangeField["classDuration"] = `Can't be empty class duration.`;
      valid = false;
    } else if (inputChangeField.classDuration <= 0) {
      errorInputChangeField["classDuration"] = `Please select valid class duration.`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.tuitionFee) {
      errorInputChangeField["tuitionMethod"] = `Can't be empty in tuition fee.`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.numberOfClasses) {
      errorInputChangeField["numberOfClasses"] = `Can't be empty in Session Details.`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.tuitionType) {
      errorInputChangeField["tuitionType"] = `Can't be empty in Fee Type.`;
      valid = false;
    }
    if (inputChangeField && !inputChangeField.teachingLocation && inputChangeField?.preferredTeachMode !== "ONLINE_TUITION") {
      errorInputChangeField[
        "teachingLocation"
      ] = `can't be empty teaching location.`;
      valid = false;
    }
    setErrorInputChangeField(errorInputChangeField);
    return valid;
  };
  const ApproveHandle = (data) => {
    if (data && data.status && data.status === "APPROVED") {
      if (handleValidate()) {
        const resData = {
          status: data && data.status,
          demoId: activateData && activateData._id ? activateData._id : null,
          tuitionFee:
            inputChangeField && inputChangeField.tuitionFee
              ? inputChangeField.tuitionFee
              : 0,

          classDuration:
            inputChangeField && inputChangeField.classDuration
              ? inputChangeField.classDuration
              : 0,
          inHandFees:
            inputChangeField && inputChangeField.tutorFee
              ? inputChangeField.tutorFee
              : null,
          tuitionType:
            inputChangeField && inputChangeField.tuitionType
              ? inputChangeField.tuitionType
              : 0,
          numberOfClasses:
            inputChangeField && inputChangeField.numberOfClasses
              ? inputChangeField.numberOfClasses
              : "Full Week",
          teachingLocation:
            inputChangeField && inputChangeField.teachingLocation
              ? inputChangeField.teachingLocation
              : null,
          remark:
            inputChangeField && inputChangeField.remark
              ? inputChangeField.remark
              : null,
          tuitionMethod:
            inputChangeField && inputChangeField.tuitionMethod
              ? inputChangeField.tuitionMethod
              : "Online",
          classReference:
            inputChangeField && inputChangeField?.classReference
              ? inputChangeField.classReference
              : null,
          assignToEmployee:
            inputChangeField && inputChangeField?.assignToEmployee && inputChangeField.assignToEmployee !== ""
              ? inputChangeField.assignToEmployee?._id || inputChangeField.assignToEmployee
              : null,
        };
        const demoData = {
          status: [0],
          keyWord: searchText,
          fromDate: "",
          toDate: "",
          sortOrder: "",
          sortBy: "",
          pageNo: currentPage,
          size: pageSize,
          ...filters,
          tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
          cityId: headerCity ? [headerCity] : "",
          parentId: (filters.parentId !== "") ? [filters.parentId] : [],
        };
        dispatch(
          tutionAction.updateStatusDemoApproveOrReject(resData, demoData)
        );


      }
    }
  };




  const [remarkModal, setRemarkModal] = useState(false);
  const [remarkId, setRemarkId] = useState("");
  const [remark, setRemark] = useState("");

  const addRemark = (data) => {
    let remarkId = data?._id;
    setRemarkId(remarkId);
    setRemarkModal(true);
    setRemark(data?.reason ?? "");
  };

  const handleRemarkSubmit = (e) => {
    e.preventDefault();
    let demoData = {
      status: [0],

      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : "",
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],
    };
    let resStatus = 'Hold'

    let remarkData = {
      demoId: remarkId,
      remark: remark ? remark : 'N/A',
    };
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData, resStatus));
    setRemarkModal(false);
    setRemark("");
  };

  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState("");
  const [tuitionDetails, setTuitionDetails] = useState({})

  const handleFullDetails = (data) => {
    userActions.getUserByPromiseId({ id: data.parentId._id }).then((res) => {
      setfullDetailsModal(true);
      setfullDetailsModalData(res?.data);
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        location: data?.location
      })
    })

  };

  const handleRejectResion = (e) => {
    e.preventDefault();

    let demoData = {
      status: [0],
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : "",
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],
    };

    let data = {
      ...rejectData,
      remark: rejectResion,
    };

    dispatch(tutionAction.updateStatusDemoApproveOrReject(data, demoData));
    setRejectResionModal(false);
    setRejectResion("");


    setOpen(false);
  };

  const handlerejctCloseModal = () => {
    setRejectResionModal(false);
    setRejectResion("");
  };





  useEffect(() => {
    let details = {
      cityId: "",
      userType: "PARENT",
      isDisable: false,
    };
    dispatch(userListAction.getUserTypeList(details));
  }, []);


  const handleActiveClose = () => {
    setActiveateModal(false)
    setErrorInputChangeField({})

  }

  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [studentListdata, setStudentListData] = useState();
  const viewStudent = (data, demoId) => {
    let studentListDetails = {
      parentId: data._id,
      demoId: demoId
    };
    parentAction.getStudentListByParentIdAndDemoIdPromise(studentListDetails).then((resData) => {
      setViewStudentModal(true);
      setStudentListData(resData.data)
    }).catch((err) => {
    });
  };


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState("")
  const handleCloseFunctionImageModel = (data) => {
    if (data) {
      setImage(() => data)
    }
    else {
      setImage("")
    }
    setIsModalVisible(!isModalVisible);
  };


  const [updateDemoModel, setUpdateDemoModel] = useState(false)
  const [inputChangeField2, setInputChangeField2] = useState({})
  const [allStudentList, setAllStudentData] = useState([])
  function updateDemoModelFunction(data) {
    tutionAction.getDemoByIdPromise({ demoId: data._id }).then((res) => {
      setUpdateDemoModel(true)
      let bookFreeDemo = [...res.data.bookFreeDemo]
      bookFreeDemo = bookFreeDemo.map(free => ({ ...free, studentId: free.studentId.studentFullName }))

      setInputChangeField2({
        ...res.data,
        bookFreeDemo: bookFreeDemo
      })
    })

    let studentListDetails = {
      parentId: data.parentId._id,
    };
    parentAction.getStudentListByParentIdForDemoPromise(studentListDetails).then((resData) => {
      setAllStudentData(resData.data)
    }).catch((err) => {
    });

  }

  const handleDeleteDemo = (data) => {

    const demoData = {
      status: [0],
      keyWord: searchText,
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : "",
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],
    };

    confirmAlert({
      title: "Confirm to delete?",
      message: "Are you sure to delete this lead.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            tutionAction.deleteDemoByAdminByPromise({ demoId: data._id }).then(() => {
              dispatch(alertActions.success("Lead successfully deleted."))
              dispatch(tutionAction.getDemoClassListStatus(demoData));
            }).catch(() => {
              dispatch(alertActions.error("Please try again later."))
              dispatch(tutionAction.getDemoClassListStatus(demoData));

            })
        },
        {
          label: "No",
        },
      ],
    });


  }





  return (
    <>
      <div className="h-screen  overflow-y-auto overflow-hidden overflow-x-auto">
        <Loader loading={selector?.tution?.loading} />

        <div className=" lg:block hidden ">
          <table className="min-w-full table-auto divide-y divide-gray-200 bg-white border border-[#D3D3D3] rounded-lg">
            <thead className="min-w-full rounded-sm">
              <tr className="table_head border-b ">
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Parent Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Student</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                  <tr className="flex justify-center items-center">{" "}Address</tr>
                  <tr className="flex justify-center items-center"> City</tr>
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                  <tr className="flex justify-center items-center">{" "}Teaching</tr>
                  <tr className="flex justify-center items-center">Location</tr>
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                  <tr className="flex justify-center items-center">{" "} Demo Date</tr>
                  <tr className="flex justify-center items-center"> Time</tr>
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                  <tr className="flex justify-center items-center">Preferred</tr>
                  <tr className="flex justify-center items-center">Gender</tr>
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                  <tr className="flex justify-center items-center">Lead Source</tr>
                  <tr className="flex justify-center items-center">Manager</tr>
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Mode</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm text-black">Note</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Action</th>
              </tr>
            </thead>
            <tbody className=" divide-y divide-gray-200">
              {demoRequestData && demoRequestData.length > 0 ? (
                demoRequestData.map((item, index) => (
                  <tr key={index} className="border-b  capitalize">
                    <td className="px-4 py-4 flex items-center gap-4 text-sm text-gray-900">
                      <div className="flex items-center justify-center gap-2">
                        <span onClick={item?.parentId?.image ? () => handleCloseFunctionImageModel(item?.parentId?.image) : null}>
                          {item?.parentId && item?.parentId?.image ?
                            <img
                              alt=""
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              onError={(e) => {
                                e.target.src =
                                  "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                              }}
                              className="rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                            />
                            : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                              <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                            </div>}
                        </span>
                        <div className="w-24">
                          <p className="font-semibold whitespace-nowrap text-[#023565]">
                            {item?.parentId?.name ?? "Not Available"}
                          </p>
                          <p className="text-gray-500 whitespace-nowrap">
                            {item?.parentId?.mobNo ?? "Not Available"}
                          </p>
                          <div className="flex items-center gap-1 cursor-pointer pt-1" onClick={() => handleFullDetails(item)} >
                            <p className="text-base font-normal cursor-pointer">More</p>
                            <FaChevronCircleRight
                              className="text-[#023565] text-base cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="px-4 py-4 text-sm">
                      <span className="flex items-center justify-center"><FaEye size={24} className="cursor-pointer text-[#023565] hover:text-[#E4006F]" onClick={() => viewStudent(item?.parentId, item._id)} /></span>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      <span className="flex justify-center items-center text-center cursor-pointer" title={item?.address || 'N/A'}>  {truncateText(item?.address, 15) ?? 'N/A'}</span>
                      <span className="flex justify-center items-center">{item.cityId?.name ?? "N/A"}</span>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item?.teachingLocation ? item?.teachingLocation : "Online Tuition"}</td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      <span className="text-center flex items-center justify-center">{item?.demoDate ? item?.demoDate : null}</span>
                      <span className="text-center flex items-center justify-center">{item?.demoTime ? timeStanderFollowFunction(item?.demoTime) : null}</span>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 capitalize">
                      <span className="flex items-center justify-center">{item?.requiredGender === 'MALE' ? 'Male' : item?.requiredGender === 'Male' ? 'Male' : item?.requiredGender === 'NO_PREFERENCE' ? 'No ' : 'Female'}</span>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      <span className="flex items-center justify-center">{item.leadSource === "CREATED_SELF" ? "App" : "Admin"}</span>
                      <span className="flex items-center justify-center">{item?.assignToEmployee ? item.assignToEmployee.userName : "Not Assigned"}</span>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item?.preferredTeachMode === "ONLINE_TUITION" ? "Online" : "In-Home"}</td>

                    <td className={`px-4 py-4 cursor-pointer font-semibold text-center ${item.reason ? "text-[#00AFE2]" : "text-[#023565]"}`} onClick={() => addRemark(item)}>
                      {item?.reason ? "View" : "Add"}
                    </td>


                    <td className="px-4 py-4 text-gray-900 relative table-cell">
                      <span className="flex justify-center items-center"> <BsThreeDotsVertical onClick={() => openAction(item._id)} className="text-2xl cursor-pointer text-red-500 " /></span>
                      {open === item._id && (
                        <ul className="bg-white border text-[#023565] rounded-md font-bold  absolute  w-48 right-20 -mt-10 -bottom-12 py-2 text-start px-2 text-sm shadow-lg z-50" ref={menuRef}>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleActivate(item)}>
                            <button className="w-full text-left">Activate</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => assignTutor(item)}>
                            <button className="w-full text-left">Assign Tutor</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => updateDemoModelFunction(item)}>
                            <button className="w-full text-left">View & Update Info</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => handleCreateCopy(item)}>
                            <button className="w-full text-left">Make A Copy</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => { handleDeleteDemo(item); }}>
                            <button className="w-full text-left">Delete</button>
                            <FaAngleRight className="text-md" />
                          </li>
                          <li className=" px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]" onClick={() => viewHistory(item)}>
                            <button className="w-full text-left">View History</button>
                            <FaAngleRight className="text-md" />
                          </li>
                        </ul>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>


        <UpdateTuitionModel
          handleBack={() => { setUpdateDemoModel(false); setInputChangeField2({}) }}
          updateDemoModel={updateDemoModel}
          inputChangeField={inputChangeField2}
          allStudentList={allStudentList}
          setInputChangeField={setInputChangeField2}
          listPayload={{
            status: [0], keyWord: searchText,
            fromDate: "",
            toDate: "",
            sortOrder: "",
            sortBy: "",
            pageNo: currentPage,
            size: pageSize,
            ...filters,
            tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
            cityId: headerCity ? [headerCity] : "",
          }}
        />


        <UpdateNoteModel
          remarkClose={() => setRemarkModal(false)}
          remarkModal={remarkModal}
          Heading={"Update Note"}
          remark={remark}
          setRemark={setRemark}
          handleRemarkSubmit={handleRemarkSubmit}
          btnName={`${remark !== "N/A" || remark ? "Update" : "Add"} `}
        />

        <ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />
        <ViewParent
          fullDetails={fullDetailsModal}
          setFullDetails={setfullDetailsModal}
          DetailsData={fullDetailsModalData}
          tuitionDetails={tuitionDetails}
        />
        <section className="space-y-5 lg:hidden block pb-10">
          {demoRequestData && demoRequestData.length > 0 ? (
            demoRequestData.map((item, index) => (
              <div key={index} className=" bg-white p-4 rounded-[10px] border border-[#023565]">
                <>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">
                        Parent Name
                      </p>
                    </div>
                    <div className="w-full flex items-start">
                      <div className="flex md:justify-start justify-center gap-2 w-[95%]">
                        <div onClick={item?.parentId?.image ? () => handleCloseFunctionImageModel(item?.parentId?.image) : null}>
                          {item?.parentId && item?.parentId?.image ?
                            <img
                              alt=""
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : ""
                              }
                              className="rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                            />
                            : <div className="bg-gray-300 rounded h-14 w-14 flex justify-center items-center">
                              <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                            </div>}
                        </div>
                        <div className="">
                          <p className="text-[12px] font-medium whitespace-nowrap">
                            {item?.parentId?.name ?? "Not Available"}
                          </p>
                          <p className="text-[12px] font-medium text-gray-500 whitespace-nowrap">
                            {item?.parentId?.mobNo ?? "Not Available"}
                          </p>
                          <div className="flex items-center  gap-3 cursor-pointer" >
                            <div className="flex items-center  gap-1" onClick={() => handleFullDetails(item)}>
                              <p className="text-[12px] font-medium cursor-pointer">More</p>
                              <FaChevronCircleRight
                                className="text-[#023565] text-base cursor-pointer" />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="relative w-[5%]">
                        <BsThreeDotsVertical
                          onClick={() => openAction(item._id)}
                          size={24}
                          className="text-2xl cursor-pointer text-red-500"
                        />
                        {open === item._id && (
                          <div
                            className="absolute right-0 mt-2 w-48 text-start rounded-md  text-[#023565] shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40"
                            ref={mobileRef}
                          >
                            <div
                              className="py-2 px-3 hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleActivate(item)}
                            >
                              <div className="flex justify-between items-center hover:text-[#E4006F] ">
                                Activate{" "}
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </div>
                            </div>
                            <div
                              className="py-2 px-3 text-[#E4006F] hover:text-[#E4006F] cursor-pointer"
                              onClick={() => assignTutor(item)}
                            >
                              <div className="flex justify-between items-center  ">
                                Assign Tutor{" "}
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </div>
                            </div>
                            <div
                              className="py-2 px-3  cursor-pointer flex justify-between hover:text-[#E4006F] "
                              onClick={() => viewAndUpdate(item)}
                            >
                              <button className="flex justify-between items-center gap-3">
                                View & Update Info
                              </button>
                              <div>
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </div>
                            </div>
                            <div
                              className="py-2 px-3  cursor-pointer hover:text-[#E4006F] "
                              onClick={() => handleCreateCopy(item)}
                            >
                              <div className="flex justify-between items-center">
                                Make A Copy{" "}
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </div>
                            </div>
                            <div
                              className="py-2 px-3  cursor-pointer flex justify-between hover:text-[#E4006F] "
                              onClick={() => {
                                handleRejectMsg(item);
                              }}
                            >
                              <button className="flex justify-between items-center">
                                Reject
                              </button>
                              <div>
                                <FaAngleRight className="hover:text-[#C60E6B]" />
                              </div>
                            </div>
                            <div
                              className="py-2 px-3 cursor-pointer relative flex justify-between hover:text-[#E4006F]"
                              onClick={() => viewHistory(item)}
                            >
                              <button className="flex justify-between items-center">
                                View History
                              </button>


                              <FaAngleRight />

                            </div>

                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">
                        Student Name
                      </p>
                    </div>
                    <div className="w-full">
                      <p className="text-[14px] font-[400]">
                        <FaEye size={24} className="  cursor-pointer  text-[#023565] hover:text-[#E4006F] " onClick={() => viewStudent(item?.parentId, item._id)} />
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">Address</p>
                    </div>
                    <div className="w-full">
                      <p className="text-[14px] font-[400] break-all" title={item?.address || 'N/A'}>
                        {/* {truncateText(item?.address, 100) ?? 'N/A'} */}
                        {item?.address || 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">City</p>
                    </div>
                    <div className="w-full">
                      <p className="text-[14px] font-[400]">
                        {item?.cityId?.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">
                        Teaching Location
                      </p>
                    </div>
                    <div className="w-full">
                      <p className="text-[14px] font-[400]">{item?.teachingLocation ? item?.teachingLocation : "---"}</p>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">Demo Date / Time</p>
                    </div>
                    <div className="w-full">
                      <p className="text-[14px] font-[400]">{item?.demoDate ? item?.demoDate : null} / {item?.demoTime ? timeStanderFollowFunction(item?.demoTime) : null}</p>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">
                        Preferred Gender
                      </p>
                    </div>
                    <div className="w-full">
                      <p className="text-[14px] font-[400]">{item?.requiredGender === 'MALE' ? 'Male' : item?.requiredGender === 'Male' ? 'Male' : item?.requiredGender === 'NO_PREFERENCE' ? 'No ' : 'Female'} </p>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">
                        Lead Source
                      </p>
                    </div>
                    <div className="w-full">
                      <p className="text-[14px] font-[400]">{item.leadSource === "CREATED_SELF" ? "App" : "Admin"} / {item?.assignToEmployee ? item.assignToEmployee.userName : "N/A"}</p>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">
                        Mode
                      </p>
                    </div>
                    <div className="w-full">
                      <p className="text-[14px] font-[400]">{item?.preferredTeachMode === "ONLINE_TUITION" ? "Online" : "In-Home"} </p>
                    </div>
                  </div>
                  <div className="flex justify-between p-2 w-full">
                    <div className="w-full">
                      <p className="text-[14px] font-[600] text-left">Note</p>
                    </div>
                    <div className="w-full">
                      <p
                        className={`text-[14px] font-[700] ${item.reason ? "text-[#00AFE2]" : "text-[#023565]"
                          }`}
                        onClick={() => addRemark(item)}
                      >
                        {item?.reason ? "View" : "Add"}
                      </p>
                    </div>
                  </div>

                </>
              </div>
            ))
          ) : (
            <p className="text-center">Data Not Found</p>
          )}
        </section>


        {totalPages > 1 && (
          <div className="flex justify-end items-center py-2 px-4">
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}

        {assignTutorModal && (
          <AssignTutorPopUp
            assignTutorModal={assignTutorModal}
            setAssignTutorModal={setAssignTutorModal}
            suggestTutorBtn={suggestTutorBtn}
            assignTutorTab={assignTutorTab}
            handleAssignTutor={handleAssignTutor}
            assignTutorList={null}
            allTutorDataList={allTutorDataList}
            handleAssign={handleAssign}
            suggestedTutor={suggestedTutor}
          />
        )}

        <StudentDetail
          setViewStudentModal={setViewStudentModal} viewStudentModal={viewStudentModal} setStudentListData={setStudentListData} studentListdata={studentListdata}
        />

        {rejectResionModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={handlerejctCloseModal}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{" "}
                  Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Reason</h2>
                  </div>
                  <div className="relative  overflow-y-auto">
                    <form onSubmit={handleRejectResion} className="p-4">
                      <div className="mb-4">
                        <label
                          htmlFor="skipResion"
                          className="block text-gray-700 font-bold mb-2"
                        >
                          Reason for Reject
                        </label>
                        <input
                          type="text"
                          id="skipResion"
                          value={rejectResion}
                          onChange={(e) => setRejectResion(e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded-md"
                          placeholder="Enter reason"
                        />
                      </div>
                      <div className="flex justify-center space-x-2">
                        <button
                          type="submit"
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {activeateModal && (
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[700px] ">
              <button
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                onClick={handleActiveClose}
              >
                <MdOutlineClose />
                Close
              </button>

              <div className="form_class rounded-[10px] shadow-lg relative flex justify-between place-content-center place-items-center flex-col w-full bg-white outline-none focus:outline-none border-2 p-4">
                {activateData && (
                  <>
                    <ul className="space-y-2 grid grid-cols-2 gap-3 items-center w-full p-2">
                      <li className=" mt-4">
                        <label
                          htmlFor="parentName"
                          className="text-[12px] font-[700]  text-left text-[#313131]"
                        >
                          <div className="flex">Teaching Location {inputChangeField?.preferredTeachMode === "ONLINE_TUITION" ? <span className="ml-1 text-red-500">(Class is Online)</span> : <CgAsterisk color="red" />}:</div>
                        </label>
                        <div>
                          <input
                            type="text"
                            name="teachingLocation"
                            id="teachingLocation"
                            value={
                              inputChangeField &&
                                inputChangeField.teachingLocation
                                ? inputChangeField.teachingLocation
                                : ""
                            }
                            onChange={(e) => handleTabChangeInput(e)}
                            placeholder="Enter teaching location"
                            className="mt-1 border p-2  block w-full border-gray-300 rounded-md"
                            disabled={inputChangeField?.preferredTeachMode === "ONLINE_TUITION" ? true : false}
                          />
                          {errorInputChangeField &&
                            errorInputChangeField["teachingLocation"] && (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["teachingLocation"]}
                              </div>
                            )}
                        </div>
                      </li>

                      <li className="">
                        <label
                          htmlFor="classDuration"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          <div className="flex">Class Duration (in min) <CgAsterisk color="red" />:</div>
                        </label>
                        <div>
                          <input
                            name="classDuration"
                            id="classDuration"
                            value={
                              inputChangeField && inputChangeField.classDuration
                                ? inputChangeField.classDuration
                                : ""
                            }
                            onChange={(e) => handleTabChangeInput(e)}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          />
                          {errorInputChangeField &&
                            errorInputChangeField["classDuration"] && (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["classDuration"]}
                              </div>
                            )}
                        </div>
                      </li>
                      <li className="">
                        <label
                          htmlFor="numberOfClasses"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          <div className="flex">Session Details <CgAsterisk color="red" />:</div>
                        </label>
                        <div>
                          <select
                            name="numberOfClasses"
                            id="numberOfClasses"
                            value={inputChangeField && inputChangeField.numberOfClasses}
                            onChange={(e) => handleTabChangeInput(e)}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          >
                            <option value="" >
                              Select Session
                            </option>
                            <option value="Full Week">Full Week</option>
                            <option value="Weekly 6 Days">Weekly 6 Days</option>
                            <option value="Weekly 5 Days">Weekly 5 Days</option>
                            <option value="Weekly 4 Days">Weekly 4 Days</option>
                            <option value="Weekly 3 Days">Weekly 3 Days</option>
                            <option value="Weekly 2 Days">Weekly 2 Days</option>
                            <option value="Once in a Week">
                              Once in a Week
                            </option>
                          </select>
                          {errorInputChangeField &&
                            errorInputChangeField["numberOfClasses"] && (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["numberOfClasses"]}
                              </div>
                            )}
                        </div>
                      </li>

                      <li className="">
                        <label
                          htmlFor="tuitionType"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          <div className="flex">Fee Type <CgAsterisk color="red" />:</div>
                        </label>
                        <div>
                          <select
                            name="tuitionType"
                            id="tuitionType"
                            value={
                              inputChangeField && inputChangeField.tuitionType
                                ? inputChangeField.tuitionType
                                : ""
                            }
                            onChange={(e) => handleTabChangeInput(e)}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          >
                            <option value="">Select Type</option>
                            <option value="Per Month">Per Month</option>
                            <option value="For Course">For Course</option>
                            <option value="Per Class">Per Class</option>
                          </select>
                          {errorInputChangeField &&
                            errorInputChangeField["tuitionType"] && (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["tuitionType"]}
                              </div>
                            )}
                        </div>
                      </li>
                      <li className="">
                        <label
                          htmlFor="tuitionFee"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >

                          <div className="flex">Tuition Fee <CgAsterisk color="red" />:</div>
                        </label>
                        <div>
                          <input
                            name="tuitionFee"
                            type="number"
                            id="tuitionFee"
                            required
                            value={
                              inputChangeField && inputChangeField?.tuitionFee
                                ? inputChangeField.tuitionFee
                                : ""
                            }
                            onChange={(e) => handleTabChangeInput(e)}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          />
                          {errorInputChangeField &&
                            errorInputChangeField["tuitionFee"] && (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["tuitionFee"]}
                              </div>
                            )}
                        </div>
                      </li>

                      <li className="">
                        <label
                          htmlFor="parentName"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >

                          <div className="flex">Tutor Fee<CgAsterisk color="red" />:</div>
                        </label>
                        <div>
                          <input
                            type="number"
                            name="tutorFee"
                            id="tutorFee"
                            value={
                              inputChangeField && inputChangeField?.tutorFee
                                ? inputChangeField.tutorFee
                                : ""
                            }
                            onChange={(e) => handleTabChangeInput(e)}
                            placeholder="Enter tutor fee"
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          />
                          {errorInputChangeField &&
                            errorInputChangeField["tutorFee"] && (
                              <div className="text-xs text-red-600 invalid-feedback">
                                {errorInputChangeField["tutorFee"]}
                              </div>
                            )}
                        </div>
                      </li>
                      <li className="">
                        <label
                          htmlFor="parentName"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >
                          {" "}
                          Remark (Optional):
                        </label>
                        <div>
                          <input
                            type="text"
                            name="remark"
                            id="remark"
                            value={
                              inputChangeField && inputChangeField.remark
                                ? inputChangeField.remark
                                : ""
                            }
                            onChange={(e) => handleTabChangeInput(e)}
                            placeholder="Enter remark"
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          />
                        </div>
                      </li>
                      <li className="">
                        <label
                          htmlFor="classReference"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >

                          <div className="flex">Class Reference:</div>
                        </label>
                        <div>
                          <input
                            type="text"
                            name="classReference"
                            id="classReference"
                            value={
                              inputChangeField && inputChangeField?.classReference
                                ? inputChangeField.classReference
                                : ""
                            }
                            onChange={(e) => (!/^\d+$/.test(e.target.value)) ? handleTabChangeInput(e) : {}}
                            placeholder="Enter class reference"
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                          />

                        </div>
                      </li>
                      <li className="">
                        <label
                          htmlFor="assignToEmployee"
                          className="text-[12px] font-[700] text-left text-[#313131]"
                        >

                          <div className="flex">Lead Manager:</div>
                        </label>
                        <div>
                          <select
                            name="assignToEmployee"
                            id="assignToEmployee"
                            value={
                              inputChangeField && inputChangeField.assignToEmployee
                                ? inputChangeField?.assignToEmployee?._id
                                : ""
                            }
                            onChange={(e) => handleTabChangeInput(e)}
                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md capitalize"
                          >
                            <option value="">Select lead manager</option>
                            {selector?.tution?.getAllLeadManger?.data?.filter(lead => lead.city.includes(inputChangeField.cityId._id))?.map((lead, index) => (
                              <option key={index} value={lead._id}>
                                {lead.userName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </li>

                    </ul>
                    <div className="flex justify-center items-center py-4">
                      <button
                        className="text-white border rounded-full px-6 py-2 bg-[#023565] hover:bg-[#4e7aa3]"
                        onClick={() => ApproveHandle({ status: "APPROVED" })}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <LeadCreateModel closeLeadModal={() => props.setCreateLeadModal(!props.createModal)} createModal={props.createModal} />
      </div>


    </>
  );
});


const mapStateToProps = (state) => ({
  isStateMangerRes: state.tution.isStateMangerRes,
  suggestedTutorList: state.tution.suggestedTutorList,
  getAllTutorList: state.tution.getAllTutorList,
  getPincodeList: state.tution.getPincodeList,
});

const mapDispatchToProps = (dispatch) => ({
  tutionAction: () => dispatch(tutionAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InActiveTable);